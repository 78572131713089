import { all } from 'redux-saga/effects';
import userSagaWatcher from './user/saga';
import companySagaWatcher from './company/saga';
import investorSagaWatcher from './investor/saga';
import dashboardSaga from './dashboard/saga';
import dashboardOverviewSaga from './dashboard/overview/saga';
import creditBureauSaga from './dashboard/creditBureau/saga';
import auditedFinancialsSaga from './dashboard/auditedFinancials/saga';
import authSagaV2 from './user/userV2/authSaga';

export default function* rootSaga() {
    yield all([
        userSagaWatcher(),
        companySagaWatcher(),
        investorSagaWatcher(),
        dashboardSaga(),
        dashboardOverviewSaga(),
        creditBureauSaga(),
        auditedFinancialsSaga(),
        authSagaV2(),
        // dashboardSagaWatcher(),
        // financialsSagaWatcher(),
        // benchmarkingSagaWatcher(),
        // classificationsSagaWatcher(),
        // dataVaultSagaWatcher(),
        // forecastsSagsWatcher(),
    ]);
}
