import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loginForSummonAicaV2 } from '../../store/user/userV2/actions';
import { useAppSelector } from '../../app/hooks';
import { USER_TYPE } from '../../utils/constants/user';
import { Player } from '@lottiefiles/react-lottie-player';
import { summonAicaJson } from '../../assets/hostedassets';

const SummonAica = () => {
    const dispatch = useDispatch();
    const [params, setParams] = useSearchParams();
    const domainMetadata = useAppSelector((state) => state.user.domainMetadata);

    const summonAica = async () => {
        dispatch(
            loginForSummonAicaV2({
                ssoOrgId: params.get('orgId') || '',
                apiPayload: {
                    user: {
                        authToken: params.get('token') || '',
                        investorId: domainMetadata?.investorOrgId || '',
                        accountType: USER_TYPE.INVESTOR,
                        accountTypes: [
                            USER_TYPE.INVESTOR,
                            USER_TYPE.PARTNER,
                            USER_TYPE.ADMIN_TEAM,
                            USER_TYPE.LENDER,
                        ],
                    },
                    platformId: domainMetadata?.productName || '',
                    loginType: 'TOKEN',
                    source: params.get('source') || '',
                },
            }),
        );
        localStorage.setItem('isDSInvestor', params.get('isDSInvestor') || 'false');
    };
    useEffect(() => {
        if (domainMetadata) summonAica();
    }, [domainMetadata]);
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                width: '100%',
                background: 'white',
            }}
        >
            <Player
                src={summonAicaJson}
                autoplay
                loop
                speed={2}
                style={{
                    width: '50%',
                }}
            />
            <h1>Summoning AICA...</h1>
        </div>
    );
};
export default SummonAica;
