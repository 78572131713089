import { hotjar } from 'react-hotjar';
import { getDomain, is_prod } from './utils';

export function hotjarIdentify(userId = '', organizationId = '', isInvestor = false) {
    if (is_prod) {
        hotjar.identify(userId, {
            Organization_id: organizationId,
        });
        console.info(
            `hotjar identification successful for ${isInvestor ? 'investor' : 'assessee'}`,
        );
    }
}

export function hotjarInitialize() {
    const domain = getDomain();
    if (is_prod) {
        if (domain?.includes('aicatech')) {
            hotjar.initialize({ id: 5015655, sv: 6 });
            if (hotjar.initialized()) console.info('hotjar initialised with tracking', 5015655);
        } else {
            hotjar.initialize({ id: 3765502, sv: 6 });
            if (hotjar.initialized()) console.info('hotjar initialised with tracking', 3765502);
        }
    }
}
