import React, { useRef, useState } from 'react';
import styles from './ThreeDotMenu.module.scss';
import { isEmpty } from '../../utils/utils';
import clsx from 'clsx';
import { useOutsideClick } from '../../utils/constants/hooks';

type ThreeDotMenuProps = {
    menuItems: {
        name: string;
        onClick: Function;
        className?: string;
    }[];
    color?: React.CSSProperties['color'];
    tableName?: string;
};

const ThreeDotMenu = (props: ThreeDotMenuProps) => {
    const { menuItems, color } = props;
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);
    const parentRef = useRef(null);

    const toggleMenu = () => {
        setIsOpen((prevState) => !prevState);
    };

    useOutsideClick(parentRef, handleClickOutside);

    function handleClickOutside(event: MouseEvent) {
        setIsOpen(false);
    }

    if (isEmpty(props.menuItems)) return <></>;

    return (
        <div className={styles.threeDotMenu} ref={parentRef}>
            <div className={styles.icon} onClick={toggleMenu} style={{ color: color ?? undefined }}>
                &#x22EE; {/* This is the vertical three-dot icon */}
            </div>
            {isOpen && (
                <div
                    className={clsx(
                        {
                            [styles.ManageEligibilityMenu]:
                                props.tableName === 'ManageEligibilityTable',
                        },
                        styles.menu,
                    )}
                    ref={menuRef}
                >
                    <ul>
                        {menuItems.map((item, index) => (
                            <li
                                key={index}
                                onClick={() => {
                                    item.onClick();
                                    setIsOpen(false);
                                }}
                                className={item.className === 'Delete' ? styles.Delete : undefined}
                            >
                                {item.name}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default ThreeDotMenu;
