import styles from './FormSideStepper.module.scss';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

type FormSideStepperProps = {
    stepperConfig: {
        text: string;
        navigation: 'UNLOCKED' | 'LOCKED';
        isViewOnly: boolean;
    }[];
    onStepClick: (index: number) => void;
    statusTrigger: boolean;
    step: number;
    isViewOnly: boolean;
    checkCompleteForm: boolean;
};

const FormSideStepper = (props: FormSideStepperProps) => {
    const { stepperConfig, onStepClick, statusTrigger, step, isViewOnly } = props;
    const [activeStep, setActiveStep] = useState(1);
    const [steppers, setSteppers] = useState<
        {
            text: string;
            navigation: 'UNLOCKED' | 'LOCKED';
            isViewOnly: boolean;
        }[]
    >([]);
    const [stepStatus, setStepStatus] = useState<string[]>([]);

    useEffect(() => {
        if (isViewOnly) {
            setSteppers(stepperConfig.filter((step) => step.isViewOnly));
        } else {
            setSteppers(stepperConfig);
        }
    }, [isViewOnly, stepperConfig]);

    useEffect(() => {
        setActiveStep(step);
    }, [step]);

    const handleStepStatus = () => {
        let newStepStatus = [...stepStatus];
        if (props.checkCompleteForm) {
            newStepStatus = steppers.map(() => 'UNLOCKED');
        }
        if (isViewOnly) {
            newStepStatus = steppers.map(() => 'UNLOCKED');
        } else {
            if (statusTrigger) {
                newStepStatus[step] = 'UNLOCKED';
            } else {
                // Lock all the steps after the current step
                for (let i = step; i < newStepStatus.length; i++) {
                    newStepStatus[i] = 'LOCKED';
                }
            }
        }
        setStepStatus(newStepStatus);
    };

    useEffect(() => {
        // Initialize stepStatus based on stepperConfig initially
        if (stepStatus.length === 0) {
            setStepStatus(steppers.map((step) => step.navigation));
        } else {
            handleStepStatus();
        }
    }, [statusTrigger, step, steppers]);

    return (
        <div className={styles.FormStepperWrapper}>
            <div className={styles.StepperContianer}>
                {steppers.map((step, index) => {
                    return (
                        <div
                            onClick={() => {
                                if (stepStatus[index] === 'UNLOCKED') {
                                    setActiveStep(index + 1);
                                    onStepClick(index + 1);
                                }
                            }}
                            key={index}
                            className={clsx({
                                [styles.StepperItem]: stepStatus[index] === 'UNLOCKED',
                                [styles.StepperItemActive]: activeStep === index + 1,
                                [styles.StepperItemDisabled]: stepStatus[index] === 'LOCKED',
                            })}
                        >
                            <div className={styles.StepperText}>
                                <span>{index + 1}. </span>
                                <span>{step.text}</span>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default FormSideStepper;
