import React from 'react';

const FileUploadIcon = ({ colour = '#2F8FFF', width = '20', height = '20' }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 27 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="Frame 2" clipPath="url(#clip0_11371_197581)">
                <g id="Group">
                    <path
                        id="Path_360-2"
                        d="M25.5393 10.7733C24.7375 9.94852 23.6814 9.42181 22.5437 9.27926C22.5538 9.10652 22.5589 8.93442 22.5589 8.7636C22.5596 4.21619 19.1037 0.424025 14.6021 0.0328786C10.1006 -0.358268 6.04996 2.78163 5.27632 7.26189C2.09039 7.70286 -0.210163 10.5545 0.0152334 13.7832C0.24063 17.0119 2.91476 19.5117 6.13066 19.5C6.20539 19.5 6.28329 19.5 6.37322 19.4942C6.44479 19.498 6.51255 19.5 6.57968 19.5H10.1402C10.4209 19.5 10.6484 19.2709 10.6484 18.9884C10.6484 18.7059 10.4209 18.4769 10.1402 18.4769H6.57968C6.52395 18.4769 6.46695 18.4769 6.40425 18.4718H6.37892H6.35359C6.26809 18.4757 6.19716 18.4776 6.13003 18.4776C3.38879 18.4804 1.13741 16.2984 1.03692 13.5414C0.936436 10.7843 3.02293 8.44211 5.75701 8.24284L6.17309 8.21289L6.22503 7.79666C6.73682 3.74618 10.2873 0.79279 14.3362 1.04957C18.3852 1.30636 21.5392 4.68496 21.5418 8.76806C21.5416 9.07802 21.5232 9.3877 21.4867 9.69548L21.4202 10.257L21.9819 10.2679C24.1717 10.3738 25.8867 12.2028 25.8651 14.4091C25.8435 16.6155 24.093 18.4101 21.9015 18.4725C21.804 18.4725 21.7033 18.4686 21.6007 18.461H21.562H21.5234C21.429 18.4686 21.3473 18.4718 21.2701 18.4718H17.6393C17.3586 18.4718 17.131 18.7008 17.131 18.9833C17.131 19.2658 17.3586 19.4949 17.6393 19.4949H21.2751C21.3657 19.4949 21.462 19.491 21.5671 19.4834C21.6811 19.491 21.7938 19.4949 21.9034 19.4949C23.9564 19.4949 25.809 18.2552 26.6031 16.3498C27.3973 14.4444 26.9779 12.2455 25.5393 10.7714V10.7733Z"
                        fill={colour}
                        stroke={colour}
                        strokeWidth="0.5"
                    />
                </g>
                <g id="Group_2">
                    <path
                        id="Path_361-2"
                        d="M14.2503 10.5L10.6367 14.3227C10.4544 14.5156 10.4544 14.8283 10.6367 15.0211C10.8191 15.214 11.1147 15.214 11.297 15.0211L13.7832 12.391V25.0062C13.7832 25.2789 13.9922 25.5 14.25 25.5C14.5078 25.5 14.7168 25.2789 14.7168 25.0062V12.391L17.203 15.0211C17.3853 15.214 17.6809 15.214 17.8633 15.0211C18.0456 14.8283 18.0456 14.5156 17.8633 14.3227L14.2503 10.5Z"
                        fill={colour}
                        stroke={colour}
                        strokeWidth="0.5"
                    />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_11371_197581">
                    <rect width="18" height="17" fill="white" transform="scale(1.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default FileUploadIcon;
