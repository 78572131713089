/**
 * @Description Component to apply interceptor to all requests and responses made using axios
 */

import React from 'react';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { applyInterceptor } from './utils/axios-interceptor';
import { useAppDispatch } from './app/hooks';
import { setUserData } from './store/user/actions';

function ApplyInterceptor() {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const orgId = searchParams.get('orgId');
    const userId = searchParams.get('userId');
    const token = searchParams.get('token');
    const userEmail = searchParams.get('userEmail');

    React.useEffect(() => {
        let data = sessionStorage.getItem('user_data');
        if (!data) {
            data = localStorage.getItem('user_data');
        }
        const userData = data ? JSON.parse(data) : null;
        userData && dispatch(setUserData(userData));
    }, []);

    React.useEffect(() => {
        applyInterceptor(axios, navigate);
    }, [navigate]);

    return <></>;
}

export default ApplyInterceptor;
