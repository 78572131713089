import React, { useEffect, useRef, useState } from 'react';
import useContextMenu from '../../utils/customHooks/useContextMenu.js';
import Icon from '../../common/Icon.jsx';
import { TableUtil } from '../../utils/table.js';
import { Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import styles from './TableContextMenu.module.scss';
import { useOutsideClick } from '../../../../utils/constants/hooks';

const TableContextMenu = (props) => {
    const { editor } = props;
    const [selection, setSelection] = useState();
    const [showMenu, setShowMenu, { top, left }] = useContextMenu(
        editor,
        ['table', 'tableWithoutHeaders'],
        setSelection,
    );
    const table = new TableUtil(editor);
    const tableContextRef = useRef(null);

    useOutsideClick(tableContextRef, () => {
        setShowMenu(false);
    });

    const menu = [
        {
            icon: 'insertColumnRight',
            text: 'Insert Columns to the Right',
            action: {
                type: 'insertColumn',
                position: 'after',
            },
        },
        {
            icon: 'insertColumnLeft',
            text: 'Insert Columns to the Left',
            action: {
                type: 'insertColumn',
                position: 'at',
            },
        },
        {
            icon: 'insertRowAbove',
            text: 'Insert Row Above',
            action: {
                type: 'insertRow',
                positon: 'at',
            },
        },
        {
            icon: 'insertRowBelow',
            text: 'Insert Row Below',
            action: {
                type: 'insertRow',
                position: 'after',
            },
        },
        {
            icon: 'trashCan',
            text: 'Remove Table',
            action: {
                type: 'remove',
            },
        },
    ];

    const handleInsert = ({ type, position }) => {
        Transforms.select(editor, selection);
        switch (type) {
            case 'insertRow':
                table.insertRow(position);
                break;
            case 'insertColumn':
                table.insertColumn(position);
                break;
            case 'remove':
                table.removeTable();
                break;
            default:
                return;
        }
        ReactEditor.focus(editor);
    };

    return (
        showMenu && (
            <div className={styles.ContextMenu} style={{ top, left }} ref={tableContextRef}>
                {menu.map(({ icon, text, action }, index) => (
                    <div
                        className={styles.MenuOption}
                        key={index}
                        onClick={() => handleInsert(action)}
                    >
                        {/* <Icon icon={icon} /> */}
                        <span>{text}</span>
                    </div>
                ))}
            </div>
        )
    );
};

export default TableContextMenu;
