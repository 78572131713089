import React from 'react';
import editorStyles from '../TextEditor.module.scss';
import clsx from 'clsx';

const Button = (props) => {
    const { children, format, active, className, ...rest } = props;
    return (
        <button
            type="button"
            className={clsx(editorStyles.Button, className, { [editorStyles.btnActive]: active })}
            title={format}
            {...rest}
            // style={{ width: '30px', height: '20px', margin: '0 2px' }}
        >
            {children}
        </button>
    );
};

export default Button;
