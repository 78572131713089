import styles from './PartnerAdditionalDetails.module.scss';
import { useEffect, useState } from 'react';
import LoadingSpinner from '../../../../../common/_custom/LoadingSpinner/LoadingSpinner';
import { ORG_MASTER_STATUS } from '../../../../../utils/constants/user';
import { INVESTOR_ROUTES } from '../../../../../utils/constants/routesConst';
import { useNavigate } from 'react-router-dom';
import {
    EditUser,
    getCityNames,
    getPartnetConfigDataAndSetFormData,
} from '../../../../../store/investor/apiHelpers';
import { Button, Input } from '../../../../../common/_custom';
import Dropdown from '../../../../../common/_custom/Dropdown';
import Form from '../../../../../common/_custom/Form/Form';
import { toast } from 'react-toastify';
import { useAppSelector } from '../../../../../app/hooks';
import { useDispatch } from 'react-redux';
import { Mixpanel } from '../../../../../utils/mixpanel';
import { AICA_PARTNERS_SUBMIT_DETAILS } from '../../../../../utils/constants/mixpanelEvents/investorEvents';
import { getPlatformUserId } from '../../../../../utils/utils';

interface DropdownOption {
    id: number | string;
    label: string;
}

interface AdditionalDetailsConfig {
    [key: string]: {
        type: 'dropdown' | 'text' | 'number';
        isRequired: boolean;
        placeholder?: string;
        dropdownOptions?: DropdownOption[];
    };
}

interface TermsAndConditionConfig {
    privacyPolicy: string;
    termsAndCondition: string;
}

const PartnerAdditionalDetails = () => {
    const [fetchingConfig, setIsFetchingConfig] = useState(true);
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({});
    const user = useAppSelector((state) => state.user);
    const userData = user.userData;
    const [configData, setConfigData] = useState<AdditionalDetailsConfig>({});
    const [termsAndPolicyConfig, setTermsAndPolicyConfig] = useState<TermsAndConditionConfig>();
    const [cityDropdownOptions, setCityDropdownOptions] = useState<DropdownOption[]>([]);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const initialiseFormAndErrorsFromConfig = (additionalDetailsConfig = {}) => {
        const formDataObject = Object.entries(additionalDetailsConfig).reduce(
            (formDataObj, [fieldName, fieldConfig]: any) => {
                formDataObj = {
                    ...formDataObj,
                    [fieldName]: fieldConfig?.type === 'dropdown' ? null : '',
                };
                return formDataObj;
            },
            {},
        );
        const errorsObject = Object.entries(additionalDetailsConfig).reduce(
            (errorsObj, [fieldName]: any) => {
                errorsObj = {
                    ...errorsObj,
                    [fieldName]: false,
                };
                return errorsObj;
            },
            {},
        );
        setFormData(formDataObject);
        setErrors(errorsObject);
        setIsFetchingConfig(false);
    };

    const changeValue = (val, fieldName) => {
        setErrors((errorsObj) => ({
            ...errorsObj,
            [fieldName]: false,
        }));
        setFormData((prevData) => ({
            ...prevData,
            [fieldName]: typeof val === 'string' ? val?.trim() : val,
        }));
    };
    useEffect(() => {
        cityOptions();
    }, []);
    const renderFormField = (fieldName, fieldConfig, index) => {
        const { type, placeholder, isRequired, dropdownOptions } = fieldConfig;
        const cityDropdownOption = cityDropdownOptions;
        switch (type) {
            case 'text':
            case 'number':
                return (
                    <Input
                        type={type}
                        key={fieldName}
                        placeholder={`${placeholder}` + (isRequired ? '' : ' (Optional)')}
                        value={formData?.[fieldName] || ''}
                        onChange={(val) => changeValue(val, fieldName)}
                        errorMessage={'This field is required'}
                        isValid={!errors[fieldName]}
                        style={{ marginTop: '35px' }}
                    />
                );
            case 'dropdown':
                return (
                    <Dropdown
                        enableSearch={fieldName === 'city'}
                        selectedOption={formData?.[fieldName]}
                        optionsList={
                            fieldName === 'city' ? cityDropdownOption : dropdownOptions ?? []
                        }
                        itemClickHandler={(selectedItem: DropdownOption) =>
                            changeValue(selectedItem, fieldName)
                        }
                        style={{ marginTop: '35px', zIndex: 100 - index }}
                        label={placeholder}
                        placeholder={`${placeholder}` + (isRequired ? '' : ' (Optional)')}
                        hasError={errors[fieldName]}
                        errorMessage={'This field is required'}
                    />
                );
            default:
                return null;
        }
    };
    const cityOptions = async () => {
        const res = await getCityNames();
        if (res?.error) {
            toast.error(res.msg ?? 'Failed to fetchCityNames');
        } else {
            setCityDropdownOptions(res?.data?.map((city) => ({ id: city, label: city })));
        }
    };

    useEffect(() => {
        if (userData?.applicationStatus !== ORG_MASTER_STATUS.ADDITIONAL_DETAIL_PENDING) {
            navigate(INVESTOR_ROUTES.PORTFOLIO);
        } else if (userData?.primaryEmail && userData?.investorOrgId) {
            getPartnetConfigDataAndSetFormData(
                {
                    primaryEmail: userData?.primaryEmail,
                    investorOrgId: userData?.investorOrgId,
                },
                {
                    onSuccess: (config) => {
                        initialiseFormAndErrorsFromConfig(config?.additionalDetailsConfig || {});
                        setConfigData(config?.additionalDetailsConfig || {});
                        setTermsAndPolicyConfig(config?.termsAndPolicyConfig || {});
                    },
                    onError: () => {
                        navigate(INVESTOR_ROUTES.LOGIN);
                    },
                },
            );
        }
    }, [userData]);

    const isStartApplicationDisabled = () => {
        return !Object.entries(configData).every(([fieldName, fieldConfig]) => {
            if (fieldConfig.isRequired && !formData[fieldName]) return false;
            return true;
        });
    };

    const checkIfFormHasErrors = () => {
        let hasErrors = false;
        let updatedErrors = Object.keys(errors).reduce((errorsObj, fieldName) => {
            const error = configData[fieldName]?.isRequired
                ? configData[fieldName]?.type === 'dropdown'
                    ? formData[fieldName] === null
                    : !!!formData[fieldName]?.length
                : false;
            errorsObj[fieldName] = error;
            if (!hasErrors && error) hasErrors = true;
            return errorsObj;
        }, {});
        setErrors(updatedErrors);
        return hasErrors;
    };
    const onStartYourApplication = async (payload) => {
        const inviteCode = localStorage.getItem('invite_code') || '';

        const hasErrors = checkIfFormHasErrors();
        if (hasErrors) return;
        else {
            const updatedPayload = getPayloadToSubmit(payload);
            const res = await EditUser({
                userId: getPlatformUserId(),
                userAdditionalDetails: updatedPayload,
            });
            if (res.responseData.responseCode === 20) {
                Mixpanel.track(AICA_PARTNERS_SUBMIT_DETAILS, {
                    parterDSAId: inviteCode,
                    partnerType: payload?.partnerType?.id,
                    PartnerId: userData?.userId,
                    websiteUrl: window.location.href,
                });
                navigate(`${INVESTOR_ROUTES.APP}/${INVESTOR_ROUTES.WATCH}`);
            } else {
                toast.error(res.responseData.responseMessage ?? 'Failed to submit details');
            }
        }
    };

    const getPayloadToSubmit = (formData) => {
        const payload = {};
        Object.keys(formData).map((formDataKey) => {
            if (formData[formDataKey])
                payload[formDataKey] =
                    configData?.[formDataKey]?.type === 'dropdown'
                        ? formData?.[formDataKey]?.label
                        : configData?.[formDataKey]?.type === 'number'
                        ? parseFloat(formData?.[formDataKey]) || 0
                        : formData?.[formDataKey];
        });
        return payload;
    };

    return (
        <div className={styles.PartnerAdditionalFormContainer}>
            {fetchingConfig ? (
                <div className={styles.Loading}>
                    <LoadingSpinner color="black" height="60px" />{' '}
                </div>
            ) : (
                <div className={styles.Content}>
                    <Form>
                        <div className={styles.Title}>Additional Details</div>
                        {Object.entries(configData).map(([fieldName, fieldConfig], index) => (
                            <div key={index}>{renderFormField(fieldName, fieldConfig, index)}</div>
                        ))}

                        <Button
                            text="Submit Details"
                            variant={'purple'}
                            onClick={() => {
                                onStartYourApplication(formData);
                            }}
                            disabled={isStartApplicationDisabled()}
                            loading={false}
                            style={{ width: '100%' }}
                        />

                        {!!termsAndPolicyConfig && (
                            <div className={styles.Tnc}>
                                <div>
                                    By clicking on “Submit Details” you agree to our{' '}
                                    <a
                                        className={styles.Link}
                                        href={termsAndPolicyConfig?.termsAndCondition}
                                        target="_blank"
                                    >
                                        Terms of Service
                                    </a>{' '}
                                    and
                                    <a
                                        className={styles.Link}
                                        href={termsAndPolicyConfig?.privacyPolicy}
                                        target="_blank"
                                    >
                                        {' '}
                                        Privacy Policy
                                    </a>
                                    .
                                </div>
                            </div>
                        )}
                    </Form>
                </div>
            )}
        </div>
    );
};

export default PartnerAdditionalDetails;
