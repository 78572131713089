import { useEffect, useRef } from 'react';

export function useOutsideClick(ref, callback) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current === event.target) {
            } else if (ref.current && !ref.current.contains(event.target)) {
                callback?.();
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
}

export const useWindowUnloadEffect = (handler, callOnCleanup) => {
    const cb: any = useRef();

    cb.current = handler;

    useEffect(() => {
        const handler = () => cb?.current?.();
        window.addEventListener('beforeunload', handler);

        return () => {
            if (callOnCleanup) handler();

            window.removeEventListener('beforeunload', handler);
        };
    }, [callOnCleanup]);
};
