import * as Effects from 'redux-saga/effects';
import * as apiService from './service';
import { CONSTANTS } from './constants';
import { toast } from 'react-toastify';
import { isTabConfigValid } from './transformer';
import { JAVA_API_STATUS, SOMETHING_WRONG } from '../../utils/axios-interceptor';
import { takeEvery, call, put, takeLatest } from 'redux-saga/effects';

function* fetchDashboardConfigData(action: any) {
    try {
        yield put({
            type: CONSTANTS.SET_IS_LOADING,
            payload: true,
        });
        const response = yield call(apiService.getDashboardConfig, action.payload);
        const responseData = response.data?.responseData;
        // const { mainConfig } = require('./config.json');
        const {
            data: { mainConfig },
        } = response;
        const { selectedTabId } = mainConfig;
        const selectedTabConfig = mainConfig?.tabConfigs?.[selectedTabId];
        const charts = selectedTabConfig?.chartPanel?.cards || [];
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                yield put({
                    type: CONSTANTS.GET_DASHBOARD_CONFIG_SUCCESS,
                    payload: mainConfig,
                });
                if (charts.length)
                    yield put({
                        type: CONSTANTS.INITIALIZE_GRAPH_CARDS,
                        payload: { charts: charts },
                    });
                if (selectedTabId) action?.payload?.setDefaultTab(selectedTabId);
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                yield put({
                    type: CONSTANTS.SET_IS_LOADING,
                    payload: false,
                });
                return toast.error(responseData?.responseMessage || SOMETHING_WRONG);
        }
    } catch (err: any) {
        yield put({
            type: CONSTANTS.SET_IS_LOADING,
            payload: false,
        });
        console.error(err?.message || 'Some error occoured');
    }
}

function* fetchHeadlineData(action: any) {
    try {
        const isPolling = action?.data?.isPolling;
        if (!isPolling)
            yield put({
                type: CONSTANTS.SET_IS_LOADING_HEADLINE,
                payload: true,
            });
        const response = yield call(apiService.getHeadlineData, action.payload);
        const tabData = response?.data?.tabData;
        const responseData = response.data?.responseData;

        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                yield put({
                    type: CONSTANTS.GET_HEADLINE_DATA_SUCCESS,
                    payload: tabData || {},
                });
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                yield put({
                    type: CONSTANTS.SET_IS_LOADING_HEADLINE,
                    payload: false,
                });
            // return toast.error(responseData?.responseMessage || SOMETHING_WRONG);
        }
    } catch (err: any) {
        yield put({
            type: CONSTANTS.SET_IS_LOADING_HEADLINE,
            payload: false,
        });
        console.error(err?.message || 'Some error occoured');
    }
}

function* fetchGraphData(action: any) {
    const graphName = action?.payload?.graphNames?.[0] || '--';
    const isPollingCall = action?.data?.isPollingCall;

    try {
        if (!isPollingCall) {
            yield put({
                type: CONSTANTS.SET_IS_LOADING_GRAPH,
                payload: true,
                graphName: graphName,
            });
        }

        const response = yield call(apiService.getGraphData, action.payload);
        const responseData = response.data?.responseData;
        let graphs = response?.data?.graphs;

        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                yield put({
                    type: CONSTANTS.GET_GRAPH_DATA_SUCCESS,
                    payload: {
                        graphNames: Object.keys(graphs || {}),
                        data: graphs,
                    },
                });
                break;
            case JAVA_API_STATUS.ERROR:
                yield put({
                    type: CONSTANTS.GET_GRAPH_DATA_ERROR,
                    payload: {
                        graphNames: [graphName],
                        data: graphs,
                    },
                });
                yield put({
                    type: CONSTANTS.SET_IS_LOADING_HEADLINE,
                    payload: false,
                });
                break;
            default:
                return;
        }
    } catch (err: any) {
        yield put({
            type: CONSTANTS.GET_GRAPH_DATA_ERROR,
            payload: {
                graphNames: [graphName],
            },
        });
        yield put({
            type: CONSTANTS.SET_IS_LOADING_HEADLINE,
            payload: false,
        });
        console.error(err?.message || 'Some error occoured');
    }
}

let aicaGradeD = {
    responseData: {
        responseCode: 20,
        responseMessage: 'Request Processed Successfully',
    },
    requestId: '2024040214:47:17',
    companyScore: 75,
    companyGrade: 'A',
    headers: [
        {
            displayName: 'Operating Score',
            tooltipDescription: 'Operating Score',
            headerScore: 30,
            metrics: [
                {
                    displayName: 'Revenue',
                    tooltipDescription: null,
                    metricScore: 20,
                },
                {
                    displayName: 'Revenue Growth',
                    tooltipDescription: null,
                    metricScore: 40,
                },
            ],
        },
        {
            displayName: 'Financial Score',
            tooltipDescription: 'Financial Score',
            headerScore: null,
            metrics: [],
        },
        {
            displayName: 'Management Score',
            tooltipDescription: 'Management Score',
            headerScore: null,
            metrics: [],
        },
        {
            displayName: 'Governance Score',
            tooltipDescription: 'Governance Score',
            headerScore: null,
            metrics: [],
        },
        {
            displayName: 'Sector Score',
            tooltipDescription: 'Sector Score',
            headerScore: null,
            metrics: [],
        },
    ],
};

function* fetchAicaGradeData(action: any) {
    try {
        yield put({
            type: CONSTANTS.SET_IS_LOADING_AICA_GRADE_DATA,
            payload: true,
        });
        const response = yield call(apiService.getAicaGradeDataService, action.payload);
        const aicaGradeData = response?.data;
        // const aicaGradeData = aicaGradeD;

        const responseData = response.data?.responseData;

        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                yield put({
                    type: CONSTANTS.GET_AICA_GRADE_DATA_SUCCESS,
                    payload: aicaGradeData || {},
                });
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                yield put({
                    type: CONSTANTS.SET_IS_LOADING_AICA_GRADE_DATA,
                    payload: false,
                });
        }
    } catch (err: any) {
        yield put({
            type: CONSTANTS.SET_IS_LOADING_AICA_GRADE_DATA,
            payload: false,
        });
        console.error(err?.message || 'Some error occoured');
    }
}

export default function* actionWatcher() {
    yield takeLatest(CONSTANTS.GET_DASHBOARD_CONFIG, fetchDashboardConfigData);
    yield takeLatest(CONSTANTS.GET_HEADLINE_DATA, fetchHeadlineData);
    yield takeEvery(CONSTANTS.GET_GRAPH_DATA, fetchGraphData);
    yield takeLatest(CONSTANTS.GET_AICA_GRADE_DATA, fetchAicaGradeData);
}
