import styles from './AIText.module.scss';

type AITextProps = {
    text: string;
    style?: React.CSSProperties;
    showLoadingDots?: boolean;
    loadingDotsStyle?: React.CSSProperties;
};

const AIText = (props: AITextProps) => {
    const { text, style, loadingDotsStyle, showLoadingDots } = props;
    return (
        <div className={styles.AIWrapper}>
            <div style={style} className={styles.AIText}>
                {text}
            </div>
            {showLoadingDots && (
                <span style={loadingDotsStyle} className={styles.LoadingDots}>
                    ...
                </span>
            )}
        </div>
    );
};

export default AIText;
