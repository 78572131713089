import { useState } from 'react';
import { COUNTRY_CODE, EMAIL, NAME, PHONE } from '../../../utils/constants/companyLogin';
import { addAssessee } from '../../../store/user/userV2/actions';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../app/hooks';
import { toast } from 'react-toastify';

interface CountryCodeData {
    countryId: string;
    countryName: string;
    countryCode: string;
    callingCode: string;
    minCallingNumberLength: number;
    maxCallingNumberLength: number;
}

const useAddAssessee = (assesseeOrgId, closeModal) => {
    const [formData, setFormData] = useState<{
        [NAME]: string;
        [EMAIL]: string;
        [PHONE]: string;
        [COUNTRY_CODE]: CountryCodeData | null;
    }>({
        [NAME]: '',
        [EMAIL]: '',
        [PHONE]: '',
        [COUNTRY_CODE]: null,
    });
    const user = useAppSelector((state) => state.user);
    const domainMetadata = useAppSelector((state) => state.user.domainMetadata);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const disabled = !(
        formData[NAME] &&
        formData[EMAIL] &&
        formData[PHONE] &&
        formData[COUNTRY_CODE]
    );
    const changeFormValue = (value, key) => {
        setFormData((formData) => ({
            ...formData,
            [key]: value,
        }));
    };
    const onInviteUserClick = () => {
        setLoading(true);
        dispatch(
            addAssessee({
                payload: {
                    user: {
                        emailId: formData[EMAIL],
                        mobileNumber: formData[PHONE],
                        name: formData[NAME],
                        countryCode: formData[COUNTRY_CODE]?.countryCode ?? '',
                        accountType: 'ASSESSEE',
                        accountTypes: ['ASSESSEE'],
                        journeyName: 'ADD_BORROWER_USER',
                        countryId: formData[COUNTRY_CODE]?.countryId ?? '',
                        investorId: user.userData.investorOrgId,
                        organizationId: assesseeOrgId,
                    },
                    platformId: domainMetadata?.productName ?? '',
                },
                handlers: {
                    onSuccess: () => {
                        toast.success('User invited successfully');
                        closeModal();
                        setLoading(false);
                    },
                    onError: () => {
                        setLoading(false);
                    },
                },
            }),
        );
    };

    return {
        formData,
        changeFormValue,
        disabled,
        loading,
        onInviteUserClick,
        setFormData,
    };
};

export default useAddAssessee;
