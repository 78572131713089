import React from 'react';
import './ToggleButton.scss';

export default function ToggleButton({
    checked,
    onChange,
    disabled = false,
}: {
    checked: boolean;
    onChange: () => void;
    disabled?: boolean;
}) {
    return (
        <label className="switch">
            <input type="checkbox" checked={checked} onChange={onChange} disabled={disabled} />
            <span className="slider"></span>
        </label>
    );
}
