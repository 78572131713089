import React, { useState } from 'react';
import styles from './InvestorSignup.module.scss';
import InvestorSignupForm from './components/InvestorSignupForm/InvestorSignupForm';
import { DropdownOption } from '../../../common/_custom/Dropdown/dropdownTypes';
import { CountryCodeData } from '../../Company/CompanyLogin/CountryCodesDropdown/CountryCodesDropdown';
import InvestorSignupLoginForm from './components/InvestorSignupLoginForm/InvestorSignupLoginForm';
import { investorSignup, investorSignupLogin } from '../../../store/user/userV2/actions';
import { useDispatch } from 'react-redux';
import { USER_TYPE } from '../../../utils/constants/user';
import { OTP_CREATE } from '../../Company/CompanyLogin/CompanyLogin';
import { askAicaLogo } from '../../../assets/hostedassets';

export type InvestorSignupData = {
    firstName: string;
    lastName: string;
    organisationName: string;
    organisationType: DropdownOption;
    emailId: string;
    mobileNumber: string;
    password: string;
    countryCode: null | CountryCodeData;
};
export type InvestorSignupLoginData = {
    emailId: string;
};

function InvestorSignup({ isLogin = false }: { isLogin?: boolean }) {
    const dispatch = useDispatch();
    const [loginFormData, setLoginFormData] = useState<InvestorSignupLoginData>({ emailId: '' });
    const [formData, setFormData] = useState<InvestorSignupData>({
        firstName: '',
        lastName: '',
        organisationName: '',
        organisationType: { label: '', id: '' },
        emailId: '',
        mobileNumber: '',
        countryCode: null,
        password: '',
    });
    const handleFormChange = (val: string, identifier: string) => {
        setFormData((data) => ({
            ...data,
            [identifier]: val,
        }));
    };
    const handleLoginFormChange = (val: string, identifier: string) => {
        setLoginFormData((data) => ({
            ...data,
            [identifier]: val,
        }));
    };
    const handleSignupClick = () => {
        dispatch(
            investorSignup({
                orgInfo: {
                    accountType: USER_TYPE.INVESTOR,
                    accountTypes: [
                        USER_TYPE.INVESTOR,
                        USER_TYPE.PARTNER,
                        USER_TYPE.ADMIN_TEAM,
                        USER_TYPE.LENDER,
                    ],
                    countryCode: formData.countryCode?.countryCode ?? '',
                    countryId: formData.countryCode?.countryId ?? '',
                    investorOrgType: formData.organisationType.label,
                    orgName: formData.organisationName,
                    password: formData.password,
                    phoneNumber: formData.mobileNumber,
                    primaryEmail: formData.emailId?.toLowerCase(),
                    primaryUserFirstName: formData.firstName,
                    primaryUserLastName: formData.lastName,
                },
                onSuccess: (domain: string, subDomain: string, signupToken: string) => {
                    let url = new URL(`https://${subDomain}.${domain}/investor/signup`);
                    url.searchParams.append('signupToken', signupToken);
                    url.searchParams.append('showOtp', OTP_CREATE);
                    url.searchParams.append('emailId', formData.emailId?.toLowerCase());
                    window.open(url.href);
                },
                onError: () => {
                    console.error('error');
                },
            }),
        );
    };
    const handleLoginClick = () => {
        dispatch(
            investorSignupLogin({
                emailId: loginFormData.emailId?.toLowerCase(),
                onSuccess: (domain: string, subDomain: string, emailId: string) => {
                    let url = new URL(`https://${subDomain}.${domain}/investor/login`);
                    url.searchParams.append('emailId', emailId?.toLowerCase());
                    window.open(url.href);
                },
                onError: () => {
                    console.error('error');
                },
            }),
        );
    };
    const renderForm = () => {
        if (isLogin) {
            return (
                <InvestorSignupLoginForm
                    formData={loginFormData}
                    handleFormChange={handleLoginFormChange}
                    handleSubmitClick={handleLoginClick}
                />
            );
        } else {
            return (
                <InvestorSignupForm
                    formData={formData}
                    handleFormChange={handleFormChange}
                    handleSubmitClick={handleSignupClick}
                />
            );
        }
    };
    return (
        <div className={styles.InvestorSignup}>
            <div className={styles.Left}>
                <img src={askAicaLogo} alt="logo" />
                <h1>Hi, I'm AICA</h1>
                <p>Your AI assistant for company assessment</p>
            </div>
            <div className={styles.Right}>{renderForm()}</div>
        </div>
    );
}

export default InvestorSignup;
