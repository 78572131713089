import React from 'react';

const EnterPasswordIcon = ({ colour = '#6021B3', width = '15', height = '15' }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.15084 2.94975C0.838084 4.2625 0.100586 6.04298 0.100586 7.8995C0.100586 9.75601 0.838084 11.5365 2.15084 12.8492C3.46359 14.162 5.24407 14.8995 7.10059 14.8995C8.9571 14.8995 10.7376 14.162 12.0503 12.8492C13.3631 11.5365 14.1006 9.75601 14.1006 7.8995C14.1006 6.04298 13.3631 4.2625 12.0503 2.94975C10.7376 1.63699 8.9571 0.899495 7.10059 0.899495C5.24407 0.899495 3.46359 1.63699 2.15084 2.94975ZM4.06948 8.33693C4.0114 8.33794 3.9537 8.32737 3.89974 8.30584C3.84578 8.28431 3.79666 8.25225 3.75522 8.21153C3.71379 8.17081 3.68088 8.12225 3.65842 8.06867C3.63595 8.0151 3.62438 7.95759 3.62438 7.89949C3.62438 7.8414 3.63595 7.78389 3.65842 7.73032C3.68088 7.67674 3.71379 7.62818 3.75522 7.58746C3.79666 7.54674 3.84578 7.51468 3.89974 7.49315C3.9537 7.47162 4.0114 7.46105 4.06948 7.46206L9.13803 7.46206L7.42541 5.74945C7.34337 5.6674 7.29727 5.55612 7.29727 5.44009C7.29727 5.32406 7.34337 5.21278 7.42541 5.13073C7.50746 5.04868 7.61874 5.00259 7.73477 5.00259C7.8508 5.00259 7.96208 5.04868 8.04413 5.13073L10.5035 7.59014C10.5856 7.67218 10.6317 7.78346 10.6317 7.89949C10.6317 8.01553 10.5856 8.12681 10.5035 8.20885L8.04413 10.6683C7.96208 10.7503 7.85081 10.7964 7.73477 10.7964C7.61874 10.7964 7.50746 10.7503 7.42541 10.6683C7.34337 10.5862 7.29727 10.4749 7.29727 10.3589C7.29727 10.2429 7.34337 10.1316 7.42541 10.0495L9.13803 8.33693L4.06948 8.33693Z"
                fill={colour}
            />
        </svg>
    );
};

export default EnterPasswordIcon;
