import * as Effects from 'redux-saga/effects';
import * as apiService from './service';
import { CONSTANTS } from './constants';
import { toast } from 'react-toastify';
import { takeEvery } from 'redux-saga/effects';
import { JAVA_API_STATUS, SOMETHING_WRONG } from '../../../utils/axios-interceptor';
const call: any = Effects.call;
const put: any = Effects.put;
const takeLatest: any = Effects.takeLatest;

function* fetchCompanyOverview(action: any) {
    try {
        const response = yield call(apiService.getCompanyOverviewData, action.payload);
        const responseData = response?.data?.responseData;
        const data = response?.data?.data;
        const actionCta = response?.data?.actionCTA;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                action.payload?.onSuccess?.(data, actionCta);
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                return toast.error(responseData?.responseMessage || SOMETHING_WRONG);
        }
    } catch (err: any) {
        console.error(err?.message || 'Some error occoured');
    }
}

function* fetchScorecard(action: any) {
    try {
        const response = yield call(apiService.getScorecardData, action.payload);
        const responseData = response?.data?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                action.payload?.onSuccess?.(response.data);
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                return toast.error(SOMETHING_WRONG);
        }
    } catch (err: any) {
        console.error(err?.message || 'Some error occoured');
    }
}

function* fetchAlerts(action: any) {
    try {
        const response = yield call(apiService.getAlertsData, action.payload);
        const responseData = response?.data?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                action.payload?.onSuccess?.(response.data);
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                return toast.error(SOMETHING_WRONG);
        }
    } catch (err: any) {
        console.error(err?.message || 'Some error occoured');
    }
}

function* fetchShareholdingPattern(action: any) {
    try {
        const response = yield call(apiService.getShareholdingPatternData, action.payload);
        const responseData = response?.data?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                action.payload?.onSuccess?.(response.data?.graphs);
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                return toast.error(SOMETHING_WRONG);
        }
    } catch (err: any) {
        console.error(err?.message || 'Some error occoured');
    }
}

export default function* actionWatcher() {
    yield takeLatest(CONSTANTS.FETCH_COMPANY_OVERVIEW, fetchCompanyOverview);
    yield takeLatest(CONSTANTS.FETCH_SCORECARD, fetchScorecard);
    yield takeEvery(CONSTANTS.FETCH_ALERTS, fetchAlerts);
    yield takeEvery(CONSTANTS.FETCH_SHAREHOLDING_PATTERN, fetchShareholdingPattern);
}
