import { PartnerSignupData } from '../../PartnerSignup';
import Form from '../../../../../common/_custom/Form/Form';
import {
    BUSINESS_NAME,
    COUNTRY_CODE,
    EMAIL,
    EMAIL_ERROR_MSG,
    FULL_NAME,
    FULL_NAME_ERROR_MSG,
    ORG_NAME,
    PAN,
    PASSWORD,
    PASSWORD_ERROR_MSG,
    PHONE,
    PHONE_ERROR_MSG,
    SECTOR,
    WEBSITE,
} from '../../../../../utils/constants/companyLogin';
import { Button, Input } from '../../../../../common/_custom';
import { useState } from 'react';
import styles from '../../PartnerSignup.module.scss';
import CountryCodesDropdown from '../../../../Company/CompanyLogin/CountryCodesDropdown/CountryCodesDropdown';
import { INVESTOR_ROUTES } from '../../../../../utils/constants/routesConst';
import { useNavigate } from 'react-router-dom';
import { Mixpanel } from '../../../../../utils/mixpanel';
import { COMPANY_CREATE_ACCOUNT } from '../../../../../utils/constants/mixpanelEvents/companyEvents';
import {
    companyFormValidations,
    partnerFormValidations,
} from '../../../../../utils/constants/formValidations';
import { useAppSelector } from '../../../../../app/hooks';

type GettingStartedFormProps = {
    formData: PartnerSignupData;
    setFormData: Function;
    handleFormChange: Function;
    handleSubmitClick: Function;
};

const GettingStartedForm = (props: GettingStartedFormProps) => {
    const navigate = useNavigate();
    const { formData, setFormData, handleFormChange, handleSubmitClick } = props;
    const isSigupLoading = useAppSelector((state) => state.user.creatingAccount);
    const disabled = !(
        formData[FULL_NAME] &&
        formData[EMAIL] &&
        formData[PASSWORD] &&
        formData[PHONE] &&
        formData[COUNTRY_CODE]
    );
    const [errors, setErrors] = useState({
        [FULL_NAME]: false,
        [EMAIL]: false,
        [PHONE]: false,
        [PASSWORD]: false,
    });
    const changeValue = (val, key) => {
        setErrors((errors) => ({
            ...errors,
            [key]: false,
        }));
        handleFormChange(val, key);
    };
    const onSubmit = () => {
        setErrors({
            [FULL_NAME]: !partnerFormValidations.isFullNameValid(formData[FULL_NAME]),
            [EMAIL]: !partnerFormValidations.isEmailValid(formData[EMAIL]),
            [PHONE]: !partnerFormValidations.isPhoneValid(formData[PHONE], formData[COUNTRY_CODE]),
            [PASSWORD]: !partnerFormValidations.isPasswordValid(formData[PASSWORD]),
        });

        if (partnerFormValidations.allValid(formData)) handleSubmitClick();
    };

    return (
        <>
            <h2>Getting Started</h2>
            <Form>
                <Input
                    placeholder="Full Name"
                    value={formData[FULL_NAME]}
                    onChange={(val: string) => changeValue(val, FULL_NAME)}
                    errorMessage={FULL_NAME_ERROR_MSG}
                    isValid={!errors[FULL_NAME]}
                />
                <Input
                    placeholder="Email"
                    value={formData[EMAIL]}
                    onChange={(val: string) => changeValue(val.trim(), EMAIL)}
                    errorMessage={EMAIL_ERROR_MSG}
                    isValid={!errors[EMAIL]}
                />
                <div className={styles.Row}>
                    <CountryCodesDropdown
                        //@ts-ignore
                        setCountryCode={(val) => changeValue(val, COUNTRY_CODE)}
                    />
                    <Input
                        type="number"
                        placeholder="Phone Number"
                        value={formData[PHONE]}
                        onChange={(val: string) => changeValue(val, PHONE)}
                        errorMessage={PHONE_ERROR_MSG}
                        isValid={!errors[PHONE]}
                        style={{ marginTop: '0', width: '100%' }}
                    />
                </div>
                <Input
                    value={formData[ORG_NAME]}
                    onChange={(val) => handleFormChange(val, ORG_NAME)}
                    placeholder="Organisation Name (Optional)"
                    style={{ marginTop: '30px' }}
                />
                <Input
                    placeholder="Password (min 8 characters)"
                    type="password"
                    value={formData[PASSWORD]}
                    onChange={(val: string) => changeValue(val, PASSWORD)}
                    errorMessage={PASSWORD_ERROR_MSG}
                    isValid={!errors[PASSWORD]}
                />
                <Button
                    variant={'primary'}
                    style={{ width: '100%' }}
                    text="Create Account"
                    loadingText="Please Wait"
                    loading={isSigupLoading}
                    onClick={onSubmit}
                    disabled={disabled}
                />
                <div className={styles.Separator} />
                <div style={{ textAlign: 'center', fontSize: '14px' }} className={styles.Signup}>
                    Already have an account?&nbsp;
                    <button
                        className={styles.Login}
                        onClick={() => navigate(INVESTOR_ROUTES.LOGIN)}
                    >
                        Login
                    </button>
                </div>
            </Form>
        </>
    );
};

export default GettingStartedForm;
