import { CompanyLoginData } from '../../modules/Company/CompanyLogin/CompanyLogin';
import { CountryCodeData } from '../../modules/Company/CompanyLogin/CountryCodesDropdown/CountryCodesDropdown';
import { InvestorSignupData } from '../../modules/Investor/InvestorSignup/InvestorSignup';
import { emailRegex, websiteUrlRegex } from '../utils';
import {
    BUSINESS_NAME,
    COUNTRY_CODE,
    EMAIL,
    PASSWORD,
    PHONE,
    SECTOR,
    WEBSITE,
} from './companyLogin';
import { PartnerSignupData } from '../../modules/Investor/PartnerSignup/PartnerSignup';
import { AddUserData } from '../../modules/Investor/ManageUser/ManageUserTable/components/AddUserModal';

export const companyFormValidations = {
    isPANValid: (pan: string) => !!pan?.length,
    isSectorValid: (sector: string | undefined) => (sector ? !!sector?.length : true),
    isNameValid: (name: string) => !!(name?.length > 2),
    isEmailValid: (email: string) => !!emailRegex.test(email),
    isWebsiteValid: (websiteUrl: string) =>
        websiteUrl ? !!websiteUrlRegex.test(websiteUrl) : true,
    isPasswordValid: (password: string) => !!(password?.length > 0),
    isPhoneValid: (phone: string, countryCodeData: null | CountryCodeData) =>
        countryCodeData?.minCallingNumberLength &&
        countryCodeData?.maxCallingNumberLength &&
        !!(
            phone?.length >= countryCodeData?.minCallingNumberLength &&
            phone?.length <= countryCodeData?.maxCallingNumberLength
        ),
    // isIdentifierValid: (identifier: string) => !!identifier?.length,
    allValid: (formData: CompanyLoginData) => {
        return !!(
            // companyFormValidations.isIdentifierValid(formData[IDENTIFIER]) &&
            (
                companyFormValidations.isSectorValid(formData[SECTOR]?.label) &&
                companyFormValidations.isNameValid(formData[BUSINESS_NAME]) &&
                companyFormValidations.isEmailValid(formData[EMAIL]) &&
                companyFormValidations.isWebsiteValid(formData[WEBSITE]) &&
                companyFormValidations.isPasswordValid(formData[PASSWORD]) &&
                companyFormValidations.isPhoneValid(formData[PHONE], formData[COUNTRY_CODE])
            )
        );
    },
};

export const investorFormValidations = {
    isFirstNameValid: (name: string) => !!(name?.length > 2),
    // isLastNameValid: (name: string) => !!(name?.length > 2),
    isOrgNameValid: (name: string) => !!(name?.length > 2),
    isEmailValid: (email: string) => !!emailRegex.test(email),
    isOrgTypeValid: (orgType: string) => !!(orgType?.length > 2),
    isPasswordValid: (password: string) => !!(password?.length >= 8 && password?.length <= 20),
    isPhoneValid: (phone: string, countryCodeData: null | CountryCodeData) =>
        countryCodeData?.minCallingNumberLength &&
        countryCodeData?.maxCallingNumberLength &&
        !!(
            phone?.length >= countryCodeData?.minCallingNumberLength &&
            phone?.length <= countryCodeData?.maxCallingNumberLength
        ),
    allValid: (formData: InvestorSignupData) => {
        return !!(
            investorFormValidations.isFirstNameValid(formData.firstName) &&
            // investorFormValidations.isLastNameValid(formData.lastName) &&
            investorFormValidations.isEmailValid(formData.emailId) &&
            investorFormValidations.isPasswordValid(formData.password) &&
            investorFormValidations.isPhoneValid(formData.mobileNumber, formData.countryCode)
        );
    },
};

export const partnerFormValidations = {
    isFullNameValid: (name: string) => !!(name?.length > 2),
    isEmailValid: (email: string) => !!emailRegex.test(email),
    isPasswordValid: (password: string) => !!(password?.length >= 8 && password?.length <= 20),
    isPhoneValid: (phone: string, countryCodeData: null | CountryCodeData) =>
        countryCodeData?.minCallingNumberLength &&
        countryCodeData?.maxCallingNumberLength &&
        !!(
            phone?.length >= countryCodeData?.minCallingNumberLength &&
            phone?.length <= countryCodeData?.maxCallingNumberLength
        ),
    allValid: (formData: PartnerSignupData) => {
        return !!(
            partnerFormValidations.isFullNameValid(formData.FULL_NAME) &&
            partnerFormValidations.isEmailValid(formData.EMAIL) &&
            partnerFormValidations.isPasswordValid(formData.PASSWORD) &&
            partnerFormValidations.isPhoneValid(formData.PHONE, formData.COUNTRY_CODE)
        );
    },
};

export const InviteUserFormValidations = {
    isFullNameValid: (name: string) => !!(name?.length > 2),
    isEmailValid: (email: string) => !!emailRegex.test(email),
    isPhoneValid: (phone: string, countryCodeData: null | CountryCodeData) =>
        countryCodeData?.minCallingNumberLength &&
        countryCodeData?.maxCallingNumberLength &&
        !!(
            phone?.length >= countryCodeData?.minCallingNumberLength &&
            phone?.length <= countryCodeData?.maxCallingNumberLength
        ),
    allValid: (formData: AddUserData) => {
        return !!(
            partnerFormValidations.isFullNameValid(formData.FULL_NAME) &&
            partnerFormValidations.isEmailValid(formData.EMAIL) &&
            partnerFormValidations.isPhoneValid(formData.PHONE, formData.COUNTRY_CODE)
        );
    },
};
