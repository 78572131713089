import React from 'react';

const HighLevelIcon = ({ colour = '#6021B3', width = '12', height = '12', style }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={style}
        >
            <path
                d="M3 10.25L6 7.25L9 10.25"
                stroke={colour}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3 7.25L6 4.25L9 7.25"
                stroke={colour}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3 4.25L6 1.25L9 4.25"
                stroke={colour}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default HighLevelIcon;
