import React, { useEffect, useState } from 'react';
import { STEP_CHECKS, UPLOAD_TABS } from '../../uploadJourneyUtils';
import styles from './Alerts.module.scss';
import { USER_TYPE } from '../../../../../utils/constants/user';
import { infoPurpleIcon } from '../../../../../assets/hostedassets';

function Alerts({ userType = '', selectedStep, uploadJourneyData }) {
    const [alertsToShow, setAlertsToShow] = useState<string[]>([]);

    useEffect(() => {
        if (userType === USER_TYPE.INVESTOR) getInvestorAlerts();
        else if (userType === USER_TYPE.COMPANY) getCompanyAlerts();
    }, [userType, selectedStep]);

    const getInvestorAlerts = () => {
        let alerts: string[] = [];

        switch (selectedStep) {
            // case UPLOAD_TABS.CREDIT_BUREAU:
            //     alerts.push('This data will be used to generate credit bureau summary');
            //     break;
            case UPLOAD_TABS.GST_REPORT:
                const gstText = getGSTConnectedText(uploadJourneyData?.gstMasterList || []);
                alerts.push(
                    'This data will be used to generate Revenue, Vendor and Compliance summary',
                );
                gstText && alerts.push(gstText);
                break;
            case UPLOAD_TABS.BANK_STATEMENT:
                const bankStmtText = getBankStmtsText(
                    uploadJourneyData?.bankAccountStatementList || [],
                );
                alerts.push('This data will be used to generate analysis from bank statements');
                bankStmtText && alerts.push(bankStmtText);
                break;
            case UPLOAD_TABS.FINANCIALS:
                alerts.push('This data will be used to generate a financial statement summary');
                break;
        }
        setAlertsToShow(alerts);
    };

    const getCompanyAlerts = () => {
        let alerts: string[] = [];
        switch (selectedStep) {
            case UPLOAD_TABS.CREDIT_BUREAU:
                // alerts.push('This data will be used to generate credit bureau summary');
                break;
            case UPLOAD_TABS.GST_REPORT:
                // alerts.push(
                //     'This data will be used to generate Revenue, Vendor and Compliance summary',
                // );
                break;
            case UPLOAD_TABS.BANK_STATEMENT:
                // alerts.push('This data will be used to generate analysis from bank statements');

                break;
            case UPLOAD_TABS.FINANCIALS:
                // alerts.push('This data will be used to generate a financial statement summary');
                break;
        }
        setAlertsToShow(alerts);
    };

    const getGSTConnectedText = (gstMasterList) => {
        let totalGst = gstMasterList?.length,
            connectedCount = 0,
            connectedGsts: string[] = [];
        gstMasterList?.forEach((gst) => {
            if (gst?.connected) {
                connectedCount++;
                connectedGsts.push(gst?.gstin);
            }
        });

        const text1 = `${connectedCount}/${totalGst} GSTs connected by company.`;
        const text2 = connectedGsts?.length ? ` (${connectedGsts?.join(', ')})` : '';

        return text1 + text2;
    };

    const getBankStmtsText = (bankAccounts) => {
        let connectedCount = 0,
            connectedAccnts: string[] = [];

        bankAccounts?.forEach((account) => {
            if (STEP_CHECKS.isAccountConnected(account)) {
                connectedCount++;
                connectedAccnts.push(`${account?.bankName}`);
            }
        });

        return !!connectedCount
            ? `${connectedCount} Bank Accounts connected by company. (${connectedAccnts?.join(
                  ', ',
              )})`
            : '';
    };

    return (
        <div className={styles.Alerts}>
            {alertsToShow?.map((alert, i) => (
                <div key={`${alert}-${i}`} className={styles.SingleAlert}>
                    <img src={infoPurpleIcon} alt="info" height={'15px'} />
                    {alert}
                </div>
            ))}
        </div>
    );
}

export default Alerts;
