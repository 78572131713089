import { useSearchParams } from 'react-router-dom';
import styles from './CibilVerification.module.scss';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { captureConsent } from '../../../store/company/action';
import { toast } from 'react-toastify';
import { Player } from '@lottiefiles/react-lottie-player';
import { askAicaLogo, cibilSoftPullJson } from '../../../assets/hostedassets';

function CibilVerification() {
    const dispatch = useDispatch();
    const token = window.location.search.split('?token=')[1];
    useEffect(() => {
        if (token) {
            handleCibilConsent();
        }
    }, [token]);
    const handleCibilConsent = () => {
        dispatch(
            captureConsent({
                encryptedKey: token ?? '', // token is coming from the url
                onSuccess: () => {
                    toast.success('Consent captured successfully');
                },
                onError: (message) => {
                    toast.error(message ?? 'Failed to capture consent');
                },
            }),
        );
    };

    return (
        <div className={styles.InvestorSignup}>
            <div className={styles.Left}>
                <img src={askAicaLogo} alt="logo" />
                <h1>Hi, I'm AICA</h1>
                <p>Your AI assistant for company assessment</p>
            </div>
            <div className={styles.Right}>
                <Player
                    src={cibilSoftPullJson}
                    autoplay
                    loop
                    speed={1}
                    style={{ height: '10rem', width: '10rem' }}
                />
                <h3 style={{ padding: '0 1rem' }}>
                    Thank you! Your consent has been successfully recorded.
                </h3>
            </div>
        </div>
    );
}

export default CibilVerification;
