import React, { useEffect, useRef, useState } from 'react';
import styles from './CircularSearchBar.module.scss';
import InviteCompanyModal, {
    INVITE_SCREENS,
} from '../../../../modules/Dashboard/components/InviteCompanyModal/InviteCompanyModal';
import { useAppSelector } from '../../../../app/hooks';
import clsx from 'clsx';
import AddToWatchlist, {
    ADD_JOURNEY,
} from '../../../../modules/Investor/Portfolio/components/AddToWatchlist/AddToWatchlist';

interface CircularSearchBarProps {
    placeholder?: string;
    options: {
        orgName: string;
        assesseeOrgId: string;
    }[];
    onChange: (value: string) => void;
    onSelect: (value: { orgName: string; assesseeOrgId: string }) => Promise<boolean>;
    showList?: boolean;
    onAddLeadSuccess: (leadData: any) => void;
    onFocus: () => void;
}

const CircularSearchBar: React.FC<CircularSearchBarProps> = ({
    placeholder = 'Search...',
    options,
    onChange,
    onSelect,
    showList,
    onAddLeadSuccess,
    onFocus,
}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const investorOrgId = useAppSelector((state) => state.user.userData.investorOrgId);
    const platformId = useAppSelector((state) => state.user.domainMetadata?.productName);
    const [filteredOptions, setFilteredOptions] = useState<
        {
            orgName: string;
            assesseeOrgId: string;
            disabled?: true;
        }[]
    >([]);
    const [showAddNewLead, setShowAddNewLead] = useState(false);
    const [showAddNewLeadSuccess, setShowAddNewLeadSuccess] = useState(false);
    const dropdownRef = useRef(null);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !(dropdownRef.current as any).contains(event.target)) {
            setSearchTerm('');
            onChange('');
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    useEffect(() => {
        if (showList && searchTerm.length > 3) {
            if (options.length === 0) {
                setFilteredOptions([
                    {
                        orgName: 'No Results Found',
                        assesseeOrgId: '',
                        disabled: true,
                    },
                ]);
            } else {
                setFilteredOptions(options);
            }
        }
    }, [options]);

    const handleChange = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        onChange(value);
    };

    return (
        <div ref={dropdownRef} style={{ position: 'relative' }}>
            <div className={styles.searchBar}>
                <input
                    type="text"
                    value={searchTerm}
                    onChange={handleChange}
                    placeholder={placeholder}
                    onFocus={(e) => {
                        e.stopPropagation();
                        if (!showList) {
                            setSearchTerm('');
                            onChange('');
                            onFocus();
                        }
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    className={styles.input}
                />
                <div className={styles.searchIcon} />
            </div>
            {showList && searchTerm.length > 3 && (
                <div className={styles.dropdown}>
                    {filteredOptions.map((option, index) => (
                        <div
                            onMouseDown={async (e) => {
                                if (option.disabled) return;
                                e.stopPropagation();
                                setSearchTerm('');
                                const res = await onSelect(option);
                            }}
                            key={index}
                            className={clsx({
                                [styles.option]: true,
                                [styles.optionDisabled]: option.disabled,
                            })}
                        >
                            {option.orgName}
                        </div>
                    ))}
                    <div
                        onMouseDown={(e) => {
                            e.stopPropagation();
                            setShowAddNewLead(true);
                        }}
                        className={styles.addNewLead}
                    >
                        <span className={styles.addIcon}>+</span> Add New Lead
                    </div>
                </div>
            )}

            {showAddNewLead ? (
                <AddToWatchlist
                    prefilledData={searchTerm}
                    journey={ADD_JOURNEY.ADD_TO_SCAN}
                    modalOpen={showAddNewLead}
                    closeModal={() => setShowAddNewLead(false)}
                    investorOrgId={investorOrgId}
                    onSuccess={(leadData) => {
                        onAddLeadSuccess(leadData);
                    }}
                />
            ) : null}
        </div>
    );
};

export default CircularSearchBar;
