import React, { useEffect, useState } from 'react';
import styles from './EditableDropdown.module.scss';
import bottomAngleIcon from '../../assets/bottomAngleIcon.svg';
import clsx from 'clsx';
import greenTick from '../../assets/greenTick.svg';

function EditableDropdown({
    id,
    value,
    options,
    setOutput,
    removeInput,
    imgStyle,
    placeHolder,
    showTick,
    removeBorder,
    wrapperStyle,
    disabled,
    optionStyle,
    inputStyle,
    optionsObject,
}: {
    id: string;
    value?: string | number;
    options: string[];
    imgStyle?: any;
    wrapperStyle?: any;
    showTick?: boolean;
    setOutput: Function;
    removeBorder?: boolean;
    removeInput?: boolean;
    placeHolder?: string;
    disabled?: boolean;
    optionStyle?: any;
    inputStyle?: any;
    optionsObject?: any[];
}) {
    const [inputValue, setInputValue] = useState<number | string>('');
    const [isOpen, setIsOpen] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState(options);
    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
        filterOptions(value);
        setOutput(value);
    };
    useEffect(() => {
        setFilteredOptions(options);
    }, [options]);
    useEffect(() => {
        setInputValue(value ?? '');
    }, [value]);

    const filterOptions = (value) => {
        const filtered = options.filter((option) =>
            option.toLowerCase().includes(value.toLowerCase()),
        );
        setFilteredOptions(filtered);
    };

    const handleOptionClick = (option) => {
        setInputValue(option);
        setOutput(option);
        setIsOpen(false);
    };

    return (
        <div style={wrapperStyle} className={styles.Wrapper}>
            <input
                id={`editableDropdown${id}`}
                style={
                    removeInput
                        ? disabled
                            ? {
                                  color: '#C8C8C8',
                                  caretColor: 'transparent',
                                  cursor: 'not-allowed',
                                  pointerEvents: 'none',
                                  width: '100%',
                                  ...inputStyle,
                              }
                            : {
                                  caretColor: 'transparent',
                                  cursor: 'pointer',
                                  width: '100%',
                                  ...inputStyle,
                              }
                        : { ...inputStyle }
                }
                type="text"
                value={inputValue}
                autoComplete={'off'}
                placeholder={placeHolder}
                className={removeBorder ? styles.InputNoB : styles.Input}
                onChange={(e) => {
                    if (removeInput) return;
                    handleInputChange(e);
                }}
                onFocus={() => setIsOpen(true)}
                onBlur={() => setTimeout(() => setIsOpen(false), 150)} // Delay closing dropdown to allow click on options
            />
            <img
                style={imgStyle}
                className={clsx(styles.DropdownIcon, {
                    [styles.DropdownIconInverted]: isOpen,
                })}
                src={bottomAngleIcon}
                onClick={() => {
                    if (disabled) return;
                    document.getElementById(`editableDropdown${id}`)?.focus();
                }}
                alt="bottomAngleIcon"
                width="24px"
                height="24px"
            />

            <div
                style={
                    isOpen
                        ? { maxHeight: '200px', overflowY: 'auto' }
                        : { maxHeight: '0', overflowY: 'hidden' }
                }
                className={styles.Dropdown}
            >
                {filteredOptions?.map((option: any) => (
                    <div
                        className={clsx({
                            [styles.OptionWithSubtext]: typeof option === 'object',
                            [styles.Option]: typeof option !== 'object',
                            [styles.Disabled]:
                                optionsObject?.find((obj) => obj.misDisplayName === option)
                                    ?.isDataAvailable === false,
                        })}
                        style={optionStyle}
                        key={option}
                        onClick={() =>
                            handleOptionClick(
                                typeof option === 'object' ? option.cellRange : option,
                            )
                        }
                    >
                        {showTick && <img src={greenTick} alt="tick" />}
                        {typeof option === 'object' ? option.cellRange : option}
                        {typeof option === 'object' && (
                            <span style={{ color: 'grey', fontSize: '0.8rem' }}>
                                {option.cellDescription}
                            </span>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default EditableDropdown;
