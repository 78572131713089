import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
dayjs.extend(quarterOfYear);

export const getTimeStamp = () => dayjs().format(`YYYYMMDDHH:mm:ss`);
export const getTimeStampForFileName = () => dayjs().format(`YYYYMMDD_HHmmss`);

export const getMonthsByYear = (numOfMonths: number) => {
    const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

    let endDate = dayjs();
    let endDateMonth = endDate.month();
    let endDateYear = endDate.year();
    let startDate = dayjs().subtract(numOfMonths, 'months');
    let startDateYear = startDate.year();
    let datesObj: any = {};
    for (let i = startDateYear; i <= endDateYear; i++) {
        let monthsArr;
        if (i === endDateYear)
            monthsArr = months.filter((month) => parseInt(month) <= endDateMonth);
        else monthsArr = months;
        datesObj[i] = monthsArr;
    }
    return { months, datesObj, startDate, endDate };
};

export const monthsEnum: { [x: string]: { name: string; fullName: string } } = {
    '01': {
        name: 'Jan',
        fullName: 'January',
    },
    '02': {
        name: 'Feb',
        fullName: 'February',
    },
    '03': {
        name: 'Mar',
        fullName: 'March',
    },
    '04': {
        name: 'Apr',
        fullName: 'April',
    },
    '05': {
        name: 'May',
        fullName: 'May',
    },
    '06': {
        name: 'Jun',
        fullName: 'June',
    },
    '07': {
        name: 'Jul',
        fullName: 'July',
    },
    '08': {
        name: 'Aug',
        fullName: 'August',
    },
    '09': {
        name: 'Sep',
        fullName: 'September',
    },
    '10': {
        name: 'Oct',
        fullName: 'October',
    },
    '11': {
        name: 'Nov',
        fullName: 'November',
    },
    '12': {
        name: 'Dec',
        fullName: 'December',
    },
};

export function getFormattedDate(
    date: string,
    periodSelectorVariant: string | null,
    isFinancials = false,
) {
    //USED IN DATEPICKER
    if (!date || !periodSelectorVariant) return '-';

    const dateTime = dayjs(date);
    if (isFinancials) return dateTime.format('YYYY');

    switch (periodSelectorVariant) {
        case 'Monthly':
            return dateTime.format("MMM'YY");
        case 'Quarterly': {
            let fyQuarter = Math.ceil((parseInt(dateTime.format('MM')) - 3) / 3);
            fyQuarter = fyQuarter <= 0 ? 4 : fyQuarter;
            return `Q${fyQuarter}'FY${parseInt(
                dateTime.add(parseInt(dateTime.format('MM')) > 3 ? 1 : 0, 'years').format('YY'),
            )}`;
        }
        case 'Yearly':
            return dateTime.format('YYYY');
        default:
            return dateTime.format("MMM'YY");
    }
}

export function initializeDatepickerDates(periodSelectorVariant: string | null) {
    const DATE_FORMAT = 'YYYY-MM-DD';
    if (!periodSelectorVariant) return [];
    //Initializing dates for non recurring metrics. (Graphs in Section 1 of Dashboard)
    const now = dayjs();
    // const now = dayjs('2023-04-01');
    const previousMonthLastDate = now.subtract(1, 'month').endOf('month');
    const monthlyStartDate = previousMonthLastDate.subtract(11, 'months').startOf('month');

    const currentQuarterStart = now.startOf('quarter');
    const previousQuarterEnd = currentQuarterStart.subtract(1, 'day');
    const quarterlyStartDate = currentQuarterStart.subtract(3, 'quarters').startOf('quarter');

    const currentFinancialYearStart =
        now.month() < 3
            ? now
                  .year(now.year() - 1)
                  .startOf('year')
                  .add(3, 'month')
            : now.startOf('year').add(3, 'month');
    const previousFinancialYearStart = currentFinancialYearStart.subtract(1, 'year');
    const previousFinancialYearEnd = currentFinancialYearStart.subtract(1, 'day');
    // const yearlyStartDate = previousFinancialYearEnd.subtract(1, 'years').startOf('month');

    let toDate = '';
    let fromDate = '';
    switch (periodSelectorVariant) {
        case 'Monthly':
            toDate = previousMonthLastDate.format(DATE_FORMAT);
            fromDate = monthlyStartDate.format(DATE_FORMAT);
            break;
        case 'Quarterly':
            toDate = now.format(DATE_FORMAT);
            fromDate = quarterlyStartDate.format(DATE_FORMAT);
            break;
        case 'Yearly':
            toDate = now.format(DATE_FORMAT);
            fromDate = previousFinancialYearStart.format(DATE_FORMAT);
            break;
    }

    return [fromDate, toDate, previousMonthLastDate.format(DATE_FORMAT)];
}

export const getFinancialHeadersForDebtSchedule = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;

    let startYear;

    // Between April and September
    if (currentMonth >= 4 && currentMonth <= 9) {
        startYear = currentYear - 4; // FY'20-21
    } else {
        // Between October and March
        startYear = currentYear - 3; // FY'21-22
    }

    return [
        {
            name: 'Particulars (Crs.)',
            key: 'classificationName',
        },
        {
            name: `FY'${String(startYear).slice(-2)}-${String(startYear + 1).slice(-2)}`,
            key: String(startYear),
        },
        {
            name: `FY'${String(startYear + 1).slice(-2)}-${String(startYear + 2).slice(-2)}`,
            key: String(startYear + 1),
        },
        {
            name: `FY'${String(startYear + 2).slice(-2)}-${String(startYear + 3).slice(-2)}`,
            key: String(startYear + 2),
        },
    ];
};
