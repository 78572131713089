import axios from 'axios';
import { toast } from 'react-toastify';
import {
    coreBaseUrl,
    GET,
    polusBaseUrl,
    POST,
    PUT,
    utilBaseUrl,
    uwBaseUrl,
} from '../../utils/axios-interceptor';
import { getTimeStamp } from '../../utils/dateUtils';
import {
    CaptureConsentPayload,
    DebtSchedulePayload,
    DirectorListPayload,
    FetchMetadataForInputModalPayload,
    fetchTableDataForMISPayload,
    RawDataForVisualisationPayload,
    updateDataMetricsPayload,
    VisualisationTableDataPayload,
} from './action';

export async function getCompanyConfigData(payload) {
    return axios({
        url: API_ENDPOINTS.FETCH_CONFIG,
        method: POST,
        data: {
            requestId: getTimeStamp(),
            orgInfo: {
                accountType: payload.accountType,
                investorOrgId: payload.investorOrgId,
                primaryEmail: payload.primaryEmai,
                configName: payload?.configName,
            },
        },
    });
}

export function connectGSTService(payload) {
    return axios({
        url: API_ENDPOINTS.CONNECT_GST,
        method: POST,
        data: {
            requestId: getTimeStamp(),
            investorOrgId: payload.investorOrgId,
            assesseeOrgId: payload.assesseeOrgId,
            gstin: payload.gstin,
            userName: payload.username,
            password: payload.password,
        },
    });
}

// ***************** UPLOAD JOURNEY SERVICES *****************//

export function fetchUploadJourneyDataService(payload) {
    return axios({
        url: API_ENDPOINTS.GET_UPLOAD_DATA,
        method: POST,
        data: {
            requestId: getTimeStamp(),
            assesseeOrgId: payload.assesseeOrgId,
            // accountType: payload.accountType,
            // investorOrgId: payload.investorOrgId,
            // primaryEmail: payload.primaryEmai,
        },
    });
}

export function updateCreditBureauData(payload: UpdateCreditBureauPayload) {
    return axios({
        url: API_ENDPOINTS.UPDATE_CREDIT_BUREAU,
        method: POST,
        data: {
            ...payload,
        },
    });
}

export function updateGSTReportsData(payload: UpdateCreditBureauPayload) {
    return axios({
        url: API_ENDPOINTS.UPDATE_GST_REPORTS,
        method: POST,
        data: {
            ...payload,
        },
    });
}

export function updateFinancialReportsData(payload: any) {
    return axios({
        url: API_ENDPOINTS.UPDATE_FINANCIAL_REPORTS,
        method: POST,
        data: {
            ...payload,
        },
    });
}

export function updateAdditionalDocumentsService(payload: any) {
    return axios({
        url: API_ENDPOINTS.UPDATE_ADDITIONAL_DOCUMENTS,
        method: POST,
        data: {
            ...payload,
        },
    });
}

export function updateBankAccountsData(payload: any) {
    return axios({
        url: API_ENDPOINTS.UPDATE_BANK_ACCOUNT_STATEMENTS,
        method: POST,
        data: {
            ...payload,
        },
    });
}

export function getFinboxRedirectUrlService(payload: any) {
    return axios({
        baseURL: coreBaseUrl,
        url: API_ENDPOINTS.GET_FINBOX_URL,
        method: POST,
        data: payload,
    });
}

export function submitData(payload: any) {
    return axios({
        url: API_ENDPOINTS.SUBMIT_DATA,
        method: POST,
        data: { ...payload },
    });
}

export function updateProvisionalStatements(payload: ProvisionalUpdatePayload) {
    return axios({
        url: API_ENDPOINTS.UPDATE_PROVISIONAL_STATEMENTS,
        method: POST,
        data: { ...payload },
    });
}
export function ingestProvisionalStatements(payload: ProvisionalUpdatePayload) {
    return axios({
        url: API_ENDPOINTS.INGEST_PROVISIONAL_STATEMENTS,
        method: POST,
        data: { ...payload },
    });
}
export function updateDebtScheduleDocs(payload: DebtSchedulePayload) {
    return axios({
        url: API_ENDPOINTS.UPDATE_DEBT_SCHEDULE_DOCS,
        method: POST,
        data: { ...payload },
    });
}

export function fetchMonthlyViewData(assesseeOrgId: string) {
    return axios({
        url: `${uwBaseUrl}/insights/data-vault/${assesseeOrgId}/monthly-view`,
        method: GET,
    });
}

export function fetchVisualisationTableData(payload: VisualisationTableDataPayload) {
    return axios({
        url: `${uwBaseUrl}${API_ENDPOINTS.FETCH_MIS_CLASSIFIED_DATA}`,
        method: GET,
        params: {
            orgId: payload.orgId,
            dataType: payload.dataType,
            requestId: payload.requestId,
        },
    });
}

export function fetchRawDataForVisualisation(payload: RawDataForVisualisationPayload) {
    return axios({
        url: `${uwBaseUrl}${API_ENDPOINTS.FETCH_MIS_RAW_DATA}`,
        method: GET,
        params: {
            orgId: payload.orgId,
            classificationId: payload.classificationId,
            dataType: payload.dataType,
            requestId: payload.requestId,
        },
    });
}

export function fetchCountryCodesApi() {
    return axios({
        url: '/geo/getCountryCallingCodes',
        method: GET,
        baseURL: utilBaseUrl,
    });
}
export function sendCibilConsentLink(payload: DirectorListPayload) {
    return axios({
        url: API_ENDPOINTS.SEND_CIBIL_CONSENT,
        method: POST,
        data: {
            ...payload,
        },
    });
}
export function captureCibilConset(payload: CaptureConsentPayload) {
    return axios({
        url: API_ENDPOINTS.CAPTURE_CIBIL_CONSET,
        method: POST,
        data: {
            ...payload,
        },
    });
}
export function fetchMetadataForInputModal(payload: FetchMetadataForInputModalPayload) {
    return axios({
        url: `${uwBaseUrl}${API_ENDPOINTS.FETCH_METADATA_FOR_INPUT_MODAL}`,
        method: GET,
        params: {
            orgId: payload.assesseeOrgId,
            docId: payload.docId,
        },
    });
}
export function fetchTableDataForMIS(payload: fetchTableDataForMISPayload) {
    return axios({
        url: `${uwBaseUrl}${API_ENDPOINTS.FETCH_TABLE_DATA_FOR_MIS}`,
        method: GET,
        params: {
            id: payload.id,
            orgId: payload.orgId,
            requestId: getTimeStamp(),
        },
    });
}

export function fetchAicaOverviewData(payload) {
    return axios({
        url: API_ENDPOINTS.FETCH_AICA_OVERVIEW,
        method: GET,
        params: {
            orgId: payload.orgId,
            moduleId: payload.moduleId,
            ...(payload.resourceId && { resourceId: payload.resourceId }),
        },
    });
}

export function sendAICAOverviewFeedback(payload) {
    return axios({
        url: API_ENDPOINTS.SEND_AICA_OVERVIEW_FEEDBACK,
        method: POST,
        data: {
            orgId: payload.orgId,
            moduleId: payload.moduleId,
            ...(payload.resourceId && { resourceId: payload.resourceId }),
            generationStatus: 'COMPLETED',
            feedback: payload.feedback,
        },
    });
}

export function fetchAuditedTableData(payload: any) {
    return axios({
        baseURL: polusBaseUrl,
        url: API_ENDPOINTS.FETCH_AUDITED_TABLE_DATA,
        method: POST,
        data: {
            ...payload,
        },
    });
}

export function updateAuditedTableData(payload: any) {
    return axios({
        url: `${uwBaseUrl}${API_ENDPOINTS.UPDATE_AUDITED_TABLE_DATA}`,
        method: PUT,
        params: {
            investeeOrgId: payload.orgId,
            source: payload.source,
        },
        data: {
            classifications: payload.changedData,
            misType: payload.metrics,
            requestId: getTimeStamp(),
        },
    });
}

export function fetchMISTableOptions(payload) {
    return axios({
        url: `${uwBaseUrl}${API_ENDPOINTS.FETCH_MIS_TABLE_OPTIONS}`,
        method: GET,
        params: {
            orgId: payload?.orgId,
            source: payload?.source,
            type: payload?.type,
        },
    });
}
export function updateDataMetrics(payload: updateDataMetricsPayload) {
    return axios({
        url: `${uwBaseUrl}${API_ENDPOINTS.UPDATE_MIS_DATA_METRICS}/${payload.metadataId}/update-mapping`,
        method: PUT,
        data: {
            orgId: payload.orgId,
            changedValues: payload.changedValues,
        },
    });
}

export function underwritingExportRaw(payload: any, handlers: any = {}) {
    return fetch(
        `${uwBaseUrl}${API_ENDPOINTS.EXPORT_RAW_UNDERWRITING}?investeeOrgId=${payload.assesseeOrgId}&dataType=raw&source=AICA`,
        {
            method: 'POST',
            headers: {
                ['x-cerberus-token']: localStorage.getItem('auth_token') || '',
                ['x-user-id']: localStorage.getItem('user_id') || '',
                ['x-organization-id']: localStorage.getItem('org_id') || '',
                ['Content-Type']: 'application/json',
            },
            body: JSON.stringify({
                requiredFiles: ['GST', 'GSTR2A', 'BANK_RAW_NO_CLASSIFIED'],
            }),
        },
    )
        .then((res) => {
            const contentSize = parseInt(res.headers?.get('content-length') || '0');
            if (res.status >= 200 && res.status <= 300 && contentSize > 50) return res.blob();
            else throw new Error('');
        })
        .then((blob) => {
            const newBlob = new Blob([blob]);
            const url = window.URL.createObjectURL(newBlob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `${payload?.orgName ? payload?.orgName + '_' : ''}Raw_data.zip`,
            );
            document.body.appendChild(link);
            link.click();
            handlers?.onSuccess?.();
        })
        .catch(({ message }) => {
            message && typeof message === 'string' && toast.error(message);
        });
}

export function polusExportRaw(payload: any, handlers: any = {}) {
    payload?.setIsDownloading && payload?.setIsDownloading(true);
    return fetch(
        `${polusBaseUrl}${API_ENDPOINTS.EXPORT_RAW_POLUS}?assesseeOrgId=${payload.assesseeOrgId}`,
        {
            method: 'POST',
            headers: {
                ['x-cerberus-token']: localStorage.getItem('auth_token') || '',
                ['x-user-id']: localStorage.getItem('user_id') || '',
                ['x-organization-id']: localStorage.getItem('org_id') || '',
                ['Content-Type']: 'application/json',
            },
            body: JSON.stringify({
                requestId: getTimeStamp(),
                exportDocsRequest: payload?.exportDocsRequest ?? {
                    gst: true,
                    gstr2a: true,
                    bankRawNoClassified: true,
                    additionalDocs: true,
                    gstReports: true,
                    source: 'AICA',
                    dataType: 'raw',
                },
            }),
        },
    )
        .then((res) => {
            const isFileStreamAvailable = res.headers.get('content-type') !== 'application/json';
            if (res.status >= 200 && res.status <= 300 && isFileStreamAvailable) return res.blob();
            else throw new Error('');
        })
        .then((blob) => {
            const newBlob = new Blob([blob]);
            const url = window.URL.createObjectURL(newBlob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `${payload?.orgName ? payload?.orgName + '_' : ''}Additional_GstReports.zip`,
            );
            document.body.appendChild(link);
            link.click();
            payload?.setIsDownloading && payload?.setIsDownloading(false);
            handlers?.onSuccess();
        })
        .catch(({ message }) => {
            message && typeof message === 'string' && toast.error(message);
            payload?.setIsDownloading && payload?.setIsDownloading(false);
        });
}

export function getCompaniesLegalNamesListService(payload) {
    return axios({
        url: 'https://api.recur.club/tools/rest/search/autocomplete/companies/name',
        method: GET,
        params: {
            requestId: getTimeStamp(),
            queryString: payload?.queryString,
        },
    });
}

const API_ENDPOINTS = {
    FETCH_CONFIG: '/account/config',
    CONNECT_GST: '/data/org/gst/connect',
    GET_FINBOX_URL: '/finbox/get-session-url',

    GET_UPLOAD_DATA: '/data/org/getOnboardingData',
    UPDATE_CREDIT_BUREAU: '/data/org/updateCreditBureau',
    UPDATE_GST_REPORTS: '/data/org/updateGSTReports',
    UPDATE_FINANCIAL_REPORTS: '/data/org/updateFinancialReports',
    UPDATE_BANK_ACCOUNT_STATEMENTS: '/data/org/updateBankAccountStatement',
    UPDATE_ADDITIONAL_DOCUMENTS: '/data/org/updateAdditionalDocDetails',

    SUBMIT_DATA: '/data/org/submitData',
    EXPORT_RAW_UNDERWRITING: '/mis/download',
    EXPORT_RAW_POLUS: '/data/exportDocuments',
    SEND_CIBIL_CONSENT: '/cibil/sendConsentLink',
    CAPTURE_CIBIL_CONSET: '/cibil/captureConsent',
    UPDATE_PROVISIONAL_STATEMENTS: '/data/org/addProvisionalStatements',
    INGEST_PROVISIONAL_STATEMENTS: '/data/org/ingestProvisionalStatements',
    UPDATE_DEBT_SCHEDULE_DOCS: '/data/org/updateDebtSchedule',
    FETCH_METADATA_FOR_INPUT_MODAL: '/mis/fetch/table/metadata',
    FETCH_TABLE_DATA_FOR_MIS: '/mis/fetch/sheet/data',
    FETCH_MIS_TABLE_OPTIONS: '/mis/fetch/options',
    UPDATE_MIS_DATA_METRICS: '/mis',
    FETCH_MIS_CLASSIFIED_DATA: '/mis/fetch/classified/data',
    FETCH_MIS_RAW_DATA: '/mis/fetch/sheet/data',
    FETCH_AUDITED_TABLE_DATA: '/insights/financialsAuditedDataRange',
    UPDATE_AUDITED_TABLE_DATA: '/mis/balance-sheets/update-classifications',
    FETCH_AICA_OVERVIEW: '/data/summary/fetch',
    SEND_AICA_OVERVIEW_FEEDBACK: '/data/summary/feedback',
};

export interface UpdateCreditBureauPayload {
    lenderCMRConsent?: boolean;
    lenderDirectorCibilConsent?: boolean;
    borrowerCMRConsent?: boolean;
    borrowerDirectorCibilConsent?: boolean;
    saveCMRReportMetadata?: {
        docId: string;
        docName: string;
        isDeleted?: boolean;
        // url: string;
    };
    saveDirectorCibilMetadata?: {
        docId: string;
        docName: string;
        url: string;
    };
}

export type ProvisionalUpdatePayload = {
    assesseeOrgId: string;
    provisionalDoc: {
        docId: string;
        docName: string;
        isDeleted?: boolean;
        source?: string;
    };
    sourcesIntegrated: { [key: string]: boolean };
    onSuccess: Function;
    onError?: Function;
};
