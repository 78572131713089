import clsx from 'clsx';
import React from 'react';

import styles from './Checkbox.module.scss';

type Props = {
    label: string;
    checked: boolean;
    onChange: (checked: boolean) => void;
    isMultiSelect?: boolean;
    customStyles?: React.CSSProperties;
};

const Checkbox: React.FC<Props> = ({ label, checked, onChange, isMultiSelect, customStyles }) => {
    return (
        <label
            className={clsx(styles.Checkbox, {
                [styles.MultiSelect]: isMultiSelect,
            })}
            onClick={(e) => {
                e.stopPropagation();
            }}
            style={customStyles}
        >
            <input
                type="checkbox"
                checked={checked}
                onChange={(e) => {
                    onChange(e.target.checked);
                }}
            />
            <span className={styles.Checkmark}></span>
            {label}
        </label>
    );
};

export default Checkbox;
