import React from 'react';
import styles from './DebtProducts.module.scss';
import useLegacyBusinessDataFetch from '../DataRoom/useLegacyBusinessDataFetch';
import { createPortal } from 'react-dom';
import DashboardHeader from '../Dashboard/components/DashboardHeader/DashboardHeader';
import { Button } from '../../common/_custom';
import { caretRight, ctaGrey, debtProductsEmpty, editProfileIcon } from '../../assets/hostedassets';
import LoadingSpinner from '../../common/_custom/LoadingSpinner/LoadingSpinner';
import ManageDealDrawer from '../../common/ManageDealDrawer/ManageDealDrawer';
import { setDealDrawer } from '../../store/investor/action';
import { useAppDispatch } from '../../app/hooks';
import clsx from 'clsx';
import ProbabilityBar from './components/ProbabilityBar/ProbabilityBar';
import DebtProductOverview from './components/DebtProductOverview/DebtProductOverview';
import Tooltip from '../../common/_custom/Tooltip/Tooltip';
import { DebtProductRowData } from './debtProductTypes';
import { useParams } from 'react-router-dom';
import useDebtProductLogic from './useDebtProductLogic';

function DebtProducts() {
    const dispatch = useAppDispatch();
    const params = useParams();
    const {
        exportData,
        isUserRestricted,
        selectedCompany,
        refreshReportStatus,
        downloadPDFStatus,
        goBackToPortfolio,
        unlockAicaReport,
    } = useLegacyBusinessDataFetch();
    const header = document.getElementById('header') ?? document.createElement('div');

    const {
        loading, //component loading state
        noPecExists, //true if there are no existing PECs
        companyMetricsDataExists,
        showDetailOverview, //used to store row data for opening modal
        debtProductsData, //data used to render table
        showEmptyScreen, //boolean to show empty screen
        dealDrawerState,
        setDebtProductsData,
        setShowDetailOverview,
        fetchPECDataAndDebtProductsData,
        fetchCompanyMetrics,
        //MIXPANEL
        registerMixpanelForRowExpand,
        registerMixpanelForUserHelpClick,
        registerMixpanelForEditProfileClicked,
    } = useDebtProductLogic({ params, selectedCompany });

    // const openCreatePECDrawer = () => {
    //     dispatch(
    //         setDealDrawer({
    //             open: true,
    //             drawerLabel: 'Create New Eligibility',
    //             isPec: true,
    //         }),
    //     );
    // };

    const renderHeaderButton = () => {
        return <></>;
    };

    const renderEmptyScreen = () => {
        return (
            <div className={styles.Empty}>
                <img src={debtProductsEmpty} alt={''} height={'100px'} />
                {noPecExists ? (
                    <div className={styles.Text}>
                        {/*<span className={styles.Link} onClick={openCreatePECDrawer}>*/}
                        {/*    Add PEC*/}
                        {/*</span>*/}
                        &nbsp;Please set investment preferences first
                    </div>
                ) : companyMetricsDataExists ? (
                    <></>
                ) : (
                    <div className={styles.Text}>
                        <span
                            className={styles.Link}
                            onClick={() => {
                                dispatch(
                                    setDealDrawer({ open: true, drawerLabel: 'Edit Profile' }),
                                );
                                registerMixpanelForEditProfileClicked('Empty Screen');
                            }}
                        >
                            Edit Profile
                        </span>
                        &nbsp;to view products with probability
                    </div>
                )}
            </div>
        );
    };

    const showLoader = () => (
        <div className={styles.Loader}>
            <LoadingSpinner color="#6021B3" height={'40px'} />
            <div className={styles.Text}>Fetching Debt Products</div>
        </div>
    );

    const getSecurityTooltip = (securities: string[] = []) => {
        return (
            <div className={styles.SecuritiesTooltip}>
                {securities?.map((sec, i) => (
                    <div className={styles.SingleSecurity} key={sec}>
                        &bull;&nbsp;&nbsp;{sec}
                    </div>
                ))}
            </div>
        );
    };

    const renderRowItem = (headerId, rowData: DebtProductRowData, isParentRow = false) => {
        let renderComponent = <></>;
        switch (headerId) {
            case 'productName':
                renderComponent = (
                    <div
                        className={clsx(styles.Product, {
                            [styles.ProductSub]: !isParentRow,
                        })}
                    >
                        {(isParentRow ? rowData?.[headerId] : rowData?.['lenderName']) || '-'}
                        {isParentRow && !!rowData?.lenders?.length && (
                            <div className={styles.Count}>{rowData?.lenders?.length}</div>
                        )}
                    </div>
                );
                break;
            case 'select':
                renderComponent = <input type="checkbox" checked={false} onChange={(e) => {}} />;
                break;
            case 'caret':
                if (isParentRow)
                    renderComponent = (
                        <div
                            className={clsx(styles.CaretDiv, {
                                [styles.CaretDivHov]: rowData.expand,
                            })}
                        >
                            <img
                                className={clsx(styles.CaretImg, {
                                    [styles.ExpandedCaret]: rowData?.expand && isParentRow,
                                    [styles.DisabledCaret]: !rowData?.lenders?.length,
                                })}
                                src={caretRight}
                                height={'14px'}
                                alt={''}
                                style={{ marginTop: '4px' }}
                            />
                        </div>
                    );
                break;
            case 'probability':
                renderComponent = (
                    <div className={styles.ProbabilityRow} style={{ opacity: 1 }}>
                        <ProbabilityBar probability={rowData?.[headerId]} />
                        <img
                            onClick={(e) => {
                                e.stopPropagation();
                                setShowDetailOverview(rowData);
                                registerMixpanelForUserHelpClick(rowData, isParentRow);
                            }}
                            src={ctaGrey}
                            alt={''}
                            height={'12px'}
                        />
                    </div>
                );
                break;
            case 'security': {
                const totalElements = rowData?.security?.length;
                renderComponent = totalElements ? (
                    <Tooltip
                        content={getSecurityTooltip(rowData?.security)}
                        direction={'top'}
                        fitContent
                    >
                        <div className={styles.SecurityContainer}>
                            {rowData?.security?.slice(0, 1)?.map((sec, i) =>
                                !!sec ? (
                                    <div key={i} className={styles.SecurityItem}>
                                        {sec}
                                    </div>
                                ) : (
                                    '-'
                                ),
                            )}
                            {totalElements > 1 && (
                                <div className={styles.More}>+{totalElements - 1} more</div>
                            )}
                        </div>
                    </Tooltip>
                ) : (
                    <>-</>
                );
                break;
            }

            default:
                renderComponent = rowData?.[headerId] ?? '-';
        }

        return (
            <td
                key={header.id}
                className={clsx({
                    [styles.RowItem]: true,
                    [styles.SelectCol]: headerId === 'select',
                    [styles.CaretCol]: headerId === 'caret',
                    [styles.ProductsCol]: headerId === 'productName',
                    [styles.ProbabilityCol]: headerId === 'probability',
                    [styles.SecurityCol]: headerId === 'security',
                    [styles.DisabledRowItem]: isParentRow && !rowData?.lenders?.length,
                })}
                onClick={(e) => {
                    if (headerId === 'caret') {
                        e.stopPropagation();
                        expandOrCollapseRow(rowData, isParentRow);
                    }
                }}
            >
                {renderComponent}
            </td>
        );
    };

    const expandOrCollapseRow = (rowData, isParentRow) => {
        if (isParentRow && rowData?.lenders?.length) {
            if (!rowData.expand) {
                registerMixpanelForRowExpand(rowData);
            }
            setDebtProductsData((existing: any) => ({
                ...existing,
                rows: existing.rows.map((row) => ({
                    ...row,
                    ...(row.productId === rowData.productId && {
                        expand: !row.expand,
                    }),
                })),
            }));
        }
    };

    return (
        <div className={styles.DebtProducts}>
            {createPortal(
                <DashboardHeader
                    exportData={exportData}
                    isUserRestricted={isUserRestricted}
                    selectedCompany={selectedCompany}
                    refreshReportStatus={refreshReportStatus}
                    downloadPDFStatus={downloadPDFStatus}
                    goBackToPortfolio={goBackToPortfolio}
                    renderHeaderButton={renderHeaderButton}
                    unlockAicaReport={unlockAicaReport}
                />,
                header,
            )}

            <div className={styles.Container}>
                <div className={styles.Top}>
                    <div className={styles.Title}>Debt Products</div>
                    <div className={styles.Right}>
                        <Button
                            text={'Edit Profile'}
                            onClick={() => {
                                dispatch(
                                    setDealDrawer({ open: true, drawerLabel: 'Edit Profile' }),
                                );
                                registerMixpanelForEditProfileClicked('Kebab Menu');
                            }}
                            variant="purple"
                            style={{ marginTop: '0' }}
                            icon={editProfileIcon}
                        />
                    </div>
                </div>

                <div className={styles.Body}>
                    <div className={styles.TableContainer}>
                        <table className={styles.Table}>
                            <thead>
                                <tr className={styles.HeadersRow}>
                                    {debtProductsData?.headers?.map((header) => (
                                        <th
                                            key={header?.id}
                                            className={clsx({
                                                [styles.Header]: true,
                                                [styles.SelectCol]: header.id === 'select',
                                                [styles.CaretCol]: header.id === 'caret',
                                                [styles.ProductsCol]: header.id === 'productName',
                                                [styles.ProbabilityCol]:
                                                    header.id === 'probability',
                                                [styles.SecurityCol]: header.id === 'security',
                                            })}
                                        >
                                            {header.label}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            {!loading && !showEmptyScreen && (
                                <tbody>
                                    {debtProductsData?.rows?.map(
                                        (rowData: DebtProductRowData, i) => (
                                            <React.Fragment key={i}>
                                                <tr
                                                    key={`${i}-${rowData?.productId}`}
                                                    className={styles.TableRow}
                                                    onClick={() => {
                                                        setShowDetailOverview(rowData);
                                                        registerMixpanelForUserHelpClick(
                                                            rowData,
                                                            true,
                                                        );
                                                    }}
                                                >
                                                    {debtProductsData?.headers?.map((header) => (
                                                        <React.Fragment key={header.id}>
                                                            {renderRowItem(
                                                                header.id,
                                                                rowData,
                                                                true,
                                                            )}
                                                        </React.Fragment>
                                                    ))}
                                                </tr>
                                                {rowData?.expand &&
                                                    rowData?.lenders?.map(
                                                        (lenderData: DebtProductRowData, j) => (
                                                            <tr
                                                                key={j}
                                                                className={clsx(
                                                                    styles.TableRow,
                                                                    styles.Subrow,
                                                                )}
                                                                onClick={() => {
                                                                    setShowDetailOverview(
                                                                        lenderData,
                                                                    );
                                                                    registerMixpanelForUserHelpClick(
                                                                        rowData,
                                                                        false,
                                                                    );
                                                                }}
                                                            >
                                                                {debtProductsData?.headers?.map(
                                                                    (header) => (
                                                                        <React.Fragment
                                                                            key={header.id}
                                                                        >
                                                                            {renderRowItem(
                                                                                header.id,
                                                                                lenderData,
                                                                                false,
                                                                            )}
                                                                        </React.Fragment>
                                                                    ),
                                                                )}
                                                            </tr>
                                                        ),
                                                    )}
                                            </React.Fragment>
                                        ),
                                    )}
                                </tbody>
                            )}
                        </table>
                        {loading && showLoader()}
                        {!loading && showEmptyScreen && renderEmptyScreen()}
                    </div>
                </div>
            </div>

            {dealDrawerState.open && (
                <ManageDealDrawer
                    source={dealDrawerState?.drawerSource ?? 'Debt Products'}
                    onClose={() => {
                        fetchPECDataAndDebtProductsData();
                        fetchCompanyMetrics();
                    }}
                />
            )}

            {showDetailOverview && (
                <DebtProductOverview
                    data={showDetailOverview}
                    closeModal={() => setShowDetailOverview(null)}
                />
            )}
        </div>
    );
}

export default DebtProducts;
