import * as Effects from 'redux-saga/effects';
import * as apiService from './service';
import { CONSTANTS } from './constants';
import { toast } from 'react-toastify';
import { takeEvery } from 'redux-saga/effects';
import { JAVA_API_STATUS, SOMETHING_WRONG } from '../../../utils/axios-interceptor';
const call: any = Effects.call;
const put: any = Effects.put;
const takeLatest: any = Effects.takeLatest;

function* getAuditedFinancialsReportId(action: any) {
    try {
        const response = yield call(apiService.getReportId, action.payload);
        const responseData = response?.data?.responseData;
        const auditedFinancialsData = response?.data?.auditFinancials;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                action.handlers?.onSuccess?.(auditedFinancialsData?.reportId);
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                action.handlers?.onError?.();
                return toast.error(responseData?.responseMessage || SOMETHING_WRONG);
        }
    } catch (err: any) {
        action.handlers?.onError?.();
        console.error(err?.message || 'Some error occoured');
    }
}

export default function* actionWatcher() {
    yield takeLatest(CONSTANTS.GET_AUDITED_FINANCIALS_REPORT_ID, getAuditedFinancialsReportId);
}
