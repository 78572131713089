// -- REDUX CONSTANTS --//

const namespace = 'DASHBOARD_';

export const CONSTANTS = {
    SET_IS_LOADING: `${namespace}SET_IS_LOADING`,
    SET_IS_LOADING_HEADLINE: `${namespace}SET_IS_LOADING_HEADLINE`,
    SET_IS_LOADING_GRAPH: `${namespace}SET_IS_LOADING_GRAPH`,
    SET_IS_LOADING_AICA_GRADE_DATA: `${namespace}SET_IS_LOADING_AICA_GRADE_DATA`,
    SET_SHOW_AICA_GRADE_DRAWER: `${namespace}SET_SHOW_AICA_GRADE_DRAWER`,
    RESET_IS_FETCHED_GRAPH: `${namespace}RESET_IS_FETCHED_GRAPH`,
    INITIALIZE_GRAPH_CARDS: `${namespace}INITIALIZE_GRAPH_CARDS`,

    GET_DASHBOARD_CONFIG: `${namespace}GET_DASHBOARD_CONFIG`,
    GET_DASHBOARD_CONFIG_SUCCESS: `${namespace}GET_DASHBOARD_CONFIG_SUCCESS`,

    GET_HEADLINE_DATA: `${namespace}GET_HEADLINE_DATA`,
    GET_HEADLINE_DATA_SUCCESS: `${namespace}GET_HEADLINE_DATA_SUCCESS`,
    GET_TAB_DATA_SUCCESS: `${namespace}GET_TAB_DATA_SUCCESS`,

    GET_GRAPH_DATA: `${namespace}GET_GRAPH_DATA`,
    GET_GRAPH_DATA_SUCCESS: `${namespace}GET_GRAPH_DATA_SUCCESS`,
    GET_GRAPH_DATA_ERROR: `${namespace}GET_GRAPH_DATA_ERROR`,

    GET_AICA_GRADE_DATA: `${namespace}GET_AICA_GRADE_DATA`,
    GET_AICA_GRADE_DATA_SUCCESS: `${namespace}GET_AICA_GRADE_DATA_SUCCESS`,
};

// ---- xx ---- //

//-- GRAPH CONSTANTS --//

export const GRAPH_TYPES = {
    LINE: 'linechart',
    AREA_CHART: 'areachart',
    WATERFALL: 'waterfall',
    STACKBAR: 'stackbar',
    DOUBLEBAR: 'DoubleBar', //not needed
    LINEBAR: 'linebarchart',
    DASHEDLINEBAR: 'dashedlinebarchart',
    BRUSH: 'brush',
    BARCHART: 'barchart',
    HORIZONTAL_BARCHART: 'horizontalbarchart',
    PIECHART: 'piechart',
    PIECHARTCUSTOMTOOLTIP: 'piechartwithcustomtooltip',
    VERTICALBARCHART: 'verticalBarchart',
    // INDIAMAP: 'indiamap',
    MONTHLYVIEW: 'monthlyview',
    HEATMAP: 'heatmap',
    YEAR_MONTH_TABLE: 'yearMonthTable',
};

export const GRAPH_NAMES = {
    REVENUE: 'revenue',
    REVENUE_GROWTH: 'revenueGrowth',
    EXISTING_VS_NEW_CUSTOMER: 'existingVsNewCustomer',
    SPLIT_OF_EXISTING_CUSTOMERS: 'splitOfExsistingCustomers',
    AVG_REVENUE_PER_CUSTOMER: 'avgRevenuePerCustomer',
    DOMESTIC_REVENUE_ACROSS_STATES: 'domesticRevenueAcrossStates',
    ARPC: 'arpc',
    CUSTOMER_CONCENTRATION: 'customerConcentration',
    TOP_FIVE_CUSTOMERS: 'topFiveCustomers',
    REVENUE_BRIDGE: 'revenueBridge',
    REVENUES_BY_SEGMENT: 'revenuesBySegment',
    REVENUE_BY_HSN_CATEGORY: 'revenueByHsnCategory',
    CUSTOMER_COHORT: 'customerCohort',
    REVENUE_TYPE: 'revenueType',
    // vendor expense
    VENDOR_EXPENSES_VS_REVENUE: 'vendorExpensesVsRevenue',
    EXPENSES_CHANGE: 'expensesChange',
    EXISTING_VS_NEW_EXPENSES: 'existingVsNewExpenses',
    SPLIT_OF_EXISTING_VENDORS: 'splitOfExsitingVendors',
    DOMESTIC_PURCHASE_ACROSS_STATES: 'domesticPurchaseAcrossStates',
    AVG_EXPENSE_PER_VENDOR: 'avgExpensePerVendor',
    VENDOR_EXPENSE_CONCENTRATION: 'vendorExpenseConcentration',
    TOP_FIVE_VENDORS: 'topFiveVendors',
    TOP_FIVE_NON_COMPLIANT_VENDORS: 'topFiveNonCompliantVendors',
    GSTR1_FILING_STATUS: 'gstr1FilingStatus',
    SALES_UNDER_REPORTING: 'salesUnderReporting',
    GSTR3B_FILING_STATUS: 'gstr3bFilingStatus',
    ITC_REPORTED_VS_CLAIMED: 'itrcReportedVsClaimed',
    CASH_BALANCE: 'cashBalance',
    CASH_FLOW_MOVEMENT: 'cashFlowMovement',
    TOTAL_COSTS: 'totalCosts',
    GROSS_MARGIN: 'grossMargin',
    EBITDA: 'ebitda',
    PNL_SNAPSHOT: 'pnlSnapshot',
    WORKING_CAPITAL_CYCLE: 'workingCapitalCycle',
    CASHFLOW_SNAPSHOT: 'cashflowSnapshot',
};

export const TABLES = {
    COMPLIANCES: {
        LEGAL_CASES: 'legalCases',
    },
};

export const DATA_FETCH_STATUS = {
    PROCESSING: 'Processing',
    PROCESSED: 'Processed',
};
