import { useEffect, useState } from 'react';
import { Modal } from '../../../../../../../common/_custom';
import styles from './MapDataModal.module.scss';
import ConsentScreens from './ModalScreens/ConsentScreens';
import { useSearchParams } from 'react-router-dom';
import clsx from 'clsx';
import DataInputScreen from './ModalScreens/DataInputScreen';
import React from 'react';
import FirstConsentScreen from './ModalScreens/FirstConsentScreen';

type MapDataModalProps = {
    showModal: {
        mapDataModal: boolean;
    };
    setShowModal: Function;
    setShowDrawer: Function;
    fetchDropdownData: Function;
    rawDropdownOptions: any;
    sheetNameOptions: string[];
    metricRangeOptions: string[];
    dateRangeOptions: string[];
    fillDataAccordingly: Function;
    importMetrics: {
        sheetName: string;
        metricRange: string;
        dateRange: string;
    };
    setImportMetrics: Function;
    isInvestor: boolean;
    setDateRangeOptions: Function;
    setSheetNameOptions: Function;
};

function MapDataModal(props: MapDataModalProps) {
    const {
        showModal,
        setShowModal,
        setShowDrawer,
        fetchDropdownData,
        rawDropdownOptions,
        sheetNameOptions,
        metricRangeOptions,
        dateRangeOptions,
        fillDataAccordingly,
        importMetrics,
        setImportMetrics,
        isInvestor,
        setDateRangeOptions,
        setSheetNameOptions,
    } = props;
    const [currentScreen, setCurrentScreen] = useState<number | null>();
    const [params, setParams] = useSearchParams();
    useEffect(() => {
        if (params.get('currentScreen')) {
            setCurrentScreen(parseInt(params.get('currentScreen') ?? '0'));
        } else {
            setCurrentScreen(null);
        }
    }, [params.get('currentScreen')]);
    const renderModalScreens = () => {
        switch (currentScreen) {
            case 1:
                return (
                    <DataInputScreen
                        setSheetNameOptions={setSheetNameOptions}
                        isInvestor={isInvestor}
                        setShowDrawer={setShowDrawer}
                        setImportMetrics={setImportMetrics}
                        importMetrics={importMetrics}
                        setDateRangeOptions={setDateRangeOptions}
                        setShowModal={setShowModal}
                        fetchDropdownData={fetchDropdownData}
                        rawDropdownOptions={rawDropdownOptions}
                        sheetNameOptions={sheetNameOptions}
                        metricRangeOptions={metricRangeOptions}
                        dateRangeOptions={dateRangeOptions}
                        fillDataAccordingly={fillDataAccordingly}
                    />
                );
            case 2:
                return (
                    <FirstConsentScreen
                        isInvestor={isInvestor}
                        setImportMetrics={setImportMetrics}
                        setShowModal={setShowModal}
                        setCurrentScreen={setCurrentScreen}
                    />
                );
            default:
                return (
                    <ConsentScreens
                        isInvestor={isInvestor}
                        setImportMetrics={setImportMetrics}
                        setShowModal={setShowModal}
                        setCurrentScreen={setCurrentScreen}
                    />
                );
        }
    };

    return (
        <Modal
            className={styles.MapDataModal}
            open={showModal.mapDataModal}
            style={{ padding: '2rem' }}
            onClose={() => setShowModal({ mapDataModal: false, closeModal: false })}
            size="flexible"
        >
            <div
                className={clsx({
                    [styles.Enter]: currentScreen !== null,
                    [styles.ModalBody]: true,
                })}
            >
                {renderModalScreens()}
            </div>
        </Modal>
    );
}

export default MapDataModal;
