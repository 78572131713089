import { useEffect, useState } from 'react';
import { DebtProductRowData } from './debtProductTypes';
import { useAppSelector } from '../../app/hooks';
import { USER_TYPE } from '../../utils/constants/user';
import { fetchConfigByConfigName } from '../../store/investor/investorApiHelper';
import { fetchAllPecData } from '../../store/investor/apiHelpers';
import {
    getCompanyMetrics,
    getDebtProductsData,
} from '../../store/debtProducts/apiHelper_debtProducts';
import { Mixpanel } from '../../utils/mixpanel';
import {
    CLICK_PROBABILITY_HELP,
    EXPAND_PRODUCT,
    OPEN_EDIT_PROFILE,
    VIEW_DEBT_PRODUCTS_PAGE,
} from '../../utils/constants/mixpanelEvents/investorEvents';
import { getTimeStamp } from '../../utils/dateUtils';

interface DebtProductsState {
    headers: Array<{ id: string; label: string }>;
    rows: DebtProductRowData[];
}

const useDebtProductLogic = ({ params, selectedCompany }) => {
    const [loading, setLoading] = useState(false);
    const [showDetailOverview, setShowDetailOverview] = useState<null | DebtProductRowData>(null);
    const [debtProductsData, setDebtProductsData] = useState<DebtProductsState>({
        headers: [],
        rows: [],
    });
    const [noPecExists, setNoPECExists] = useState(false);
    const [companyMetricsDataExists, setCompanyMetricsDataExists] = useState(false);

    const showEmptyScreen = noPecExists || !companyMetricsDataExists;

    const dealDrawerState = useAppSelector((state) => state.investor.dealDrawer);
    const user = useAppSelector((state) => state.user);
    const isLender = user.userData.accountType === USER_TYPE.LENDER;

    useEffect(() => {
        Mixpanel.track(VIEW_DEBT_PRODUCTS_PAGE, {
            user_email: user.userData.primaryEmail,
            timestamp: getTimeStamp(),
            company_id: selectedCompany?.assesseeOrgId,
            company_name: selectedCompany?.orgName,
            pno_id: user.userData.investorOrgId,
            pno_name: user.domainMetadata?.orgName,
        });
    }, []);

    useEffect(() => {
        if (user.userData.investorOrgId) {
            fetchHeadersFromConfig();
            fetchPECDataAndDebtProductsData();
            fetchCompanyMetrics();
        }
    }, [user.userData.investorOrgId]);

    const fetchHeadersFromConfig = () => {
        fetchConfigByConfigName({
            accountType: user.userData.accountType,
            investorOrgId: user.userData.investorOrgId,
            primaryEmail: user.userData.primaryEmail,
            assesseeOrgId: params.id as string,
            userRole: user.userData?.roles?.[0],
            configName: 'AICA_DEBT_PRODUCT',
        })
            .then((configResponse) => {
                let headers = [
                    { id: 'caret', label: '-' },
                    ...(configResponse?.additionalDetailsConfig?.columns || []),
                ];
                setDebtProductsData((existing) => ({
                    ...existing,
                    headers: headers,
                }));
            })
            .catch(() => {});
    };

    const fetchCompanyMetrics = () => {
        getCompanyMetrics({
            assesseeOrgId: params.id as string,
        }).then((data) => {
            if (Object.keys(data || {})?.length) {
                setCompanyMetricsDataExists(true);
            }
        });
        // .catch((err) => {
        //
        // });
    };
    const fetchPECDataAndDebtProductsData = () => {
        setLoading(true);
        fetchAllPecData({
            requestPayload: {
                aggregatorId: user.userData?.investorOrgId,
                investmentPreferenceType: 'PEC',
                ...(isLender && { lenderId: user.userData?.lenderOrgId }),
            },
        })
            .then((PECdata) => {
                if (PECdata?.length) {
                    setNoPECExists(false);
                    fetchDebtProductsData();
                } else {
                    setNoPECExists(true);
                    setLoading(false);
                }
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const fetchDebtProductsData = () => {
        getDebtProductsData({
            orgId: params.id as string,
            aggregatorId: user.userData.investorOrgId,
        })
            .then((data) => {
                const debtProductsWithLenders =
                    data?.filter((rowdata) => !!rowdata?.lenders?.length) || [];
                const debtProductsWithoutLenders =
                    data?.filter((rowdata) => !rowdata?.lenders?.length) || [];

                setDebtProductsData((e) => ({
                    ...e,
                    rows: [...debtProductsWithLenders, ...debtProductsWithoutLenders],
                }));
                setLoading(false);
            })
            .catch((err) => {
                console.error('Error in fetching Debt Products Data', err);
                setLoading(false);
            });
    };

    const registerMixpanelForRowExpand = (rowData) => {
        Mixpanel.track(EXPAND_PRODUCT, {
            user_email: user.userData.primaryEmail,
            timestamp: getTimeStamp(),
            productId: rowData?.productId,
            company_id: selectedCompany?.assesseeOrgId,
            company_name: selectedCompany?.orgName,
            pno_id: user.userData.investorOrgId,
            pno_name: user.domainMetadata?.orgName,
        });
    };

    const registerMixpanelForUserHelpClick = (rowData, isParentRow = false) => {
        Mixpanel.track(CLICK_PROBABILITY_HELP, {
            user_email: user.userData.primaryEmail,
            timestamp: getTimeStamp(),
            productId: rowData?.productId,
            company_id: selectedCompany?.assesseeOrgId,
            company_name: selectedCompany?.orgName,
            pno_id: user.userData.investorOrgId,
            pno_name: user.domainMetadata?.orgName,
            ...(!isParentRow && {
                lender_id: rowData?.lenderId,
                lender_name: rowData?.lenderName,
            }),
        });
    };

    const registerMixpanelForEditProfileClicked = (source) => {
        Mixpanel.track(OPEN_EDIT_PROFILE, {
            user_email: user.userData.primaryEmail,
            timestamp: getTimeStamp(),
            source: source,
            company_id: selectedCompany?.assesseeOrgId,
            company_name: selectedCompany?.orgName,
            pno_id: user.userData.investorOrgId,
            pno_name: user.domainMetadata?.orgName,
        });
    };

    return {
        loading,
        noPecExists,
        companyMetricsDataExists,
        showDetailOverview,
        setShowDetailOverview,
        debtProductsData,
        showEmptyScreen,
        dealDrawerState,
        setDebtProductsData,
        fetchPECDataAndDebtProductsData,
        fetchCompanyMetrics,
        //Mixpanel
        registerMixpanelForRowExpand,
        registerMixpanelForUserHelpClick,
        registerMixpanelForEditProfileClicked,
    };
};

export default useDebtProductLogic;
