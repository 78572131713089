import styles from '../MagicUpload.module.scss';
import closeIcon from '../../../common/_custom/SideDrawer/close.svg';
import { useState } from 'react';
import useMagicUploadLogic from '../useMagicUploadLogic';
import clsx from 'clsx';
import MagicUploadFilesTable from './MagicUploadFilesTable/MagicUploadFilesTable';
import MagicUploadCenter from './MagicUploadCenter/MagicUploadCenter';
import { MagicUploadFileData } from '../useFetchDataForMU';
import { useAppSelector } from '../../../app/hooks';

const DrawerContent = ({ toggleDrawer }) => {
    const { tabState, setTabState } = useMagicUploadLogic();
    const [fileData, setFileData] = useState<MagicUploadFileData[]>([]);
    const untaggedCount = useAppSelector((state) => state.investor.untaggedCount);

    return (
        <div style={{ position: 'relative', height: '100%' }}>
            <div className={styles.drawerHeader}>
                <div className={styles.DrawerText}>
                    <h3 className={styles.Title}>Magic Upload</h3>
                    <div className={styles.VerticalSeparator} />
                    <div className={styles.TabSelector}>
                        <div
                            onClick={() => setTabState('all')}
                            className={clsx({
                                [styles.Tab]: true,
                                [styles.Active]: tabState === 'all',
                            })}
                        >
                            All
                        </div>
                        <div
                            onClick={() => setTabState('tagged')}
                            className={clsx({
                                [styles.Tab]: true,
                                [styles.Active]: tabState === 'tagged',
                            })}
                        >
                            Tagged
                        </div>
                        <div
                            onClick={() => setTabState('new')}
                            className={clsx({
                                [styles.Tab]: true,
                                [styles.Active]: tabState === 'new',
                            })}
                        >
                            Untagged <span style={{ color: '#B4B4B4' }}>{untaggedCount}</span>
                        </div>
                    </div>
                </div>
                <div className={styles.closeIcon}>
                    <img onClick={() => toggleDrawer()} src={closeIcon} alt="close" />
                </div>
            </div>
            {tabState === 'all' && (
                <MagicUploadCenter
                    fileData={fileData}
                    setFileData={setFileData}
                    tabState={tabState}
                />
            )}
            <MagicUploadFilesTable
                fileData={fileData}
                setFileData={setFileData}
                tabState={tabState}
            />
        </div>
    );
};

export default DrawerContent;
