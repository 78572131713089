import { UpdateCreditBureauPayload } from '../../../../../store/company/service';
import { COMPANY_CB_PROVIDE_CONSENT } from '../../../../../utils/constants/mixpanelEvents/companyEvents';
import { USER_TYPE } from '../../../../../utils/constants/user';
import { Mixpanel } from '../../../../../utils/mixpanel';
import UploadCard from '../UploadCard/UploadCard';
import { FILE_FORMATS } from '../UploadCard/utils/utils';
import styles from './CompanyCMR.module.scss';

export const CMR_DATA = 'cmrData';
interface CompanyCMRProps {
    updateFunction: (payload: UpdateCreditBureauPayload, updateKey: string) => void;
    userType: string;
    cmrData: any;
    assesseeOrgId: string;
    categoryId: number;
    flowId: number;
}

function CompanyCMR({
    cmrData,
    updateFunction,
    userType,
    assesseeOrgId,
    categoryId,
    flowId,
}: CompanyCMRProps) {
    const lenderConsent = cmrData?.lenderCMRConsent || false;
    const borrowerConsent = cmrData?.borrowerCMRConsent || false;
    const updateBorrowerCMRConsent = (e) => {
        const checked = e.target?.checked || false;
        checked &&
            Mixpanel.track(COMPANY_CB_PROVIDE_CONSENT, {
                type: 'CompanyCMR',
            });
        updateFunction(
            {
                borrowerCMRConsent: checked,
            },
            CMR_DATA,
        );
    };

    const updateLenderCMRConsent = (e) => {
        const checked = e.target?.checked || false;
        updateFunction(
            {
                lenderCMRConsent: checked,
            },
            CMR_DATA,
        );
    };

    const updateCreditBureauOnFileUpload = async (fileData) => {
        await updateFunction(
            {
                saveCMRReportMetadata: {
                    docId: fileData.url,
                    docName: fileData.fileName,
                },
            },
            CMR_DATA,
        );
    };

    const deleteFile = async (fileData) => {
        await updateFunction(
            {
                saveCMRReportMetadata: {
                    docId: fileData.docId,
                    docName: fileData.docName,
                    isDeleted: true,
                },
            },
            CMR_DATA,
        );
    };
    return (
        <div className={styles.CompanyCMR}>
            <div className={styles.Title}>Company CMR</div>

            {userType === USER_TYPE.COMPANY && (
                <>
                    <div className={styles.Text}>
                        We need consent for CMR report of company to process the application
                    </div>

                    <div className={styles.Check}>
                        <input
                            type="checkbox"
                            id="terms"
                            checked={borrowerConsent}
                            onChange={updateBorrowerCMRConsent}
                            // disabled={!!borrowerConsent}
                        />
                        I, hereby, give consent to fetch my CMR report.
                    </div>
                </>
            )}

            {userType === USER_TYPE.INVESTOR && (
                <>
                    <div className={styles.Check}>
                        <input
                            type="checkbox"
                            id="terms"
                            // value={1}
                            onChange={updateLenderCMRConsent}
                            checked={borrowerConsent ? borrowerConsent : lenderConsent}
                            disabled={!!borrowerConsent}
                        />
                        We have consent of borrower to fetch their details
                    </div>
                    <UploadCard
                        disabled={borrowerConsent ? false : !lenderConsent}
                        uploadText="Upload Company CMR"
                        existingFiles={cmrData?.lenderCmrReports || []}
                        acceptedFormats={FILE_FORMATS.PDF_AND_ZIP}
                        assesseeOrgId={assesseeOrgId}
                        metadataUpdate={updateCreditBureauOnFileUpload}
                        // categoryId={18}
                        categoryId={categoryId}
                        flowId={flowId}
                        // flowId={16}
                        fileDeleteHandler={deleteFile}
                    />
                </>
            )}
        </div>
    );
}

export default CompanyCMR;
