import React, { useState } from 'react';
import { useEffect } from 'react';
import styles from '../CountryCodesDropdown/CountryCodesDropdown.module.scss';
import { fetchCountryCodes } from '../../../../store/company/apiHelpers';

export interface CountryCodeData {
    countryId: string;
    countryName: string;
    countryCode: string;
    callingCode: string;
    minCallingNumberLength: number;
    maxCallingNumberLength: number;
}

function CountryCodesDropdown({
    setCountryCode,
    style = undefined,
    isModal = false,
    countryCodeDefault = '',
    disabled = false,
    needNumber = false,
}) {
    const { countryCodeBox, modalCountryCodebox } = styles;
    const [countryCodes, setCountryCodes] = useState<CountryCodeData[]>([]);

    useEffect(() => {
        fetchCountryCodes({ onSuccess: onFetchCountryCodesSuccess });
    }, []);

    const onFetchCountryCodesSuccess = (list = []) => {
        setCountryCodes(list);
        setCountryCode(list?.[0]);
    };

    const handleChange = (i) => {
        setCountryCode(countryCodes[i]);
    };

    return (
        <select
            disabled={disabled}
            style={style}
            className={isModal ? modalCountryCodebox : countryCodeBox}
            defaultValue={countryCodeDefault}
            onChange={(e) => handleChange(e.target.value)}
        >
            {countryCodes &&
                countryCodes.map((item, index) => {
                    return (
                        <option key={index} value={index}>
                            +{item.callingCode} {!needNumber && `(${item.countryCode})`}
                        </option>
                    );
                })}
        </select>
    );
}

export default CountryCodesDropdown;
