import { useRef, useEffect } from 'react';

/**
 * A custom useEffect hook that only triggers on updates, not on initial mount
 * @param {Function} effect
 * @param {Array<any>} dependencies
 */
export function useUpdateEffect(effect: Function, dependencies: any[] = []) {
    const isInitialMount = useRef(true);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            return effect();
        }
    }, dependencies);
}

export const useApplyTheme = (uiStyle) => {
    useEffect(() => {
        if (uiStyle) {
            const root = document.documentElement;
            const buttons = uiStyle?.buttons;
            const tabs = uiStyle?.tabs;
            const texts = uiStyle?.texts;
            const backgrounds = uiStyle?.backgrounds;
            const borders = uiStyle?.borders;

            root.style.setProperty('--primary-button-bg', buttons?.primary?.backgroundColour);
            root.style.setProperty('--primary-button-text', buttons?.primary?.textColour);

            root.style.setProperty('--secondary-button-bg', buttons?.secondary?.backgroundColour);
            root.style.setProperty('--secondary-button-text', buttons?.secondary?.textColour);
            root.style.setProperty('--secondary-button-border', buttons?.secondary?.borderColour);

            root.style.setProperty('--tertiary-button-text', buttons?.tertiary?.textColour);

            root.style.setProperty('--tab-active-bg', tabs?.active?.backgroundColour);
            root.style.setProperty('--tab-active-text', tabs?.active?.textColour);

            root.style.setProperty('--tab-inactive-bg', tabs?.inactive?.backgroundColour);
            root.style.setProperty('--tab-inactive-text', tabs?.inactive?.textColour);

            root.style.setProperty('--primary-text-colour', texts?.primary?.textColour);

            root.style.setProperty('--primary-bg-colour', backgrounds?.primary?.backgroundColour);
            root.style.setProperty(
                '--secondary-bg-colour',
                backgrounds?.secondary?.backgroundColour,
            );

            root.style.setProperty('--primary-border-colour', borders?.primary?.borderColour);
        }
    }, [uiStyle]);
};
