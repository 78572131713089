export const orgTypeOptions: { id: number | string; label: string }[] = [
    {
        id: '1',
        label: 'Equity - Venture Capital',
    },
    {
        id: '2',
        label: 'Equity - Private Equity',
    },
    {
        id: '3',
        label: 'Equity - Growth Equity Firms',
    },
    {
        id: '4',
        label: 'Equity - Hedge Funds',
    },
    {
        id: '5',
        label: 'Equity - Pension Funds',
    },
    {
        id: '6',
        label: 'Equity - Others',
    },
    {
        id: '7',
        label: 'Debt - Banks',
    },
    {
        id: '8',
        label: 'Debt - NBFCs',
    },
    {
        id: '9',
        label: 'Debt - Hedge Funds',
    },
    {
        id: '10',
        label: 'Debt - Mutual Fund Companies',
    },
    {
        id: '11',
        label: 'Debt - Others',
    },
    {
        id: '12',
        label: 'Direct Selling Agent (DSA)',
    },
    {
        id: '13',
        label: 'Others',
    },
];
