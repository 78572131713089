import { Handlers } from '../../types/actions/types';
import { CONSTANTS, UnlockAICASourcePage } from './constants';
import { DealDrawerState } from './reducer';
import {
    CompanyUpdatePayload,
    DealUpdatePayload,
    FetchDealMetricsPayload,
    FetchDealsPayload,
    FetchInvestmentPreferencesPayload,
    FetchLenderDealsPayload,
    InvestorAccessTypePermissionServicePayload,
    InvestorDealUpdatePayload,
} from './service';

export function fetchInvestorConfig(payload: FetchInvestorConfigPayload) {
    return {
        type: CONSTANTS.FETCH_INVESTOR_CONFIG,
        payload,
    };
}

export function fetchLenderConfig(payload: FetchInvestorConfigPayload) {
    return {
        type: CONSTANTS.FETCH_LENDER_CONFIG,
        payload,
    };
}

export function fetchPortfolioData(payload: FetchPortfolioDataPayload) {
    return {
        type: CONSTANTS.FETCH_PORTFOLIO_DATA,
        payload,
    };
}

export function updateUntaggedMuFileNumber(uploadCount: number) {
    return {
        type: CONSTANTS.UPDATE_UNTAGGED_MU_FILE_NUMBER,
        payload: uploadCount,
    };
}

export function fetchOwners(payload: GetOwnerDataPayload) {
    return {
        type: CONSTANTS.FETCH_OWNER_DATA,
        payload,
    };
}

export function fetchInvestorAccessTypePermissions(
    payload: InvestorAccessTypePermissionServicePayload,
    handlers: Handlers,
) {
    return {
        type: CONSTANTS.FETCH_INVESTOR_PERMISSIONS,
        payload,
        handlers,
    };
}

export function fetchSingleCompanyDetails(payload, handlers) {
    return {
        type: CONSTANTS.FETCH_SINGLE_COMPANY_DETAILS,
        payload,
        handlers,
    };
}

export function initiateReportGeneration(payload: InitiateReportGenerationPayload) {
    return {
        type: CONSTANTS.INITIATE_REPORT_GENERATION,
        payload,
    };
}

export function inviteCompany(payload: InviteCompanyPayload, handlers) {
    return {
        type: CONSTANTS.INVITE_COMPANY,
        payload,
        handlers,
    };
}

export const inviteCompanyV2 = (payload: InviteCompanyPayloadV2, handlers) => {
    return {
        type: CONSTANTS.INVITE_COMPANY_V2,
        payload,
        handlers,
    };
};

export function changeSelectedFetchType(payload: any) {
    return {
        type: CONSTANTS.SET_PORTFOLIO_SELECTED_FETCH_TYPE,
        payload,
    };
}

export function changePortfolioPageNum(payload: any) {
    return {
        type: CONSTANTS.SET_PORTFOLIO_PAGE_NUMBER,
        payload,
    };
}

export function setShowUpgradeToScan(payload: string) {
    return {
        type: CONSTANTS.SET_SHOW_UPGRADE_TO_SCAN,
        payload,
    };
}

export function setShowUnlockAicaConfirmation(payload: any) {
    return {
        type: CONSTANTS.SET_SHOW_UNLOCK_AICA_CONFIRMATION,
        payload,
    };
}

export function updateCompany(payload: CompanyUpdatePayload, handlers?: Handlers) {
    return {
        type: CONSTANTS.UPDATE_COMPANY,
        payload,
        handlers,
    };
}

export function createDeal(payload: any, handlers) {
    return {
        type: CONSTANTS.CREATE_DEAL,
        payload,
        handlers,
    };
}

export function setDealDrawer(payload: DealDrawerState) {
    return {
        type: CONSTANTS.SET_DEAL_DRAWER,
        payload,
    };
}

export function getMetricsForDealCreation(payload: FetchDealMetricsPayload, handlers) {
    return {
        type: CONSTANTS.METRICS_FOR_DEAL_CREATION,
        payload,
        handlers,
    };
}

export function getPecMatchingData(payload: any, handlers) {
    return {
        type: CONSTANTS.PEC_MATCHING,
        payload,
        handlers,
    };
}

export function getAllDeals(payload: FetchDealsPayload) {
    return {
        type: CONSTANTS.GET_ALL_DEALS,
        payload,
    };
}

export function getAllLenderDeals(payload: FetchLenderDealsPayload) {
    return {
        type: CONSTANTS.GET_ALL_LENDER_DEALS,
        payload,
    };
}

export function updateDeal(payload: DealUpdatePayload) {
    return {
        type: CONSTANTS.UPDATE_DEAL,
        payload,
    };
}

export function updateInvestorDeal(payload: InvestorDealUpdatePayload) {
    return {
        type: CONSTANTS.UPDATE_INVESTOR_DEAL,
        payload,
    };
}

export function getDealData(payload: { dealId: string }, handlers) {
    return {
        type: CONSTANTS.GET_DEAL_DATA,
        payload,
        handlers,
    };
}

export function getLenderDealData(payload: { dealId: string }, handlers) {
    return {
        type: CONSTANTS.GET_LENDER_DEAL_DATA,
        payload,
        handlers,
    };
}

export function getInvestmentPreferences(payload: FetchInvestmentPreferencesPayload, handlers) {
    return {
        type: CONSTANTS.GET_INVESTMENT_PREFERENCES,
        payload,
        handlers,
    };
}

export function getInvestmentPreference(payload: { investmentPreferenceId: string }, handlers) {
    return {
        type: CONSTANTS.GET_INVESTMENT_PREFERENCE,
        payload,
        handlers,
    };
}

export function updateInvestmentPreference(payload: any, handlers) {
    return {
        type: CONSTANTS.UPDATE_INVESTMENT_PREFERENCE,
        payload,
        handlers,
    };
}

export function createInvestmentPreference(payload: any, handlers) {
    return {
        type: CONSTANTS.CREATE_INVESTMENT_PREFERENCE,
        payload,
        handlers,
    };
}

export function setShowUnlockAicaAndAddToLeads(payload: ShowUnlockAicaAndAddToLeadsPayload) {
    return {
        type: CONSTANTS.SET_SHOW_UNLOCK_AICA_AND_ADD_TO_LEADS,
        payload,
    };
}

export function setShowInviteUserModal(payload: boolean) {
    return {
        type: CONSTANTS.SET_SHOW_INVITE_USER,
        payload,
    };
}

export function fetchTabLockStatus(payload: FetchTabLockStatusPayload) {
    return {
        type: CONSTANTS.FETCH_TAB_LOCK_STATUS,
        payload,
    };
}

export type FetchInvestorConfigPayload = {
    accountType: string;
    primaryEmail: string;
    investorOrgId: string;
    assesseeOrgId?: string;
    reportId?: string;
    userRole?: string;
    configName?: string;
};

export type FetchPortfolioDataPayload = {
    portfolioTypeToFetch: string;
    pageNumber: number;
    investorOrgId: string;
    companyAccessType: string;
    partnerUserId?: string;
    companyName?: string;
};

export type GetOwnerDataPayload = {
    investorOrgId: string;
};

export type InviteCompanyPayload = {
    accountType: string;
    primaryEmail: string;
    orgDisplayName: string;
    pan: string;
    journeyName: string;
    websiteUrl?: string;
    sector?: string;
    investorOrgId: string;
    phoneNumber: string;
    countryId: string;
    countryCode: string;
    // primaryUserName: string;
};
export type InviteCompanyPayloadV2 = {
    user: {
        pan: string;
        name: string;
        emailId: string;
        investorId: string;
        accountType: string;
        password: string;
        journeyName: string;
        sector?: string;
        mobileNumber: string;
        countryId: string;
        countryCode: string;
    };
    platformId: string;
};

export type InitiateReportGenerationPayload = {
    assesseeOrgId: string;
    portfolioTypeToFetch: string;
    pageNumber: number;
    investorOrgId: string;
};

export type ShowUnlockAicaAndAddToLeadsPayload = {
    showModal: boolean;
    assesseeOrgId?: string;
    orgName?: string;
    source?: UnlockAICASourcePage;
};

export type FetchTabLockStatusPayload = {
    tabsList: string[];
    assesseeOrgId: string;
};
