import { useEffect, useRef, useState } from 'react';
import { Button, Input, Modal } from '../../../../../common/_custom';
import { addCompanyToWatchlist } from '../../../../../store/investor/investorApiHelper';
import {
    IDENTIFIER,
    WEBSITE,
    WEBSITE_ERROR_MSG,
} from '../../../../../utils/constants/companyLogin';
import styles from './AddToWatchlist.module.scss';
import { Mixpanel } from '../../../../../utils/mixpanel';
import { INVESTOR_ADD_TO_WATCHLIST_CLICK } from '../../../../../utils/constants/mixpanelEvents/investorEvents';
import { infoPurpleIcon } from '../../../../../assets/hostedassets';
import CompanyLegalNameSearch, {
    CompanyData,
} from '../../../../../common/CompanyLegalNameSearch/CompanyLegalNameSearch';

//component states
const { ENTER_WESBITE, WEBSITE_OK, IDENTIFIER_ERROR, IDENTIFIER_OK, SUCCESS } = {
    ENTER_WESBITE: 'ENTER_WEBSITE',
    // WEBSITE_ERROR: 'WEBSITE_ERROR',
    WEBSITE_OK: 'WEBSITE_OK',
    IDENTIFIER_ERROR: 'IDENTIFIER_ERROR',
    IDENTIFIER_OK: 'IDENTIFIER_OK',
    SUCCESS: 'SUCCESS',
};

export enum ADD_JOURNEY {
    ADD_TO_SCAN = 'ADD_TO_SCAN',
    ADD_TO_WATCH = 'ADD_TO_WATCH',
}

function AddToWatchlist({
    prefilledData,
    journey,
    modalOpen,
    closeModal,
    investorOrgId,
    fetchPortfolioCompanies = () => {},
    onSuccess = (data) => {},
}: {
    prefilledData?: string;
    journey: ADD_JOURNEY;
    modalOpen?: any;
    closeModal: any;
    investorOrgId: string;
    fetchPortfolioCompanies?: () => void;
    onSuccess?: (data: { orgName: string; assesseeOrgId: string }) => void;
}) {
    const [loading, setLoading] = useState(false);
    const [identifierState, setIdentitifierState] = useState(ENTER_WESBITE);
    const [isPANselected, setIsPANselected] = useState(false);
    const [formData, setFormData] = useState({
        [WEBSITE]: '',
        [IDENTIFIER]: '',
    });
    const [formErrors, setFormErrors] = useState({
        [WEBSITE]: '',
        [IDENTIFIER]: '',
    });
    const hasError = !!formErrors[WEBSITE] || !!formErrors[IDENTIFIER];
    const isAddToWatch = journey === ADD_JOURNEY.ADD_TO_WATCH;

    const inviteCode =
        sessionStorage.getItem('invite_code') ?? localStorage.getItem('invite_code') ?? '';
    const companyPickedFromDropdown = useRef<CompanyData>(null);

    const changeFormValue = (value, key) => {
        setFormData((formData) => ({
            ...formData,
            [key]: value,
        }));
    };
    useEffect(() => {
        if (prefilledData) {
            setIsPANselected(true);
            setIdentitifierState('');
            setFormData({
                [WEBSITE]: '',
                [IDENTIFIER]: prefilledData,
            });
        }
    }, [prefilledData]);

    const addToWatchlist = async () => {
        Mixpanel.track(INVESTOR_ADD_TO_WATCHLIST_CLICK);
        if (formData[WEBSITE] && !formData[WEBSITE]?.includes('.')) {
            setFormErrors((errors) => ({
                ...errors,
                [WEBSITE]: WEBSITE_ERROR_MSG,
            }));
            return;
        }
        setLoading(true);
        await addCompanyToWatchlist(
            {
                ...(formData[WEBSITE] && {
                    websiteUrl: formData[WEBSITE],
                }),
                investorOrgId: investorOrgId,
                journeyName: journey,
                ...(identifierState !== ENTER_WESBITE && { identifier: formData[IDENTIFIER] }),
                ...(!!companyPickedFromDropdown.current && {
                    isPickedFromCompaniesList: true,
                    companyDetails: {
                        ...companyPickedFromDropdown.current,
                    },
                }),
                inviteCode: inviteCode,
                // accountType: USER_TYPE.COMPANY
            },
            {
                onSuccess: (data) => {
                    if (prefilledData) {
                        setFormData({
                            [WEBSITE]: '',
                            [IDENTIFIER]: '',
                        });
                        setIdentitifierState(SUCCESS);
                        fetchPortfolioCompanies?.();
                        closeModal?.();
                        onSuccess?.({
                            orgName: data?.orgInfo?.orgName,
                            assesseeOrgId: data?.orgInfo?.assesseeOrgId,
                        });
                    }
                    if (data?.domainErrorMessage) {
                        setFormErrors((errors) => ({
                            ...errors,
                            [WEBSITE]: data?.domainErrorMessage,
                        }));
                        if (data?.domainErrorMessage?.includes('legal name')) {
                            setIdentitifierState(WEBSITE_OK);
                        }
                    } else if (data?.identifierErrorMessage) {
                        setFormErrors((errors) => ({
                            ...errors,
                            [IDENTIFIER]: data?.identifierErrorMessage,
                        }));
                        setIdentitifierState(IDENTIFIER_ERROR);
                    } else if (data?.orgInfo?.orgName) {
                        if (
                            identifierState === IDENTIFIER_OK ||
                            identifierState === IDENTIFIER_ERROR ||
                            identifierState === WEBSITE_OK
                        ) {
                            setIdentitifierState(SUCCESS);
                            fetchPortfolioCompanies?.();
                            closeModal?.();
                            onSuccess?.({
                                orgName: data?.orgInfo?.orgName,
                                assesseeOrgId: data?.orgInfo?.assesseeOrgId,
                            });
                        } else {
                            setFormData((formdata) => ({
                                ...formdata,
                                [IDENTIFIER]: data?.orgInfo?.orgName,
                            }));
                            setIdentitifierState(IDENTIFIER_OK);
                        }
                    }
                    setLoading(false);
                },
                onError: () => {
                    setLoading(false);
                },
            },
        );
    };

    const handlePANselection = () => {
        setFormData({
            [WEBSITE]: '',
            [IDENTIFIER]: '',
        });
        setFormErrors({
            [WEBSITE]: '',
            [IDENTIFIER]: '',
        });
        setIsPANselected((prevIsPANselected) => {
            const newIsPANselected = !prevIsPANselected;
            if (newIsPANselected) setIdentitifierState(IDENTIFIER_OK);
            else setIdentitifierState(ENTER_WESBITE);
            return newIsPANselected;
        });
    };

    const showMessage = (isPANselected, identifierState) => {
        if (identifierState === ENTER_WESBITE) return 'Or Add using Legal Name or PAN?';
        else {
            if (isPANselected) return 'Or Add using Website?';
            return 'Or Add using Legal Name or PAN?';
        }
    };

    return (
        <Modal
            open={modalOpen ?? true}
            onClose={() => closeModal(false)}
            size="flexible"
            persistent
        >
            <div className={styles.AddToWatchlist}>
                <div className={styles.Title}>{isAddToWatch ? 'Add Prospect' : 'Add Lead'}</div>
                {!isPANselected && (
                    <Input
                        placeholder="Company Website"
                        value={formData[WEBSITE]}
                        onChange={(val) => {
                            setFormErrors({ [WEBSITE]: '', [IDENTIFIER]: '' });
                            changeFormValue(val, WEBSITE);
                        }}
                        style={{ marginTop: '36px' }}
                        disabled={
                            loading ||
                            identifierState === WEBSITE_OK ||
                            identifierState === IDENTIFIER_OK ||
                            identifierState === IDENTIFIER_ERROR
                        }
                        isValid={!formErrors[WEBSITE]}
                        errorMessage={formErrors[WEBSITE]}
                    />
                )}
                {identifierState !== ENTER_WESBITE && (
                    <>
                        <CompanyLegalNameSearch
                            prefilledData={prefilledData}
                            placeholder={
                                isPANselected ? 'Company Legal Name or PAN' : 'Company Legal Name'
                            }
                            value={formData[IDENTIFIER]}
                            onChange={(val) => {
                                if (identifierState === IDENTIFIER_OK)
                                    setIdentitifierState(WEBSITE_OK);
                                setFormErrors({ [WEBSITE]: '', [IDENTIFIER]: '' });
                                changeFormValue(val, IDENTIFIER);
                            }}
                            errorMessage={formErrors[IDENTIFIER]}
                            isValid={!formErrors[IDENTIFIER]}
                            disabled={loading}
                            inputStyle={{ fontWeight: '550' }}
                            source={'add_prospect'}
                            companyPickedFromDropdown={companyPickedFromDropdown}
                        />
                        {identifierState === IDENTIFIER_OK && formData[IDENTIFIER] && (
                            <div className={styles.IdentifierFound}>
                                <img src={infoPurpleIcon} alt="" height={'10px'} />
                                Legal Name found! Please validate before submitting it.
                            </div>
                        )}
                    </>
                )}
                <Button
                    disabled={
                        loading ||
                        hasError ||
                        ((identifierState !== ENTER_WESBITE || !formData[WEBSITE]) &&
                            !formData[IDENTIFIER])
                    }
                    variant="primary"
                    onClick={addToWatchlist}
                    text={identifierState === ENTER_WESBITE ? 'Proceed' : 'Submit'}
                    style={{ width: '100%', marginTop: '24px' }}
                    loading={loading}
                />
                <div>
                    <button className={styles.PANoption} onClick={handlePANselection}>
                        {showMessage(isPANselected, identifierState)}
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default AddToWatchlist;
