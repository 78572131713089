import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import { useState } from 'react';

import { Input } from '../../../../../../common/_custom';
import {
    GREEN_BG_WHITE_TICK_CIRCLE,
    VERIFY_GREY_CIRCLE,
    VERIFY_PURPLE_CIRCLE,
    verifyOrange,
} from '../../../../../../assets/hostedassets';

import styles from '../DirectorDetails.module.scss';
import LoadingSpinner from '../../../../../../common/_custom/LoadingSpinner/LoadingSpinner';
import FetchFillIcon from '../../../../../../assets/Svg/FetchFilIcon';

const renderAsset = (asset: string | React.ReactNode) => {
    if (typeof asset === 'string') {
        return <img src={asset} alt="" className={styles.VerifyIcon} />;
    }
    return asset;
};

interface DirectorDetails {
    directorName: string;
    phoneNo: string;
    consentStatus?: boolean;
    lastInviteSentAt?: string;
    isNewDir?: boolean;
}

interface DirectorInputFieldProps {
    director: DirectorDetails;
    index: number;
    handleChange: (e: string, index: number, name: string) => void;
    sendLink: (name: string, phoneNo: string, setDisableSendLink: Function, input: number) => void;
    errorMessage: string;
    isLoading?: boolean;
    isError: boolean;
}

function DirectorInputField({
    director,
    index,
    handleChange,
    sendLink,
    errorMessage,
    isLoading,
    isError,
}: DirectorInputFieldProps) {
    const { FormContainer, Field, DisabledBannerText, BannerTextVerified, BannerText } = styles;

    const [disableSendLink, setDisableSendLink] = useState<boolean>(true);

    return (
        <div key={index} className={styles.dflex}>
            <div style={{ width: '40%' }} className={styles.FormContainer}>
                <div className={styles.Field}>{director.directorName}</div>
            </div>
            <div style={{ width: '60%' }} className={FormContainer}>
                <div className={Field}>
                    <Input
                        name="phoneNo"
                        type="text"
                        style={{ margin: 0 }}
                        inputStyle={{
                            backgroundColor: director.consentStatus ? '#ffffff' : '#f9f9f9',
                            borderColor: director.consentStatus ? '#D9D9D9' : 'transparent',
                            borderWidth: '0.5px',
                            borderStyle: 'solid',
                        }}
                        value={director.phoneNo}
                        onChange={(e) => handleChange(e, index, 'phoneNo')}
                        disabled={director.consentStatus}
                        placeholder="Director Mobile No."
                        errorMessage={errorMessage}
                        isValid={!isError}
                    />
                    {director.phoneNo &&
                        (director.consentStatus ? (
                            <div className={BannerTextVerified}>
                                {renderAsset(GREEN_BG_WHITE_TICK_CIRCLE)}
                                <span>Verified</span>
                            </div>
                        ) : (
                            <div
                                onClick={() => {
                                    sendLink(
                                        director.directorName,
                                        director.phoneNo,
                                        setDisableSendLink,
                                        index,
                                    );
                                }}
                                className={clsx(BannerText, {
                                    [DisabledBannerText]:
                                        !disableSendLink || director.phoneNo.length !== 10,
                                })}
                            >
                                {isLoading ? (
                                    <LoadingSpinner color={'var(--primary-text-colour)'} />
                                ) : (
                                    <div className={styles.VerifyImg}>
                                        {renderAsset(
                                            director?.phoneNo?.length === 10 ? (
                                                <FetchFillIcon
                                                    colour="var(--primary-text-colour)"
                                                    height="15"
                                                    width="15"
                                                    className={styles.VerifyIcon}
                                                />
                                            ) : (
                                                VERIFY_GREY_CIRCLE
                                            ),
                                        )}
                                        <span>Verify</span>
                                    </div>
                                )}
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
}

export default DirectorInputField;
