import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Descendant, createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { Slate, Editable, withReact } from 'slate-react';
import Toolbar from './Toolbar/Toolbar';
import { getMarked, getBlock } from './utils/SlateUtilityFunctions.js';
import withLinks from './plugins/withLinks.js';
import withTables from './plugins/withTable.js';
import withEmbeds from './plugins/withEmbeds.js';
import withEquation from './plugins/withEquation.js';
import styles from './TextEditor.module.scss';

const Element = (props) => {
    return getBlock(props);
};
const Leaf = (props) => {
    let { attributes, children, leaf } = props;
    children = getMarked(leaf, children);
    return <span {...attributes}>{children}</span>;
};
const SlateEditor = ({ editorInitialData, targetRef, assesseeOrgId, saving }) => {
    const [editor] = useState(() =>
        withEquation(withHistory(withEmbeds(withTables(withLinks(withReact(createEditor())))))),
    );

    useEffect(() => {
        localStorage.setItem('one_pager_content', JSON.stringify(editorInitialData));
        localStorage.setItem('one_pager_assessee', assesseeOrgId);
    }, []);

    const handleEditorChange = (value) => {
        const isAstChange = editor.operations.some((op) => 'set_selection' !== op.type);
        if (isAstChange) {
            // Save the value to Local Storage.
            const content = JSON.stringify(value);
            localStorage.setItem('one_pager_content', content);
            // localStorage.setItem('one_pager_assessee', assesseeOrgId);
        }
    };

    const renderElement = useCallback((props) => <Element {...props} />, []);

    const renderLeaf = useCallback((props) => {
        return <Leaf {...props} />;
    }, []);

    const [htmlAction, setHtmlAction] = useState({
        showInput: false,
        html: '',
        action: '',
        location: '',
    });

    const handleCodeToText = (partialState) => {
        setHtmlAction((prev) => ({
            ...prev,
            ...partialState,
        }));
    };

    return (
        <div className={styles.SlateWrapper}>
            <Slate editor={editor} initialValue={editorInitialData} onChange={handleEditorChange}>
                <Toolbar handleCodeToText={handleCodeToText} />
                <div
                    className={styles.EditorWrapper}
                    ref={targetRef}
                    id="element-to-be-downloaded-as-pdf"
                >
                    <Editable
                        placeholder="Write your text here..."
                        renderElement={renderElement}
                        renderLeaf={renderLeaf}
                    />
                </div>
            </Slate>
        </div>
    );
};

export default SlateEditor;
