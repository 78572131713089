import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { plusPurpleCircle, searchIcon } from '../../assets/hostedassets';
import { Input } from '../_custom';
import styles from './CompanyLegalNameSearch.module.scss';
import useDebounce from '../../utils/constants/useDebounce';
import { getCompaniesLegalNamesList } from '../../store/company/apiHelpers';
import LoadingSpinner from '../_custom/LoadingSpinner/LoadingSpinner';
import { validatePAN } from '../../utils/validatePAN';

export type CompanyData = {
    id: string;
    name: string;
    registrationCategory: string;
    companyType: string;
    identifier: string;
    companyClass: string;
    registrationDate: string;
    dateAdded: string;
    activityCode: string;
    source: string;
};

interface CompanyLegalNameSearchProps {
    placeholder: string;
    value: string;
    onChange: Function;
    errorMessage: string;
    isValid: boolean;
    disabled?: boolean;
    inputStyle?: React.CSSProperties;
    source: 'add_prospect' | 'add_leads' | 'company_signup';
    companyPickedFromDropdown: MutableRefObject<CompanyData | null>;
    prefilledData?: string;
}

function CompanyLegalNameSearch({
    placeholder,
    value,
    onChange,
    errorMessage,
    isValid,
    disabled = false,
    inputStyle = {},
    source,
    companyPickedFromDropdown,
    prefilledData,
}: CompanyLegalNameSearchProps) {
    const [showOptions, setShowOptions] = useState(false);
    const [optionsList, setOptionsList] = useState<CompanyData[]>([]);
    const [loading, setLoading] = useState(false);
    const debouncedQueryText = useDebounce(value?.trim(), 300);
    const skipSearchRef = useRef(true);

    useEffect(() => {
        if (debouncedQueryText?.length > 2) {
            !skipSearchRef.current && fetchCompaniesList(debouncedQueryText);
        } else {
            setShowOptions(false);
            setOptionsList([]);
        }
    }, [debouncedQueryText]);

    useEffect(() => {
        if (prefilledData) {
            fetchCompaniesList(prefilledData);
        }
    }, [prefilledData]);

    const fetchCompaniesList = (searchTerm = '') => {
        setLoading(true);
        getCompaniesLegalNamesList(
            { queryString: searchTerm },
            {
                onSuccess: (data) => {
                    setOptionsList(data);
                    setLoading(false);
                    setShowOptions(true);
                },
                onError: () => {},
            },
        );
    };

    const onTextChange = (val) => {
        companyPickedFromDropdown.current = null;
        skipSearchRef.current = false;
        onChange(val);
    };

    const onOptionClick = (company: CompanyData) => {
        companyPickedFromDropdown.current = company;
        skipSearchRef.current = true;
        setShowOptions(false);
        onChange(company.name);
    };

    return (
        <div className={styles.CompanyLegalNameSearch} id="CompanyDropDown">
            <Input
                placeholder={placeholder}
                onChange={onTextChange}
                value={value}
                errorMessage={errorMessage}
                isValid={isValid}
                inputStyle={inputStyle}
                disabled={disabled}
            />
            {loading ? (
                <LoadingSpinner className={styles.Icon} height="15px" color="#e5e5e5" />
            ) : (
                <img className={styles.Icon} src={searchIcon} alt="" height={'15px'} />
            )}

            {showOptions && !validatePAN(value) && (
                <div className={styles.Options}>
                    {optionsList?.map((company: CompanyData) => (
                        <div
                            className={styles.Option}
                            onClick={() => onOptionClick(company)}
                            key={company.identifier}
                        >
                            <div className={styles.Left}>{company.name}</div>
                            <div className={styles.Right}>{company.identifier}</div>
                        </div>
                    ))}
                    {!validatePAN(value) && (
                        <div
                            className={styles.AddNew}
                            onClick={() => {
                                setShowOptions(false);
                                setOptionsList([]);
                            }}
                        >
                            <img src={plusPurpleCircle} alt="" height={'15px'} />
                            Add new organisation "{value}"
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default CompanyLegalNameSearch;
