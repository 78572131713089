import styles from './DashboardHeader.module.scss';
import { ACCESS_TYPES } from '../../../../store/investor/constants';
import { FEATURE_STATUS } from '../../../../store/user/constants';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { backButtonImg, backButtonImgHovered } from './TabConstants';
import ReportNavigation from './components/ReportNavigation/ReportNavigation';
import HeaderButtonContainer from './components/HeaderButtonContainer/HeaderButtonContainer';
// import { tabOptions } from '../../../../common/Topbar/Topbar';
import React, { useEffect, useState } from 'react';
import { INVESTOR_ROUTES } from '../../../../utils/constants/routesConst';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../../../../app/hooks';

type DashboardHeaderProps = {
    isUserRestricted: boolean;
    selectedCompany: any;
    metricScoreGenerated?: boolean;
    refreshReportStatus: string;
    downloadPDFStatus: string;
    goBackToPortfolio: () => void;
    renderHeaderButton: () => void;
    exportData: () => void;
    unlockAicaReport?: () => void;
};

const DashboardHeader = (props: DashboardHeaderProps) => {
    const {
        isUserRestricted,
        selectedCompany,
        metricScoreGenerated,
        refreshReportStatus,
        downloadPDFStatus,
        goBackToPortfolio,
        exportData,
        renderHeaderButton,
        unlockAicaReport,
    } = props;
    const dispatch = useDispatch();
    const isDSInvestor = localStorage.getItem('isDSInvestor') === 'true';
    const permissions = useAppSelector((state) => state.investor.permissions);
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [selected, setSelected] = useState<any>({
        id: 'report',
        label: 'Aica Report',
        img: 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/AicaReportHeaderIcon.svg',
        route: '',
        isLocked: false,
    });
    useEffect(() => {
        if (selected.id === 'dataroom') {
            navigate(
                `${INVESTOR_ROUTES.APP}/${INVESTOR_ROUTES.DATA_ROOM}${
                    params.id ?? ''
                }?${searchParams.toString()}`,
            );
        }
    }, [selected]);
    return (
        <div className={styles.DashboardHeader}>
            <div className={styles.CompanyName}>
                {!isUserRestricted && (
                    <img
                        onMouseEnter={(e) => (e.currentTarget.src = backButtonImgHovered)}
                        onMouseLeave={(e) => (e.currentTarget.src = backButtonImg)}
                        alt="back"
                        src={backButtonImg}
                        className={styles.HeaderBackButton}
                        onClick={() => goBackToPortfolio()}
                    />
                )}
                <div className={styles.Name}>
                    {/*@todo: the first character is a fallback we need to add favicons here.*/}
                    <span className={styles.FavIcon}>
                        {selectedCompany?.orgName?.slice(0, 1) ?? ''}
                    </span>
                    <div className={styles.NameTextContainer}>
                        <span className={styles.NameText} title={selectedCompany?.orgName}>
                            {selectedCompany?.orgName}
                        </span>
                        <span className={styles.SubText}>
                            Created on {dayjs(selectedCompany?.dateCreated).format('DD MMM YYYY')}
                        </span>
                    </div>
                </div>
                {/* {selectedCompany?.companyAccessType !== ACCESS_TIERS.WATCH &&
                   metricScoreGenerated && (
                       <AicaGradeBatch
                           showAicaGradeInfo={() => dispatch(setShowAicaGradeDrawer(true))}
                       />
                   )} */}
            </div>
            <div className={styles.DashboardHeaderMiddle}>
                {!isDSInvestor && <ReportNavigation selectedCompany={selectedCompany} />}
                {/*<TabSlider*/}
                {/*    selected={selected}*/}
                {/*    onChange={(tab) => {*/}
                {/*        setSelected(tab);*/}
                {/*    }}*/}
                {/*    navbarItems={[...tabOptions]}*/}
                {/*/>*/}
            </div>
            <div className={styles.DashboardHeaderRight}>
                <>
                    {permissions?.TRACK !== ACCESS_TYPES.LOCKED && renderHeaderButton()}
                    <HeaderButtonContainer
                        exportData={exportData}
                        selectedCompany={selectedCompany}
                        refreshReportStatus={refreshReportStatus}
                        downloadPDFStatus={downloadPDFStatus}
                        unlockAicaReport={unlockAicaReport}
                    />
                    {refreshReportStatus && refreshReportStatus === FEATURE_STATUS.SHOW && (
                        <div className={styles.RefreshReport}>Refresh Report</div>
                    )}
                    {downloadPDFStatus && downloadPDFStatus === FEATURE_STATUS.SHOW && (
                        <div className={styles.DownloadPDF}>Download PDF</div>
                    )}
                </>
            </div>
        </div>
    );
};

export default DashboardHeader;
