import React from 'react';
import styles from '../ProvisionalFinancials.module.scss';
import { INTEGRATION_LOGOS, INTEGRATION_TYPES, allIntegrations } from '../../Integrations';
import clsx from 'clsx';
import Tooltip from '../../../../../../common/_custom/Tooltip/Tooltip';
import uploadSmallPurple from '../../../../../../assets/upload-small-purple.svg';

type LandingCardProps = {
    selected: string | null;
    integration: { [key: string]: boolean } | null;
    selectOption: (option: string) => void;
    integrationClick: (type: string) => void;
    isInvestor: boolean;
};

function LandingCard(props: LandingCardProps) {
    const { selected, integration, selectOption, integrationClick, isInvestor } = props;
    const UPLOADED_MANUAL =
        'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/UploadProvisionalsGreen.svg';
    const UPLOAD_PURPLE =
        'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/uploadManuallyInvestor.svg';
    const BANNER_ICON = 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/lightBulbBlack.svg';
    return (
        <div>
            <h3 className="mainHeading">Add Provisional Financials for last 24 months</h3>
            <div className={styles.MainBanner}>
                <img src={BANNER_ICON} alt="Banner Icon" />
                {/* <p>Connecting software is recommended, manual uploading slow underwriting</p> */}
                <p> Uploading Tally is recommended, manual uploading slows underwriting</p>
            </div>
            {isInvestor ? (
                <>
                    <div style={{ gap: '1rem' }} className={styles.InvestorOptions}>
                        {/*{allIntegrations?.map((item, index) => {*/}
                        {/*    return (*/}
                        {/*        <>*/}
                        {/*            <div*/}
                        {/*                key={index}*/}
                        {/*                style={{ width: '100%', margin: '0' }}*/}
                        {/*                className={clsx({*/}
                        {/*                    [styles.Option]: true,*/}
                        {/*                    [styles.Integrated]: integration?.[item],*/}
                        {/*                    [styles.Selected]: selected === item,*/}
                        {/*                })}*/}
                        {/*                onClick={() => integrationClick(item)}*/}
                        {/*            >*/}
                        {/*                <img*/}
                        {/*                    src={INTEGRATION_LOGOS[item]}*/}
                        {/*                    height="14px"*/}
                        {/*                    alt="zoho"*/}
                        {/*                />*/}
                        {/*                <div className={styles.uploadManually}>*/}
                        {/*                    <div*/}
                        {/*                        style={*/}
                        {/*                            integration?.[item]*/}
                        {/*                                ? { color: '#00BD00' }*/}
                        {/*                                : { color: '#6021B3' }*/}
                        {/*                        }*/}
                        {/*                        className={styles.upload}*/}
                        {/*                    >*/}
                        {/*                        {item === INTEGRATION_TYPES.TALLY*/}
                        {/*                            ? 'Upload Tally'*/}
                        {/*                            : 'Upload CSV'}*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </>*/}
                        {/*    );*/}
                        {/*})}*/}
                        <div
                            className={clsx({
                                [styles.Option]: true,
                                [styles.Integrated]: integration?.manualUpload,
                                [styles.Selected]: selected === INTEGRATION_TYPES.manualUpload,
                            })}
                            onClick={() => selectOption(INTEGRATION_TYPES.manualUpload)}
                        >
                            <img
                                src={integration?.manualUpload ? UPLOADED_MANUAL : UPLOAD_PURPLE}
                                height="18px"
                                alt="zoho"
                            />
                            <div className={styles.uploadManually}>
                                <div
                                    style={{ color: 'var(--primary-text-colour)' }}
                                    className={styles.upload}
                                >
                                    Upload manually
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div style={{ gap: '1rem' }} className={styles.Options}>
                        <Tooltip
                            containerFullWidth
                            fitContent
                            content={
                                <span style={{ fontSize: '0.8rem', whiteSpace: 'nowrap' }}>
                                    Coming Soon
                                </span>
                            }
                            direction="top"
                        >
                            <div
                                className={clsx({
                                    [styles.Option]: true,
                                    [styles.Integrated]: integration?.zoho,
                                    [styles.Selected]: selected === INTEGRATION_TYPES.zoho,
                                    [styles.Disabled]: true,
                                })}
                            >
                                <div className={styles.uploadManually}>
                                    <div
                                        style={
                                            integration?.zoho
                                                ? { color: '#00BD00' }
                                                : { color: '#fff' }
                                        }
                                        className={styles.upload}
                                    >
                                        <img
                                            src={
                                                'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/zoho-logo.svg'
                                            }
                                            height="12px"
                                            alt="zoho"
                                            style={{ marginRight: '0.5rem' }}
                                        />
                                        Connect Zoho
                                    </div>
                                </div>
                            </div>
                        </Tooltip>
                        {/*{allIntegrations?.map((item, index) => {*/}
                        {/*    return (*/}
                        {/*        <>*/}
                        {/*            <div*/}
                        {/*                key={index}*/}
                        {/*                style={{ width: '100%', margin: '0' }}*/}
                        {/*                className={clsx({*/}
                        {/*                    [styles.Option]: true,*/}
                        {/*                    [styles.Integrated]: integration?.[item],*/}
                        {/*                    [styles.Selected]: selected === item,*/}
                        {/*                })}*/}
                        {/*                onClick={() => integrationClick(item)}*/}
                        {/*            >*/}
                        {/*                <img*/}
                        {/*                    src={INTEGRATION_LOGOS[item]}*/}
                        {/*                    height="14px"*/}
                        {/*                    alt="zoho"*/}
                        {/*                />*/}
                        {/*                <div className={styles.uploadManually}>*/}
                        {/*                    <div*/}
                        {/*                        style={*/}
                        {/*                            integration?.[item]*/}
                        {/*                                ? { color: '#00BD00' }*/}
                        {/*                                : { color: '#2F8FFF' }*/}
                        {/*                        }*/}
                        {/*                        className={styles.upload}*/}
                        {/*                    >*/}
                        {/*                        {item === INTEGRATION_TYPES.TALLY*/}
                        {/*                            ? 'Upload Tally'*/}
                        {/*                            : 'Upload CSV'}*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </>*/}
                        {/*    );*/}
                        {/*})}*/}
                    </div>
                    <div className={styles.Options}>
                        <div
                            className={clsx({
                                [styles.Option]: true,
                                [styles.Integrated]: integration?.manualUpload,
                                [styles.Selected]: selected === INTEGRATION_TYPES.manualUpload,
                            })}
                            onClick={() => selectOption(INTEGRATION_TYPES.manualUpload)}
                        >
                            <img
                                src={integration?.manualUpload ? UPLOADED_MANUAL : UPLOAD_PURPLE}
                                height="20px"
                                alt="zoho"
                            />
                            <div className={styles.uploadManually}>
                                <div
                                    style={
                                        integration?.manualUpload
                                            ? { color: '#00BD00' }
                                            : { color: 'var(--primary-text-colour)' }
                                    }
                                    className={styles.upload}
                                >
                                    Upload manually
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default LandingCard;
