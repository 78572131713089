import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import createSagaMiddleware from '@redux-saga/core';
import userReducerV2 from './user/userV2/reducer';
import companyReducer from './company/reducer';
import investorReducer from './investor/reducer';
import dashboardReducer from './dashboard/reducer';
import periodSelectorReducer from './periodSelector/reducer';
import creditBureauReducer from './dashboard/creditBureau/reducer';
import rootSaga from './rootsaga';

const sagaMiddleware = createSagaMiddleware();

const combinedDashboardReducer = combineReducers({
    dashboardReducer: dashboardReducer,
    creditBureau: creditBureauReducer,
});

const rootReducer = combineReducers({
    user: userReducerV2,
    company: companyReducer,
    investor: investorReducer,
    dashboard: combinedDashboardReducer,
    periodSelector: periodSelectorReducer,
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: (defaultMiddleware) =>
        defaultMiddleware({
            serializableCheck: false,
        }).concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
