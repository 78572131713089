import { COMPANY_CB_PROVIDE_CONSENT } from '../../../../../utils/constants/mixpanelEvents/companyEvents';
import { USER_TYPE } from '../../../../../utils/constants/user';
import { Mixpanel } from '../../../../../utils/mixpanel';
import DirectorDetails from '../DirectorDetails/DirectorDetails';
import UploadCard from '../UploadCard/UploadCard';
import { FILE_FORMATS } from '../UploadCard/utils/utils';
import styles from './DirectorCibil.module.scss';
import { uploadCibil } from '../../../../../assets/hostedassets';

export const DIRECT_CIBIL_DATA = 'directorCibilData';

function DirectorCibil({
    directorCibilData,
    updateFunction,
    userType,
    assesseeOrgId,
    categoryId,
    flowId,
}) {
    const lenderConsent = directorCibilData?.lenderDirectorCibilConsent || false;
    const borrowerConsent = directorCibilData?.borrowerDirectorCibilConsent || false;
    const updateBorrowerCibilConsent = (e) => {
        const checked = e.target?.checked || false;
        checked &&
            Mixpanel.track(COMPANY_CB_PROVIDE_CONSENT, {
                type: 'DirectorCibil',
            });
        updateFunction(
            {
                borrowerDirectorCibilConsent: checked,
            },
            DIRECT_CIBIL_DATA,
        );
    };

    const updateLenderCibilConsent = (e) => {
        const checked = e.target?.checked || false;
        updateFunction(
            {
                lenderDirectorCibilConsent: checked,
            },
            DIRECT_CIBIL_DATA,
        );
    };

    const updateDirectorCibilOnFileUpload = async (fileData) => {
        await updateFunction(
            {
                saveDirectorCibilMetadata: {
                    docId: fileData.docId,
                    docName: fileData.fileName,
                    isDeleted: false,
                },
            },
            DIRECT_CIBIL_DATA,
        );
    };

    const deleteFile = async (fileData) => {
        await updateFunction(
            {
                saveDirectorCibilMetadata: {
                    docId: fileData.docId,
                    docName: fileData.docName,
                    isDeleted: true,
                },
            },
            DIRECT_CIBIL_DATA,
        );
    };
    return (
        <div className={styles.DirectorCibil}>
            {userType === USER_TYPE.COMPANY && (
                <>
                    <DirectorDetails isInvestorSide={false} />
                </>
            )}

            {userType === USER_TYPE.INVESTOR && (
                <>
                    {/* <div className={styles.Title}>Director CIBIL</div> */}
                    {/* <div className={styles.Check}>
                        <input
                            type="checkbox"
                            id="terms"
                            value={1}
                            onChange={updateLenderCibilConsent}
                            checked={borrowerConsent ? borrowerConsent : lenderConsent}
                            disabled={!!borrowerConsent}
                        />
                        We have consent of borrower to fetch their details
                    </div> */}
                    <div className={styles.CIBIL}>
                        <img src={uploadCibil} alt="" />
                    </div>
                    <UploadCard
                        existingFiles={directorCibilData?.lenderDirectorCibilReports || []}
                        disabled={borrowerConsent ? false : !lenderConsent}
                        uploadText="Upload CIBIL reports of all directors"
                        acceptedFormats={FILE_FORMATS.PDF_AND_ZIP}
                        assesseeOrgId={assesseeOrgId}
                        metadataUpdate={updateDirectorCibilOnFileUpload}
                        fileDeleteHandler={deleteFile}
                        categoryId={categoryId}
                        flowId={flowId}
                        // categoryId={16}
                        // flowId={1}
                        allowMultiple
                        isNotVisible={true}
                    />
                </>
            )}
        </div>
    );
}

export default DirectorCibil;
