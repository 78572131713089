import React from 'react';
import infoYellow from '../../../../../../../../../assets/info-yellow.svg';
import styles from '../EditableTable.module.scss';
import Tooltip from '../../../../../../../../../common/_custom/Tooltip/Tooltip';

type MetricBannerProps = {
    totalRows: number;
    unclassifiedRows: number;
};

function MetricBanner(props: MetricBannerProps) {
    return (
        <div className={styles.MetricBanner}>
            <img src={infoYellow} alt="info" />
            <span>
                {props?.unclassifiedRows}/{props?.totalRows} metrics mapped as "Other Metrics"
            </span>
            <Tooltip
                direction="right"
                content="Please make necessary data type changes for the particulars which is classified as Other Metrics"
            >
                <span className={styles.LearnMore}>Learn More</span>
            </Tooltip>
        </div>
    );
}

export default MetricBanner;
