import axios from 'axios';
import { GET, POST } from '../../../utils/axios-interceptor';
import { getTimeStamp } from '../../../utils/dateUtils';

export function getReportId(payload) {
    return axios({
        url: API_ENDPOINTS.GET_REPORT_ID,
        method: POST,
        data: {
            requestId: getTimeStamp(),
            source: 'AICA',
            ...payload,
        },
    });
}

const API_ENDPOINTS = {
    GET_REPORT_ID: '/auditedFinancials/generateReportId',
};
