import React, { useState } from 'react';
import styles from '../GstFetch/GstFetch.module.scss';
import FetchGstModal from '../FetchGstModal/FetchGstModal';

import step_done from '../../../../../assets/step-done.svg';
import step_not_done from '../../../../../assets/step-not-done.svg';
import fetch_purple from '../../../../../assets/fetch-purple.svg';
import { fetchOrangeIcon } from '../../../../../assets/hostedassets';
import clsx from 'clsx';
import GSTModal from './GSTModal/Components/GSTModal';
import { GstContext } from './GSTModal/Context/GstContext';
import { Mixpanel } from '../../../../../utils/mixpanel';
import { COMPANY_GST_FETCH_GST } from '../../../../../utils/constants/mixpanelEvents/companyEvents';
import FetchIcon from '../../../../../assets/Svg/FetchIcon';

export default function GstFetch({ gstData, fetchData }: any) {
    const [gstInfo, setGstInfo] = useState({
        state: '',
        gstin_number: '',
    });
    const [showModal, setShowModal] = useState(false);

    const fetchGst = (gstData: any) => {
        Mixpanel.track(COMPANY_GST_FETCH_GST);
        setGstInfo({
            state: gstData.state ?? '',
            gstin_number: gstData.gstin,
        });
        setShowModal(true);
    };

    const renderCheck = (check: any) => {
        if (check) return <img src={step_done} alt="" style={{ width: '20px' }} />;
        return <img src={step_not_done} alt="" style={{ width: '20px' }} />;
    };

    return (
        <GstContext
            gstValues={{ showModal, setShowModal, gstData: { ...gstInfo, fetchData: fetchData } }}
        >
            <div className={styles.GstFetch}>
                {gstData &&
                    Object.keys(gstData).map((gst, index) => (
                        <div className={styles.Card} key={gstData[gst].gstin}>
                            {index === 0 && (
                                <>
                                    <div className={styles.CardHeader}>GST Report</div>
                                    <div className={styles.CardSubHeader}>
                                        Connect to fetch GST Data.
                                        <span> We request view only data</span>.
                                    </div>
                                </>
                            )}

                            <div className={styles.Place}>
                                <div>{gstData[gst].gstin}</div>
                                <div className="text-right dflex">
                                    <span>{gstData[gst].state}</span>
                                    {renderCheck(gstData[gst].connected)}
                                </div>
                            </div>

                            <div
                                className={clsx(styles.FetchBtn, {
                                    [styles.FetchSuccessful]: gstData[gst].connected,
                                })}
                                onClick={() => fetchGst(gstData[gst])}
                            >
                                {/*<img src={fetch_purple} alt="" className="mr-1" />*/}
                                <FetchIcon
                                    colour={'var(--primary-text-colour)'}
                                    height="20"
                                    width="20"
                                />
                                {gstData[gst].connected ? 'Fetched Successfully' : 'Fetch GST Data'}
                            </div>
                        </div>
                    ))}

                <div className={styles.Separator}>
                    <div className={styles.Line}></div>
                    <div>Or</div>
                    <div className={styles.Line}></div>
                </div>

                <GSTModal />
            </div>
        </GstContext>
    );
}
