import React, { useState, useEffect, memo, useRef } from 'react';
import styles from './EnterPassword.module.scss';
import clsx from 'clsx';
import LoadingSpinner from '../../../_custom/LoadingSpinner/LoadingSpinner';
import {
    EnterPasswordErrorExclamation,
    EnterPasswordSaveBtn,
} from '../../../../assets/hostedassets';
import EnterPasswordIcon from '../../../../assets/Svg/EnterPasswordIcon';

const EnterPassword = ({ setUpdatedFileData, file, submitPassword }) => {
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const uniqueShakeClass = `shake-${file.id}`; // Create a unique class based on file ID

    useEffect(() => {
        if (file.id.includes('temp')) return;
        // Automatically add the shake animation class based on the unique class name
        const element = document.querySelector(`.${uniqueShakeClass}`);
        if (element) {
            element.classList.add(styles.errorShake);
            // Automatically remove it after animation duration
            setTimeout(() => {
                element.classList.remove(styles.errorShake);
            }, 300);
        }
    }, [file.id]); // Run effect when file.id changes
    const onEnter = async () => {
        setLoading(true);
        const res = await submitPassword(password, file);
        if (!res) {
            setPassword('');
            // Trigger animation on failure with the unique class
            const element = document.querySelector(`.${uniqueShakeClass}`);
            if (element) {
                element.classList.add(styles.errorShake);
                setTimeout(() => {
                    element.classList.remove(styles.errorShake);
                }, 300);
            }
        }
        setLoading(false);
    };

    // Call the onEnter Function when the user presses Enter use inputRef
    useEffect(() => {
        const handleKeyPress = async (e) => {
            if (e.key === 'Enter') {
                await onEnter();
            }
        };
        inputRef.current?.addEventListener('keypress', handleKeyPress);
        return () => {
            inputRef.current?.removeEventListener('keypress', handleKeyPress);
        };
    }, [password]);

    return (
        <div className={clsx(styles.PasswordInputWrapper, uniqueShakeClass)}>
            <input
                ref={inputRef}
                disabled={file.id.includes('temp')}
                className={clsx(styles.PasswordInput, {
                    [styles.Valid]: password.length > 0,
                    [styles.Disabled]: file.id.includes('temp'),
                })}
                type="password"
                placeholder="Enter password"
                value={password}
                onBlur={() => {
                    if (password.length > 0) onEnter();
                }}
                onChange={(e) => {
                    setPassword(e.target.value);
                }}
            />
            {password.length > 0 ? (
                <button
                    disabled={file.id.includes('temp')}
                    className={styles.SaveBtn}
                    onClick={onEnter}
                >
                    {loading ? (
                        <div style={{ marginLeft: '-1rem' }}>
                            <LoadingSpinner height="0.875rem" color="var(--primary-text-colour)" />
                        </div>
                    ) : (
                        <EnterPasswordIcon
                            colour={'var(--primary-text-colour)'}
                            height="15"
                            width="15"
                        />
                    )}
                </button>
            ) : (
                <img
                    src={EnterPasswordErrorExclamation}
                    className={styles.ErrorImg}
                    alt="Error Icon"
                />
            )}
        </div>
    );
};

export default memo(EnterPassword);
