import React from 'react';
import { useSelected, useFocused } from 'slate-react';
import Icon from '../../common/Icon';
import useResize from '../../utils/customHooks/useResize.js';
import styles from './Embed.module.scss';

const Image = ({ attributes, element, children }) => {
    const { url, alt } = element;
    // const selected = useSelected();
    const focused = useFocused();
    // const [size, onMouseDown] = useResize();

    return (
        <div
            {...attributes}
            className={styles.Embed}
            style={{ display: 'flex', width: '100%', justifyContent: element?.justify || '' }}
            {...element.attr}
        >
            <div contentEditable={false} style={{ height: `${element?.height}` }}>
                <img alt={alt} src={url} />
                {/* {selected && (
                    <button
                        onMouseDown={onMouseDown}
                        style={{
                            width: '15px',
                            height: '15px',
                            opacity: 1,
                            background: 'transparent',
                        }}
                    >
                        <Icon icon="resize" />
                    </button>
                )} */}
            </div>
            {children}
        </div>
    );
};
export default Image;
