import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import cloudBlue from '../../../../../../../assets/cloud-upload.svg';
import { uploadOrange, plusOrange } from '../../../../../../../assets/hostedassets';
import bluePlus from '../../../../../../../assets/blue-plus.svg';
import { Input } from '../../../../../../../common/_custom';
import styles from './FileWithDescription.module.scss';
import Dropdown from '../../../../../../../common/_custom/Dropdown';
import { DropdownOption } from '../../../../../../../common/_custom/Dropdown/dropdownTypes';
import FileUploadIcon from '../../../../../../../assets/Svg/FileuploadIcon';
import PlusIcon from '../../../../../../../assets/Svg/PlusIcon';

function FileWithDescription({
    onChange,
    disabled = false,
    acceptedFormats,
    fileDescription,
    existingFiles = [],
    fileToUpload,
    setFileDescription,
    fileDeleteHandler,
    downloadFile,
    isDropdown = false,
    dropdownOptions = [],
}: any) {
    const [selectedDropdownOption, setSelectedDropdownOption] = useState<null | DropdownOption>(
        null,
    );
    const [enableAdd, setEnableAdd] = useState(false);
    const fileInputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
    const handleClick = () => fileInputRef?.current?.click?.();

    useEffect(() => {
        if (existingFiles?.length) {
            setEnableAdd(true);
            setSelectedDropdownOption(null);
        }
    }, [existingFiles]);

    useEffect(() => {
        if (isDropdown) {
            if (selectedDropdownOption) setFileDescription(selectedDropdownOption.label);
            else setFileDescription('');
        }
    }, [isDropdown, selectedDropdownOption]);

    const handleChange = (e) => {
        onChange(e.target.files);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        onChange(e.dataTransfer.files);
        if (e.dataTransfer) e.dataTransfer.clearData();
    };

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === 'dragenter' || e.type === 'dragover') {
            if (e.dataTransfer) e.dataTransfer.dropEffect = 'copy';
        } else if (e.type === 'dragleave') {
        }
    };

    const fileNameTrucated = (filename = '') => {
        return filename?.length > 35 ? `${filename?.substring(0, 34)}...` : filename;
    };

    return (
        <div className={styles.FileWithDescription}>
            {existingFiles?.map((file) => (
                <div className={styles.Row} key={file?.docId}>
                    <Input
                        value={file?.description}
                        disabled
                        onChange={(val) => setFileDescription(val)}
                        style={{ marginTop: '0' }}
                    />
                    <div
                        className={styles.FileInfoDownload}
                        onClick={() => downloadFile?.(file?.docId, file?.fileName)}
                    >
                        {fileNameTrucated(file.docName)}
                    </div>
                </div>
            ))}
            {!enableAdd && (
                <div className={styles.Row}>
                    {isDropdown ? (
                        <Dropdown
                            optionsList={dropdownOptions}
                            selectedOption={selectedDropdownOption}
                            itemClickHandler={(selectedOption) =>
                                setSelectedDropdownOption(selectedOption)
                            }
                        />
                    ) : (
                        <Input
                            value={fileDescription}
                            onChange={(val) => setFileDescription(val)}
                            style={{ marginTop: '0' }}
                            placeholder={'Enter Description'}
                        />
                    )}

                    <div
                        className={clsx(styles.FileInfo, {
                            [styles.DisableFileInfo]: !fileDescription,
                        })}
                        onDrop={handleDrop}
                        onDragOver={handleDrag}
                        onDragEnter={handleDrag}
                        onDragLeave={handleDrag}
                        onClick={handleClick}
                    >
                        <FileUploadIcon
                            colour={'var(--primary-text-colour)'}
                            height="20"
                            width="20"
                        />
                        {fileNameTrucated(
                            fileToUpload?.docName ||
                                fileToUpload?.file?.name ||
                                'Upload Data or Document',
                        )}
                    </div>
                </div>
            )}

            <div className={styles.Bottom}>
                <div
                    className={clsx(styles.AddButton, { [styles.DisableAdd]: !enableAdd })}
                    onClick={() => setEnableAdd(false)}
                >
                    <PlusIcon colour={'var(--primary-text-colour)'} height="10" width="10" />
                    Add Additional Data
                </div>
            </div>

            <input
                type="file"
                ref={fileInputRef}
                onChangeCapture={handleChange}
                style={{ display: 'none' }}
                accept={acceptedFormats}
            />
        </div>
    );
}

export default FileWithDescription;
