import { Handlers } from '../../types/actions/types';
import { CONSTANTS } from './constants';
import { ProvisionalUpdatePayload } from './service';

export function fetchCompanyUploadConfig(payload: FetchCompanyConfigPayload) {
    return {
        type: CONSTANTS.FETCH_COMPANY_CONFIG,
        payload,
    };
}

export function connectGST(payload: any) {
    return {
        type: CONSTANTS.CONNECT_GST,
        payload,
    };
}

export function fetchUploadJourneyData(payload) {
    return {
        type: CONSTANTS.FETCH_UPLOAD_JOURNEY_DATA,
        payload,
    };
}

export function updateCreditBureauCMRAction(payload) {
    return {
        type: CONSTANTS.UPDATE_CREDIT_BUREAU_CMR,
        payload,
    };
}

export function updateCreditBureauCibilAction(payload) {
    return {
        type: CONSTANTS.UPDATE_CREDIT_BUREAU_CIBIL,
        payload,
    };
}

export function updateGstReportsAction(payload) {
    return {
        type: CONSTANTS.UPDATE_GST_REPORTS,
        payload,
    };
}

export function updateFinancialReports(payload) {
    return {
        type: CONSTANTS.UPDATE_FINANCIAL_REPORTS,
        payload,
    };
}

export function updateBankAccounts(payload) {
    return {
        type: CONSTANTS.UPDATE_BANK_ACCOUNTS,
        payload,
    };
}

export function updateAdditionalDocuments(payload) {
    return {
        type: CONSTANTS.UPDATE_ADDITIONAL_DOCS,
        payload,
    };
}

export function sendCibilConsentLink(payload: DirectorListPayload) {
    return {
        type: CONSTANTS.SEND_CIBIL_CONSENT_LINK,
        payload,
    };
}

export function captureConsent(payload: CaptureConsentPayload) {
    return {
        type: CONSTANTS.CAPTURE_CIBIL_CONSENT,
        payload,
    };
}

export function updateProvisionalStatements(payload: ProvisionalUpdatePayload) {
    return {
        type: CONSTANTS.UPDATE_PROVISIONAL_STATEMENTS,
        payload,
    };
}

export const ingestProvisionalStatements = (payload: ProvisionalUpdatePayload) => {
    return {
        type: CONSTANTS.INGEST_PROVISIONAL_STATEMENTS,
        payload,
    };
};

export function updateUploadingStatus(payload) {
    return {
        type: CONSTANTS.UPDATING_UPLOADING_STATUS,
        payload,
    };
}

export const FetchTableDataForMIS = (payload: fetchTableDataForMISPayload) => {
    return {
        type: CONSTANTS.FETCH_TABLE_DATA_FOR_MIS,
        payload,
    };
};

export function updateDebtSchedule(payload: DebtSchedulePayload) {
    return {
        type: CONSTANTS.UPDATE_DEBT_SCHEDULE,
        payload,
    };
}
export function fetchMonthlyViewData(payload: FetchMonthlyViewDataPayload) {
    return {
        type: CONSTANTS.FETCH_MONTHLY_VIEW_DATA,
        payload,
    };
}

export function fetchMetadataForInputModal(payload: FetchMetadataForInputModalPayload) {
    return {
        type: CONSTANTS.FETCH_METADATA_FOR_INPUT_MODAL,
        payload,
    };
}

export function fetchMisTableOptions(payload: {
    orgId: string;
    source: string;
    type?: string;
    onSuccess: Function;
}) {
    return {
        type: CONSTANTS.FETCH_MIS_TABLE_OPTIONS,
        payload,
    };
}

export function updateDataMetrics(payload: updateDataMetricsPayload) {
    return {
        type: CONSTANTS.UPDATE_DATA_METRICS,
        payload,
    };
}

export function getVisualisationTableData(payload: VisualisationTableDataPayload) {
    return {
        type: CONSTANTS.FETCH_VISUALISATION_TABLE_DATA,
        payload,
    };
}

export const getRawDataForVisualisation = (payload: RawDataForVisualisationPayload) => {
    return {
        type: CONSTANTS.FETCH_RAW_DATA_FOR_VISUALISATION,
        payload,
    };
};

export const fetchAuditedTableData = (payload: AuditedTableDataPayload, handlers: Handlers) => {
    return {
        type: CONSTANTS.FETCH_AUDITED_TABLE_DATA,
        payload,
        handlers,
    };
};

export const updateAuditedTableData = (payload: UpdateAuditedPayload) => {
    return {
        type: CONSTANTS.UPDATE_AUDITED_TABLE_DATA,
        payload,
    };
};

export const fetchAICAOverviewData = (payload: AICAOverviewPayload, handlers: Handlers) => {
    return {
        type: CONSTANTS.FETCH_AICA_OVERVIEW_DATA,
        payload,
        handlers,
    };
};

export const sendAICAOverviewFeedback = (
    payload: AICAOverviewFeedbackPayload,
    handlers: Handlers,
) => {
    return {
        type: CONSTANTS.SEND_AICA_OVERVIEW_FEEDBACK,
        payload,
        handlers,
    };
};

export type AICAOverviewPayload = {
    orgId: string;
    moduleId: string;
    resourceId?: string;
};

export enum AICAOverviewFeedbackType {
    POSITIVE = 'POSITIVE',
    NEGATIVE = 'NEGATIVE',
}

export type AICAOverviewFeedbackPayload = AICAOverviewPayload & {
    feedback: {
        feedbackType: AICAOverviewFeedbackType;
        feedback: string;
    };
};

export type UpdateAuditedPayload = {
    orgId: string;
    metrics: string;
    changedData: { [key: string]: string }[];
    source: string;
    onSuccess: Function;
    onError: Function;
};

export type AuditedTableDataPayload = {
    orgId: string;
    requestId: string;
    type: string;
    raw: boolean;
    mis: string;
    classificationId?: string;
};

export type RawDataForVisualisationPayload = {
    orgId: string;
    requestId: string;
    dataType: string;
    classificationId: string;
    onSuccess: Function;
    onError: Function;
};

export type VisualisationTableDataPayload = {
    orgId: string;
    dataType: string;
    requestId: string;
    onSuccess: Function;
    onError: Function;
};

export type updateDataMetricsPayload = {
    orgId: string;
    metadataId: string;
    changedValues: {
        id: string;
        parent: string;
        misClassification: string;
        misType?: string;
        dataFormat?: string;
    }[];
    onSuccess: Function;
    onError: Function;
};

export type fetchTableDataForMISPayload = {
    id: string;
    orgId: string;
    onSuccess: Function;
    onError: Function;
};

export type FetchMetadataForInputModalPayload = {
    assesseeOrgId: string;
    docId: string;
    onSuccess: Function;
    onError: Function;
};

type FetchMonthlyViewDataPayload = {
    assesseeOrgId: string;
    onSuccess: Function;
};

export type DebtSchedulePayload = {
    hasDebtSchedule: boolean;
    assesseeOrgId: string;
    debtScheduleDoc?: {
        docId: string;
        docName: string;
        isDeleted: boolean;
    };
    onSuccess: Function;
};

export type CaptureConsentPayload = {
    encryptedKey: string;
    onSuccess: Function;
    onError: Function;
};

export type DirectorListPayload = {
    assesseeOrgId: string;
    directorName: string;
    phoneNumber?: string;
    email: string;
    flowType?: string;
    onSuccess: Function;
    onError: Function;
};

export type FetchCompanyConfigPayload = {
    accountType: string;
    primaryEmai: string;
    investorOrgId: string;
    configName?: string;
    assesseeOrgId?: string;
};

export type ConnectGSTPayload = {
    requestId: string;
    orgId: string;
    gstin: string;
    userName: string;
    password: string;
};
