import React, { useRef, useState } from 'react';
import { colors } from './defaultColors.js';
import { addMarkData, activeMark } from '../../utils/SlateUtilityFunctions.js';
import { Transforms } from 'slate';
import usePopup from '../../utils/customHooks/usePopup';
import { ReactEditor } from 'slate-react';
import editorStyles from '../../TextEditor.module.scss';
import styles from './ColorPicker.module.scss';
import clsx from 'clsx';
import Icon from '../../common/Icon.jsx';
import { fillSvg, textColorSvg } from './colorpickericons.js';
import { useOutsideClick } from '../../../../utils/constants/hooks';

const logo = {
    color: textColorSvg,
    bgColor: fillSvg,
};

const ColorPicker = ({ format, editor }) => {
    const colorPickerRef = useRef(null);
    const popupOptionsRef = useRef(null);
    const [selection, setSelection] = useState();
    const [hexValue, setHexValue] = useState('');
    const [validHex, setValidHex] = useState();
    const [showOptions, setShowOptions] = usePopup(colorPickerRef);

    const isValideHexSix = new RegExp('^#[0-9A-Za-z]{6}');
    const isValideHexThree = new RegExp('^#[0-9A-Za-z]{3}');

    useOutsideClick(popupOptionsRef, () => {
        setShowOptions(false);
    });

    const changeColor = (e) => {
        const clickedColor = e.target.getAttribute('data-value');
        selection && Transforms.select(editor, selection);
        selection && ReactEditor.focus(editor);

        addMarkData(editor, { format, value: clickedColor });
        setShowOptions(false);
    };
    const toggleOption = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setSelection(editor.selection);
        selection && ReactEditor.focus(editor);

        setShowOptions((prev) => !prev);
    };
    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (!validHex) return;
        selection && Transforms.select(editor, selection);

        addMarkData(editor, { format, value: hexValue });
        setShowOptions(false);
        setValidHex('');
        setHexValue('');
        selection && ReactEditor.focus(editor);
    };
    const handleHexChange = (e) => {
        e.preventDefault();
        const newHex = e.target.value;
        setValidHex(isValideHexSix.test(newHex) || isValideHexThree.test(newHex));
        setHexValue(newHex);
    };
    return (
        <div className={clsx(editorStyles.PopupWrapper, styles.ColorPicker)} ref={colorPickerRef}>
            <button
                style={{ color: showOptions ? 'black' : activeMark(editor, format), opacity: '1' }}
                className={clsx(editorStyles.Button, { [styles.Clicked]: showOptions })}
                onClick={toggleOption}
            >
                {logo[format]}
            </button>
            {showOptions && (
                <div className={editorStyles.Popup} ref={popupOptionsRef}>
                    <div className={styles.ColorOptions}>
                        {colors.map((color, index) => {
                            return (
                                <div
                                    key={index}
                                    data-value={color}
                                    onClick={changeColor}
                                    className={styles.Option}
                                    style={{ background: color }}
                                ></div>
                            );
                        })}
                    </div>
                    <p
                        style={{
                            textAlign: 'center',
                            opacity: '0.7',
                            width: '100%',
                            margin: '10px 0 5px 0',
                        }}
                    >
                        OR
                    </p>
                    <div className={styles.Bottom}>
                        <div
                            className={styles.hexPreview}
                            style={{ background: validHex ? hexValue : '#000000' }}
                        ></div>
                        <input
                            type="text"
                            placeholder="#000000"
                            value={hexValue}
                            onChange={handleHexChange}
                            style={{
                                border:
                                    validHex === false ? '1px solid red' : '1px solid lightgray',
                            }}
                        />
                        <button
                            style={{ color: validHex ? 'green' : '' }}
                            className={editorStyles.Button}
                            onClick={handleFormSubmit}
                        >
                            <Icon
                                icon={
                                    'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/small-checkl-grey.svg'
                                }
                                height="20px"
                            />
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ColorPicker;
