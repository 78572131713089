import dayjs from 'dayjs';
import { CRORE, LAKH } from '../../utils/utils';

export const isTabConfigValid = (tabConfigData) => {
    // a valid tab config must have headline cards or charts
    if (tabConfigData.headline && tabConfigData.headline.totalCards > 0) return true;
};

export const chartInitData = {
    isLoading: true,
    isFetched: false,
    // type: GRAPH_TYPES.LINE,
    // type: 'line',
    // label: 'Annual Recurring Revenue (ARR)',
    colors: {
        primary: '#000000',
        secondary: '',
        tertiary: '',
        error: { primary: '#000000', secondary: '#505050' },
    },
    // linkToFinancials: ROUTES.PRIVATE.FINANCIALS.INVOICING,
    // linkToFinancials: '',
    // amount: [],
    // aggregateValue: '',
    // hasNegative: false,
    // info: 'Annual recurring revenue refers to the predictable revenue a company derived from all its customers during a year. It can be computed as:\nMonthly recurring revenue * 12 OR\nQuarterly recurring revenue * 4 OR \nRevenue for the last 12 months, depending on the billing cycles of the business',
};

export const secondDataPointExists = (graphData) => {
    const obj = graphData?.[0];
    return !!(obj && 'yValue2' in obj);
};
export const thirdDataPointExists = (graphData) => {
    const obj = graphData?.[0];
    return !!(obj && 'yValue3' in obj);
};

export const getColorKeyFromDataKey = (dataKey: string) => {
    switch (dataKey) {
        case 'yValue':
            return 'primary';
        case 'yValue2':
            return 'secondary';
        case 'yValue3':
            return 'tertiary';
        case 'yValue4':
            return 'fourth';
        case 'yValue5':
            return 'fifth';
        default:
            return 'primary';
    }
};

export const getColorKeyFromIndex = (index: number) => {
    switch (index) {
        case 0:
            return 'primary';
        case 1:
            return 'secondary';
        case 2:
            return 'tertiary';
        case 3:
            return 'fourth';
        case 4:
            return 'fifth';
        default:
            return 'primary';
    }
};

export const formatPeriod = (period) => {
    //THIS FUNCTION IS USED TO MOD X AXIS OF GRAPHS AND PERIOD IN GRAPH METADATA
    const today = dayjs();
    const currentYear = today.format('YYYY');
    const currentMonth = today.format('MM');
    let val = period;
    if (typeof val !== 'string') val += '';
    if (val.includes('q')) {
        let periods = val.split('_');
        periods[0] = periods[0].replace('q', 'Q');
        periods[1] = parseInt(periods[1].slice(-2));
        val = `${periods[0]}'FY${periods[1] - 1}-${periods[1]}`;
        return val;
    }
    if (val.includes('_20')) return val.replace('_20', "'");
    else if (val.includes('20') && val.indexOf('20') === 0) {
        const currentFy =
            parseInt(currentMonth) <= 3 ? parseInt(currentYear) - 1 : parseInt(currentYear);
        let fyVal = parseInt(val.slice(-2));
        return `${parseInt(period) === currentFy ? 'YTD ' : ''}FY'${fyVal}-${fyVal + 1}`;
    }
    return val;
};

export const getHeatMapData = (plotValues, colors, heatMapRanges) => {
    let initialData = [
        { id: 'AN', state: 'Andaman and Nicobar Islands', value: 0 },
        { id: 'AP', state: 'Andhra Pradesh', value: 0 },
        { id: 'AR', state: 'Arunachal Pradesh', value: 0 },
        { id: 'AS', state: 'Assam', value: 0 },
        { id: 'BR', state: 'Bihar', value: 0 },
        { id: 'CH', state: 'Chandigarh', value: 0 },
        { id: 'CT', state: 'Chhattisgarh', value: 0 },
        { id: 'DN', state: 'Dadra and Nagar Haveli', value: 0 },
        { id: 'DD', state: 'Daman and Diu', value: 0 },
        { id: 'DL', state: 'Delhi', value: 0 },
        { id: 'GA', state: 'Goa', value: 0 },
        { id: 'GJ', state: 'Gujarat', value: 0 },
        { id: 'HR', state: 'Haryana', value: 0 },
        { id: 'HP', state: 'Himachal Pradesh', value: 0 },
        { id: 'JK', state: 'Jammu and Kashmir', value: 0 },
        { id: 'JH', state: 'Jharkhand', value: 0 },
        { id: 'KA', state: 'Karnataka', value: 0 },
        { id: 'KL', state: 'Kerala', value: 0 },
        { id: 'LA', state: 'Ladakh', value: 0 },
        { id: 'LD', state: 'Lakshadweep', value: 0 },
        { id: 'MP', state: 'Madhya Pradesh', value: 0 },
        { id: 'MH', state: 'Maharashtra', value: 0 },
        { id: 'MN', state: 'Manipur', value: 0 },
        { id: 'ML', state: 'Meghalaya', value: 0 },
        { id: 'MZ', state: 'Mizoram', value: 0 },
        { id: 'NL', state: 'Nagaland', value: 0 },
        { id: 'OD', state: 'Odisha', value: 0 },
        { id: 'PB', state: 'Punjab', value: 0 },
        { id: 'RJ', state: 'Rajasthan', value: 0 },
        { id: 'SK', state: 'Sikkim', value: 0 },
        { id: 'TN', state: 'Tamil Nadu', value: 0 },
        { id: 'TS', state: 'Telangana', value: 0 },
        { id: 'TR', state: 'Tripura', value: 0 },
        { id: 'UK', state: 'Uttarakhand', value: 0 },
        { id: 'UP', state: 'Uttar Pradesh', value: 0 },
        { id: 'WB', state: 'West Bengal', value: 0 },
        { id: 'PY', state: 'Puducherry', value: 0 },
    ];
    if (plotValues?.length) {
        return initialData.map((state) => {
            const plotDataForState = plotValues?.find((stateData) => state.id === stateData.id);
            return {
                ...state,
                value: plotDataForState?.value || state.value || 0,
                color: getColorFromValue(
                    plotDataForState?.value || state.value || 0,
                    colors,
                    heatMapRanges,
                ),
            };
        });
    } else return initialData;
};

const getColorFromValue = (value, colors, heatMapRanges) => {
    const range1Min = heatMapRanges?.[3]?.min;
    const range1Max = heatMapRanges?.[3]?.max;

    const range2Min = heatMapRanges?.[2]?.min;
    const range2Max = heatMapRanges?.[2]?.max;

    const range3Min = heatMapRanges?.[1]?.min;
    const range3Max = heatMapRanges?.[1]?.max;

    const range4Min = heatMapRanges?.[0]?.min;
    // const range4Max = heatMapRanges?.[0]?.max;
    if (value >= range4Min) return colors?.primary;
    else if (value === 0) return colors?.fifth;
    else if (value >= range3Min && value < range3Max) return colors?.secondary;
    else if (value >= range2Min && value < range3Max) return colors?.tertiary;
    else if (value >= range1Min && value < range2Max) return colors?.fourth;
    else return colors?.fifth;
};
