import React from 'react';
import { useAppSelector } from '../../../app/hooks';
import OnePagerGenerate from '../OnePagerGenerateButton/OnePagerGenerate';
import InvestmentReport from '../InvestmentReport/InvestmentReport';
import { Button } from '../../_custom';
import { FILE_SHARE_STATUS, UPLOAD_FILES_PREFIXES } from '../DealDrawerConstants';

function DealDrawerButtonComponents({
    componentKey,
    formData,
    config,
    categoryId,
    handleChange,
    step,
    source,
}) {
    const { INVESTMENTREPORT__PREFIX, ONEPAGER_PREFIX } = UPLOAD_FILES_PREFIXES;
    const dealDrawerState = useAppSelector((state) => state.investor.dealDrawer);
    const documentsListKey = 'dealTerms.documents';

    const handleDocWithPrefixUpdate = (fileInfo, documentPrefix) => {
        let updateFileObj = {
            docId: fileInfo?.docId,
            documentName: `${documentPrefix}${fileInfo?.fileName}`,
            action: FILE_SHARE_STATUS.SEND_TO_INVESTOR,
        };
        let docsToUpdate = [...(formData?.[documentsListKey] || [])];
        let existingfileIndex = docsToUpdate.findIndex(
            (doc) => doc.documentName?.includes(documentPrefix),
        );
        if (existingfileIndex >= 0) docsToUpdate[existingfileIndex] = updateFileObj;
        else docsToUpdate.push(updateFileObj);
        handleChange(documentsListKey, docsToUpdate);
    };

    const handleShareToggle = (actionStatus, documentPrefix) => {
        handleChange(
            documentsListKey,
            (formData[documentsListKey] || [])?.map((document) => ({
                ...document,
                ...(document?.documentName?.includes(documentPrefix)
                    ? { action: actionStatus }
                    : {}),
            })),
        );
    };

    const getRenderComponent = () => {
        switch (componentKey) {
            case 'generateOnePager':
                return (
                    <OnePagerGenerate
                        formData={formData}
                        step={step}
                        source={source}
                        categoryId={categoryId}
                        flowId={16}
                        metadataUpdate={(fileInfo) =>
                            handleDocWithPrefixUpdate(fileInfo, ONEPAGER_PREFIX)
                        }
                        onShareToggle={(action) => handleShareToggle(action, ONEPAGER_PREFIX)}
                        existingData={formData[documentsListKey]?.findLast(
                            (document) => document?.documentName?.includes(ONEPAGER_PREFIX),
                        )}
                        assesseeOrgId={dealDrawerState?.companyId as string}
                        isLocked={dealDrawerState?.profileGenerationStatus === 'LOCKED'}
                    />
                );
            case 'investmentReport':
                return (
                    <InvestmentReport
                        source={source}
                        assesseeOrgId={dealDrawerState?.companyId as string}
                        companyName={dealDrawerState.companyName as string}
                        isLocked={dealDrawerState?.profileGenerationStatus === 'LOCKED'}
                        metadataUpdate={(fileInfo) =>
                            handleDocWithPrefixUpdate(fileInfo, INVESTMENTREPORT__PREFIX)
                        }
                        onShareToggle={(action) =>
                            handleShareToggle(action, INVESTMENTREPORT__PREFIX)
                        }
                        existingData={formData[documentsListKey]?.findLast(
                            (document) =>
                                document?.documentName?.includes(INVESTMENTREPORT__PREFIX),
                        )}
                    />
                );
            default:
                return (
                    <Button
                        text="Not Available"
                        onClick={() => {}}
                        style={{ marginTop: '0', width: '50%' }}
                        disabled
                    />
                );
        }
    };

    return <React.Fragment>{getRenderComponent()}</React.Fragment>;
}

export default DealDrawerButtonComponents;
