import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input } from '../../../../../common/_custom';
import styles from '../../InvestorSignup.module.scss';
import { InvestorSignupLoginData } from '../../InvestorSignup';
import { INVESTOR_ROUTES } from '../../../../../utils/constants/routesConst';
import Form from '../../../../../common/_custom/Form/Form';
import { useAppSelector } from '../../../../../app/hooks';

type InvestorSignupLoginFormProps = {
    formData: InvestorSignupLoginData;
    handleFormChange: Function;
    handleSubmitClick: Function;
};

function InvestorSignupLoginForm(props: InvestorSignupLoginFormProps) {
    const navigate = useNavigate();
    const { formData, handleFormChange, handleSubmitClick } = props;
    const isLoggingIn = useAppSelector((state) => state.user.loggingIn);
    const [errors, setErrors] = useState({
        emailId: false,
    });
    const disabled = !formData.emailId;
    return (
        <div style={{ width: '40%' }} className={styles.InvestorLoginFormContainer}>
            <div className={styles.Title}>Welcome to AICA!</div>
            <Form>
                <Input
                    placeholder="Work Email"
                    value={formData.emailId}
                    onChange={(val: string) => handleFormChange(val.trim(), 'emailId')}
                    isValid={!errors.emailId}
                />
                <Button
                    text="Proceed To Login"
                    loadingText="Please Wait"
                    onClick={() => handleSubmitClick()}
                    style={{ width: '100%' }}
                    loading={isLoggingIn}
                    disabled={disabled}
                    variant="purple"
                />

                <div className={styles.Signup}>
                    Don’t have an account?{' '}
                    <button
                        className={styles.Login}
                        onClick={() => navigate(INVESTOR_ROUTES.SIGNUP)}
                    >
                        Signup
                    </button>
                </div>
            </Form>
        </div>
    );
}

export default InvestorSignupLoginForm;
