import { useEffect, useState } from 'react';
import ToggleButton from '../../../../../common/ToggleButton/ToggleButton';
import styles from './GSTUpload.module.scss';
import UploadCard from '../UploadCard/UploadCard';
import { useAppSelector } from '../../../../../app/hooks';

function GSTUpload({ gstReportsData, updateFunction, categoryId, flowId, assesseeOrgId }) {
    const [checked, setChecked] = useState(false);
    const reports = gstReportsData?.gstReports?.filter((report) => !report?.isDeleted);
    const requiredGstMonths = useAppSelector(
        (state) => state.investor.config?.requiredMonthsForGstData,
    );
    const showReports = checked || !!reports?.length;

    useEffect(() => {
        if (gstReportsData?.gstReports) {
            if (!!gstReportsData?.gstReports?.filter((report) => !report?.isDeleted)?.length)
                setChecked(true);
        }
    }, [gstReportsData?.gstReports]);

    const deleteFile = async (fileData) => {
        await updateFunction({
            saveGSTReportData: {
                docId: fileData.docId,
                docName: fileData.docName,
                isDeleted: true,
            },
        });
    };

    const updateGSTReportsOnUpload = async (fileData) => {
        await updateFunction({
            saveGSTReportData: {
                docId: fileData.docId,
                docName: fileData.fileName,
                status: 'PROCESSING',
            },
        });
    };

    return (
        <div className={styles.GSTUpload}>
            <div className={styles.Top}>
                Not able to fetch GST Data ? Upload manually
                <ToggleButton
                    checked={showReports}
                    onChange={() => setChecked(!checked)}
                    disabled={reports?.length}
                />
            </div>
            {showReports && (
                <div className={styles.UploadContent}>
                    <div className={styles.Text1}>
                        {' '}
                        Upload GSTR-1, GSTR-2A, GSTR-3B reports
                        {requiredGstMonths
                            ? ` of last ${requiredGstMonths} months`
                            : ' of last 12 months'}
                        .{/* <a className={styles.Link}>&nbsp;Learn how to export.</a> */}
                    </div>

                    <UploadCard
                        existingFiles={gstReportsData?.gstReports || []}
                        assesseeOrgId={assesseeOrgId}
                        categoryId={categoryId}
                        flowId={flowId}
                        metadataUpdate={updateGSTReportsOnUpload}
                        fileDeleteHandler={deleteFile}
                        allowMultiple
                    />
                </div>
            )}
        </div>
    );
}

export default GSTUpload;
