import mixpanel from 'mixpanel-browser';
import { is_prod } from './utils';

!!process.env.REACT_APP_MIXPANEL_TOKEN &&
    is_prod &&
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
        debug: false,
        track_pageview: 'full-url',
        persistence: 'localStorage',
    });

let actions = {
    identify: (id: string) => {
        if (is_prod) mixpanel.identify(id);
    },
    alias: (id: string) => {
        if (is_prod) mixpanel.alias(id);
    },
    track: (name: string, properties = {}) => {
        if (is_prod) mixpanel.track(name, properties);
    },
    people: {
        set: (properties) => {
            if (is_prod) mixpanel.people.set(properties);
        },
    },
    register: (payload) => {
        if (is_prod) mixpanel.register(payload);
    },
};

export let Mixpanel = actions;
