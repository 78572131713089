import { ACCESS_TYPES, COMPANIES_LIST_TAB, CONSTANTS } from './constants';
import { ShowUnlockAicaAndAddToLeadsPayload } from './action';

export interface PortfolioCompanyRow {
    // isUnlocked: boolean;
    profileGenerationStatus: string;
    action: string;
    aicaScore: string;
    assesseeOrgId: string;
    contactEmail: string;
    contactPerson: string;
    gstConnected: string;
    lastFetched: string;
    lastUpdated: string;
    orgName: string;
    reportId: string;
    companyAccessType: string;
    demoOrg?: boolean;
    unlockAt?: string;
    journeyName?: string;
}

interface InvestorReducer {
    fetchingConfig: boolean;
    fetchingPortfolio: boolean;
    fetchingDeals: boolean;
    showUpgradeToScan: string;
    untaggedCount: number;
    unlockAicaReport: {
        showUnlockAicaConfirmation: boolean;
        orgId: string;
        orgName: string;
    };
    unlockAicaReportAndAddToLeads: {
        showConfirmationModal: boolean;
        assesseeOrgId: string;
        orgName: string;
        source: string;
    };
    showInviteUser: boolean;
    searchingPortfolio: boolean;
    config: null | InvestorConfig;
    portfolio: Portfolio;
    role: null | string;
    selectedCompany: null | { [key: string]: unknown };
    permissions: {
        fetching: boolean;
        permissionsFetched: boolean;
        SCAN: string;
        WATCH: string;
        TRACK: string;
        DEALS: string;
        onboardingTabs: string[];
        showMagicUpload?: boolean;
    };
    ownerDropdownOptions: {
        userId: string;
        emailId: string;
        userType: string;
        name?: string;
    }[];
    dealDrawer: DealDrawerState;
    deals: Deals;

    tabLockStatus: null | { isLocked: boolean };
}

interface InvestorConfig {
    dataConfig?: any;
    requiredMonthsForGstData?: number;
}
interface Portfolio {
    portfolioFetched: boolean;
    currentPageNumber: number;
    hasNextPage: boolean;
    totalPortfolioCount: number;
    newCompaniesCount: number;
    viewedCompaniesCount: number;
    monitoringCompaniesCount: number;
    nonMonitoringCompaniesCount: number;
    fetchedType: string;
    companiesList: PortfolioCompanyRow[];
}

export type DealDrawerState = {
    open: boolean;
    drawerLabel?: string;
    companyId?: string;
    companyName?: string;
    dealId?: string;
    lenderDealId?: string;
    readonly?: boolean;
    step?: string;
    profileGenerationStatus?: string;
    isPec?: boolean;
    drawerSource?: string;
};

export interface DealData {
    dealId: string;
    dealProposerId: string;
    dealBeneficiaryId: string;
    borrowerName: string;
    amount: string;
    tenure: string;
    roi: string;
    loanTypes: string[];
    security: string[];
    dealStatus: string;
    dealNotes: string;
    comment?: {
        text: string;
        activityBy: string;
        activityDate: string;
    };
    sharedDate: string;
    borrowerDetails: {
        companyAge: string;
        companySector: string;
        annualRevenue: string;
        growthRate: string;
        runway: string;
        networth: string;
        ebitda: string;
        equityRaised: string;
    };
    docIds: [];
    documents?: { action: string; docId: string; documentName: string }[];
}

export interface InvestorDealData extends DealData {
    dealReceiverId: string;
    lenderName: string;
}

export type FetchDealsData = DealData & {
    investorDeals: InvestorDealData[];
};

export type Deals = {
    pageNumber: number;
    hasNextPage: boolean;
    data: FetchDealsData[];
    dealCount: {
        totalDeals: number;
        approvedDeals: number;
        sharedDeals: number;
        passedDeals: number;
    };
};

export enum DealStatus {
    SHARED = 'SHARED',
    APPROVED = 'APPROVED',
    PASSED = 'PASSED',
}

const initialState: InvestorReducer = {
    fetchingConfig: false,
    fetchingPortfolio: false,
    fetchingDeals: false,
    searchingPortfolio: false,
    role: '',
    untaggedCount: 0,
    config: null,
    showUpgradeToScan: '',
    unlockAicaReport: {
        showUnlockAicaConfirmation: false,
        orgId: '',
        orgName: '',
    },
    unlockAicaReportAndAddToLeads: {
        showConfirmationModal: false,
        assesseeOrgId: '',
        orgName: '',
        source: '',
    },
    showInviteUser: false,
    selectedCompany: { companyAccessType: '' },
    portfolio: {
        portfolioFetched: false,
        currentPageNumber: 1,
        hasNextPage: true,
        totalPortfolioCount: 0,
        newCompaniesCount: 0,
        viewedCompaniesCount: 0,
        monitoringCompaniesCount: 0,
        nonMonitoringCompaniesCount: 0,
        fetchedType: COMPANIES_LIST_TAB.ALL,
        companiesList: [],
    },
    ownerDropdownOptions: [],
    permissions: {
        fetching: true,
        permissionsFetched: false,
        SCAN: ACCESS_TYPES.LOCKED,
        WATCH: ACCESS_TYPES.LOCKED,
        TRACK: ACCESS_TYPES.LOCKED,
        DEALS: ACCESS_TYPES.LOCKED,
        onboardingTabs: [],
        showMagicUpload: false,
    },
    dealDrawer: {
        open: false,
    },
    deals: {
        pageNumber: 1,
        hasNextPage: false,
        data: [],
        dealCount: {
            totalDeals: 0,
            approvedDeals: 0,
            sharedDeals: 0,
            passedDeals: 0,
        },
    },
    tabLockStatus: null,
};

//--------LOADERS----------//

const setIsFetchingConfig = (state, action) => {
    return {
        ...state,
        fetchingConfig: action?.payload,
    };
};

const setIsFetchingPortfolio = (state, action) => {
    return {
        ...state,
        fetchingPortfolio: action?.isSearching ? false : action?.payload,
        searchingPortfolio: action?.isSearching,
    };
};

const setShowUpgradeToScan = (state, action) => {
    return {
        ...state,
        showUpgradeToScan: action?.payload,
    };
};

const showUnlockAicaConfirmation = (state, action) => {
    return {
        ...state,
        unlockAicaReport: action?.payload,
    };
};

const setIsFetchingDeals = (state, action) => {
    return {
        ...state,
        fetchingDeals: action.payload,
    };
};

const setIsFetchingPermissions = (state, action) => {
    return {
        ...state,
        permissions: {
            ...state.permissions,
            fetching: action.payload.fetching,
            permissionsFetched: action.payload.permissionsFetched,
        },
    };
};

//----------EXTERNAL----------//

const fetchInvestorConfigSuccess = (state, action) => {
    return {
        ...state,
        fetchingConfig: false,
        config: {
            ...action?.payload,
        },
    };
};

const fetchPortfolioDataSuccess = (state, action) => {
    return {
        ...state,
        fetchingPortfolio: false,
        searchingPortfolio: false,
        portfolio: {
            portfolioFetched: true,
            ...action?.payload,
            // companiesList: [],
        },
    };
};

const fetchOwnerDataSuccess = (state, action) => {
    return {
        ...state,
        ownerDropdownOptions: action?.payload ?? [],
    };
};

const fetchDealsDataSuccess = (state, action) => {
    return {
        ...state,
        fetchingDeals: false,
        deals: action.payload,
    };
};

const updateDealStatus = (state, action) => {
    let previousDealStatus = '';
    const dealData = state.deals.data.map((deal) => {
        if (action.payload.source === 'LENDER' && deal.dealId === action.payload.investorDealId) {
            previousDealStatus = deal.dealStatus;
            return {
                ...deal,
                dealStatus: action.payload.dealStatus,
            };
        } else if (deal.dealId === action.payload.dealId) {
            previousDealStatus = deal.dealStatus;
            return {
                ...deal,
                dealStatus: action.payload.dealStatus,
            };
        }
        return deal;
    });
    const dealCount = state.deals.dealCount;
    switch (previousDealStatus) {
        case DealStatus.APPROVED:
            dealCount.approvedDeals -= 1;
            break;
        case DealStatus.SHARED:
            dealCount.sharedDeals -= 1;
            break;
        case DealStatus.PASSED:
            dealCount.passedDeals -= 1;
            break;
        default:
            break;
    }
    switch (action.payload.dealStatus) {
        case DealStatus.APPROVED:
            dealCount.approvedDeals += 1;
            break;
        case DealStatus.SHARED:
            dealCount.sharedDeals += 1;
            break;
        case DealStatus.PASSED:
            dealCount.passedDeals += 1;
            break;
        default:
            break;
    }
    return {
        ...state,
        deals: {
            ...state.deals,
            data: dealData,
            dealCount: dealCount,
        },
    };
};

const updateDealComments = (state, action) => {
    return {
        ...state,
        deals: {
            ...state.deals,
            data: state.deals.data.map((deal) => {
                if (deal.dealId === action.payload.dealId) {
                    return {
                        ...deal,
                        comment: {
                            text: action.payload.comment?.text,
                            activityBy: action.payload.comment?.activityBy,
                            activityDate: action.payload.comment?.activityDate,
                        },
                    };
                }
                return deal;
            }),
        },
    };
};

const updateDealOwner = (state, action) => {
    return {
        ...state,
        deals: {
            ...state.deals,
            data: state.deals.data.map((deal) => {
                if (deal.dealId === action.payload.dealId) {
                    return {
                        ...deal,
                        owner: action.payload.owner?.name,
                        investorDeals: deal.investorDeals.map((investor) => {
                            return {
                                ...investor,
                                owner: action.payload.owner?.name,
                            };
                        }),
                    };
                }
                return deal;
            }),
        },
    };
};

const updateInvestorDealStatus = (state, action) => {
    if (action?.payload?.hasOwnProperty('dealStatus')) {
        return {
            ...state,
            deals: {
                ...state.deals,
                data: state.deals.data.map((deal) => {
                    if (deal.dealId === action.payload.dealId) {
                        return {
                            ...deal,
                            investorDeals: deal.investorDeals.map((investorDeal) => {
                                if (investorDeal.dealId === action.payload.investorDealId) {
                                    return {
                                        ...investorDeal,
                                        dealStatus: action.payload.dealStatus,
                                    };
                                }
                                return investorDeal;
                            }),
                        };
                    }
                    return deal;
                }),
            },
        };
    } else if (action?.payload?.hasOwnProperty('comment')) {
        return {
            ...state,
            deals: {
                ...state.deals,
                data: state.deals.data.map((deal) => {
                    if (deal.dealId === action.payload.dealId) {
                        return {
                            ...deal,
                            investorDeals: deal.investorDeals.map((investorDeal) => {
                                if (investorDeal.dealId === action.payload.investorDealId) {
                                    return {
                                        ...investorDeal,
                                        comment: {
                                            text: action.payload.comment.text,
                                            activityBy: action.payload.comment.activityBy,
                                            activityDate: action.payload.comment.activityDate,
                                        },
                                    };
                                }
                                return investorDeal;
                            }),
                        };
                    }
                    return deal;
                }),
            },
        };
    }
};

const updateCompanyStatus = (state, action) => {
    return {
        ...state,
        portfolio: {
            ...state.portfolio,
            companiesList: state.portfolio.companiesList.map((company) => {
                if (company.assesseeOrgId === action.payload.assesseeOrgId) {
                    return {
                        ...company,
                        ...(action?.payload?.hasOwnProperty('companyStatus') && {
                            companyStatus: action.payload.companyStatus,
                        }),
                        ...(action?.payload?.hasOwnProperty('companyRiskGrade') && {
                            companyRiskGrade: action.payload.companyRiskGrade,
                        }),
                        // ...(action.payload.hasOwnProperty('monitoringStatus') && {
                        //     monitoringStatus: action.payload.monitoringStatus,
                        // }),
                        ...(action?.payload?.hasOwnProperty('companyOwner') && {
                            companyOwner: action.payload.companyOwner?.name,
                        }),
                        ...(action?.payload?.hasOwnProperty('companyComment') && {
                            companyComment: action.payload.companyComment,
                        }),
                    };
                }
                return company;
            }),
        },
    };
};

const setSelectedFetchType = (state, action) => {
    return {
        ...state,
        portfolio: {
            ...state.portfolio,
            fetchedType: action?.payload,
            currentPageNumber: 1,
        },
    };
};

const setPortfolioPageNumber = (state, action) => {
    return {
        ...state,
        portfolio: {
            ...state.portfolio,
            currentPageNumber: action?.payload,
        },
    };
};

const setDealDrawer = (state, action) => {
    return {
        ...state,
        dealDrawer: {
            ...action.payload,
        },
    };
};

const setSingleCompanyDetails = (state, action) => {
    return {
        ...state,
        selectedCompany: action.payload,
    };
};

const fetchInvestorPermissionsSuccess = (state, action) => {
    return {
        ...state,
        permissions: {
            ...state.permissions,
            fetching: false,
            permissionsFetched: true,
            SCAN: action?.payload?.scan || action?.payload?.SCAN || ACCESS_TYPES.LOCKED,
            WATCH: action?.payload?.watch || action?.payload?.WATCH || ACCESS_TYPES.LOCKED,
            TRACK: action?.payload?.track || action?.payload?.TRACK || ACCESS_TYPES.LOCKED,
            DEALS: action?.payload?.deals || action?.payload?.DEALS || ACCESS_TYPES.LOCKED,
            onboardingTabs: action?.payload?.onboardingTabs || [],
            showMagicUpload: action?.payload?.showMagicUpload || false,
            // TRACK: 'TRIAL',
        },
    };
    //@TODO remove the upper and lowercase comparisons. just spread action.payload after checking api is sending all permissions in uppercase
};

const showUnlockAicaAndAddToLeadsConfirmation = (
    state,
    action: { payload: ShowUnlockAicaAndAddToLeadsPayload },
) => {
    return {
        ...state,
        unlockAicaReportAndAddToLeads: {
            ...(action.payload?.showModal
                ? {
                      showConfirmationModal: true,
                      assesseeOrgId: action.payload?.assesseeOrgId || '',
                      orgName: action.payload?.orgName || '',
                      source: action.payload?.source,
                  }
                : {
                      showConfirmationModal: false,
                      assesseeOrgId: '',
                      orgName: '',
                      source: '',
                  }),
        },
    };
};

const setShowInviteUser = (state, action) => {
    return {
        ...state,
        showInviteUser: action.payload,
    };
};

const fetchTabLockStatusSuccess = (state, action) => {
    return {
        ...state,
        tabLockStatus: {
            ...action.payload,
        },
    };
};

const reducer = (state: InvestorReducer = initialState, action): InvestorReducer => {
    switch (action.type) {
        case CONSTANTS.SET_IS_FETCHING_CONFIG:
            return setIsFetchingConfig(state, action);
        case CONSTANTS.SET_IS_FETCHING_PORTFOLIO:
            return setIsFetchingPortfolio(state, action);
        case CONSTANTS.SET_IS_FETCHING_DEALS:
            return setIsFetchingDeals(state, action);
        case CONSTANTS.SET_IS_FETCHING_PERMISSIONS:
            return setIsFetchingPermissions(state, action);
        case CONSTANTS.SET_SHOW_UPGRADE_TO_SCAN:
            return setShowUpgradeToScan(state, action);
        case CONSTANTS.SET_SHOW_UNLOCK_AICA_CONFIRMATION:
            return showUnlockAicaConfirmation(state, action);
        case CONSTANTS.SET_SHOW_UNLOCK_AICA_AND_ADD_TO_LEADS:
            return showUnlockAicaAndAddToLeadsConfirmation(state, action);
        case CONSTANTS.SET_SHOW_INVITE_USER:
            return setShowInviteUser(state, action);
        case CONSTANTS.UPDATE_DEAL_STATUS:
            return updateDealStatus(state, action);
        case CONSTANTS.UPDATE_COMPANY_STATUS:
            return updateCompanyStatus(state, action);
        case CONSTANTS.UPDATE_INVESTOR_DEAL_STATUS:
            return updateInvestorDealStatus(state, action);
        case CONSTANTS.FETCH_INVESTOR_CONFIG_SUCCESS:
            return fetchInvestorConfigSuccess(state, action);
        case CONSTANTS.FETCH_PORTFOLIO_DATA_SUCCESS:
            return fetchPortfolioDataSuccess(state, action);
        case CONSTANTS.FETCH_OWNER_DATA_SUCCESS:
            return fetchOwnerDataSuccess(state, action);
        case CONSTANTS.FETCH_DEALS_SUCCESS:
            return fetchDealsDataSuccess(state, action);
        case CONSTANTS.FETCH_INVESTOR_PERMISSIONS_SUCCESS:
            return fetchInvestorPermissionsSuccess(state, action);
        case CONSTANTS.SET_PORTFOLIO_SELECTED_FETCH_TYPE:
            return setSelectedFetchType(state, action);
        case CONSTANTS.SET_PORTFOLIO_PAGE_NUMBER:
            return setPortfolioPageNumber(state, action);
        case CONSTANTS.UPDATE_DEAL_COMMENTS:
            return updateDealComments(state, action);
        case CONSTANTS.UPDATE_DEAL_OWNER:
            return updateDealOwner(state, action);
        case CONSTANTS.SET_DEAL_DRAWER:
            return setDealDrawer(state, action);
        case CONSTANTS.FETCH_SINGLE_COMPANY_DETAILS_SUCCESS:
            return setSingleCompanyDetails(state, action);
        case CONSTANTS.FETCH_TAB_LOCK_STATUS_SUCCESS:
            return fetchTabLockStatusSuccess(state, action);
        case CONSTANTS.UPDATE_UNTAGGED_MU_FILE_NUMBER:
            return {
                ...state,
                untaggedCount: action.payload,
            };
        default:
            return state;
    }
};

export default reducer;
