import { OverviewData } from './types';

const namespace = 'DASHBOARD_OVERVIEW_';

export const CONSTANTS = {
    FETCH_COMPANY_OVERVIEW: `${namespace}FETCH_COMPANY_OVERVIEW`,
    FETCH_SCORECARD: `${namespace}FETCH_SCORECARD`,
    FETCH_ALERTS: `${namespace}FETCH_ALERTS`,
    FETCH_SHAREHOLDING_PATTERN: `${namespace}FETCH_SHAREHOLDING_PATTERN`,
};

export const initialOverviewData: OverviewData = {
    competitorsInfo: {
        competitors: [],
    },
    businessModel: '',
    employeeData: {
        currentEmployeeRange: '',
        momEmployeeData: [],
    },
    foundationDate: '',
    fundingInfo: {
        investors: [],
    },
    headquarterLocation: {
        city: '',
        fullAddress: '',
        state: '',
    },
    managementProfile: {
        profiles: [],
    },
    newsInfo: {
        news: [],
    },
    overview: '',
    cinNumber: '',
    panNumber: '',
    gstin: [],
    sectorInfo: {
        sector: '',
        subSector: '',
        reports: [],
        sectorOutlook: '',
    },
    socialInfo: {
        linkedinUrl: '',
        twitterUrl: '',
        websiteUrl: '',
    },
    investmentThesis: '',
    overallStatus: '',
    websiteTraffic: {
        momWebsiteTraffic: [],
    },
};
