import { useEffect, useState } from 'react';
import styles from './DataMappingDrawer.module.scss';
import EditableDropdown from '../../../../../../../common/EditableDropdown/EditableDropdown';
import { Player } from '@lottiefiles/react-lottie-player';
import EditableTable from './EditableTable/EditableTable';
import { useDispatch } from 'react-redux';
import { FetchTableDataForMIS, updateDataMetrics } from '../../../../../../../store/company/action';
import { useAppSelector } from '../../../../../../../app/hooks';
import { toast } from 'react-toastify';
import MetricBanner from './EditableTable/Components/MetricBanner';
import usePrevious from '../../../../../../../common/_custom/Hooks/usePrevious';
import ConfirmationModal from './ConfirmationModal/ConfirmationModal';
import { useParams, useSearchParams } from 'react-router-dom';
import React from 'react';

type DataMappingDrawerProps = {
    importMetrics: {
        sheetName: string;
        metricRange: string;
        dateRange: string;
    };
    setImportMetrics: Function;
    setShowDrawer: Function;
    setShowModal: Function;
    fetchDropdownData: Function;
    rawDropdownOptions: any;
    sheetNameOptions: string[];
    metricRangeOptions: string[];
    dateRangeOptions: string[];
    fillDataAccordingly: Function;
    showModal: {
        mapDataModal: boolean;
        closeModal: boolean;
    };
    changedData: any;
    setChangedData: Function;
    setFunctionToCall: Function;
    functionToCall: string;
    isInvestor: boolean;
};

function DataMappingDrawer(props: DataMappingDrawerProps) {
    const {
        showModal,
        setShowDrawer,
        fetchDropdownData,
        rawDropdownOptions,
        setFunctionToCall,
        functionToCall,
        sheetNameOptions,
        metricRangeOptions,
        dateRangeOptions,
        fillDataAccordingly,
        importMetrics,
        setImportMetrics,
        setShowModal,
        changedData,
        setChangedData,
        isInvestor,
    } = props;
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [tableData, setTableData] = useState<any>();
    const userData = useAppSelector((state) => state.user.userData);
    const queryParams = useParams();
    const [params, setParams] = useSearchParams();
    const previousValue = usePrevious<{
        sheetName: string;
        metricRange: string;
        dateRange: string;
    }>(importMetrics);
    useEffect(() => {
        if (
            importMetrics?.sheetName === previousValue?.sheetName &&
            importMetrics?.metricRange === previousValue?.metricRange &&
            importMetrics?.dateRange === previousValue?.dateRange
        ) {
            setDisabled(true);
        }
        if (
            importMetrics?.sheetName === '' ||
            importMetrics?.metricRange === '' ||
            importMetrics?.dateRange === ''
        ) {
            setDisabled(true);
            return;
        }
        if (
            importMetrics?.sheetName !== previousValue?.sheetName ||
            importMetrics?.metricRange !== previousValue?.metricRange ||
            importMetrics?.dateRange !== previousValue?.dateRange
        ) {
            setDisabled(false);
            return;
        }
    }, [importMetrics]);

    const saveMetaData = () => {
        let payload = {
            metadataId:
                rawDropdownOptions.find(
                    (option: any) => option.sheetName === importMetrics.sheetName,
                ).id ?? '',
            changedValues: changedData,
        };
        dispatch(
            updateDataMetrics({
                orgId: userData.assesseeOrgId ?? queryParams?.id,
                ...payload,
                onSuccess: () => {
                    setChangedData([]);
                    toast.success(
                        'Data Mapped:' +
                            importMetrics.sheetName +
                            ' | ' +
                            importMetrics.metricRange +
                            ' | ' +
                            importMetrics.dateRange,
                    );
                },
                onError: () => toast.error('Failed to save data'),
            }),
        );
    };
    const callFunctionsAccordingly = () => {
        switch (functionToCall) {
            case 'fetchTableDataForMIS':
                updateData();
                setShowModal({ mapDataModal: false, closeModal: false });
                break;
            case 'closeDrawer':
                closeDrawer();
                break;
            default:
                break;
        }
    };
    const updateData = () => {
        setChangedData([]);
        fetchTableDataForMIS(true);
    };

    const fetchTableDataForMIS = (overrideChangedData = false) => {
        if (changedData.length > 0 && !overrideChangedData) {
            setShowModal({ mapDataModal: false, closeModal: true });
            setFunctionToCall('fetchTableDataForMIS');
            return;
        }
        setLoading(true);
        let id =
            rawDropdownOptions.find((option: any) => option.sheetName === importMetrics.sheetName)
                ?.id ?? '';
        dispatch(
            FetchTableDataForMIS({
                id,
                orgId: userData.assesseeOrgId ?? queryParams?.id,
                onSuccess: (res) => {
                    setDisabled(true);
                    setLoading(false);
                    setTableData(res);
                    toast.success(
                        `AICA automatically mapped ${
                            (Number(res.data.data.totalRows) ?? 0) -
                            (Number(res?.data?.data?.unclassifiedRows) ?? 0)
                        }/${res.data.data.totalRows} metrics.`,
                    );
                },
                onError: () => setLoading(false),
            }),
        );
    };
    useEffect(() => {
        fetchTableDataForMIS();
    }, []);
    const closeDrawer = () => {
        params.delete('fileId');
        setChangedData([]);
        setParams(params);
        setShowDrawer(false);
    };
    return (
        <div className={styles.DrawerWrapper}>
            <div className={styles.DrawerHeading}>
                <h1>Confirm Mapping</h1>
            </div>
            <div className={styles.DrawerContent}>
                <div className={styles.ContentHeadingContianer}>
                    <div className={styles.ContentHeading}>
                        Sheet to Import:
                        <EditableDropdown
                            id="sheetName"
                            value={importMetrics.sheetName}
                            removeInput
                            setOutput={(value) => {
                                if (value !== importMetrics.sheetName) {
                                    setImportMetrics({
                                        sheetName: value,
                                        metricRange: '',
                                        dateRange: '',
                                    });
                                    fillDataAccordingly(rawDropdownOptions, 'metric', value);
                                } else {
                                    setImportMetrics({
                                        sheetName: value,
                                        metricRange: importMetrics.metricRange,
                                        dateRange: importMetrics.dateRange,
                                    });
                                }
                            }}
                            options={sheetNameOptions}
                        />
                    </div>
                    <div className={styles.separator} />
                    <div className={styles.ContentHeading}>
                        Column with Metrics:
                        <EditableDropdown
                            id="metricRange"
                            value={importMetrics.metricRange}
                            removeInput
                            setOutput={(value) => {
                                setImportMetrics({ ...importMetrics, metricRange: value });
                                fillDataAccordingly(
                                    rawDropdownOptions,
                                    'date',
                                    importMetrics.sheetName,
                                );
                            }}
                            options={metricRangeOptions}
                        />
                    </div>
                    <div className={styles.separator} />
                    <div className={styles.ContentHeading}>
                        Date Range:
                        <EditableDropdown
                            id="dateRange"
                            value={importMetrics.dateRange}
                            removeInput
                            setOutput={(value) => {
                                setImportMetrics({ ...importMetrics, dateRange: value });
                            }}
                            options={dateRangeOptions}
                        />
                    </div>
                    <div className={styles.separator} />
                    <button
                        disabled={disabled}
                        className={isInvestor ? styles.WhiteButtonInv : styles.WhiteButton}
                        onClick={() => fetchTableDataForMIS()}
                    >
                        Update
                    </button>
                </div>
                <div className={styles.DrawerBody}>
                    {tableData?.data?.data?.unclassifiedRows !== 0 && (
                        <MetricBanner
                            totalRows={tableData?.data?.data?.totalRows}
                            unclassifiedRows={tableData?.data?.data?.unclassifiedRows}
                        />
                    )}
                    {loading ? (
                        <div className={styles.Loader}>
                            <Player
                                autoplay
                                loop
                                src="https://fl-fe-assets.s3.ap-south-1.amazonaws.com/json/LoaderCompanySide.json"
                                style={{ height: '150px', width: '150px' }}
                            />
                            <p>Loading data...</p>
                        </div>
                    ) : (
                        <EditableTable
                            changedData={changedData}
                            setChangedData={setChangedData}
                            tableValue={tableData?.data}
                            isMappingConfirmed={
                                rawDropdownOptions.find(
                                    (item) => item.sheetName === importMetrics.sheetName,
                                )?.isConfirmed
                            }
                        />
                    )}
                </div>
            </div>
            <div className={styles.DrawerFooter}>
                <button
                    className={isInvestor ? styles.WhiteButtonInv : styles.WhiteButton}
                    onClick={() => {
                        if (changedData.length > 0) {
                            setShowModal({ mapDataModal: false, closeModal: true });
                            setFunctionToCall('closeDrawer');
                            return;
                        }
                        params.delete('fileId');
                        setParams(params);
                        setShowDrawer(false);
                    }}
                >
                    Cancel
                </button>
                <button
                    className={isInvestor ? styles.BlueButtonInv : styles.BlueButton}
                    onClick={() => {
                        saveMetaData();
                        setShowDrawer(false);
                        setTimeout(() => {
                            setShowModal({ mapDataModal: true, closeModal: false });
                        }, 500);
                    }}
                >
                    Save Data
                </button>
            </div>
            {showModal.closeModal && (
                <ConfirmationModal
                    isInvestor={isInvestor}
                    showModal={showModal}
                    onClose={() => {
                        setShowModal({ mapDataModal: false, closeModal: false });
                    }}
                    onClickNoSave={() => {
                        callFunctionsAccordingly();
                    }}
                    onClickSave={() => {
                        saveMetaData();
                        setChangedData([]);
                        callFunctionsAccordingly();
                    }}
                />
            )}
        </div>
    );
}

export default DataMappingDrawer;
