export function updateQueryParams(
    existingParams,
    paramSetterFunction,
    paramsToUpdate,
    location: any = {},
) {
    const existingQParams = Object.fromEntries(existingParams.entries());
    Object.keys(existingQParams).forEach((key) => {
        existingParams.set(key, existingQParams[key]);
    });
    Object.keys(paramsToUpdate).forEach((key) => {
        existingParams.set(key, paramsToUpdate[key]);
    });
    if (location && location?.state) {
        paramSetterFunction?.(existingParams, { state: location.state });
    } else {
        paramSetterFunction?.(existingParams);
    }
}
