import * as Effects from 'redux-saga/effects';
import * as apiService from './service';
import { CONSTANTS } from './constants';
import { CONSTANTS as USER_CONSTANTS } from '../user/constants';
import { CONSTANTS as DASHBOARD_CONSTANTS } from '../dashboard/constants';
import { JAVA_API_STATUS, SOMETHING_WRONG } from '../../utils/axios-interceptor';
import { toast } from 'react-toastify';
import { isEmpty } from '../../utils/utils';
import { creditBureauConfig } from './transformer';

const call: any = Effects.call;
const put = Effects.put;
const takeLatest = Effects.takeLatest;

function* fetchInvestorConfig(action) {
    try {
        yield put({
            type: CONSTANTS.SET_IS_FETCHING_CONFIG,
            payload: true,
        });
        let config: any = {};
        const response = yield call(apiService.getInvestorConfig, action.payload);
        const responseData = response?.data?.responseData;

        config = { ...response?.data?.mainConfig };

        //TODO:  CREDIT BUREAU HARDCODED CONFIG:
        config.tabConfigs['creditBureau'] = { ...creditBureauConfig };
        switch (responseData && responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                yield put({
                    type: CONSTANTS.FETCH_INVESTOR_CONFIG_SUCCESS,
                    payload: config ?? {},
                });
                yield put({
                    type: DASHBOARD_CONSTANTS.GET_DASHBOARD_CONFIG_SUCCESS,
                    payload: config ?? {},
                });
                yield put({
                    type: USER_CONSTANTS.UPDATE_AICA_COINS,
                    payload: {
                        aicaCoins: response.data?.aicaCoins,
                    },
                });
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
                yield put({
                    type: CONSTANTS.SET_IS_FETCHING_CONFIG,
                    payload: false,
                });
                break;
        }
    } catch (err: any) {
        yield put({
            type: CONSTANTS.SET_IS_FETCHING_CONFIG,
            payload: false,
        });
        console.error(err?.message || 'Some error occoured');
    }
}

function* fetchLenderConfig(action) {
    try {
        yield put({
            type: CONSTANTS.SET_IS_FETCHING_CONFIG,
            payload: true,
        });
        let config: any = {};
        const response = yield call(apiService.getLenderConfig, action.payload);
        const responseData = response?.data?.responseData;
        config = { ...response?.data?.mainConfig };

        switch (responseData && responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                yield put({
                    type: CONSTANTS.FETCH_INVESTOR_CONFIG_SUCCESS,
                    payload: config ?? {},
                    // payload: response2?.mainConfig ?? {},
                });
                yield put({
                    type: DASHBOARD_CONSTANTS.GET_DASHBOARD_CONFIG_SUCCESS,
                    payload: config ?? {},
                    // payload: response2?.mainConfig ?? {},
                });
                yield put({
                    type: USER_CONSTANTS.UPDATE_AICA_COINS,
                    payload: {
                        aicaCoins: response.data?.aicaCoins,
                    },
                });
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
                yield put({
                    type: CONSTANTS.SET_IS_FETCHING_CONFIG,
                    payload: false,
                });
                break;
        }
    } catch (err: any) {
        yield put({
            type: CONSTANTS.SET_IS_FETCHING_CONFIG,
            payload: false,
        });
        console.error(err?.message || 'Some error occoured');
    }
}

function* fetchPortfolioData(action) {
    try {
        yield put({
            type: CONSTANTS.SET_IS_FETCHING_PORTFOLIO,
            payload: true,
            isSearching: !!action?.payload?.companyName,
        });
        const response = yield call(apiService.getPortfolioData, action.payload);
        // const response2 = require('./data.json');
        // console.log('response',response2)
        const responseData = response?.data?.responseData;
        switch (responseData && responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                yield put({
                    type: CONSTANTS.FETCH_PORTFOLIO_DATA_SUCCESS,
                    payload: response?.data?.portfolioDetails,
                    // payload: response2?.portfolioDetails
                });
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
                yield put({
                    type: CONSTANTS.SET_IS_FETCHING_PORTFOLIO,
                    payload: false,
                    isSearching: false,
                });
        }
    } catch (err: any) {
        yield put({
            type: CONSTANTS.SET_IS_FETCHING_PORTFOLIO,
            payload: false,
            isSearching: false,
        });
        console.error(err?.message || 'Some error occurred.');
    }
}

function* fetchOwnerData(action) {
    try {
        const response = yield call(apiService.getOwnerData, action.payload);
        const responseData = response?.data?.responseData;
        switch (responseData && responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                yield put({
                    type: CONSTANTS.FETCH_OWNER_DATA_SUCCESS,
                    payload: response?.data?.userDetails,
                });
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
        }
    } catch (err: any) {
        console.error(err?.message || 'Some error occurred.');
    }
}

function* fetchSingleCompanyDetails(action) {
    try {
        const response = yield call(apiService.getSingleCompanyDetailsService, action.payload);
        const responseData = response?.data?.responseData;
        const companyData = response?.data?.portfolioCompanyDetails || {};
        switch (responseData && responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                yield put({
                    type: CONSTANTS.FETCH_SINGLE_COMPANY_DETAILS_SUCCESS,
                    payload: companyData,
                });
                action?.handlers?.onSuccess(companyData);
                break;
            case JAVA_API_STATUS.ERROR:
            default:
            // toast.error(responseData?.responseMessage || SOMETHING_WRONG);
        }
    } catch (err: any) {
        // console.error(err?.message || 'Some error occoured');
    }
}

function* triggerReportGeneration(action) {
    try {
        const response = yield call(apiService.initiateReportGenerationService, action.payload);
        const responseData = response?.data?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                toast.success(responseData?.responseMessage);
                yield put({
                    type: CONSTANTS.INITIATE_REPORT_GENERATION_SUCCESS,
                    payload: {
                        aicaCoins: response?.data?.aicaCoins,
                    },
                });
                yield put({
                    type: CONSTANTS.FETCH_PORTFOLIO_DATA,
                    payload: action.payload,
                });
                yield put({
                    type: USER_CONSTANTS.UPDATE_AICA_COINS,
                    payload: {
                        aicaCoins: response?.data?.aicaCoins,
                    },
                });
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
        }
    } catch (err: any) {
        console.error(err?.message || 'Some error occoured');
    }
}

function* inviteCompany(action) {
    try {
        const response = yield call(apiService.inviteCompanyService, action.payload);
        const responseData = response?.data?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                const newAssesseeOrgId = response.data?.assesseeOrgId;
                if (!isEmpty(response?.data?.message)) {
                    toast.success(response?.data?.message);
                } else {
                    toast.success(
                        'Invite Sent Successfully. Please request company to accept invite and generate profile.',
                    );
                }
                action.handlers?.onSuccess?.(newAssesseeOrgId);
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
        }
    } catch (err: any) {
        console.error(err?.message || 'Some error occoured');
    }
}

function* inviteCompanyV2(action) {
    try {
        const response = yield call(apiService.inviteCompanyServiceV2, action.payload);
        const responseData = response?.data?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                const newAssesseeOrgId = response.data?.assesseeOrgId;
                if (!isEmpty(response?.data?.message)) {
                    toast.success(response?.data?.message);
                } else {
                    toast.success(
                        'Invite Sent Successfully. Please request company to accept invite and generate profile.',
                    );
                }
                action.handlers?.onSuccess?.(newAssesseeOrgId);
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
        }
    } catch (err: any) {
        console.error(err?.message || 'Some error occoured');
    }
}

function* getAccesstypePermissions(action) {
    try {
        yield put({
            type: CONSTANTS.SET_IS_FETCHING_PERMISSIONS,
            payload: {
                fetching: true,
                permissionsFetched: false,
            },
        });
        const response = yield call(
            apiService.getInvestorAccessTypePermissionsService,
            action.payload,
        );
        const responseData = response?.data?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                yield put({
                    type: CONSTANTS.FETCH_INVESTOR_PERMISSIONS_SUCCESS,
                    payload: response?.data?.mainConfig || {},
                });
                if (!Object.keys(response?.data?.mainConfig)?.length)
                    toast.error('Error fetching user permissions. Please contact support.');
                action.handlers?.onSuccess?.(response?.data?.mainConfig);
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                yield put({
                    type: CONSTANTS.SET_IS_FETCHING_PERMISSIONS,
                    payload: {
                        fetching: false,
                        permissionsFetched: true,
                    },
                });
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
        }
    } catch (err: any) {
        yield put({
            type: CONSTANTS.SET_IS_FETCHING_PERMISSIONS,
            payload: {
                fetching: false,
                permissionsFetched: true,
            },
        });
        console.error(err?.message || 'Some error occoured');
    }
}

function* updateCompany(action) {
    try {
        const response = yield call(apiService.updateCompanyService, action.payload);
        const responseData = response?.data?.responseData;
        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                yield put({
                    type: CONSTANTS.UPDATE_COMPANY_STATUS,
                    payload: action.payload,
                });
                action.handlers?.onSuccess?.();
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
        }
    } catch (err: any) {
        console.error(err?.message || 'Some error occoured');
    }
}

function* createDeal(action) {
    try {
        const response = yield call(apiService.createDealService, action.payload);
        const responseData = response?.data?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                toast.success('Deal created successfully');
                action.handlers?.onSuccess?.();
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
        }
    } catch (err: any) {
        console.error(err?.message || 'Some error occoured');
    }
}

function* createInvestmentPreference(action) {
    try {
        const response = yield call(apiService.createInvestmentPreference, action.payload);
        const responseData = response?.data?.responseBody?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                // toast.success('Investment Preference created successfully');
                action.handlers?.onSuccess?.(response.data.responseBody?.data ?? {});
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
        }
    } catch (err: any) {
        console.error(err?.message || 'Some error occoured');
    }
}

function* getPecMatchingData(action) {
    try {
        const response = yield call(apiService.getPecMatchingDataService, action.payload);
        const responseData = response?.data?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS: {
                const data = response.data.responseBody?.data ?? {};
                const lenders = data.map((item) => {
                    return {
                        id: item.lenderId,
                        name: item.lenderName,
                    };
                });
                action.handlers?.onSuccess?.(lenders);
                break;
            }
            case JAVA_API_STATUS.ERROR:
            default:
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
        }
    } catch (err: any) {
        console.error(err?.message || 'Some error occoured');
    }
}

function* getMetricsForDealCreation(action) {
    try {
        const response = yield call(apiService.getMetricsForDealCreationService, action.payload);
        const responseData = response?.data?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                action.handlers?.onSuccess?.(response.data.responseBody ?? {});
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
                action.handlers?.onError?.();
        }
    } catch (err: any) {
        console.error(err?.message || 'Some error occoured');
        action.handlers?.onError?.();
    }
}

function* getAllDeals(action) {
    try {
        yield put({
            type: CONSTANTS.SET_IS_FETCHING_DEALS,
            payload: true,
        });
        const response = yield call(apiService.getAllDealsService, action.payload);
        const responseData = response?.data?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                yield put({
                    type: CONSTANTS.FETCH_DEALS_SUCCESS,
                    payload: response.data.responseBody ?? {},
                });
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                yield put({
                    type: CONSTANTS.SET_IS_FETCHING_DEALS,
                    payload: false,
                });
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
        }
    } catch (err: any) {
        yield put({
            type: CONSTANTS.SET_IS_FETCHING_DEALS,
            payload: false,
        });
        console.error(err?.message || 'Some error occoured');
    }
}

function* getAllLenderDeals(action) {
    try {
        yield put({
            type: CONSTANTS.SET_IS_FETCHING_DEALS,
            payload: true,
        });
        const response = yield call(apiService.getAllLenderDealsService, action.payload);
        const responseData = response?.data?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                yield put({
                    type: CONSTANTS.FETCH_DEALS_SUCCESS,
                    payload: response.data.responseBody ?? {},
                });
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                yield put({
                    type: CONSTANTS.SET_IS_FETCHING_DEALS,
                    payload: false,
                });
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
        }
    } catch (err: any) {
        yield put({
            type: CONSTANTS.SET_IS_FETCHING_DEALS,
            payload: false,
        });
        console.error(err?.message || 'Some error occoured');
    }
}

function* updateDeal(action) {
    try {
        const response = yield call(apiService.updateDealService, action.payload);
        const responseData = response?.data?.responseBody?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                if (
                    !action?.payload?.hasOwnProperty('comment') &&
                    !action?.payload?.hasOwnProperty('owner')
                ) {
                    yield put({
                        type: CONSTANTS.UPDATE_DEAL_STATUS,
                        payload: action.payload,
                    });
                    toast.success('Deal updated successfully');
                } else if (action?.payload?.hasOwnProperty('owner')) {
                    yield put({
                        type: CONSTANTS.UPDATE_DEAL_OWNER,
                        payload: action.payload,
                    });
                } else {
                    yield put({
                        type: CONSTANTS.UPDATE_DEAL_COMMENTS,
                        payload: action.payload,
                    });
                }

                break;
            case JAVA_API_STATUS.ERROR:
            default:
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
        }
    } catch (err: any) {
        console.error(err?.message || 'Some error occoured');
    }
}

function* updateInvestorDeal(action) {
    try {
        const response = yield call(apiService.updateInvestorDealService, action.payload);
        const responseData = response?.data?.responseBody?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                if (action.payload?.source === 'LENDER') {
                    yield put({
                        type: CONSTANTS.UPDATE_DEAL_STATUS,
                        payload: action.payload,
                    });
                } else {
                    yield put({
                        type: CONSTANTS.UPDATE_INVESTOR_DEAL_STATUS,
                        payload: action.payload,
                    });
                }
                if (!action?.payload?.hasOwnProperty('comment'))
                    toast.success('Deal updated successfully');
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
        }
    } catch (err: any) {
        console.error(err?.message || 'Some error occoured');
    }
}

function* getDealData(action) {
    try {
        const response = yield call(apiService.getDealDataService, action.payload);
        const responseData = response?.data?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                action.handlers?.onSuccess?.(response.data.responseBody?.data ?? {});
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
        }
    } catch (err: any) {
        console.error(err?.message || 'Some error occoured');
    }
}

function* getLenderDealData(action) {
    try {
        const response = yield call(apiService.getLenderDealDataService, action.payload);
        const responseData = response?.data?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                action.handlers?.onSuccess?.(response.data.responseBody?.data ?? {});
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
        }
    } catch (err: any) {
        console.error(err?.message || 'Some error occoured');
    }
}

function* getInvestmentPreferences(action) {
    try {
        const response = yield call(apiService.getAllInvestmentPreference, action.payload);
        const responseData = response?.data?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                action.handlers?.onSuccess?.(response.data.responseBody?.data ?? {});
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
        }
    } catch (err: any) {
        console.error(err?.message || 'Some error occoured');
    }
}

function* getInvestmentPreferennce(action) {
    try {
        const response = yield call(
            apiService.getInvestmentPreference,
            action.payload.investmentPreferenceId,
        );
        const responseData = response?.data?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                action.handlers?.onSuccess?.(response.data.responseBody?.data ?? {});
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
        }
    } catch (err: any) {
        console.error(err?.message || 'Some error occoured');
    }
}

function* updateInvestmentPreference(action) {
    try {
        const response = yield call(apiService.updateInvestmentPreference, action.payload);
        const responseData = response?.data?.responseBody?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                action.handlers?.onSuccess?.(response.data.responseBody?.data ?? {});
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
        }
    } catch (err: any) {
        console.error(err?.message || 'Some error occoured');
    }
}

function* fetchTabLockStatus(action) {
    try {
        const response = yield call(apiService.getTabLockStatus, action.payload);
        const responseData = response?.data?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                yield put({
                    type: CONSTANTS.FETCH_TAB_LOCK_STATUS_SUCCESS,
                    payload: response.data?.dashboardTabLockConfigMap || {},
                });
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                console.error('Error in fetching tabs lock status', response);
        }
    } catch (err: any) {
        console.error('Error in fetching tabs lock status', err);
    }
}

export default function* actionWatcher() {
    yield takeLatest(CONSTANTS.FETCH_INVESTOR_CONFIG, fetchInvestorConfig);
    yield takeLatest(CONSTANTS.FETCH_LENDER_CONFIG, fetchLenderConfig);
    yield takeLatest(CONSTANTS.FETCH_PORTFOLIO_DATA, fetchPortfolioData);
    yield takeLatest(CONSTANTS.FETCH_OWNER_DATA, fetchOwnerData);
    yield takeLatest(CONSTANTS.FETCH_SINGLE_COMPANY_DETAILS, fetchSingleCompanyDetails);
    yield takeLatest(CONSTANTS.INITIATE_REPORT_GENERATION, triggerReportGeneration);
    yield takeLatest(CONSTANTS.INVITE_COMPANY, inviteCompany);
    yield takeLatest(CONSTANTS.INVITE_COMPANY_V2, inviteCompanyV2);
    yield takeLatest(CONSTANTS.FETCH_INVESTOR_PERMISSIONS, getAccesstypePermissions);
    yield takeLatest(CONSTANTS.UPDATE_COMPANY, updateCompany);
    yield takeLatest(CONSTANTS.CREATE_DEAL, createDeal);
    yield takeLatest(CONSTANTS.METRICS_FOR_DEAL_CREATION, getMetricsForDealCreation);
    yield takeLatest(CONSTANTS.PEC_MATCHING, getPecMatchingData);
    yield takeLatest(CONSTANTS.GET_ALL_DEALS, getAllDeals);
    yield takeLatest(CONSTANTS.GET_ALL_LENDER_DEALS, getAllLenderDeals);
    yield takeLatest(CONSTANTS.UPDATE_DEAL, updateDeal);
    yield takeLatest(CONSTANTS.UPDATE_INVESTOR_DEAL, updateInvestorDeal);
    yield takeLatest(CONSTANTS.GET_DEAL_DATA, getDealData);
    yield takeLatest(CONSTANTS.GET_LENDER_DEAL_DATA, getLenderDealData);
    yield takeLatest(CONSTANTS.GET_INVESTMENT_PREFERENCES, getInvestmentPreferences);
    yield takeLatest(CONSTANTS.GET_INVESTMENT_PREFERENCE, getInvestmentPreferennce);
    yield takeLatest(CONSTANTS.CREATE_INVESTMENT_PREFERENCE, createInvestmentPreference);
    yield takeLatest(CONSTANTS.UPDATE_INVESTMENT_PREFERENCE, updateInvestmentPreference);
    yield takeLatest(CONSTANTS.FETCH_TAB_LOCK_STATUS, fetchTabLockStatus);
}
