export const FIRST_NAME_ERROR_MESSAGE = 'Please enter a valid first name';
export const LAST_NAME_ERROR_MESSAGE = 'Please enter a valid last name';
export const ORGANISATION_NAME_ERROR_MESSAGE = 'Please enter a valid organisation name';
export const ORGANISATION_TYPE_ERROR_MESSAGE = 'Please select organisation type';
export const EMAIL_ERROR_MESSAGE = 'Please enter a valid work email';
export const MOBILE_NUMBER_ERROR_MESSAGE = 'Please enter a valid contact number';
export const PASSWORD_ERROR_MESSAGE = 'Password must be 8 to 20 characters long.';

export const usersTypeDropdownList = [
    {
        id: 'INVESTOR',
        label: 'Admin',
    },
    {
        id: 'PARTNER',
        label: 'Partner',
    },
    {
        id: 'LENDER',
        label: 'Investor',
    },
    {
        id: 'ADMIN_TEAM',
        label: 'Team Member',
    },
];
