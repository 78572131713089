import React from 'react';

const OpenMuOrgIcon = ({ colour = '#6021B3', width = '14', height = '14' }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 7C0 8.85652 0.737498 10.637 2.05025 11.9497C3.36301 13.2625 5.14348 14 7 14C8.85652 14 10.637 13.2625 11.9497 11.9497C13.2625 10.637 14 8.85652 14 7C14 5.14348 13.2625 3.36301 11.9497 2.05025C10.637 0.737498 8.85652 0 7 0C5.14348 0 3.36301 0.737498 2.05025 2.05025C0.737498 3.36301 0 5.14348 0 7ZM5.166 9.45263C5.12564 9.49441 5.07737 9.52774 5.02399 9.55067C4.97061 9.5736 4.9132 9.58567 4.85511 9.58617C4.79702 9.58668 4.73941 9.57561 4.68565 9.55361C4.63188 9.53161 4.58303 9.49913 4.54195 9.45805C4.50087 9.41697 4.46839 9.36812 4.44639 9.31435C4.42439 9.26059 4.41332 9.20298 4.41383 9.14489C4.41433 9.0868 4.4264 9.02939 4.44933 8.97601C4.47226 8.92263 4.50559 8.87436 4.54738 8.834L8.13138 5.25L5.70937 5.25C5.59334 5.25 5.48206 5.20391 5.40002 5.12186C5.31797 5.03981 5.27187 4.92853 5.27187 4.8125C5.27187 4.69647 5.31797 4.58519 5.40002 4.50314C5.48206 4.42109 5.59334 4.375 5.70937 4.375L9.1875 4.375C9.30353 4.375 9.41481 4.42109 9.49686 4.50314C9.57891 4.58519 9.625 4.69647 9.625 4.8125V8.29063C9.625 8.40666 9.57891 8.51794 9.49686 8.59998C9.41481 8.68203 9.30353 8.72813 9.1875 8.72813C9.07147 8.72813 8.96019 8.68203 8.87814 8.59998C8.79609 8.51794 8.75 8.40666 8.75 8.29063V5.86862L5.166 9.45263Z"
                fill={colour}
            />
        </svg>
    );
};

export default OpenMuOrgIcon;
