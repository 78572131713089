import axios from 'axios';
import { GET, polusBaseUrl, POST } from '../../../utils/axios-interceptor';
import { getTimeStamp } from '../../../utils/dateUtils';

export function getCompanyOverviewData(payload) {
    return axios({
        // baseURL: 'https://api-dev.recur.club/aica/rest',
        url: API_ENDPOINTS.FETCH_COMPANY_OVERVIEW,
        method: POST,
        data: {
            requestId: getTimeStamp(),
            assesseeOrgId: payload.assesseeOrgId,
            investorOrgId: payload.investorOrgId,
            partnerUserId: payload.partnerUserId,
        },
    });
}

export function getScorecardData(payload) {
    return axios({
        url: API_ENDPOINTS.FETCH_SCORECARD_DATA,
        method: POST,
        data: {
            requestId: getTimeStamp(),
            assesseeOrgId: payload.assesseeOrgId,
        },
    });
}

export function getAlertsData(payload) {
    return axios({
        url: API_ENDPOINTS.FETCH_ALERTS_DATA,
        method: POST,
        data: {
            requestId: getTimeStamp(),
            assesseeOrgId: payload.assesseeOrgId,
        },
    });
}

export function getShareholdingPatternData(payload) {
    return axios({
        baseURL: polusBaseUrl,
        url: API_ENDPOINTS.GET_GRAPH_DATA.url,
        method: GET,
        params: API_ENDPOINTS.GET_GRAPH_DATA.params(payload),
    });
}

export function getManagementProfileUserData(payload) {
    return axios({
        baseURL: polusBaseUrl,
        url: API_ENDPOINTS.GET_MANAGEMENT_PROFILE_USER_DATA,
        method: POST,
        data: {
            requestPayload: {
                ...payload,
            },
        },
    });
}

export function fetchFundingHistoryDataService(payload) {
    return axios({
        baseURL: polusBaseUrl,
        url: API_ENDPOINTS.FETCH_FUNDING_HISTORY_DATA,
        method: POST,
        data: {
            requestPayload: {
                ...payload,
                requestId: getTimeStamp(),
            },
        },
    });
}

const API_ENDPOINTS = {
    FETCH_COMPANY_OVERVIEW: '/profile/assessee/overview/main',
    FETCH_SCORECARD_DATA: '/profile/assessee/aicaScorecard',
    FETCH_ALERTS_DATA: '/profile/assessee/overview/alerts',
    GET_GRAPH_DATA: {
        url: `/insights/tab/data`,
        method: GET,
        params: (payload) => ({
            requestId: getTimeStamp(),
            // multiple graphs can be passed as comma separated string
            graphs: payload.graphNames?.join(','),
            tabName: payload.tabName,
            startDate: payload.startDate,
            endDate: payload.endDate,
            tenure: payload.tenure,
            reportId: payload.reportId,
            directorId: payload?.directorId,
        }),
    },
    GET_MANAGEMENT_PROFILE_USER_DATA: '/unifier/getCompanyOverviewDirectorDetails',
    FETCH_FUNDING_HISTORY_DATA: '/unifier/getFundingHistoryData',
};
