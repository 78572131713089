import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useGSTContext } from '../../Context/GstContext';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WhiteRedirectSVG from '../../../../../../../../assets/Svg/WhiteRedirectSVG';

const AccessDenied = (
    <Box
        sx={{
            backgroundColor: '#FFF7E9',
            borderRadius: '5px',
            padding: '16px',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            columnGap: '10px',
        }}
    >
        <InfoOutlinedIcon htmlColor="#FCBF07" fontSize="12px" />
        <Box
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
            }}
        >
            <Typography
                fontSize={'10px'}
                fontWeight={500}
                fontFamily={'gilroy-medium, sans-serif'}
                lineHeight={'14px'}
                letterSpacing={'0'}
                color="#000000"
            >
                <Typography
                    component={'span'}
                    fontWeight={700}
                    fontSize={'inherit'}
                    fontFamily={'inherit'}
                    sx={{ marginRight: '5px' }}
                >
                    Access Denied:
                </Typography>
                Your GST account has not enabled access to API based fetch of reports. Please click
                here to enable API access
            </Typography>
        </Box>
    </Box>
);

const FacingIssue = (
    <Box
        sx={{
            backgroundColor: '#FFF7E9',
            borderRadius: '5px',
            padding: '16px',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            columnGap: '10px',
        }}
    >
        <InfoOutlinedIcon htmlColor="#FCBF07" fontSize="12px" />
        <Box
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
            }}
        >
            <Typography
                fontSize={'10px'}
                fontWeight={500}
                fontFamily={'gilroy-medium, sans-serif'}
                lineHeight={'14px'}
                letterSpacing={'0'}
                color="#000000"
            >
                We’re facing issues with enabling access to your GST API. Please click below to know
                how to enable API access manually
            </Typography>
        </Box>
    </Box>
);

const GstErrorScreens = (props) => {
    const { gstData, handleProceed, setStep, handleEnableViaManually } = useGSTContext();
    const { isAccessDenied } = props;
    const AccessDeniedActions = (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexDirection: 'column',
                width: '100%',
            }}
        >
            {/* <Button
                variant="contained"
                // onClick={() => {
                //     setStep({
                //         component: STEPS.GstAccessViaAPI,
                //     });
                // }}
                sx={{
                    backgroundColor: '#2F8FFF',
                    width: '100%',
                    height: '40px',
                    marginBottom: '8px',
                    color: '#FFFFFF',
                    fontFamily: 'gilroy-medium, sans-serif',
                    fontSize: '12px',
                    fontWeight: '600',
                    '&:disabled': {
                        backgroundColor: '#D8D8D8', // Set background color for disabled state
                        color: 'white', // Set text color for disabled state
                        cursor: 'not-allowed', // Change cursor for disabled state
                    },
                }}>
                Enable access manually
            </Button> */}
            <Button
                variant="contained"
                onClick={() => {
                    handleEnableViaManually();
                }}
                sx={{
                    backgroundColor: '#2F8FFF',
                    width: '100%',
                    height: '40px',
                    marginBottom: '8px',
                    color: '#FFFFFF',
                    fontFamily: 'gilroy-medium, sans-serif',
                    fontSize: '12px',
                    fontWeight: '600',
                    '&:disabled': {
                        backgroundColor: '#D8D8D8', // Set background color for disabled state
                        color: 'white', // Set text color for disabled state
                        cursor: 'not-allowed', // Change cursor for disabled state
                    },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        columnGap: '4px',
                    }}
                >
                    <WhiteRedirectSVG sx={{ width: '16px', height: '16px' }} />
                    <Typography
                        sx={{
                            fontSize: '12px',
                            fontFamily: 'inherit',
                            fontWeight: 600,
                            margin: '2px 0 0 0',
                        }}
                    >
                        Enable access manually
                    </Typography>
                </Box>
            </Button>
        </Box>
    );
    const FacingIssueActions = (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexDirection: 'column',
                width: '100%',
            }}
        >
            <Button
                variant="contained"
                onClick={() => {
                    handleEnableViaManually();
                }}
                sx={{
                    backgroundColor: '#2F8FFF',
                    width: '100%',
                    height: '40px',
                    marginBottom: '8px',
                    color: '#FFFFFF',
                    fontFamily: 'gilroy-medium, sans-serif',
                    fontSize: '12px',
                    fontWeight: '600',
                    '&:disabled': {
                        backgroundColor: '#D8D8D8', // Set background color for disabled state
                        color: 'white', // Set text color for disabled state
                        cursor: 'not-allowed', // Change cursor for disabled state
                    },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        columnGap: '4px',
                    }}
                >
                    <WhiteRedirectSVG sx={{ width: '16px', height: '16px' }} />
                    <Typography
                        sx={{
                            fontSize: '12px',
                            fontFamily: 'inherit',
                            fontWeight: 600,
                            margin: '2px 0 0 0',
                        }}
                    >
                        Enable access manually
                    </Typography>
                </Box>
            </Button>
        </Box>
    );

    return (
        <>
            <DialogContent
                sx={{
                    width: '330px',
                    maxWidth: '100%',
                    margin: '0 0 70px 0',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        margin: '0 0 30px 0',
                    }}
                >
                    <Typography
                        color={'#000000'}
                        fontWeight={'600'}
                        fontSize={'14px'}
                        fontFamily={'gilroy-medium, sans-serif'}
                    >
                        {gstData?.gstin_number}
                    </Typography>
                    <Typography
                        color={'#AEAEAE'}
                        fontSize={'14px'}
                        fontWeight={500}
                        fontFamily={'gilroy'}
                    >
                        {gstData?.state}
                    </Typography>
                </Box>
                {isAccessDenied ? AccessDenied : FacingIssue}
            </DialogContent>

            <DialogActions sx={{ p: '8px 16px 20px!important' }}>
                {FacingIssueActions}
            </DialogActions>
        </>
    );
};

export default GstErrorScreens;
