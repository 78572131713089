import { useEffect, useState } from 'react';
import { useAppSelector } from '../../app/hooks';
import {
    getAllDocs,
    getDocList,
    getOrgList,
    getUntaggedCount,
    updateDocMetadata,
} from './MagicUploadHelper';
import {
    downloadFile,
    downloadFileUsingURLHelper,
} from '../../modules/Company/UploadJourney/components/UploadCard/utils/api/helpers';
import { getObjAndFileNameS3, getSubdomain } from '../../utils/utils';
import { toast } from 'react-toastify';
import { updateUntaggedMuFileNumber } from '../../store/investor/action';
import { useDispatch } from 'react-redux';
import { Mixpanel } from '../../utils/mixpanel';
import { useParams, useSearchParams } from 'react-router-dom';
import { PASSWORD_PROTECTION_STATUS } from './Utils/PasswordProtectionUtils';

export type MagicUploadFileData = {
    id: string;
    docId: number | string;
    fileName: string;
    orgName: string;
    docType: string;
    source: string;
    uploadedBy: string;
    lastUpdated: string;
    status: string;
    orgId: string;
    zip?: string;
    passwordProtectionStatus?: string;
};

const useFetchDataForMU = (
    tabState: string,
    fileData: MagicUploadFileData[],
    setFileData: React.Dispatch<React.SetStateAction<MagicUploadFileData[]>>,
    autoFetch: boolean,
) => {
    const investorOrgId = useAppSelector((state) => state.user.userData.investorOrgId);
    const searchParams = useParams();
    const assesseeOrgId =
        useAppSelector((state) => state.user.userData.assesseeOrgId) ?? searchParams.id;
    const [index, setIndex] = useState<number | null>(null);
    const [searchText, setSearchText] = useState('');
    const [orgList, setOrgList] = useState([]);
    const [documentList, setDocumentList] = useState([]);
    const dispatch = useDispatch();
    const [params] = useSearchParams();
    const user = useAppSelector((state) => state.user);
    const isCompany = params.get('isCompany') === 'true';

    const setUpdatedFileData = (updatedFiles) => {
        setFileData((prev) => {
            let newFileData = [...prev];
            // Handle deletions
            Object.keys(updatedFiles).forEach((id) => {
                if (updatedFiles[id] === undefined) {
                    newFileData = newFileData.filter((file) => file.id !== id);
                }
            });
            // Handle updates and additions
            Object.keys(updatedFiles).forEach((id) => {
                if (updatedFiles[id] !== undefined) {
                    const existingIndex = newFileData.findIndex((file) => file.id === id);
                    if (existingIndex !== -1) {
                        // Update existing file
                        newFileData[existingIndex] = {
                            ...newFileData[existingIndex],
                            ...updatedFiles[id],
                        };
                    } else {
                        // Add new file at the beginning
                        newFileData = [updatedFiles[id], ...newFileData];
                    }
                }
            });

            return newFileData;
        });
    };

    const fetchFileData = async (
        pageNo: number,
        filters?: {
            assesseeOrgIdList: {
                value: string;
                label: string;
            }[];
            userIds: {
                value: string;
                label: string;
            }[];
            sources: {
                value: string;
                label: string;
            }[];
        },
    ) => {
        let isFetched = false;
        let hasMore = true;
        let payload: { [key: string]: string | string[] } = {};
        payload = {
            pnoId: investorOrgId,
            step: tabState,
        };
        if (filters) {
            payload = {
                pnoId: investorOrgId,
                step: tabState,
                assesseeOrgIdList: filters.assesseeOrgIdList.map((org) => org.value),
                userIds: filters.userIds.map((user) => user.value),
                sources: filters.sources.map((source) => source.value),
            };
        }
        if (isCompany) payload.orgId = assesseeOrgId;
        await getAllDocs(
            payload,
            { pageNo: pageNo, pageSize: 20 },
            {
                onSuccess: (res) => {
                    if (filters && pageNo === 0) setFileData(res);
                    else setFileData((prev) => [...prev, ...res]);
                    if (res?.length < 20) hasMore = false;
                    isFetched = true;
                },
            },
        );
        return { isFetched, hasMore };
    };

    const getUntaggedStatus = () => {
        getUntaggedCount(
            {
                pnoId: user.userData.investorOrgId,
                // add orgId key if isCompany is true
                ...(isCompany && { orgId: user.userData.assesseeOrgId ?? searchParams.id }),
            },
            {
                onSuccess: (res) => {
                    dispatch(updateUntaggedMuFileNumber(res));
                },
            },
        );
    };

    const getOrganizationList = () => {
        if (searchText?.length < 3) return;
        getOrgList(
            { pnoId: investorOrgId, searchText: searchText },
            {
                onSuccess: (res) => {
                    setOrgList(res);
                },
            },
        );
    };

    const selectOrg = async (option, file) => {
        let result = false;
        await updateDocMetadata(
            {
                id: file.id,
                orgInfo: { orgName: option.orgName },
                pnoId: investorOrgId,
            },
            {
                onSuccess: () => {
                    result = true;
                    setUpdatedFileData({
                        [file.id]: {
                            ...(fileData.find((f) => f.id === file.id) || {}),
                            orgName: option.orgName,
                            orgId: option.assesseeOrgId,
                        },
                    });
                    Mixpanel.track('Company Name Tagged', {
                        'Company Name': option.orgName,
                        fileName: file.fileName,
                        uploadedBy: user.userData.userName,
                        source: 'Website',
                        AicaDomain: getSubdomain(),
                    });
                    getUntaggedStatus();
                },
            },
        );
        return result;
    };

    const submitPassword = async (password: string, file: MagicUploadFileData) => {
        let result = false;
        await updateDocMetadata(
            {
                id: file.id,
                password: password,
                pnoId: investorOrgId,
            },
            {
                onSuccess: () => {
                    result = true;
                    setUpdatedFileData({
                        [file.id]: {
                            ...(fileData.find((f) => f.id === file.id) || {}),
                            passwordProtectionStatus: PASSWORD_PROTECTION_STATUS.PASSWORD_VALIDATED,
                        },
                    });
                    Mixpanel.track('Password Entered', {
                        fileName: file.fileName,
                        uploadedBy: user.userData.userName,
                        source: 'Website',
                        AicaDomain: getSubdomain(),
                    });
                },
                onError: () => {
                    toast.error(`Incorrect Password for file: ${file.fileName}`);
                },
            },
        );
        return result;
    };

    const getDocumentList = () => {
        getDocList(
            { pnoId: investorOrgId },
            {
                onSuccess: (res) => {
                    setDocumentList(res);
                },
            },
        );
    };

    const selectDocType = async (option, file) => {
        let result = false;
        await updateDocMetadata(
            {
                id: file.id,
                docType: option,
                pnoId: investorOrgId,
            },
            {
                onSuccess: () => {
                    result = true;
                    setUpdatedFileData({
                        [file.id]: {
                            ...(fileData.find((f) => f.id === file.id) || {}),
                            docType: option,
                        },
                    });
                    Mixpanel.track('Document Type Tagged', {
                        'Document Type': option,
                        fileName: file.fileName,
                        uploadedBy: user.userData.userName,
                        source: 'Website',
                        AicaDomain: getSubdomain(),
                    });
                    getUntaggedStatus();
                },
            },
        );
        return result;
    };

    const downloadTheFile = async (docId: number | string, name: string) => {
        if (!isNaN(Number(docId))) {
            await downloadFile(docId, () => {}, name);
        } else {
            const { obj }: any = getObjAndFileNameS3(docId);
            const bucketName = obj?.bucket;

            const res = await downloadFileUsingURLHelper(obj.url, bucketName);
            if (res) {
                const fileRes = await fetch(res);
                if (fileRes.status === 200) {
                    const blob = await fileRes.blob();
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = name ?? 'file';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                } else {
                    toast.error('Error in downloading file');
                }
            }
        }
    };

    useEffect(() => {
        // Implement debouncing here
        const timer = setTimeout(() => {
            getOrganizationList();
        }, 500);
        return () => clearTimeout(timer);
    }, [searchText]);

    return {
        setSearchText,
        orgList,
        index,
        setIndex,
        selectOrg,
        documentList,
        downloadTheFile,
        selectDocType,
        fetchFileData,
        getDocumentList,
        getUntaggedStatus,
        setUpdatedFileData,
        submitPassword,
    };
};

export default useFetchDataForMU;
