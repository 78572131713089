import React, { useEffect, useRef, useState } from 'react';
import styles from './CircularSelect.module.scss';
import clsx from 'clsx';
import {
    plusPurpleCircle,
    PURPLE_CROSS,
    PURPLE_DROPDOWN_CARET,
} from '../../../../assets/hostedassets';

interface CircularSelectProps {
    option: string[];
    onSelect: (value: string) => Promise<boolean>;
    selectedOption?: string;
    placeholder?: string;
    disabled?: boolean;
    isFinalized?: boolean;
}

const CircularSelect: React.FC<CircularSelectProps> = ({
    option,
    onSelect,
    isFinalized,
    selectedOption,
    placeholder,
    disabled,
}) => {
    const [options, setOptions] = useState<string[]>(option);
    const [filterPhrase, setFilterPhrase] = useState<string | null>(null);
    const [showOptions, setShowOptions] = useState(false);
    const [otherOption, setOtherOption] = useState('');
    const [showOtherOption, setShowOtherOption] = useState(false);
    const [loading, setLoading] = useState(false);
    const dropdownRef = useRef(null);
    const [dontTriggerBlur, setDontTriggerBlur] = useState(false);
    const inputFocus = useRef<HTMLInputElement>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !(dropdownRef.current as any).contains(event.target)) {
            setOptions(option);
            setShowOptions(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (showOtherOption) {
            inputFocus.current?.click();
        }
    }, [showOtherOption]);

    const handleChange = (value: string) => {
        if (!showOtherOption) {
            setOptions(option.filter((item) => item.toLowerCase().includes(value.toLowerCase())));
            setFilterPhrase(value);
        } else {
            setOtherOption(value);
        }
    };

    const handleSelect = async (option: string) => {
        setLoading(true);
        await onSelect(option);
        setLoading(false);
        setFilterPhrase(null);
        setShowOptions(false);
    };

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Enter' && showOtherOption) {
                inputFocus.current?.blur();
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [showOtherOption]);

    const handleBlur = () => {
        setTimeout(async () => {
            if (dontTriggerBlur) {
                // If this flag is set, don't execute the blur logic.
                setDontTriggerBlur(false); // Reset the flag for future actions.
                return;
            }

            // If the user clicked outside and didn't enter any value, reset the state.
            if (otherOption === '') {
                setShowOtherOption(false);
                setFilterPhrase(null);
                return;
            }

            // If the user entered a value in the "other option" input, proceed with selection.
            if (otherOption && showOtherOption) {
                let res = await onSelect(otherOption);
                if (res) {
                    setShowOtherOption(false);
                    setOtherOption('');
                    setFilterPhrase(null);
                }
            }
        }, 0);
    };

    return (
        <div ref={dropdownRef} style={{ position: 'relative', width: '250px' }}>
            <input
                ref={inputFocus}
                type="text"
                autoComplete={'off'}
                placeholder={showOtherOption ? 'Enter Data Type...' : placeholder ?? 'Select...'}
                value={
                    showOtherOption
                        ? otherOption
                        : filterPhrase !== null
                        ? filterPhrase
                        : selectedOption || otherOption
                }
                disabled={disabled}
                className={clsx({
                    [styles.selectBox]: true,
                    [styles.FilteredOptionAvailableInput]: filterPhrase !== null,
                    [styles.Input]: showOtherOption,
                    [styles.selectedBox]: selectedOption,
                    [styles.finalized]: isFinalized || disabled,
                })}
                onClick={(e) => {
                    if (showOtherOption || loading) return;
                    setShowOptions((prev) => !prev);
                }}
                onChange={(e) => handleChange(e.target.value)}
                onBlur={handleBlur}
            />
            {showOtherOption ? (
                <div
                    className={styles.crossIcon}
                    onMouseDown={(e) => {
                        e.preventDefault();
                        setShowOtherOption(false);
                        setOtherOption('');
                    }}
                >
                    <img src={PURPLE_CROSS} alt={'Cross'} height={9} width={9} />
                </div>
            ) : !disabled && selectedOption ? (
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                        if (showOtherOption) return;
                        setShowOptions((prev) => !prev);
                    }}
                    className={styles.crossIcon}
                >
                    <img src={PURPLE_DROPDOWN_CARET} alt={'Dropdown'} height={10} width={10} />
                </div>
            ) : disabled ? null : (
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                        if (showOtherOption) return;
                        setShowOptions((prev) => !prev);
                    }}
                    className={styles.arrowIcon}
                />
            )}
            {showOptions && !disabled && (
                <div className={styles.dropdown}>
                    {options?.map((option, index) => (
                        <div
                            onMouseDown={(e) => {
                                e.stopPropagation();
                                handleSelect(option);
                            }}
                            key={index}
                            className={styles.option}
                        >
                            {option}
                        </div>
                    ))}
                    <div
                        onMouseDown={() => {
                            setShowOptions(false);
                            setShowOtherOption(true);
                            setFilterPhrase('');
                            setDontTriggerBlur(true);
                            setTimeout(() => {
                                inputFocus.current?.focus();
                            }, 0);
                        }}
                        className={styles.option}
                        style={{
                            color: 'var(--primary-text-colour)',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.5rem',
                        }}
                    >
                        <img src={plusPurpleCircle} alt={'Add'} height={12} width={12} />
                        Add Other Data Type
                    </div>
                </div>
            )}
        </div>
    );
};

export default CircularSelect;
