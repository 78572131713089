import { useSearchParams } from 'react-router-dom';
import styles from '../MapDataModal.module.scss';

function ConsentScreens({ setShowModal, setCurrentScreen, setImportMetrics, isInvestor }) {
    const [params, setParams] = useSearchParams();
    return (
        <div className={styles.MainScreen}>
            <div style={{ padding: '1rem 0' }}>
                <h2>Do you want to map additional data?</h2>
                <p>
                    We have detected additional data in your uploaded file which has not been
                    mapped.
                </p>
            </div>
            <div className={styles.ButtonContainer}>
                <button
                    className={isInvestor ? styles.purpleWhiteBtn : styles.WhiteButton}
                    onClick={() => setShowModal({ mapDataModal: false, closeModal: false })}
                >
                    Close
                </button>
                <button
                    className={isInvestor ? styles.purpleBtn : styles.BlueButton}
                    onClick={() => {
                        params.set('currentScreen', '1');
                        setParams(params);
                        setImportMetrics({ sheetName: '', metricRange: '', dateRange: '' });
                        setCurrentScreen(1);
                    }}
                >
                    Map Additional Data
                </button>
            </div>
        </div>
    );
}

export default ConsentScreens;
