import styles from './PartnerSignup.module.scss';
import loadinggif from '../../../assets/img-spinner.gif';
import { useState } from 'react';
import { useAppSelector } from '../../../app/hooks';
import GettingStartedForm from './components/GettingStartedForm/GettingStartedForm';
import {
    BUSINESS_NAME,
    COUNTRY_CODE,
    EMAIL,
    FULL_NAME,
    ORG_NAME,
    PAN,
    PASSWORD,
    PHONE,
    SECTOR,
    WEBSITE,
} from '../../../utils/constants/companyLogin';
import { CountryCodeData } from '../../Company/CompanyLogin/CountryCodesDropdown/CountryCodesDropdown';
import { createAccountV2, generateOTPV2, verifyOtpV2 } from '../../../store/user/userV2/actions';
import { USER_TYPE } from '../../../utils/constants/user';
import { OTP_CREATE } from '../../Company/CompanyLogin/CompanyLogin';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import EnterOTPScreen from '../../../common/EnterOTPScreen/EnterOTPScreen';
import PartnerAdditionalDetails from './components/PartnerAdditionalDetails/PartnerAdditionalDetails';
import { Mixpanel } from '../../../utils/mixpanel';
import { AICA_PARTNERS_SIGNUP } from '../../../utils/constants/mixpanelEvents/investorEvents';

export type PartnerSignupData = {
    [FULL_NAME]: string;
    [EMAIL]: string;
    [PHONE]: string;
    [COUNTRY_CODE]: CountryCodeData | null;
    [PASSWORD]: string;
    [ORG_NAME]: string;
};

const PartnerSignup = ({ additionalDetailsPage }: { additionalDetailsPage: boolean }) => {
    const [logoLoading, setLogoLoading] = useState(true);
    const dispatch = useDispatch();
    const user = useAppSelector((state) => state.user);
    const [showOtp, setShowOtp] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();
    const signupToken = searchParams.get('signupToken');
    const [otp, setOtp] = useState('');
    const domainMetadata = user.domainMetadata;
    const [formData, setFormData] = useState<PartnerSignupData>({
        [FULL_NAME]: '',
        [EMAIL]: '',
        [PHONE]: '',
        [COUNTRY_CODE]: null,
        [PASSWORD]: '',
        [ORG_NAME]: '',
    });
    const handleFormChange = (val: string, identifier: string) => {
        setFormData((data) => ({
            ...data,
            [identifier]: val,
        }));
    };
    const onResendOtp = () => {
        dispatch(
            generateOTPV2({
                user: {
                    emailId: formData[EMAIL]?.trim()?.toLowerCase(),
                    medium: 'EMAIL',
                    investorId: domainMetadata?.investorOrgId || '',
                    accountType: USER_TYPE.PARTNER,
                    accountTypes: [
                        USER_TYPE.INVESTOR,
                        USER_TYPE.PARTNER,
                        USER_TYPE.ADMIN_TEAM,
                        USER_TYPE.LENDER,
                    ],
                },
                platformId: domainMetadata?.productName || '',
            }),
        );
    };
    const onSubmitOTP = () => {
        dispatch(
            verifyOtpV2(
                {
                    accountType: USER_TYPE.PARTNER,
                    apiPayload: {
                        user: {
                            emailId: formData[EMAIL]?.trim()?.toLowerCase(),
                            otp: otp,
                            medium: 'EMAIL',
                            investorId: domainMetadata?.investorOrgId || '',
                            signUpToken: signupToken || '',
                            accountType: USER_TYPE.PARTNER,
                            accountTypes: [
                                USER_TYPE.INVESTOR,
                                USER_TYPE.PARTNER,
                                USER_TYPE.ADMIN_TEAM,
                                USER_TYPE.LENDER,
                            ],
                        },
                        loginType: 'OTP',
                        platformId: domainMetadata?.productName || '',
                    },
                },
                {
                    onSuccess: (userData, inviteCode) => {
                        Mixpanel.track(AICA_PARTNERS_SIGNUP, {
                            investorId: userData?.investorOrgId,
                            partnerId: userData?.userId,
                            accountType: userData?.accountType,
                            partnerDSAId: inviteCode,
                        });
                    },
                },
            ),
        );
    };
    const handleSubmitClick = () => {
        dispatch(
            createAccountV2(
                {
                    user: {
                        name: formData[FULL_NAME],
                        emailId: formData[EMAIL]?.toLowerCase(),
                        investorId: domainMetadata?.investorOrgId || '',
                        accountType: USER_TYPE.PARTNER,
                        accountTypes: [
                            USER_TYPE.INVESTOR,
                            USER_TYPE.PARTNER,
                            USER_TYPE.ADMIN_TEAM,
                            USER_TYPE.LENDER,
                        ],
                        password: formData[PASSWORD],
                        organizationName: formData[ORG_NAME],
                        journeyName: 'PARTNER_SIGNUP',
                        mobileNumber: formData[PHONE],
                        countryId: formData[COUNTRY_CODE]?.countryId || '',
                        countryCode: formData[COUNTRY_CODE]?.callingCode || '',
                    },
                    platformId: domainMetadata?.productName || '',
                },
                {
                    onSuccess: (data) => {
                        const signupToken = data?.user?.signUpToken;
                        setSearchParams({ signupToken: signupToken });
                        setShowOtp(OTP_CREATE);
                    },
                },
            ),
        );
    };
    const renderForms = () => {
        if (additionalDetailsPage) return <PartnerAdditionalDetails />;
        if (showOtp) {
            return (
                <EnterOTPScreen
                    onSubmit={onSubmitOTP}
                    onResend={onResendOtp}
                    otp={otp}
                    setOtp={setOtp}
                    formData={formData}
                    onBackClick={() => setShowOtp('')}
                />
            );
        } else {
            return (
                <GettingStartedForm
                    formData={formData}
                    setFormData={setFormData}
                    handleFormChange={handleFormChange}
                    handleSubmitClick={handleSubmitClick}
                />
            );
        }
    };
    return (
        <div className={styles.PartnerSignup}>
            <div className={styles.Left}>
                <img
                    className={styles.Logo}
                    src={logoLoading ? loadinggif : domainMetadata?.mainImageUrl}
                    alt=""
                    onLoad={() => setLogoLoading(false)}
                />
                <h6 className={styles.Lefttext}>{domainMetadata?.partnerSignupText}</h6>
            </div>
            <div className={styles.Right}>
                <div className={styles.FormContainer}>{renderForms()}</div>
            </div>
        </div>
    );
};

export default PartnerSignup;
