const namespace = 'Investor_';
export const INVESTOR_ADD_NEW_BORROWER = `${namespace}AddNewBorrower`;
export const INVESTOR_INVITE_BORROWER = `${namespace}InviteBorrower`;
export const INVESTOR_VIEW_REPORT = `${namespace}ViewReport`;
export const INVESTOR_OPEN_TAB = `${namespace}OpenTab`;
export const INVESTOR_DOWNLOAD_DATA = `${namespace}DownloadData`;
export const INVESTOR_CB_ADD_OFFICIAL = `${namespace}CB_AddOfficial`;
export const INVESTOR_CB_SUBMIT_REPORT = `${namespace}CB_SubmitReport`;
export const INVESTOR_CB_OPEN_COMBINED_REPORT = `${namespace}CB_OpenCombinedReport`;
export const INVESTOR_CB_OPEN_FULL_REPORT = `${namespace}CB_OpenFullReport`;
export const INVESTOR_COMPANY_ACCOUNT_CREATED = `${namespace}CompanyAccountCreated`;
export const INVESTOR_HOME_PAGE_VIEW = `${namespace}HomePageView`;
export const INVESTOR_LOG_IN = `${namespace}LogIn`;
export const INVESTOR_LOG_OUT = `${namespace}LogOut`;
export const INVESTOR_COPY_INVITE_LINK = `${namespace}CopyInviteLink`;
export const INVESTOR_DOWNLOAD_CIBIL_QUERY = `${namespace}DownloadCIBILQuery`;
export const INVESTOR_CLICK_ALERTS = `${namespace}AlertsClicked`;
export const INVESTOR_CLOSE_ALERTS = `${namespace}AlertsClosed`;
export const INVESTOR_CHATBOT_OPEN = `${namespace}ChatbotOpened`;
export const INVESTOR_VIEW_SECTOR_REPORT = `${namespace}ViewSectorReport`;
export const INVESTOR_OPEN_NEWS_ARTICLE = `${namespace}OpenNewsArticle`;
export const INVESTOR_OPEN_COMPANY_WEBSITE = `${namespace}OpenCompanyWebsite`;
export const INVESTOR_CHARTS_CLICK = `${namespace}ViewChartsClicked`;
export const INVESTOR_TABLE_CLICK = `${namespace}ViewTablesClicked`;
export const INVESTOR_OPEN_ADD_TO_WATCHLIST = `${namespace}OpenAddToWatchlist`;
export const INVESTOR_ADD_TO_WATCHLIST_CLICK = `${namespace}AddToWatchlistClick`;
export const INVESTOR_VIEW_AICA_SAMPLE_REPORT = `${namespace}ViewAicaSampleReport`;
export const INVESTOR_AICA_ADD_USER = `${namespace}AicaAddUser`;
export const AICA_INVITE_USER = `Aica_InviteUser`;
export const INVESTOR_MANAGE_TEAM = `${namespace}manageTeam`;
export const INVESTOR_MANAGE_PARTNER = `${namespace}managePartner`;
export const INVESTOR_MANAGE_INVESTORS = `${namespace}manageInvestors`;

export const AICA_RELATED_PARTY = `${namespace}AICA_Related_Party`;
export const AICA_REVENUE_CHARTS = `${namespace}AICA_Revenue_Charts`;
export const AICA_REVENUE_TABLE = `${namespace}AICA_Revenue_Table`;
export const AICA_VENDOR_CHARTS = `${namespace}AICA_Vendor_Charts`;
export const AICA_VENDOR_TABLE = `${namespace}AICA_Vendor_table`;

export const UNDERWRITER_SUMMON_AICA = 'Underwriter_SummonAica';
export const RRF_VIEW_AICA_REPORT = 'RRFInvestor_View_Aica_Report';
export const AICA_DOWNLOAD_DATA = `${namespace}AICA_Download_Data`;

export const AICA_PARTNERS_LOGINOTP = `${namespace}AICA_Partners_LoginOTP`;
export const AICA_PARTNERS_LOGIN = `${namespace}AICA_Partners_Login`;
export const AICA_INVESTOR_FORGOT_PASSWORD = `${namespace}_ForgotPassword`;
export const AICA_PARTNERS_SIGNUP = `${namespace}AICA_Partners_Signup`;
export const AICA_PARTNERS_SUBMIT_DETAILS = `${namespace}AICA_Partners_Submitdetails`;

export const AICA_ADD_PROSPECT = `${namespace}AICA_Add_Prospect`;
export const AICA_ADD_LEAD = `${namespace}AICA_Add_Lead`;
export const AICA_CASHFLOW_BANKING = `${namespace}CashflowBanking`;
export const AICA_INVESTOR_UPLOAD_DATA = `${namespace}Upload_Data`;
export const AICA_DATA_ROOM_OPENED = `${namespace}AICA_Data_Room_Opened`;
export const AICA_DATA_ROOM_FILE_VIEWED = `${namespace}AICA_Data_Room_File_Viewed`;
export const AICA_DATA_ROOM_FILE_DOWNLOADED = `${namespace}AICA_Data_Room_File_Downloaded`;
export const AICA_GENERATE_INVESTMENT_REPORT = `AICA_Generate_IR`;
export const AICA_GENERATE_ONE_PAGER = `AICA_Generate_One_Pager`;
export const AICA_CREATE_DEAL = 'AICA_CREATE_DEAL';
export const AICA_FINANCIAL_METRICS = 'AICA_Financial_Metrics';
export const AICA_RELATED_PARTY_ALERTS = 'Aica_Related_Party_Alerts';
export const AICA_RELATED_PARTY_RELATIONSHIP = 'Aica_Related_Party_Relationships';

export const VIEW_DEBT_PRODUCTS_PAGE = 'View_Debt_Products_Page';
export const SELECT_PRODUCT = 'Select_Product';
export const EXPAND_PRODUCT = 'Expand_Product';
export const SELECT_LENDER = 'Select_Lender';
export const CLICK_PROBABILITY_HELP = 'Click_Probability_Help';
export const OPEN_EDIT_PROFILE = 'Open_Edit_Profile';
