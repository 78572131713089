import styles from './EmptyBank.module.scss';
import emptyBank from '../../../../../../assets/empty-bank.svg';

function EmptyBank({ text = '', style }: { text?: string; style?: React.CSSProperties }) {
    return (
        <div className={styles.EmptyBank} style={style}>
            <img src={emptyBank} alt="" height="106px" />
            {text}
        </div>
    );
}

export default EmptyBank;
