import React from 'react';

const ThreeDotsIcon = ({ colour = '#6021B3', width = '4', height = '22', style }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 4 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={style}
        >
            <path
                d="M2 13C3.10457 13 4 12.1046 4 11C4 9.89543 3.10457 9 2 9C0.895431 9 0 9.89543 0 11C0 12.1046 0.895431 13 2 13Z"
                fill={colour}
            />
            <path
                d="M2 4C3.10457 4 4 3.10457 4 2C4 0.895431 3.10457 0 2 0C0.895431 0 0 0.895431 0 2C0 3.10457 0.895431 4 2 4Z"
                fill={colour}
            />
            <path
                d="M2 22C3.10457 22 4 21.1046 4 20C4 18.8954 3.10457 18 2 18C0.895431 18 0 18.8954 0 20C0 21.1046 0.895431 22 2 22Z"
                fill={colour}
            />
        </svg>
    );
};

export default ThreeDotsIcon;
