import clsx from 'clsx';
import React from 'react';

import { isEmpty } from '../../../utils/utils';

import styles from './Input.module.scss';

type InputProps = {
    readonly placeholder?: string;
    readonly type?: string;
    readonly pattern?: string;
    readonly isValid?: boolean;
    readonly errorMessage?: string;
    readonly value: string | number;
    readonly style?: React.CSSProperties;
    readonly inputStyle?: React.CSSProperties;
    readonly labelStyle?: React.CSSProperties;
    readonly unitStyle?: React.CSSProperties;
    readonly onChange: Function;
    readonly name?: string;
    readonly onEnter?: Function;
    readonly disabled?: boolean;
    readonly maxLength?: number;
    readonly unit?: string;
    readonly textAreaRows?: number;
    readonly allowNegative?: boolean;
    readonly allowDecimal?: boolean;
};

function Input({
    type = 'text',
    placeholder = '',
    pattern = '',
    isValid = true,
    errorMessage = '',
    value = '',
    onChange,
    name,
    onEnter,
    style,
    inputStyle,
    labelStyle,
    unitStyle,
    disabled = false,
    maxLength,
    unit,
    textAreaRows = 4,
    allowNegative = false,
    allowDecimal = false,
}: InputProps) {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValue = e.target.value;
        if (pattern && !e.target.validity.valid) return;
        if (maxLength && newValue?.length > maxLength) return;
        onChange(e.target.value);
    };

    const renderInput = () => {
        if (type === 'textarea') {
            return (
                <textarea
                    name={name}
                    className={clsx(styles.FormInput, {
                        [styles.Invalid]: !isValid,
                        [styles.HasData]: !isEmpty(value),
                        [styles.WithUnit]: !!unit,
                    })}
                    value={value}
                    onChange={handleChange}
                    onKeyUp={(e) => {
                        if (onEnter && e.key === 'Enter') onEnter();
                    }}
                    disabled={disabled}
                    style={inputStyle}
                    onWheel={(e: any) => e.target?.blur?.()}
                    rows={textAreaRows}
                />
            );
        }
        return (
            <input
                name={name}
                className={clsx(styles.FormInput, {
                    [styles.Invalid]: !isValid,
                    [styles.HasData]: !isEmpty(value),
                    [styles.WithUnit]: !!unit,
                })}
                type={type}
                pattern={pattern}
                value={value}
                onChange={handleChange}
                onKeyUp={(e) => {
                    if (onEnter && e.key === 'Enter') onEnter();
                }}
                onKeyDown={(e) => {
                    if (type === 'number') {
                        ['e', 'E', '+'].includes(e.key) && e.preventDefault();
                        if (!allowNegative && e.key === '-') e.preventDefault();
                        if (!allowDecimal && e.key === '.') e.preventDefault();
                    }
                }}
                disabled={disabled}
                style={inputStyle}
                onWheel={(e: any) => e.target?.blur?.()}
            />
        );
    };

    return (
        <div
            className={clsx(styles.InputContainer, {
                [styles.ContainerWithErrorMsg]: !!errorMessage,
            })}
            style={style}
        >
            {renderInput()}
            <label
                className={clsx(styles.FormLabel, {
                    [styles.FormLabelInputHasValue]: !isEmpty(value),
                })}
                style={labelStyle}
            >
                {placeholder}
            </label>
            {unit && (
                <div
                    className={clsx({
                        [styles.UnitWrap]: true,
                        [styles.UnitWithData]: !isEmpty(value),
                        [styles.Invalid]: !isValid,
                    })}
                    style={unitStyle}
                >
                    <div className={styles.Unit}>{unit}</div>
                </div>
            )}
            {!!(!isValid && errorMessage) && <div className={styles.Error}>{errorMessage}</div>}
        </div>
    );
}

export default Input;
