import './FormInput.scss';
import { styled } from '@mui/system';

const Label = styled('label')(
    (props) => ({
        position: 'absolute',
        pointerEvents: 'none',
        left: '10px',
        transition: '0.5s',
        top: '50%',
        transform: 'translate(10px, -50%)',
        fontWeight: 300,
        marginBottom: '0px',
    }),
    (props) => ({
        fontSize: props.toDisableOrNot || props.compName ? '12px' : '14px',
        color: props.toDisableOrNot || props.compName ? '#414141' : '#C3C3C3',
    }),
);

const Input = styled('input')({
    padding: '16px 12px',
    width: '100%',
    boxSizing: 'border-box',
    boxShadow: 'none',
    outline: 'none',
    border: 'none',
    color: '#000000',
    fontSize: '12px',
    fontWeight: 500,
    borderRadius: '6px',
    backgroundColor: '#f9f9f9',
    '&:focus, &:valid': {
        border: '0.5px solid #4c8dff',
        backgroundColor: '#ffffff',
        boxShadow: 'none',
    },
    '&:-webkit-autofill + label, &:focus ~ label, &:valid ~ label': {
        top: '-7px',
        left: 0,
        fontSize: '10px',
        color: '#8d8d8d',
        fontWeight: 300,
    },
    '&:disabled': {
        backgroundColor: '#f8fff8',
    },
});

const FormInput = (props) => {
    const { inputProps, labelProps, wrapperProps, label } = props;
    return (
        <div className="w-100" {...wrapperProps}>
            <div className="input-group">
                <Input {...inputProps} />
                <Label {...labelProps}>{label}</Label>
            </div>
        </div>
    );
};

export default FormInput;
