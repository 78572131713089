export async function fetchImageFromS3AndConvertToBase64(imageUrl) {
    try {
        // Fetch the image from S3
        const response = await fetch(imageUrl, { cache: 'no-cache' });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        // Convert the response to a Blob
        const blob = await response.blob();

        // Create a FileReader to read the Blob
        const reader = new FileReader();

        return new Promise((resolve, reject) => {
            reader.onloadend = () => {
                // reader.result contains the Base64-encoded string
                const base64String = reader.result;
                resolve(base64String);
            };
            reader.onerror = reject;

            // Read the Blob as a data URL (Base64)
            reader.readAsDataURL(blob);
        });
    } catch (error) {
        console.error('Error fetching and converting image:', error);
        throw error;
    }
}
