import clsx from 'clsx';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import Checkbox from '../Checkbox/Checkbox';
import bottomAngleIcon from '../../../assets/bottomAngleIcon.svg';

import styles from './MultiSelectDropdown.module.scss';
import { isEmpty } from '../../../utils/utils';
import { useOutsideClick } from '../../../utils/constants/hooks';

type Props = {
    placeholder?: string;
    options: { id: string; label: string }[];
    selectedOptions: string[];
    onChange: (selectedOptions: string[]) => void;
    disabled?: boolean;
    WrapperStyle?: React.CSSProperties;
    hasError?: boolean;
    errorMessage?: string;
};

const MultiSelectDropdown: React.FC<Props> = ({
    placeholder,
    options,
    selectedOptions,
    onChange,
    disabled = false,
    hasError,
    errorMessage,
    WrapperStyle,
}) => {
    const [showList, setShowList] = useState<boolean>(false);
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const inputRef = useRef<HTMLInputElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);
    useOutsideClick(dropdownRef, () => {
        closeMenu();
    });

    const toggleMenu = () => {
        if (disabled) return;
        setShowList(!showList);
        if (!showList) inputRef.current?.focus();
    };

    const closeMenu = () => {
        setIsFocused(false);
        setShowList(false);
    };

    const renderSelectedOptions = useMemo(() => {
        if (!selectedOptions?.length) return <div className={styles.EmptyDiv} />;
        if (selectedOptions.length > 1 && !isFocused && !disabled) {
            return (
                <div className={styles.SelectedOptionsWrap}>
                    <div className={styles.SelectedOption}>
                        <div className={styles.SelectedOptionLabel}>{selectedOptions[0]}</div>
                    </div>
                    <span
                        className={styles.MoreOptionText}
                        title={selectedOptions.slice(1).join(', ')}
                    >
                        + {selectedOptions.length - 1} more
                    </span>
                </div>
            );
        }
        return (
            <div className={styles.SelectedOptionsWrap}>
                {selectedOptions.map((selectedOption) => {
                    const option = options.find((option) => option.id === selectedOption);
                    if (!option) return null;
                    return (
                        <div
                            key={`selected-option-${option?.id}`}
                            className={styles.SelectedOption}
                        >
                            <div className={styles.SelectedOptionLabel} title={option?.label}>
                                {option?.label}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }, [selectedOptions, options, isFocused, document.activeElement, inputRef]);

    const onMultipleOptionClick = (option: { id: string; label: string }, checked: boolean) => {
        const newSelectedOptions = checked
            ? [...selectedOptions, option.id]
            : selectedOptions.filter((selectedOption) => selectedOption !== option.id);

        onChange(newSelectedOptions);
    };

    return (
        <div
            style={WrapperStyle}
            className={clsx(styles.MultiSelectDropdown, {
                [styles.Disabled]: disabled,
            })}
            onClick={toggleMenu}
            onMouseDown={(e) => {
                e.preventDefault();
            }}
            ref={dropdownRef}
        >
            <input
                className={styles.HiddenInput}
                type="text"
                name="hidden-input"
                id="hidden-input"
                ref={inputRef}
                onFocus={() => setIsFocused(true)}
                onBlur={() => {
                    // Only close the menu if the blur event is not caused by clicking inside the dropdown
                    setTimeout(() => {
                        if (!dropdownRef.current?.contains(document.activeElement)) {
                            closeMenu();
                        }
                    }, 0);
                }}
            />
            <div
                className={clsx(styles.InputBox, {
                    [styles.OptionsSelected]: selectedOptions?.length > 0,
                    [styles.Error]: hasError && !!errorMessage,
                })}
            >
                <label className={styles.DropdownLabel}>{placeholder}</label>
                {renderSelectedOptions}
                {!disabled && (
                    <img
                        className={clsx(styles.DropdownIcon, {
                            [styles.DropdownIconInverted]: showList,
                        })}
                        src={bottomAngleIcon}
                        alt="Caret Down"
                    />
                )}
            </div>
            {showList && (
                <div className={styles.OptionsMenu}>
                    {options?.map((option) => (
                        <Checkbox
                            key={`multi-select-option-${option.id}`}
                            label={option.label}
                            checked={(selectedOptions ?? []).includes(option.id)}
                            onChange={(checked) => onMultipleOptionClick(option, checked)}
                            isMultiSelect
                            customStyles={{ padding: '14px 10px' }}
                        />
                    ))}
                </div>
            )}
            {!isEmpty(errorMessage) && hasError && (
                <div
                    className={clsx(styles.ErrorMsg, {
                        [styles.HideErrorMsg]: !hasError,
                    })}
                >
                    {errorMessage}
                </div>
            )}
        </div>
    );
};

export default MultiSelectDropdown;
