import { toast } from 'react-toastify';
import { JAVA_API_STATUS } from '../../utils/axios-interceptor';
import {
    AddCompanyToScanPayload,
    AddCompanyToWatchlistPayload,
    addToWatchlistService,
    getInvestorConfig,
    inviteCompanyUserService,
    moveCompanyToNewTierService,
    unlockAicaForCompanyService,
    UnlockAICAReportPayload,
} from './service';
import { Handlers } from '../../types/actions/types';
import { FetchInvestorConfigPayload } from './action';

export async function addCompanyToWatchlist(payload: AddCompanyToWatchlistPayload, handlers) {
    try {
        const response = await addToWatchlistService(payload);
        const responseData = response.data?.responseData;
        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                handlers?.onSuccess?.(response?.data);
                return;
            case JAVA_API_STATUS.ERROR:
                handlers?.onError?.();
                return toast.error(
                    responseData?.responseMessage || 'Unable to add. Please try again later.',
                );
        }
    } catch (err) {
        handlers?.onError();
        toast.warn('Unable to add. Please try again later.');
    }
}

export async function moveCompanyToNewTier(
    payload: AddCompanyToScanPayload,
    handlers: Handlers = {},
) {
    try {
        const response = await moveCompanyToNewTierService(payload);
        const responseData = response.data?.responseData;
        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                handlers?.onSuccess?.();
                return;
            case JAVA_API_STATUS.ERROR:
            default:
                handlers?.onError?.();
                throw new Error(responseData.responseMessage || 'Some error occoured');
        }
    } catch (err) {
        handlers?.onError?.();
        throw err;
    }
}

export async function inviteCompanyUser(payload: AddCompanyToScanPayload, handlers) {
    try {
        const response = await inviteCompanyUserService(payload);
        const responseData = response.data?.responseData;
        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                handlers?.onSuccess?.();
                return;
            case JAVA_API_STATUS.ERROR:
                handlers?.onError?.();
                return toast.error(responseData?.responseMessage || 'Some error occoured');
        }
    } catch (err) {
        // handlers?.onError();
        toast.warn('Unable to add. Please try again later.');
    }
}

export async function unlockAICAReport(payload: UnlockAICAReportPayload, handlers) {
    try {
        const response = await unlockAicaForCompanyService(payload);
        const responseData = response.data?.responseData;
        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                handlers?.onSuccess?.();
                return;
            case JAVA_API_STATUS.ERROR:
                handlers?.onError?.();
                return toast.error(responseData?.responseMessage || 'Some error occoured');
        }
    } catch (err) {
        handlers?.onError?.();
        toast.warn('Something went wrong');
    }
}

export async function fetchConfigByConfigName(payload: FetchInvestorConfigPayload) {
    try {
        const response = await getInvestorConfig(payload);
        const responseData = response.data?.responseData;

        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                return response.data?.mainConfig;
            case JAVA_API_STATUS.ERROR:
            default:
                return null;
        }
    } catch (err) {
        console.error('Error in fetching config: ', payload.configName, '\nError: ', err);
    }
}
