import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import 'react-tooltip/dist/react-tooltip.css';
import './scss/index.scss';
import * as Sentry from '@sentry/react';
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from 'react-router-dom';

const isProd = process.env.REACT_APP_ENVIRONMENT === 'production';

// Initialize Sentry
isProd &&
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DNS,
        integrations: [
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect: React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            }),
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllText: false,
            }),
        ],
        // Tracing
        tracesSampleRate: 0.2,
        // Session Replay
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <App />
    </Provider>,
);

reportWebVitals();
