import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import EnterOTPScreen from '../../../common/EnterOTPScreen/EnterOTPScreen';
import SendPasswordLink from '../../../common/SendPasswordLink/SendPasswordLink';
import {
    BUSINESS_NAME,
    COUNTRY_CODE,
    EMAIL,
    EMAIL_PHONE_ERROR_MSG,
    IDENTIFIER,
    PASSWORD,
    PHONE,
    SECTOR,
    WEBSITE,
} from '../../../utils/constants/companyLogin';
import { USER_TYPE } from '../../../utils/constants/user';
import styles from './CompanyLogin.module.scss';
import CompanySignupForm from './CompanySingupForm/CompanySignupForm';
import CompanyLoginForm from './CompanyLoginForm/CompanyLoginForm';
import { DropdownOption } from '../../../common/_custom/Dropdown/dropdownTypes';
import CompanyAdditionalDetailsForm from './CompanyAdditionalDetailsForm/CompanyAdditionalDetailsForm';
import { CountryCodeData } from './CountryCodesDropdown/CountryCodesDropdown';
import { Mixpanel } from '../../../utils/mixpanel';
import {
    COMPANY_LOGIN_WITH_OTP,
    COMPANY_SUBMIT_OTP,
} from '../../../utils/constants/mixpanelEvents/companyEvents';
import {
    createAccountV2,
    forgotPasswordV2,
    generateOTPV2,
    LoginV2,
    verifyEmail,
    verifyOtpV2,
} from '../../../store/user/userV2/actions';
import { companyFormValidations } from '../../../utils/constants/formValidations';
import { companySideBg } from '../../../assets/hostedassets';
import ShimmerLoading from '../../../common/LoginShimmerEffect/ShimmerLoading';

const BORROWER_SIGNUP = 'BORROWER_SIGNUP';
export const OTP_CREATE = 'create';
const OTP_LOGIN = 'login';
export const INDIA_COUNTRY_CODE_DATA = {
    countryId: '62f0d38a86537703a134f238',
    countryName: 'India',
    countryCode: 'IND',
    callingCode: '91',
    minCallingNumberLength: 10,
    maxCallingNumberLength: 10,
};

export interface CompanyLoginData {
    [BUSINESS_NAME]: string;
    [IDENTIFIER]: string;
    [SECTOR]: null | DropdownOption;
    [EMAIL]: string;
    [PASSWORD]: string;
    [WEBSITE]: string;
    [COUNTRY_CODE]: null | CountryCodeData;
    [PHONE]: string;
}

function CompanyLogin({ isLogin = false, applicationDetailPage = false }: any) {
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const verifyToken = searchParams.get('verifyToken');
    const signupToken = searchParams.get('signupToken');
    const inviteCode = searchParams.get('inviteCode') ?? '';
    const [bannerLoaded, setBannerLoaded] = useState(false);

    const domainMetadata = useAppSelector((state) => state.user.domainMetadata);

    const [logoLoading, setLogoLoading] = useState(false);
    const [showOtp, setShowOtp] = useState<any>(false);
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [otp, setOtp] = useState('');
    const [formData, setFormData] = useState<CompanyLoginData>({
        [BUSINESS_NAME]: '',
        [IDENTIFIER]: '',
        [SECTOR]: null,
        [EMAIL]: '',
        [PASSWORD]: '',
        [WEBSITE]: '',
        [COUNTRY_CODE]: null,
        [PHONE]: '',
    });

    useEffect(() => {
        if (companySideBg) {
            const img = new Image();
            img.src = companySideBg;
            img.onload = () => setBannerLoaded(true);
        }
    }, [companySideBg]);

    useEffect(() => {
        if (searchParams.get('email')) {
            handleFormChange(searchParams.get('email') ?? '', EMAIL);
        }
    }, [searchParams.get('email')]);

    useEffect(() => {
        const email = searchParams.get('email');
        if (domainMetadata && email && verifyToken) {
            dispatch(
                verifyEmail(
                    {
                        user: {
                            emailId: email,
                            medium: 'EMAIL',
                            investorId: domainMetadata?.investorOrgId || '',
                            signUpToken: verifyToken,
                            accountType: USER_TYPE.COMPANY,
                            accountTypes: [USER_TYPE.COMPANY],
                        },
                        platformId: domainMetadata?.productName || '',
                    },
                    {
                        onSuccess: () => {
                            toast.success('Email verified successfully');
                        },
                    },
                ),
            );
        }
    }, [domainMetadata, searchParams.get('email'), verifyToken]);

    const handleFormChange = (val: string, identifier: string) => {
        setFormData((data) => ({
            ...data,
            [identifier]: val,
        }));
    };

    const handleSubmitClick = () => {
        dispatch(
            createAccountV2(
                {
                    user: {
                        // pan: formData[IDENTIFIER],
                        name: formData[BUSINESS_NAME],
                        emailId: formData[EMAIL]?.toLowerCase(),
                        investorId: domainMetadata?.investorOrgId || '',
                        accountType: USER_TYPE.COMPANY,
                        inviteCode: inviteCode,
                        accountTypes: [USER_TYPE.COMPANY],
                        password: formData[PASSWORD],
                        journeyName: BORROWER_SIGNUP,
                        ...(formData[WEBSITE] && { websiteUrl: formData[WEBSITE] }),
                        ...(formData[IDENTIFIER] && { identifier: formData[IDENTIFIER] }),
                        ...(formData[WEBSITE] &&
                            formData[IDENTIFIER] && { newReqDomainAndIdentifier: true }),
                        ...(formData[SECTOR]?.label && { sector: formData[SECTOR].label }),
                        mobileNumber: formData[PHONE],
                        countryId: formData[COUNTRY_CODE]?.countryId || '',
                        countryCode: formData[COUNTRY_CODE]?.callingCode || '',
                        triggerNotification: true,
                    },
                    platformId: domainMetadata?.productName || '',
                },
                {
                    onSuccess: (data) => {
                        const signupToken = data?.user?.signUpToken;
                        setSearchParams({ signupToken: signupToken });
                        setShowOtp(OTP_CREATE);
                    },
                },
            ),
        );
    };

    const onSubmitOTP = (otpString = '') => {
        Mixpanel.track(COMPANY_SUBMIT_OTP);
        if (showOtp === OTP_LOGIN) {
            dispatch(
                verifyOtpV2({
                    accountType: USER_TYPE.COMPANY,
                    apiPayload: {
                        user: {
                            emailId: formData[EMAIL]?.trim()?.toLowerCase(),
                            otp: otpString || otp,
                            medium: 'EMAIL',
                            investorId: domainMetadata?.investorOrgId || '',
                            accountType: USER_TYPE.COMPANY,
                            accountTypes: [USER_TYPE.COMPANY],
                            signUpToken: verifyToken || '',
                        },
                        loginType: 'OTP',
                        platformId: domainMetadata?.productName || '',
                    },
                }),
            );
        } else if (showOtp === OTP_CREATE) {
            dispatch(
                verifyOtpV2({
                    accountType: USER_TYPE.COMPANY,
                    apiPayload: {
                        user: {
                            emailId: formData[EMAIL]?.trim()?.toLowerCase(),
                            otp: otp,
                            medium: 'EMAIL',
                            investorId: domainMetadata?.investorOrgId || '',
                            signUpToken: signupToken || '',
                            accountType: USER_TYPE.COMPANY,
                            accountTypes: [USER_TYPE.COMPANY],
                        },
                        loginType: 'OTP',
                        platformId: domainMetadata?.productName || '',
                    },
                }),
            );
        }
    };

    const onResendOtp = () => {
        let medium: 'EMAIL' | 'SMS' | '' = '';
        if (companyFormValidations.isEmailValid(formData[EMAIL])) medium = 'EMAIL';
        else if (companyFormValidations.isPhoneValid(formData[EMAIL], INDIA_COUNTRY_CODE_DATA))
            medium = 'SMS';
        if (!medium) return toast.error(EMAIL_PHONE_ERROR_MSG);
        dispatch(
            generateOTPV2({
                user: {
                    emailId: formData[EMAIL]?.trim()?.toLowerCase(),
                    medium: medium,
                    investorId: domainMetadata?.investorOrgId || '',
                    accountType: USER_TYPE.COMPANY,
                    accountTypes: [USER_TYPE.COMPANY],
                },
                platformId: domainMetadata?.productName || '',
            }),
        );
    };

    const handleLoginClick = () => {
        dispatch(
            LoginV2({
                user: {
                    emailId: formData[EMAIL]?.trim()?.toLowerCase(),
                    password: formData[PASSWORD],
                    investorId: domainMetadata?.investorOrgId || '',
                    accountType: USER_TYPE.COMPANY,
                    accountTypes: [USER_TYPE.COMPANY],
                },
                loginType: 'PASSWORD',
                platformId: domainMetadata?.productName || '',
            }),
        );
    };

    const handleLoginWithOtpClick = () => {
        Mixpanel.track(COMPANY_LOGIN_WITH_OTP);
        let medium: 'EMAIL' | 'SMS' | '' = '';
        if (companyFormValidations.isEmailValid(formData[EMAIL])) medium = 'EMAIL';
        else if (companyFormValidations.isPhoneValid(formData[EMAIL], INDIA_COUNTRY_CODE_DATA))
            medium = 'SMS';
        if (!medium) return toast.error(EMAIL_PHONE_ERROR_MSG);
        dispatch(
            generateOTPV2({
                user: {
                    emailId: formData[EMAIL]?.trim()?.toLowerCase(),
                    medium: medium,
                    investorId: domainMetadata?.investorOrgId || '',
                    accountType: USER_TYPE.COMPANY,
                    accountTypes: [USER_TYPE.COMPANY],
                },
                platformId: domainMetadata?.productName || '',
                onSuccess: (userData) => {
                    setShowOtp(OTP_LOGIN);
                    setFormData((data) => ({
                        ...data,
                        [PHONE]: userData?.mobileNumber,
                    }));
                },
            }),
        );
    };

    const onSendPasswordLinkClick = (onSuccess: Function, onError: Function) => {
        dispatch(
            forgotPasswordV2({
                user: {
                    emailId: formData[EMAIL]?.trim()?.toLowerCase(),
                    accountType: USER_TYPE.COMPANY,
                    accountTypes: [USER_TYPE.COMPANY],
                    investorId: domainMetadata?.investorOrgId || '',
                },
                platformId: domainMetadata?.productName || '',
                onSuccess,
                onError,
            }),
        );
    };

    const getFormComponent = () => {
        if (applicationDetailPage) return <CompanyAdditionalDetailsForm />;
        if (showOtp) {
            return (
                <EnterOTPScreen
                    onSubmit={onSubmitOTP}
                    onResend={onResendOtp}
                    otp={otp}
                    otpReceiver={formData[PHONE]}
                    setOtp={setOtp}
                    formData={formData}
                    onBackClick={() => setShowOtp('')}
                />
            );
        } else {
            if (showForgotPassword)
                return (
                    <SendPasswordLink
                        formData={formData}
                        onSendPasswordLinkClick={onSendPasswordLinkClick}
                        onBackClick={() => setShowForgotPassword(false)}
                        changeValue={handleFormChange}
                    />
                );
            else if (isLogin)
                return (
                    <CompanyLoginForm
                        formData={formData}
                        handleFormChange={handleFormChange}
                        handleSubmitClick={handleLoginClick}
                        handleLoginWithOtpClick={handleLoginWithOtpClick}
                        handleForgotPasswordClick={() => setShowForgotPassword(true)}
                    />
                );
            else
                return (
                    <CompanySignupForm
                        formData={formData}
                        handleFormChange={handleFormChange}
                        handleSubmitClick={handleSubmitClick}
                        setShowOtp={setShowOtp}
                    />
                );
        }
    };

    return !domainMetadata ? (
        <ShimmerLoading />
    ) : (
        <div className={styles.CompanyLogin}>
            <div
                className={styles.Left}
                style={{
                    background: bannerLoaded
                        ? `url(${
                              domainMetadata?.bannerImageUrl ?? companySideBg
                          })  center/cover no-repeat`
                        : 'linear-gradient(127deg, rgba(0,0,0,1) 0%, rgba(96,33,179,1) 100%)',
                }}
            >
                {!logoLoading && domainMetadata?.logoUrl && (
                    <div className={styles.Container}>
                        <img
                            src={domainMetadata?.logoUrl}
                            alt=""
                            className={styles.Logo}
                            onLoadStart={() => setLogoLoading(true)}
                            onLoad={() => setLogoLoading(false)}
                        />
                        {/*Connect your financial data to speed up your*/}
                        {/*<br /> evaluation process with us.*/}
                    </div>
                )}
            </div>
            <div className={styles.Right}>
                <div className={styles.FormContainer}>{getFormComponent()}</div>
            </div>
        </div>
    );
}

export default CompanyLogin;
