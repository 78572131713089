import { useEffect, useRef, useState } from 'react';
import styles from './OTP.module.scss';
import clsx from 'clsx';

const OTP1 = 'OTP1';
const OTP2 = 'OTP2';
const OTP3 = 'OTP3';
const OTP4 = 'OTP4';
const OTP5 = 'OTP5';
const OTP6 = 'OTP6';

function OTP({
    setUserOtp,
    resetOtp,
    setResetOtp,
}: {
    setUserOtp: Function;
    resetOtp: boolean;
    setResetOtp: Function;
}) {
    const firstInputRef = useRef<HTMLInputElement>(null);
    const [otp, setOtp] = useState({
        [OTP1]: '',
        [OTP2]: '',
        [OTP3]: '',
        [OTP4]: '',
        [OTP5]: '',
        [OTP6]: '',
    });

    useEffect(() => {
        if (firstInputRef?.current) {
            firstInputRef.current?.focus?.();
        }
    }, []);

    useEffect(() => {
        const userOtp = `${otp[OTP1]}${otp[OTP2]}${otp[OTP3]}${otp[OTP4]}${otp[OTP5]}${otp[OTP6]}`;
        setUserOtp(userOtp);
        //eslint-disable-next-line
    }, [otp]);

    useEffect(() => {
        if (resetOtp) {
            initialiseOtp();
            setResetOtp(false);
        }
    }, [resetOtp]);

    const initialiseOtp = () => {
        setOtp({
            [OTP1]: '',
            [OTP2]: '',
            [OTP3]: '',
            [OTP4]: '',
            [OTP5]: '',
            [OTP6]: '',
        });
    };

    const inputfocus = (elmnt: any, otpKey) => {
        const key = elmnt.key;
        const isNumber = new RegExp(/[0-9]/).test(key);
        const isDeleteKey = key === 'Backspace' || key === 'Delete';
        const isTab = key === 'Tab';
        const arrowLeft = key === 'ArrowLeft';
        const arrowRight = key === 'ArrowRight';

        if (isNumber || isDeleteKey) {
            setOtp((otp) => ({
                ...otp,
                [otpKey]: isDeleteKey ? '' : key,
            }));
        }

        if (arrowLeft || isDeleteKey) {
            const next = elmnt.target.tabIndex - 2;
            if (next > -1) {
                elmnt.target.form.elements[next].focus();
            }
        } else if (arrowRight || isTab || isNumber) {
            const next = elmnt.target.tabIndex;
            if (next < 6) {
                elmnt.target.form.elements[next].focus();
            }
        }
    };

    const handlePaste = (e: any) => {
        e.preventDefault();
        const clipboardData = e.clipboardData;
        const pastedData = clipboardData.getData('text');

        const isNumber = new RegExp(/[0-9]/).test(pastedData);
        if (isNumber && pastedData?.length > 0) {
            setOtp({
                [OTP1]: pastedData?.charAt(0) || '',
                [OTP2]: pastedData?.charAt(1) || '',
                [OTP3]: pastedData?.charAt(2) || '',
                [OTP4]: pastedData?.charAt(3) || '',
                [OTP5]: pastedData?.charAt(4) || '',
                [OTP6]: pastedData?.charAt(5) || '',
            });
        }
    };

    return (
        <form className={styles.OTPContainer}>
            {Object.keys(otp).map((otpKey, index) => (
                <input
                    key={`otp${index + 1}`}
                    name={`otp${index + 1}`}
                    type="text"
                    autoComplete="off"
                    className={clsx(styles.OTPInput, {
                        [styles.HasData]: otp[otpKey],
                    })}
                    value={otp[otpKey]}
                    tabIndex={index + 1}
                    maxLength={1}
                    ref={index === 0 ? firstInputRef : null}
                    onKeyDown={(e) => inputfocus(e, otpKey)}
                    onPaste={handlePaste}
                    onChange={() => {}}
                />
            ))}
        </form>
    );
}

export default OTP;
