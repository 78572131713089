import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../common/_custom';

function DomainNotFound() {
    const navigate = useNavigate();
    return (
        <div
            style={{
                height: '100vh',
                width: '100vw',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <div
                style={{
                    border: '1px solid #e5e5e5',
                    background: 'white',
                    padding: '30px',
                    borderRadius: '8px',
                    height: '30%',
                    width: '30%',

                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div style={{ fontSize: '16px', fontWeight: '600' }}>404</div>
                <div style={{ fontSize: '16px', fontWeight: '600' }}>Page Not Found!</div>
                <div
                    style={{
                        fontSize: '14px',
                        fontWeight: '500',
                        textAlign: 'center',
                        marginTop: '1rem',
                    }}
                >
                    The domain you are trying to access is not found, Please recheck and try again!!
                </div>
                {/*<Button text="Go Home" onClick={() => navigate('/investor')} />*/}
            </div>
        </div>
    );
}

export default DomainNotFound;
