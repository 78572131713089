import React, { useEffect, useState } from 'react';
import styles from '../ProvisionalFinancials.module.scss';
import backArrow from '../../../../../../assets/arrow-back-purple.svg';
import { CARD_HEADING, CARD_SUBHEADING } from '../../Integrations';
import UploadCard from '../../UploadCard/UploadCard';
import { useAppSelector } from '../../../../../../app/hooks';
import SideDrawer from '../../../../../../common/_custom/SideDrawer/SideDrawer';
import DataMappingDrawer from './DataMappingDrawer/DataMappingDrawer';
import MapDataModal from './MapDataModal/MapDataModal';
import { useParams, useSearchParams } from 'react-router-dom';
import { isEmpty } from '../../../../../../utils/utils';
import { fetchMetadataForInputModal } from '../../../../../../store/company/action';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { FILE_FORMATS } from '../../UploadCard/utils/utils';
import { downloadFile } from '../../UploadCard/utils/api/helpers';
import { infoRed } from '../../../../../../assets/hostedassets';
import { USER_TYPE } from '../../../../../../utils/constants/user';
import { COMPANY_PROVISIONAL_UPLOAD } from '../../../../../../utils/constants/mixpanelEvents/companyEvents';
import { Mixpanel } from '../../../../../../utils/mixpanel';

type ProvisionalsUploadCardProps = {
    selected: string | null;
    selectOption: (option: string) => void;
    onUploadSuccess: (file: any, sourceSelected: string) => void;
    categoryId: number;
    flowId: number;
    showModal: any;
    setShowModal: any;
    errorData: any;
    ingesting: { [key: string]: boolean };
    setErrorData: Function;
    fileData?: any;
    isInvestor: boolean;
};
export const FILE_CATEGORIES = {
    tally: 'TALLY',
    manualUpload: 'MANUAL_UPLOAD',
};

function ProvisionalsUploadCard(props: ProvisionalsUploadCardProps) {
    const {
        selected,
        selectOption,
        onUploadSuccess,
        categoryId,
        flowId,
        showModal,
        setShowModal,
        errorData,
        ingesting,
        setErrorData,
        fileData,
        isInvestor,
    } = props;
    const backArrowInv =
        'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/backArrowInvestor.svg';
    const [params, setParams] = useSearchParams();
    const [btnDisabled, setBtnDisabled] = useState({});
    const [showDrawer, setShowDrawer] = React.useState(false);
    const [rawDropdownOptions, setRawDropdownOptions] = React.useState<any>([]);
    const [sheetNameOptions, setSheetNameOptions] = React.useState<string[]>([]);
    const [metricRangeOptions, setMetricRangeOptions] = React.useState<string[]>([]);
    const [dateRangeOptions, setDateRangeOptions] = React.useState<string[]>([]);
    const [showError, setShowError] = useState(false);
    const [functionToCall, setFunctionToCall] = useState<any>();
    const [changedData, setChangedData] = useState<
        {
            id: string;
            parent: string;
            misClassification: string;
            [key: string]: string;
        }[]
    >([]);
    const [importMetrics, setImportMetrics] = useState({
        sheetName: '',
        metricRange: '',
        dateRange: '',
    });
    const provisionalData = useAppSelector(
        (state) => state.company.uploadJourneyData?.provisionalData?.docData,
    );
    const assesseeOrgId = useAppSelector((state) => state.user?.userData?.assesseeOrgId);
    const queryParams = useParams();
    const userData = useAppSelector((state) => state.user.userData);
    const BANNER_ICON = 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/lightBulbBlack.svg';
    useEffect(() => {
        setShowError(false);
    }, [selected]);
    const dispatch = useDispatch();
    const fetchExtensionUsingKey = (key) => {
        return key.split('.').pop();
    };
    useEffect(() => {
        if (!isEmpty(errorData)) {
            if (errorData?.hasOwnProperty('accepted_rows')) {
                if (errorData?.accepted_rows === 0) {
                    setShowError(true);
                } else if (errorData?.accepted_rows !== 0) {
                    setShowModal({ mapDataModal: true, closeModal: false });
                }
            } else {
                setShowError(false);
                setShowModal({ mapDataModal: false, closeModal: false });
                if (errorData?.s3?.key && fetchExtensionUsingKey(errorData?.s3?.key) !== 'pdf')
                    toast.error('We are not able to read the file, please try uploading again.');
                setBtnDisabled({
                    ...btnDisabled,
                    [params.get('fileId') ?? '']: true,
                });
            }
        }
    }, [errorData]);
    useEffect(() => {
        params.delete('fileId');
        params.delete('currentScreen');
        setParams(params);
        setErrorData({});
    }, [selected]);
    const fetchDropdownData = async (file?: any) => {
        dispatch(
            fetchMetadataForInputModal({
                assesseeOrgId: userData.assesseeOrgId ?? queryParams?.id,
                docId: file?.docId ?? params.get('fileId') ?? '',
                onSuccess: (data) => {
                    if (isEmpty(data.data.data)) {
                        setBtnDisabled({
                            ...btnDisabled,
                            [params.get('fileId') ?? '']: true,
                        });
                        params.delete('fileId');
                        params.delete('currentScreen');
                        setParams(params);
                        toast.error(
                            'We are not able to read the file, please try uploading again.',
                        );
                    } else {
                        setShowModal({ mapDataModal: true, closeModal: false });
                        setSheetNameOptions(data.data.data.map((item) => item.sheetName));
                        setRawDropdownOptions(data.data.data);
                    }
                },
                onError: () => {
                    setBtnDisabled({
                        ...btnDisabled,
                        [params.get('fileId') ?? '']: true,
                    });
                    setShowModal({ mapDataModal: false, closeModal: false });
                },
            }),
        );
    };

    const fillDataAccordingly = (data, type, sheetName) => {
        data?.map((item) => {
            if (type === 'metric') {
                setMetricRangeOptions(
                    data.filter((item) => item.sheetName === sheetName)?.[0].metricRange,
                );
            }
            if (type === 'date') {
                setDateRangeOptions(
                    data.filter((item) => item.sheetName === sheetName)?.[0]?.dateRange,
                );
            }
        });
    };
    const onBtnClick = (file: any) => {
        if (
            params.get('fileId') !== file?.docId ||
            !params.get('fileId') ||
            !params.get('currentScreen')
        ) {
            params.set('fileId', file?.docId);
            params.set('currentScreen', '1');
            setParams(params);
        }
        setImportMetrics({
            sheetName: '',
            metricRange: '',
            dateRange: '',
        });
        fetchDropdownData(file);
    };
    const getDisabledStatus = () => {
        return Object.values(ingesting).some((value) => value);
    };
    const getRemainingFiles = (files) => {
        let filteredFiles = files.filter((file) => {
            if (showError) {
                return file.docName !== fileData?.fileName ? file : null;
            }
            return file;
        });
        return filteredFiles;
    };

    return (
        <div>
            {!isInvestor && (
                <div
                    className={isInvestor ? styles.InvestorBackContainer : styles.BackContainer}
                    onClick={() => selectOption('')}
                >
                    <img src={isInvestor ? backArrowInv : backArrow} alt="back" />
                    {/*<span>Integrate with Provisional Financials software</span>*/}
                </div>
            )}
            {userData?.accountType === USER_TYPE.COMPANY ? (
                <>
                    <h3 className="mainHeading">Provisional Financial Statements</h3>
                </>
            ) : (
                <h3 className="mainHeading">
                    Provisional Financials Data ({CARD_HEADING[selected ?? ''] ?? ''})
                </h3>
            )}
            <div className={styles.Subheading}>
                {CARD_SUBHEADING[selected ?? '']?.text}
                {/* <a
                    href={CARD_SUBHEADING[selected ?? '']?.link}
                    target="_blank"
                    style={
                        isInvestor
                            ? { textDecoration: 'none', marginLeft: '0.25rem', color: '#6021B3' }
                            : { textDecoration: 'none', marginLeft: '0.25rem', color: '#6021B3' }
                    }
                    rel="noreferrer"
                >
                    Learn more
                </a> */}
            </div>
            <div className={styles.UploadContainer}>
                <UploadCard
                    isInvestor={isInvestor}
                    showButton
                    disabled={getDisabledStatus()}
                    acceptedFormats={FILE_FORMATS.EXCEL_PDF}
                    btnLoading={ingesting}
                    isBtnDisabled={btnDisabled}
                    buttonText="Confirm Data Mapping"
                    onBtnClickFunction={(file) => onBtnClick(file)}
                    existingFiles={getRemainingFiles(
                        provisionalData?.[FILE_CATEGORIES[selected ?? '']] ?? [],
                    )}
                    assesseeOrgId={assesseeOrgId}
                    metadataUpdate={async (file) => {
                        setShowError(false);
                        onUploadSuccess(file, selected ?? '');
                        Mixpanel.track(COMPANY_PROVISIONAL_UPLOAD);
                    }}
                    overrideLoadingState
                    categoryId={categoryId}
                    flowId={flowId}
                    fileDeleteHandler={() => {}}
                    disableDelete
                />
                {showError && fileData?.fileName && (
                    <div className={styles.ErrorText}>
                        <img
                            src={infoRed}
                            alt="info"
                            height={12}
                            width={12}
                            style={{ marginRight: '0.5rem' }}
                        />
                        <span
                            onClick={() => downloadFile(fileData.docId, () => {})}
                            className={styles.ErrorFilename}
                        >
                            {fileData?.fileName}
                        </span>
                        uploaded but system is not able to read.
                    </div>
                )}
            </div>
            {showDrawer && (
                <SideDrawer
                    dontClose
                    heading="Confirm Data Mapping"
                    width="80%"
                    onClose={() => {
                        if (changedData.length > 0) {
                            setShowModal({ mapDataModal: false, closeModal: true });
                            setFunctionToCall('closeDrawer');
                            return;
                        }
                        params.delete('fileId');
                        params.delete('currentScreen');
                        setParams(params);
                        setShowDrawer(false);
                    }}
                >
                    <DataMappingDrawer
                        isInvestor={isInvestor}
                        changedData={changedData}
                        setFunctionToCall={setFunctionToCall}
                        functionToCall={functionToCall}
                        setChangedData={setChangedData}
                        showModal={showModal}
                        importMetrics={importMetrics}
                        setImportMetrics={setImportMetrics}
                        setShowDrawer={setShowDrawer}
                        setShowModal={setShowModal}
                        fetchDropdownData={fetchDropdownData}
                        rawDropdownOptions={rawDropdownOptions}
                        sheetNameOptions={sheetNameOptions}
                        metricRangeOptions={metricRangeOptions}
                        dateRangeOptions={dateRangeOptions}
                        fillDataAccordingly={fillDataAccordingly}
                    />
                </SideDrawer>
            )}
            <MapDataModal
                setSheetNameOptions={setSheetNameOptions}
                isInvestor={isInvestor}
                importMetrics={importMetrics}
                setImportMetrics={setImportMetrics}
                setShowDrawer={setShowDrawer}
                showModal={showModal}
                setShowModal={setShowModal}
                fetchDropdownData={fetchDropdownData}
                rawDropdownOptions={rawDropdownOptions}
                sheetNameOptions={sheetNameOptions}
                metricRangeOptions={metricRangeOptions}
                dateRangeOptions={dateRangeOptions}
                setDateRangeOptions={setDateRangeOptions}
                fillDataAccordingly={fillDataAccordingly}
            />
        </div>
    );
}

export default ProvisionalsUploadCard;
