export const creditBureauConfig = {
    tabId: 'creditBureau',
    components: ['cmrCreditSummary', 'creditSummaryOfKeyOfficials', 'creditHighlights'],
    creditSummaryCombinedReport: {
        pageSections: 2,
        chartPanel: {
            totalCards: 9,
            showOverview: true,
            sections: [
                {
                    title: 'Delinquencies',
                    cards: [
                        'repaymentsOverviewHistory',

                        'dpdsBifurcation',
                        'accountsWithDelays',
                        'allDpds',
                        'repaymentsOverview',
                    ],
                },
                {
                    title: 'Loan Details',
                    cards: [
                        'newAccountsOpened',
                        'allAccountsByLoanType',
                        'allAccountsByLoanSize',
                        'allAccountsByLoanTenure',
                    ],
                },
            ],
        },
    },
    keyAccountsDetailedReport: {
        pageSections: 2,
        chartPanel: {
            totalCards: 9,
            showOverview: true,
            sections: [
                {
                    title: 'Delinquencies',
                    cards: [
                        'repaymentsOverview',
                        'repaymentsOverviewHistory',
                        'accountsWithDelays',
                        'dpdsBifurcation',
                        'allDpds',
                    ],
                },
                {
                    title: 'Loan Details',
                    cards: [
                        'newAccountsOpened',
                        'allAccountsByLoanType',
                        'allAccountsByLoanSize',
                        'allAccountsByLoanTenure',
                    ],
                },
            ],
        },
    },
};
