import tallyLogo from '../../../../assets/Svg/tally-logo.svg';
import csvLogo from '../../../../assets/Svg/csv-logo.svg';

export const INTEGRATION_TYPES = {
    TALLY: 'tally',
    CSV: 'csv',
    manualUpload: 'manualUpload',
    zoho: 'zoho',
};

export const allIntegrations = [
    INTEGRATION_TYPES.TALLY,
    // INTEGRATION_TYPES.CSV,
];

export const INTEGRATION_LOGOS = {
    [INTEGRATION_TYPES.TALLY]:
        'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/tally_logo.svg',
    [INTEGRATION_TYPES.CSV]: csvLogo,
};

export const CARD_HEADING = {
    [INTEGRATION_TYPES.TALLY]: 'Upload Tally Export',
    [INTEGRATION_TYPES.manualUpload]: 'Upload Manually',
};

export const CARD_SUBHEADING = {
    [INTEGRATION_TYPES.TALLY]: {
        text: 'Upload monthly Provisional Financials from your Tally export.',
        link: 'https://help.recur.club/en/article/how-to-upload-data',
    },
    [INTEGRATION_TYPES.manualUpload]: {
        text: 'Upload provisional financials as you maintain it (supported file formats: pdf, xls, csv, xlsx, zip).',
        link: 'https://help.recur.club/en/article/how-to-upload-data',
    },
};
