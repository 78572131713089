import { Button } from '../../../../../../../common/_custom';
import { plusWhite2 } from '../../../../../../../assets/hostedassets';

function AddToScanButton({ onClick, accessType, isDemoProfile }) {
    return (
        <Button
            icon={plusWhite2}
            variant="purple"
            onClick={onClick}
            text={'Add to Leads'}
            style={{ marginTop: 0, borderRadius: '4px' }}
            disabled={isDemoProfile}
        />
    );
}

export default AddToScanButton;
