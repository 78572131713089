import { saveKycDocsHelper } from '../UploadCard/utils/api/helpers';
import { fetchUploadJourneyData } from '../../../../../store/company/action';
import { useDispatch } from 'react-redux';
import { USER_TYPE } from '../../../../../utils/constants/user';
import { useAppSelector } from '../../../../../app/hooks';

const useHandleKycDocs = () => {
    const dispatch = useDispatch();
    const uploadJourneyData = useAppSelector((state) => state.company.uploadJourneyData);
    const kycDocumentList = uploadJourneyData?.companyKycData?.kycData?.[0]?.documentsList;
    const updateKycDocs = async (
        kycDocs: any,
        metadata: any,
        assesseeOrgId: string,
        del = false,
    ) => {
        const payload = {
            assesseeOrgId: assesseeOrgId,
            kycType: 'COMPANY',
            kycDataId: uploadJourneyData?.companyKycData?.id,
            additionalDoc: {
                docId: kycDocs.docId,
                docName: kycDocs.fileName,
                description: metadata.label,
                isDeleted: del,
                id: metadata.id,
            },
        };
        await saveKycDocsHelper(payload, {
            onSuccess: () => {
                dispatch(
                    fetchUploadJourneyData({
                        accountType: USER_TYPE.COMPANY,
                        // primaryEmai: userData.primaryEmail,
                        // investorOrgId: userData.investorOrgId,
                        assesseeOrgId: assesseeOrgId,
                    }),
                );
            },
        });
    };

    return { updateKycDocs, kycDocumentList };
};

export default useHandleKycDocs;
