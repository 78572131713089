import React, { useState, useEffect, useRef } from 'react';
import styles from './File.module.scss';
import {
    downloadIconDataRoom,
    downloadIconDataRoomHovered,
    fileIconDataRoom,
    PURPLE_THREE_DOTS_VERTICAL,
} from '../../../../../../assets/hostedassets';
import useFetchDataRoomFiles from '../../../../useFetchDataRoomFiles';
import SideDrawer from '../../../../../../common/_custom/SideDrawer/SideDrawer';
import DocumentViewer from '../../../../../../common/DocumentViewer/DocumentViewer';
import { createPortal } from 'react-dom';

const File = ({
    name,
    lastUpdated,
    index,
    docId,
    masterFolderName,
    fetchFiles,
    isRecurCompany,
}) => {
    const [downloadImgUrl, setDownloadImgUrl] = useState(downloadIconDataRoom);
    const [showOtherOptions, setShowOtherOptions] = useState(false);
    const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
    const dropdownRef: any = useRef(null);
    const threeDotsRef: any = useRef(null);

    const { handleFileClick, showFile, setShowFile, fileUrl, downloadTheFile, handleDeleteFile } =
        useFetchDataRoomFiles();

    const calculateDropdownPosition = () => {
        if (threeDotsRef.current) {
            //@ts-ignore
            const rect = threeDotsRef.current.getBoundingClientRect();
            setDropdownPosition({
                top: rect.bottom + window.scrollY,
                left: rect.left + window.scrollX,
            });
        }
    };

    useEffect(() => {
        if (showOtherOptions) {
            calculateDropdownPosition();
        }
    }, [showOtherOptions]);

    useEffect(() => {
        const handleScroll = () => {
            if (showOtherOptions) {
                setShowOtherOptions(false);
            }
        };
        window.addEventListener('scroll', handleScroll, true);
        return () => {
            window.removeEventListener('scroll', handleScroll, true);
        };
    }, [showOtherOptions]);

    // Handle outside click
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                threeDotsRef.current &&
                !threeDotsRef.current?.contains(event.target)
            ) {
                setShowOtherOptions(false);
            }
        };
        if (showOtherOptions) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showOtherOptions]);

    const dropdown = showOtherOptions
        ? createPortal(
              <div
                  ref={dropdownRef}
                  className={styles.OtherOptions}
                  style={{ top: dropdownPosition.top, left: dropdownPosition.left }}
              >
                  <div
                      onClick={(e) => {
                          e.stopPropagation();
                          if (docId) downloadTheFile(docId, name);
                          setShowOtherOptions(false);
                      }}
                  >
                      Download
                  </div>
                  <div
                      style={{ color: '#DF666A' }}
                      onClick={async (e) => {
                          e.stopPropagation();
                          if (docId) await handleDeleteFile(docId, name, masterFolderName);
                          if (fetchFiles) fetchFiles(isRecurCompany);
                          setShowOtherOptions(false);
                      }}
                  >
                      Delete
                  </div>
              </div>,
              document.body,
          )
        : null;

    return (
        <div className={styles.file}>
            <div
                style={{
                    marginLeft: `${index * 1.25}rem`,
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5rem',
                }}
                onClick={async () => {
                    if (docId) await handleFileClick(docId, name);
                }}
            >
                <span className={styles.FileIconContainer}>
                    <img src={fileIconDataRoom} alt={'folder'} />
                </span>
                <span className={styles.FileName}>{name}</span>
            </div>
            <span className={styles.FileName}>{lastUpdated}</span>
            <span
                ref={threeDotsRef}
                className={styles.ThreeDots}
                onClick={(e) => {
                    e.stopPropagation();
                    setShowOtherOptions(!showOtherOptions);
                }}
            >
                <img
                    src={PURPLE_THREE_DOTS_VERTICAL}
                    style={{ cursor: 'pointer' }}
                    alt={'More'}
                    height={14}
                />
            </span>
            {dropdown}
            {showFile && (
                <SideDrawer
                    heading={name}
                    showHeading={true}
                    width="75%"
                    allowClose
                    onClose={() => setShowFile(false)}
                >
                    <DocumentViewer fileUrl={fileUrl} />
                </SideDrawer>
            )}
        </div>
    );
};

export default File;
