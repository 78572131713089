import React from 'react';

const LogoutIcon = ({ color = '#E36414', width = '15', height = '15' }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_5119_73948)">
                <path
                    d="M10.856 11.9989V14.2846C10.856 14.5877 10.7356 14.8784 10.5213 15.0927C10.307 15.307 10.0163 15.4275 9.71317 15.4275H1.71317C1.41007 15.4275 1.11937 15.307 0.905048 15.0927C0.69072 14.8784 0.570313 14.5877 0.570312 14.2846V1.71317C0.570312 1.41007 0.69072 1.11937 0.905048 0.905048C1.11937 0.69072 1.41007 0.570313 1.71317 0.570312H9.71317C10.0163 0.570313 10.307 0.69072 10.5213 0.905048C10.7356 1.11937 10.856 1.41007 10.856 1.71317V3.99888M7.42745 7.99888H15.4275M15.4275 7.99888L13.1417 5.71317M15.4275 7.99888L13.1417 10.2846"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_5119_73948">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default LogoutIcon;
