import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch } from '../../../../app/hooks';
import { Button, Input, Modal } from '../../../../common/_custom';
import { ACCESS_TIERS } from '../../../../store/investor/constants';
import { moveCompanyToNewTier } from '../../../../store/investor/investorApiHelper';

import { createAccountV2 } from '../../../../store/user/userV2/actions';
import {
    COUNTRY_CODE,
    EMAIL,
    IDENTIFIER,
    NAME,
    PHONE,
    WEBSITE,
} from '../../../../utils/constants/companyLogin';
import { USER_TYPE } from '../../../../utils/constants/user';
import CountryCodesDropdown from '../../../Company/CompanyLogin/CountryCodesDropdown/CountryCodesDropdown';
import styles from './InviteCompanyModal.module.scss';
import { infoPurpleIcon } from '../../../../assets/hostedassets';
import CompanyLegalNameSearch, {
    CompanyData,
} from '../../../../common/CompanyLegalNameSearch/CompanyLegalNameSearch';

const INVITE_TYPE = 'INVITE_TYPE';
const EMAIL_CHECK = 'EMAIL_CHECK';

const scanDropdownOption = {
    id: ACCESS_TIERS.SCAN,
    label: 'Scan: Company Evaluation and Due Diligence',
};
const trackDropdownOption = {
    id: ACCESS_TIERS.TRACK,
    label: 'Track: Proactively monitor portfolio health',
};

export const INVITE_SCREENS = {
    PORTFOLIO: 'PORTFOLIO',
    DASHBOARD: 'DASHBOARD',
    MAGIC_UPLOAD: 'MAGIC_UPLOAD',
};

export const {
    ENTER_WEBSITE_OR_LEGAL_NAME,
    WEBSITE_OK,
    IDENTIFIER_ERROR,
    IDENTIFIER_OK,
    IDENTIFIER_ERROR_WEBSITE_OK,
    SUCCESS,
} = {
    ENTER_WEBSITE_OR_LEGAL_NAME: 'ENTER_WEBSITE_OR_LEGAL_NAME',
    // WEBSITE_ERROR: 'WEBSITE_ERROR',
    WEBSITE_OK: 'WEBSITE_OK',
    IDENTIFIER_ERROR: 'IDENTIFIER_ERROR',
    IDENTIFIER_ERROR_WEBSITE_OK: 'IDENTIFIER_ERROR_WEBSITE_OK',
    IDENTIFIER_OK: 'IDENTIFIER_OK',
    SUCCESS: 'SUCCESS',
};

function InviteCompanyModal({
    screen,
    modalState,
    closeModal,
    assesseeOrgId = '',
    investorOrgId,
    selectedCompany = null,
    platformId,
    companyAccessType = '',
    openInviteUserModal = () => {},
    onViewInLeads,
    hideButtons = false,
    onMagicUploadSuccess,
}: any) {
    const isCompanyAccessTypeWatch = companyAccessType === ACCESS_TIERS.WATCH;
    const isCompanyAccessTypeScan = companyAccessType === ACCESS_TIERS.SCAN;
    const isCompanyAccessTypeTrack = companyAccessType === ACCESS_TIERS.TRACK;
    const inviteCode =
        sessionStorage.getItem('invite_code') ?? localStorage.getItem('invite_code') ?? '';

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [formData, setFormData] = useState<any>({
        [WEBSITE]: '',
        [IDENTIFIER]: '',
        [NAME]: '',
        [EMAIL]: '',
        [PHONE]: '',
        [COUNTRY_CODE]: null,
        [INVITE_TYPE]: null,
        [EMAIL_CHECK]: true,
    });
    const [formErrors, setFormErrors] = useState({
        [WEBSITE]: '',
        [IDENTIFIER]: '',
        [NAME]: '',
        [EMAIL]: '',
        [PHONE]: '',
        [COUNTRY_CODE]: '',
    });
    const [identifierState, setIdentifierState] = useState(ENTER_WEBSITE_OR_LEGAL_NAME);

    const [inviteTypeDropdownOptions, setInviteTypeDropdownOptions] = useState<any>([]);
    const [addToScanSuccess, setSuccess] = useState(true);
    const [loading, setLoading] = useState(false);
    const companyPickedFromDropdown = useRef<CompanyData>(null);

    const existingWebsiteUrl = selectedCompany?.websiteUrl;
    const existingIdentifier = selectedCompany?.orgName;
    const existingEmail = selectedCompany?.contactEmail;
    const existingName = selectedCompany?.contactPerson;
    const existingPhone = selectedCompany?.contactNumber;

    // const newCompanyAccessType = formData[INVITE_TYPE]?.id;
    // const newAccessTypeIsScan = newCompanyAccessType === ACCESS_TIERS.SCAN;
    // const newAccessTypeIsTrack = newCompanyAccessType === ACCESS_TIERS.TRACK;

    useEffect(() => {
        if (existingWebsiteUrl) setIdentifierState(WEBSITE_OK);
        if (existingIdentifier) setIdentifierState(IDENTIFIER_OK);
    }, [existingWebsiteUrl, existingIdentifier]);

    useEffect(() => {
        if (screen === INVITE_SCREENS.PORTFOLIO) {
            if (isCompanyAccessTypeScan)
                setFormData((formData) => ({ ...formData, [INVITE_TYPE]: scanDropdownOption }));
            else if (isCompanyAccessTypeTrack)
                setFormData((formData) => ({
                    ...formData,
                    [INVITE_TYPE]: trackDropdownOption,
                }));
        } else {
            let options;
            if (isCompanyAccessTypeScan) options = [trackDropdownOption];
            else options = [scanDropdownOption, trackDropdownOption];

            options?.[0] &&
                setFormData((formData) => ({
                    ...formData,
                    [INVITE_TYPE]: options?.[0],
                }));
            setInviteTypeDropdownOptions(options);
        }
    }, [companyAccessType, screen]);

    const handleFormChange = (val, identifier: string) => {
        setFormData((data) => ({
            ...data,
            [identifier]: val,
        }));
        if (formErrors?.[identifier])
            setFormErrors((errors) => ({
                ...errors,
                [identifier]: '',
            }));
    };

    const isFormValid = () => {
        const nameValid = !!(existingName || formData[NAME]);
        const emailValid =
            (existingEmail?.includes('@') && existingEmail?.includes('.')) ||
            (formData[EMAIL]?.includes('@') && formData[EMAIL]?.includes('.'));
        const phoneValid = !!(
            existingPhone ||
            (formData[COUNTRY_CODE].callingCode === '91'
                ? formData[PHONE]?.length === 10
                : formData[PHONE])
        );
        const websiteValid =
            existingWebsiteUrl || (formData[WEBSITE] ? !!formData[WEBSITE]?.includes('.') : true);
        const phoneInvalidMessage = existingPhone
            ? ''
            : formData[COUNTRY_CODE].callingCode === '91' && formData[PHONE]?.length < 10
            ? 'Phone number must be 10 digits long'
            : phoneValid
            ? ''
            : 'Please enter Phone Number';
        const inviteTypeValid = !!formData[INVITE_TYPE];

        setFormErrors((errors) => ({
            ...errors,
            [WEBSITE]: formData[WEBSITE]
                ? !!!formData[WEBSITE]?.includes('.')
                    ? 'Please enter a valid Website Url'
                    : ''
                : '',
            [NAME]: nameValid ? '' : 'Please enter Full Name',
            [EMAIL]: emailValid ? '' : 'Please enter valid Business Email',
            [PHONE]: phoneInvalidMessage,
        }));
        if (!inviteTypeValid) toast.error('Please select Invite Type');

        return nameValid && emailValid && phoneValid && inviteTypeValid && websiteValid;
    };

    const onInviteCompanyClick = () => {
        if (!isFormValid()) return;
        if (screen === INVITE_SCREENS.PORTFOLIO) {
            createAccount(false);
        } else if (screen === INVITE_SCREENS.DASHBOARD) {
            addToScan();
        } else if (screen === INVITE_SCREENS.MAGIC_UPLOAD) {
            createAccount(true);
        }
    };

    const addToScan = async () => {
        setLoading(true);
        await moveCompanyToNewTier(
            {
                assesseeOrgId: assesseeOrgId,
                investorOrgId: investorOrgId,
                countryCode: formData[COUNTRY_CODE]?.callingCode,
                countryId: formData[COUNTRY_CODE]?.countryCode,
                inviteType: formData[INVITE_TYPE]?.id,
                triggerNotification: formData[EMAIL_CHECK],
                primaryEmail: existingEmail?.toLowerCase() || formData[EMAIL]?.toLowerCase(),
                phoneNumber: existingPhone || formData[PHONE],
                primaryUserName: existingName || formData[NAME],
                ...(!!(existingWebsiteUrl || formData[WEBSITE]) && {
                    websiteUrl: existingWebsiteUrl || formData[WEBSITE]?.trim?.(),
                }),
                ...(!!(existingIdentifier || formData[IDENTIFIER]) && {
                    identifier: existingIdentifier || formData[IDENTIFIER]?.trim?.(),
                }),
            },
            {
                onSuccess: () => {
                    setSuccess(true);
                    setLoading(false);
                },
                onError: () => {
                    setLoading(false);
                },
            },
        );
    };
    const createAccount = (isMUFlow) => {
        setLoading(true);

        dispatch(
            createAccountV2(
                {
                    user: {
                        name: formData[NAME],
                        ...(!!formData[IDENTIFIER] && {
                            identifier: formData[IDENTIFIER]?.trim?.(),
                        }),
                        ...(!!formData[WEBSITE] && { websiteUrl: formData[WEBSITE]?.trim?.() }),
                        ...(!!formData[WEBSITE] &&
                            !!formData[IDENTIFIER] && { newReqDomainAndIdentifier: true }),
                        emailId: formData[EMAIL]?.toLowerCase(),
                        investorId: investorOrgId,
                        accountType: USER_TYPE.COMPANY,
                        accountTypes: [USER_TYPE.COMPANY],
                        inviteCode: inviteCode,
                        journeyName: 'INVITE_BORROWER',
                        mobileNumber: formData[PHONE],
                        countryId: formData[COUNTRY_CODE]?.countryId || '',
                        countryCode: formData[COUNTRY_CODE]?.callingCode || '',
                        inviteType: companyAccessType,
                        triggerNotification: formData[EMAIL_CHECK],
                        ...(!!companyPickedFromDropdown.current && {
                            isPickedFromCompaniesList: true,
                            companyDetails: {
                                ...companyPickedFromDropdown.current,
                            },
                        }),
                    },
                    platformId: platformId,
                },
                {
                    isInviteCall: true,
                    onSuccess: (data) => {
                        if (data?.user?.domainErrorMessage) {
                            setFormErrors((errors) => ({
                                ...errors,
                                [WEBSITE]: data?.user?.domainErrorMessage,
                                [IDENTIFIER]: '',
                            }));
                            if (
                                data?.user?.domainErrorMessage
                                    ?.toLowerCase?.()
                                    ?.includes('legal name')
                            ) {
                                setIdentifierState(WEBSITE_OK);
                            }
                            setLoading(false);
                        } else if (data?.user?.identifierErrorMessage) {
                            setFormErrors((errors) => ({
                                ...errors,
                                [WEBSITE]: '',
                                [IDENTIFIER]: data?.user?.identifierErrorMessage,
                            }));
                            if (
                                identifierState === WEBSITE_OK &&
                                formData[WEBSITE] &&
                                formData[IDENTIFIER]
                            )
                                setIdentifierState(IDENTIFIER_ERROR_WEBSITE_OK);
                            else setIdentifierState(IDENTIFIER_ERROR);
                            setLoading(false);
                        } else if (data?.user?.orgName) {
                            if (!formData[IDENTIFIER] || existingIdentifier) {
                                setFormData((formdata) => ({
                                    ...formdata,
                                    [IDENTIFIER]: data?.user?.orgName,
                                }));
                                setIdentifierState(IDENTIFIER_OK);
                            } else {
                                if (isMUFlow) {
                                    onMagicUploadSuccess({
                                        orgName: data?.user?.orgName,
                                        assesseeOrgId: data?.user?.assesseeOrgId,
                                    });
                                    toast.success(
                                        `New Lead Created: ${data?.user?.orgName} \n Files Uploaded to their Data Room`,
                                    );
                                    setLoading(false);
                                    closeModal();
                                    return;
                                }
                                toast.success('Successfully Invited');

                                setTimeout(() => {
                                    window.location.reload();
                                    setLoading(false);
                                }, 300);

                                setLoading(false);
                            }
                        }
                        setLoading(false);
                    },
                    onError: () => {
                        setLoading(false);
                    },
                },
            ),
        );
    };

    const onClose = () => {
        if (screen === INVITE_SCREENS.DASHBOARD && addToScanSuccess) {
            if (isCompanyAccessTypeWatch) navigate('/investor/app/watch');
            else if (isCompanyAccessTypeScan) navigate('/investor/app/scan');
        } else closeModal();
    };

    return (
        <Modal open={!!modalState} onClose={onClose} size="flexible" persistent>
            <div className={styles.InviteCompanyModal}>
                {addToScanSuccess ? (
                    <div className={styles.Success}>
                        <img
                            src={
                                'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/gif/Successfull.gif'
                            }
                            width={'150px'}
                        />
                        <div className={styles.Title}>Successfully added to Leads!</div>
                        <div className={styles.Subtext}>
                            {/* {newAccessTypeIsScan */}
                            View company in Leads to gain deeper insights on its financial
                            performance!
                            {/* : 'Receive notifications on portfolio health using custom alerts on company financials, compliances and social presence!'} */}
                        </div>
                        {/* {(newAccessTypeIsScan || newAccessTypeIsTrack) && ( */}
                        {!hideButtons && (
                            <div className={styles.Buttons}>
                                <Button
                                    text={'Invite User to Upload Data'}
                                    onClick={() => {
                                        // window.location.reload();
                                        openInviteUserModal?.();
                                        closeModal?.();
                                    }}
                                    variant="purpleinverted"
                                />
                                <Button
                                    text={'View in Leads'}
                                    onClick={() => {
                                        if (onViewInLeads) onViewInLeads?.();
                                        else closeModal?.();
                                    }}
                                    variant="purple"
                                />
                            </div>
                        )}

                        {/* )} */}
                    </div>
                ) : (
                    <>
                        <div className={styles.Top}>
                            <div className={styles.Title}>
                                {isCompanyAccessTypeScan && screen === INVITE_SCREENS.DASHBOARD
                                    ? 'Add To Track'
                                    : 'Add to Lead'}
                            </div>
                            <div className={styles.Subtext}>
                                Please invite the Company to connect their data to gain access to
                                their financial insights.
                            </div>

                            <Input
                                placeholder="Website (required for AI profile)"
                                value={existingWebsiteUrl ? existingWebsiteUrl : formData[WEBSITE]}
                                onChange={(val) => handleFormChange(val, WEBSITE)}
                                style={{ marginTop: '30px' }}
                                disabled={
                                    identifierState === IDENTIFIER_OK ||
                                    identifierState === WEBSITE_OK ||
                                    (!!formData[WEBSITE] &&
                                        formErrors[WEBSITE]?.includes?.('legal')) ||
                                    !!formErrors[IDENTIFIER]
                                }
                                isValid={!formErrors[WEBSITE]}
                                errorMessage={formErrors[WEBSITE]}
                            />

                            <CompanyLegalNameSearch
                                placeholder="Company Legal Name or PAN"
                                value={
                                    existingIdentifier ? existingIdentifier : formData[IDENTIFIER]
                                }
                                onChange={(val) => {
                                    if (
                                        identifierState === IDENTIFIER_OK ||
                                        identifierState === IDENTIFIER_ERROR_WEBSITE_OK ||
                                        identifierState === IDENTIFIER_ERROR
                                    ) {
                                        if (
                                            formData[WEBSITE] &&
                                            identifierState !== IDENTIFIER_ERROR
                                        )
                                            setIdentifierState(WEBSITE_OK);
                                        else setIdentifierState(ENTER_WEBSITE_OR_LEGAL_NAME);
                                    }
                                    handleFormChange(val, IDENTIFIER);
                                }}
                                errorMessage={formErrors[IDENTIFIER]}
                                isValid={!formErrors[IDENTIFIER]}
                                disabled={!!existingIdentifier}
                                source={'add_leads'}
                                companyPickedFromDropdown={companyPickedFromDropdown}
                            />
                            {identifierState === IDENTIFIER_OK && !existingIdentifier && (
                                <div className={styles.IdentifierFetched}>
                                    <img src={infoPurpleIcon} alt="" height={'10px'} />
                                    Legal Name found! Please validate before submitting it.
                                </div>
                            )}

                            {/* <hr className={styles.Line} /> */}

                            <Input
                                placeholder="Full Name"
                                value={existingName ? existingName : formData[NAME]}
                                onChange={(val) => handleFormChange(val, NAME)}
                                style={{ marginTop: '30px' }}
                                isValid={!formErrors[NAME]}
                                errorMessage={formErrors[NAME]}
                                disabled={!!existingName}
                            />

                            <Input
                                placeholder="Business Email"
                                value={existingEmail ? existingEmail : formData[EMAIL]}
                                onChange={(val) => handleFormChange(val, EMAIL)}
                                style={{ marginTop: '30px' }}
                                disabled={!!existingEmail}
                                isValid={!formErrors[EMAIL]}
                                errorMessage={formErrors[EMAIL]}
                            />
                            <div className={styles.Row}>
                                <CountryCodesDropdown
                                    setCountryCode={(val) => handleFormChange(val, COUNTRY_CODE)}
                                />
                                <Input
                                    placeholder="Phone Number"
                                    value={existingPhone ? existingPhone : formData[PHONE]}
                                    onChange={(val) => handleFormChange(val, PHONE)}
                                    style={{ marginTop: '0', width: '100%' }}
                                    type="number"
                                    isValid={!formErrors[PHONE]}
                                    errorMessage={formErrors[PHONE]}
                                    disabled={!!existingPhone}
                                />
                            </div>
                            <div className={styles.CheckboxRow}>
                                <input
                                    type="checkbox"
                                    className={styles.Checkbox}
                                    id="terms"
                                    onChange={(e) =>
                                        handleFormChange(e.target.checked, EMAIL_CHECK)
                                    }
                                    checked={formData[EMAIL_CHECK]}
                                    disabled={false}
                                />
                                {formData[INVITE_TYPE]?.id === ACCESS_TIERS.TRACK
                                    ? 'Send a monthly email to company to update their financial data '
                                    : 'Send an invitation mail'}
                            </div>

                            <Button
                                onClick={onInviteCompanyClick}
                                text={
                                    isCompanyAccessTypeScan && screen === INVITE_SCREENS.DASHBOARD
                                        ? 'Add to Track'
                                        : 'Add to Lead'
                                }
                                variant="purple"
                                disabled={
                                    loading ||
                                    (identifierState === ENTER_WEBSITE_OR_LEGAL_NAME &&
                                        !formData[WEBSITE] &&
                                        !formData[IDENTIFIER]) ||
                                    (identifierState === WEBSITE_OK && !formData[IDENTIFIER]) ||
                                    !!formErrors[IDENTIFIER]
                                }
                                loading={loading}
                                style={{ width: '100%' }}
                            />
                        </div>

                        {/* <div className={styles.Bottom}>
                            <Dropdown
                                label="Select Invite Type"
                                placeholder="Select Invite Type"
                                optionsList={inviteTypeDropdownOptions}
                                selectedOption={formData[INVITE_TYPE]}
                                itemClickHandler={(selectedOption) =>
                                    handleFormChange(selectedOption, INVITE_TYPE)
                                }
                                disabled={screen === INVITE_SCREENS.PORTFOLIO}
                                inputStyle={{ fontFamily: 'Gilroy-Semibold' }}
                            />

                            
                        </div> */}
                    </>
                )}
            </div>
        </Modal>
    );
}

export default InviteCompanyModal;
