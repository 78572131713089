import clsx from 'clsx';
import { STEP_COMPLETION } from '../../uploadJourneyUtils';
import greenTick from '../../../../../assets/green-tick.svg';
import styles from './UploadTabs.module.scss';
import { Mixpanel } from '../../../../../utils/mixpanel';
import { COMPANY_OPEN_TAB } from '../../../../../utils/constants/mixpanelEvents/companyEvents';
interface UploadTabsProps {
    selectedStep: string;
    setSelectedStep: Function;
    uploadTabs: any;
    isInvestor: boolean;
    uploadJourneyData: any;
}

function UploadTabs({
    selectedStep,
    setSelectedStep,
    uploadTabs,
    isInvestor,
    uploadJourneyData,
}: UploadTabsProps) {
    const isStepComplete = (step) => {
        return STEP_COMPLETION?.[step]?.isComplete(uploadJourneyData);
    };

    return (
        <div style={isInvestor ? { padding: '0' } : {}} className={styles.UploadTabs}>
            {uploadTabs?.map((tab, index) => (
                <div
                    className={clsx(styles.SingleTab, {
                        [styles.SelectedTab]: uploadTabs?.length > 1 && selectedStep === tab.id,
                    })}
                    key={tab.id}
                    onClick={() => {
                        Mixpanel.track(COMPANY_OPEN_TAB, {
                            TabName: tab.id,
                        });
                        setSelectedStep(tab.id);
                    }}
                >
                    <div
                        className={clsx(styles.StepButton, {
                            [styles.SelectedStepButton]: selectedStep === tab.id,
                            [styles.Complete]: isStepComplete(tab.id),
                        })}
                    >
                        {!isInvestor && (
                            <>
                                {isStepComplete(tab.id) ? (
                                    <img className={styles.TickImg} src={greenTick} alt="grn" />
                                ) : (
                                    <div
                                        className={clsx(styles.StepNumber, {
                                            [styles.SelectedStepNumber]: selectedStep === tab.id,
                                        })}
                                    >
                                        {index + 1}
                                    </div>
                                )}
                            </>
                        )}
                        <div className={styles.StepLabel}>
                            {tab.label === 'creditBureau' ? 'Credit Bureau' : tab.label}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default UploadTabs;
