import { Button } from '../../../../../common/_custom';

import fetchGrey from '../../../../../assets/fetch-grey.svg';
import greyLock from '../../../../../assets/grey-lock.svg';
import styles from './UploadFooter.module.scss';
import { useNavigate } from 'react-router-dom';
import { submitOnboardingData } from '../../../../../store/company/apiHelpers';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Mixpanel } from '../../../../../utils/mixpanel';
import { INVESTOR_CB_SUBMIT_REPORT } from '../../../../../utils/constants/mixpanelEvents/investorEvents';

interface UploadFooterProps {
    selectedStep: string;
    uploadTabs: any;
    setSelectedStep: Function;
    assesseeOrgId: string;
    isInvestor: Boolean;
    disableSubmit?: boolean;
    closeDrawer: Function;
}

function UploadFooter({
    selectedStep,
    setSelectedStep,
    uploadTabs,
    assesseeOrgId,
    isInvestor,
    closeDrawer,
    disableSubmit = false,
}: UploadFooterProps) {
    const navigate = useNavigate();

    const [submittingData, setSubmittingData] = useState(false);
    const uploadStepsLength = uploadTabs.length;
    const stepIndex = uploadTabs.findIndex((tab) => tab.id === selectedStep);

    const showBackButton = stepIndex !== 0;
    const showFinishOption = stepIndex === uploadStepsLength - 1;

    const handleBackClick = () => {
        setSelectedStep(uploadTabs?.[stepIndex - 1]?.id);
    };

    const handleNextClick = () => {
        !showFinishOption && setSelectedStep(uploadTabs?.[stepIndex + 1]?.id);
    };

    const submitData = () => {
        Mixpanel.track(INVESTOR_CB_SUBMIT_REPORT);
        setSubmittingData(true);
        submitOnboardingData(
            {
                assesseeOrgId,
                journeyName: !isInvestor ? 'BORROWER_SIGNUP' : 'INVESTOR_UPLOAD',
            },
            {
                onSuccess: () => {
                    setTimeout(() => {
                        closeDrawer?.();
                        setSubmittingData(false);
                    }, 500);
                },
                onError: () => {
                    setSubmittingData(false);
                },
            },
        );
    };

    return (
        <div className={styles.UploadFooter}>
            <div className={styles.Left}>
                <div>
                    <img src={greyLock} alt="lock" height="10px" />
                    256-Bit Bank-Level Encryption
                </div>
                <div>
                    <img src={fetchGrey} alt="lock" height="10px" />
                    Progress Autosaved
                </div>
            </div>

            <div className={styles.Right}>
                {/* {showBackButton && (
                    <Button
                        text="Back"
                        onClick={handleBackClick}
                        style={{ marginRight: '20px' }}
                        variant="blueinverted"
                    />
                )} */}
                {/* @Ankit @Jay, we need to update this logic once we get clarity on submit button from product */}
                {/* {((selectedStep == 'creditBureau' && isInvestor) ||
                    selectedStep == 'gstReport' || selectedStep ===) && ( */}
                {isInvestor && (
                    <Button
                        variant="primary"
                        text="Submit"
                        onClick={submitData}
                        style={{ marginRight: '16px', marginTop: 0 }}
                        loadingText={'Wait'}
                        loading={submittingData}
                        disabled={disableSubmit || submittingData}
                    />
                )}
                {/* )} */}
                {/*{!isInvestor && (*/}
                {/*    <Button*/}
                {/*        // text={showFinishOption ? 'Finish' : 'Next'}*/}
                {/*        variant="purple"*/}
                {/*        text="Logout"*/}
                {/*        onClick={Logout}*/}
                {/*        style={{ marginTop: 0 }}*/}
                {/*    />*/}
                {/*)}*/}
            </div>
        </div>
    );
}

export default UploadFooter;
