import styles from './MagicUploadPreviewer.module.scss';
import React, { useEffect, useRef, useState } from 'react';
import {
    downloadFile,
    getPresignedUrl,
} from '../../../../modules/Company/UploadJourney/components/UploadCard/utils/api/helpers';
import { Mixpanel } from '../../../../utils/mixpanel';
import { AICA_DATA_ROOM_FILE_VIEWED } from '../../../../utils/constants/mixpanelEvents/investorEvents';
import { toast } from 'react-toastify';
import LoadingSpinner from '../../../_custom/LoadingSpinner/LoadingSpinner';
import { crossBtnGrey } from '../../../../assets/hostedassets';

type MagicUploadPreviewerProps = {
    docIdToView: string;
    setDocIdToView: React.Dispatch<React.SetStateAction<string>>;
    docName: string;
};

const MagicUploadPreviewer = (props: MagicUploadPreviewerProps) => {
    const [fileUrl, setFileUrl] = useState<string>('');
    const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);
    const [loading, setloading] = useState<boolean>(true);
    const PreviewRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (!PreviewRef.current) return;
        if (!props.docIdToView) {
            PreviewRef.current.className = styles.PreviewWrapperExit;
            setTimeout(() => {
                if (PreviewRef.current) {
                    PreviewRef.current.style.display = 'none';
                    PreviewRef.current.className = '';
                    setShowPreviewModal(false);
                }
            }, 200);
        } else {
            PreviewRef.current.style.display = 'block';
            PreviewRef.current.className = styles.PreviewWrapper;
        }
    }, [props.docIdToView]);

    const handleLoad = () => {
        setloading(false);
    };

    const getFileUrl = async (docId: string) => {
        // Fetch file url here
        const res = await getPresignedUrl({ docId: Number(docId) });
        if (res) {
            if (res.fileType === 'application/pdf') {
                setShowPreviewModal(true);
                setFileUrl(res.uri);
            } else {
                props.setDocIdToView('');
                setShowPreviewModal(false);
                if (PreviewRef.current) {
                    PreviewRef.current.style.display = 'none';
                }
                await downloadFile(docId, () => {}, props.docName);
            }
        } else {
            toast.error('Error in fetching file');
        }
    };

    useEffect(() => {
        if (props.docIdToView) {
            getFileUrl(props.docIdToView);
        }
    }, [props.docIdToView]);

    return (
        <>
            {showPreviewModal && (
                <div ref={PreviewRef} className={styles.PreviewWrapper}>
                    <div className={styles.PreviewHeader}>
                        <span className={styles.DocName}>{props.docName}</span>
                        <span
                            className={styles.CloseIcon}
                            onClick={() => {
                                props.setDocIdToView('');
                            }}
                        >
                            <img src={crossBtnGrey} alt="close" width={20} height={20} />
                        </span>
                    </div>
                    <div>
                        {loading && (
                            <div
                                style={{
                                    width: '100%',
                                    height: '100dvh',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <LoadingSpinner
                                    height={'5rem'}
                                    color={'var(--primary-text-colour)'}
                                />
                            </div>
                        )}
                        <iframe
                            src={`${fileUrl}#view=FitH&navpanes=0`}
                            style={{
                                width: '100%',
                                height: '100dvh',
                                display: loading ? 'none' : 'block',
                                background: 'white',
                            }}
                            width={'100%'}
                            allowFullScreen
                            onLoad={handleLoad}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default MagicUploadPreviewer;
