import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import cloudBlue from '../../../../../../../assets/cloud-upload.svg';
import { uploadOrange } from '../../../../../../../assets/hostedassets';
import bluePlus from '../../../../../../../assets/blue-plus.svg';
import { Input } from '../../../../../../../common/_custom';
import styles from '../FileWithDescriptionHandler/FileWithDescription.module.scss';
import Dropdown from '../../../../../../../common/_custom/Dropdown';
import { DropdownOption } from '../../../../../../../common/_custom/Dropdown/dropdownTypes';
import greyCross from '../../../../../../../assets/grey-cross.svg';
import FileUploadIcon from '../../../../../../../assets/Svg/FileuploadIcon';
function KycFileUpload({
    onChange,
    disabled = false,
    acceptedFormats,
    fileDescription,
    kycCategory,
    kycCategoryId,
    kycDescription,
    existingFiles = [],
    fileToUpload,
    setFileDescription,
    fileDeleteHandler,
    downloadFile,
    isDropdown = false,
    dropdownOptions = [],
}: any) {
    const [selectedDropdownOption, setSelectedDropdownOption] = useState<null | DropdownOption>(
        null,
    );
    const [enableAdd, setEnableAdd] = useState(false);
    const [addFiels, setAddFiles] = useState(false);
    const fileInputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
    const fileDetails = existingFiles.find(
        (item) => kycCategoryId === item.id && kycDescription === item.description,
    );
    const handleClick = () => fileInputRef?.current?.click?.();

    useEffect(() => {
        if (existingFiles?.length) {
            setEnableAdd(true);
            setSelectedDropdownOption(null);
        }
    }, [existingFiles]);

    useEffect(() => {
        if (isDropdown) {
            if (selectedDropdownOption) setFileDescription(selectedDropdownOption.label);
            else setFileDescription('');
        }
    }, [isDropdown, selectedDropdownOption]);

    const handleChange = (e) => {
        onChange(e.target.files);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        onChange(e.dataTransfer.files);
        if (e.dataTransfer) e.dataTransfer.clearData();
    };

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === 'dragenter' || e.type === 'dragover') {
            if (e.dataTransfer) e.dataTransfer.dropEffect = 'copy';
        } else if (e.type === 'dragleave') {
        }
    };

    const fileNameTrucated = (filename = '') => {
        return filename?.length > 25 ? `${filename?.substring(0, 25)}...` : filename;
    };

    return (
        <div className={styles.FileWithDescription}>
            <div className={styles.Row}>
                <div className={styles.FileLabel}>{kycCategory} :</div>
                {existingFiles.find(
                    (item) => kycCategoryId === item.id && kycDescription === item.description,
                ) ? (
                    <div
                        className={styles.FileInfoDownload}
                        style={{ justifyContent: 'space-between' }}
                        onClick={() => downloadFile?.(fileDetails?.docId, fileDetails?.fileName)}
                    >
                        {fileNameTrucated(fileDetails?.docName)}
                        <img
                            className={styles.DeleteIcon}
                            src={greyCross}
                            alt="gc"
                            height="16px"
                            onClick={(e) => {
                                e.stopPropagation();
                                fileDeleteHandler?.(fileDetails);
                            }}
                        />
                    </div>
                ) : (
                    <div
                        style={{ justifyContent: 'flex-start', padding: '0 0.5rem ' }}
                        className={clsx(styles.FileInfo, {})}
                        onDrop={handleDrop}
                        onDragOver={handleDrag}
                        onDragEnter={handleDrag}
                        onDragLeave={handleDrag}
                        onClick={handleClick}
                    >
                        <FileUploadIcon
                            colour={'var(--primary-text-colour)'}
                            height="20"
                            width="20"
                        />
                        {fileNameTrucated(
                            fileToUpload?.docName ||
                                fileToUpload?.file?.name ||
                                'Upload Data or Document',
                        )}
                    </div>
                )}
            </div>
            {addFiels && (
                <div className={styles.Row}>
                    {isDropdown ? (
                        <Dropdown
                            optionsList={dropdownOptions}
                            selectedOption={selectedDropdownOption}
                            itemClickHandler={(selectedOption) =>
                                setSelectedDropdownOption(selectedOption)
                            }
                        />
                    ) : (
                        <Input
                            value={fileDescription}
                            onChange={(val) => setFileDescription(val)}
                            style={{ marginTop: '0' }}
                            placeholder={'Enter Description'}
                        />
                    )}

                    <div
                        className={clsx(styles.FileInfo, {
                            [styles.DisableFileInfo]: !fileDescription,
                        })}
                        onDrop={handleDrop}
                        onDragOver={handleDrag}
                        onDragEnter={handleDrag}
                        onDragLeave={handleDrag}
                        onClick={handleClick}
                    >
                        <FileUploadIcon
                            colour={'var(--primary-text-colour)'}
                            height="20"
                            width="20"
                        />
                        {fileNameTrucated(
                            fileToUpload?.docName ||
                                fileToUpload?.file?.name ||
                                'Upload Data or Document',
                        )}
                    </div>
                </div>
            )}

            {/*<div className={styles.Bottom}>*/}
            {/*    <div*/}
            {/*        className={clsx(styles.AddButton, { [styles.DisableAdd]: !enableAdd })}*/}
            {/*        onClick={() => {*/}
            {/*            setEnableAdd(false)*/}
            {/*            setAddFiles(true)*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        <img src={bluePlus} alt="add" height="10px" />*/}
            {/*        Add Additional Data*/}
            {/*    </div>*/}
            {/*</div>*/}

            <input
                type="file"
                ref={fileInputRef}
                onChangeCapture={handleChange}
                style={{ display: 'none' }}
                accept={acceptedFormats}
            />
        </div>
    );
}

export default KycFileUpload;
