import isEmpty from 'lodash/isEmpty';
import { INVESTOR_ROLES, RESTRICTED_ROLES } from '../../investor/constants';
import { CONSTANTS } from './constants';
import { uiStyleType } from '../../../utils/constants/commonConstants';

interface UserReducer {
    fetchingDomainMetadata: boolean;
    creatingAccount: boolean;
    generatingOtp: boolean;
    loggingIn: boolean;
    ip: string;
    fetchingConfig: boolean;
    isAuthenticated: boolean;
    domainMetadata: null | DomainMetadata;
    userData: UserData;
    isSaveApplicationDetails: boolean;
}
interface DomainMetadata {
    demoOrgId: DomainMetadata | null;
    displayName: string;
    domain: string;
    logoUrl: string;
    mainImageUrl: string;
    bannerImageUrl?: string;
    mainText: string;
    orgName: string;
    productName: string;
    investorOrgId: string;
    availableFeatures: string[];
    partnerSignupText: string;
    faviconUrl?: string;
    borrower?: uiStyleType;
    lender?: uiStyleType;
    pno?: uiStyleType;
}

interface UserData {
    fetched: boolean;
    roles: Array<string>;
    accountType: string;
    investorOrgId: string;
    primaryEmail: string;
    investorPrimaryEmail: string;
    userName: string;
    aicaCoins: string;
    assesseeOrgId: string;
    applicationStatus: string;
    userId: string;
    lenderOrgId?: string;
    emailVerified: boolean;
}

const initialState: UserReducer = {
    fetchingDomainMetadata: false,
    creatingAccount: false,
    generatingOtp: false,
    loggingIn: false,
    fetchingConfig: false,
    isAuthenticated: false,
    ip: '',
    domainMetadata: null,
    isSaveApplicationDetails: false,
    userData: {
        fetched: false,
        accountType: '',
        investorOrgId: '',
        primaryEmail: '',
        userName: '',
        aicaCoins: '',
        assesseeOrgId: '',
        investorPrimaryEmail: '',
        applicationStatus: '',
        roles: [],
        userId: '',
        emailVerified: false,
    },
};

export function setRolesForUser(role = '') {
    if (RESTRICTED_ROLES.includes(role)) {
        if (role === INVESTOR_ROLES.RESTRICTED_UNDERWRITER_ACCESS) {
            return 'Underwriter';
        } else if (role === INVESTOR_ROLES.RESTRICTED_INVESTOR_ACCESS) {
            return 'RRF Investor';
        }
    }
    return 'AICA_Investor';
}

const setUserDataV2 = (state, action) => {
    return {
        ...state,
        creatingAccount: false,
        userData: {
            assesseeOrgId: action.payload.assesseeOrgId,
            primaryEmail: action.payload.emailId,
        },
    };
};
const setUserDataAfterConfig = (state, action) => {
    return {
        ...state,
        userData: {
            ...state.userData,
            ...action.payload,
        },
    };
};
const loginWithPasswordSuccessV2 = (state, action) => {
    return {
        ...state,
        loggingIn: false,
        isAuthenticated: true,
        userData: {
            fetched: true,
            ...action.payload.data,
            ...action.payload.userData,
        },
    };
};
const generatingOtpSuccessV2 = (state, action) => {
    return {
        ...state,
        generatingOtp: false,
    };
};

const setIsCreatingAccount = (state, action) => {
    return {
        ...state,
        creatingAccount: action.payload,
    };
};
const setLoggingIn = (state, action) => {
    return {
        ...state,
        loggingIn: action.payload,
    };
};
const verifyOtpSuccess = (state, action) => {
    return {
        ...state,
        isAuthenticated: true,
        ...action.payload.data,
        userData: {
            fetched: true,
            ...action.payload.userData,
        },
    };
};
const setFetchingDomainMetadata = (state, action) => {
    return {
        ...state,
        fetchingDomainMetadata: action.payload,
    };
};
const fetchDomainMetadataSuccess = (state, action) => {
    return {
        ...state,
        fetchingDomainMetadata: false,
        domainMetadata: {
            ...action.payload,
        },
    };
};
const setUserData = (state, action) => {
    return {
        ...state,
        userData: {
            fetched: true,
            ...action.payload,
        },
    };
};
const resetUserData = (state) => {
    return {
        ...state,
        userData: {
            ...initialState.userData,
        },
    };
};
const updateUserFromSummonAica = (state, action) => {
    const updatedUserData = {
        ...state.userData,
        primaryEmail: action.payload.primaryEmail,
        userName: action.payload.userName,
        investorOrgId: action.payload.investorOrgId,
    };
    localStorage.setItem('user_data', JSON.stringify(updatedUserData));
    return {
        ...state,
        userData: updatedUserData,
    };
};
const setIsSavingApplicationDetails = (state, action) => {
    return {
        ...state,
        isSaveApplicationDetails: action.payload,
    };
};
const setApplicationStatus = (state, action) => {
    let userData = sessionStorage.getItem('user_data')
        ? JSON.parse(sessionStorage.getItem('user_data') || '{}')
        : {};
    if (!isEmpty(userData)) {
        userData = localStorage.getItem('user_data')
            ? JSON.parse(localStorage.getItem('user_data') || '{}')
            : {};
        userData = {
            ...userData,
            applicationStatus: action.payload,
        };
        localStorage.setItem('user_data', JSON.stringify(userData));
    } else {
        userData = {
            ...userData,
            applicationStatus: action.payload,
        };
        sessionStorage.setItem('user_data', JSON.stringify(userData));
    }
    return {
        ...state,
        applicationStatus: action.payload,
    };
};
const setGeneratingOtp = (state, action) => {
    return {
        ...state,
        generatingOtp: action.payload,
    };
};
const setIsFetchingConfig = (state, action) => {
    return {
        ...state,
        fetchingConfig: action.payload,
    };
};

const updateUserDataFromCompanyConfig = (state, action) => {
    return {
        ...state,
        userData: {
            ...state.userData,
            ...action.payload.user,
        },
    };
};
const updateAicaCoins = (state, action) => {
    return {
        ...state,
        userData: {
            ...state.userData,
            aicaCoins: action?.payload?.aicaCoins ?? state.userData.aicaCoins,
        },
    };
};
const updateAssesseeOrgId = (state, action) => {
    return {
        ...state,
        userData: {
            ...state.userData,
            assesseeOrgId: action.payload.assesseeOrgId,
        },
    };
};

const updateUserEmailVerified = (state, action) => {
    return {
        ...state,
        userData: {
            ...state.userData,
            emailVerified: action.payload.userEmailVerified,
        },
    };
};

const setIp = (state, action) => {
    return {
        ...state,
        ip: action.payload,
    };
};

const reducer = (state: UserReducer = initialState, action: any): UserReducer => {
    switch (action.type) {
        case CONSTANTS.SET_FETCHING_DOMAIN_METADATA_V2:
            return setFetchingDomainMetadata(state, action);
        case CONSTANTS.FETCH_DOMAIN_METADATA_SUCCESS_V2:
            return fetchDomainMetadataSuccess(state, action);
        case CONSTANTS.CREATE_ACCOUNT_V2_SUCCESS:
            return setUserDataV2(state, action);
        case CONSTANTS.FETCHING_CONFIG_AFTER_LOGIN_SUCCESS:
            return setUserDataAfterConfig(state, action);
        case CONSTANTS.LOGIN_WITH_PASSWORD_V2_SUCCESS:
            return loginWithPasswordSuccessV2(state, action);
        case CONSTANTS.GENERATE_OTP_SUCCESS_V2:
            return generatingOtpSuccessV2(state, action);
        case CONSTANTS.SET_IS_CREATING_ACCOUNT_V2:
            return setIsCreatingAccount(state, action);
        case CONSTANTS.SET_LOGGING_IN_V2:
            return setLoggingIn(state, action);
        case CONSTANTS.VERIFY_OTP_SUCCESS_V2:
            return verifyOtpSuccess(state, action);
        case CONSTANTS.SET_USER_DATA:
            return setUserData(state, action);
        case CONSTANTS.RESET_USER_DATA:
            return resetUserData(state);
        case CONSTANTS.UPDATE_USER_FROM_SUMMON_AICA:
            return updateUserFromSummonAica(state, action);
        case CONSTANTS.SET_IS_SAVING_APPLICATION_DETAILS:
            return setIsSavingApplicationDetails(state, action);
        case CONSTANTS.SET_APPLICATION_STATUS:
            return setApplicationStatus(state, action);
        case CONSTANTS.SET_GENERATING_OTP_V2:
            return setGeneratingOtp(state, action);
        case CONSTANTS.SET_IS_FETCHING_CONFIG:
            return setIsFetchingConfig(state, action);
        case CONSTANTS.SET_USER_DATA_FROM_COMPANY_CONFIG:
            return updateUserDataFromCompanyConfig(state, action);
        case CONSTANTS.UPDATE_AICA_COINS:
            return updateAicaCoins(state, action);
        case CONSTANTS.UPDATE_ASSESSEE_ORG_ID:
            return updateAssesseeOrgId(state, action);
        case CONSTANTS.UPDATE_USER_EMAIL_VERIFIED:
            return updateUserEmailVerified(state, action);
        case CONSTANTS.SET_IP:
            return setIp(state, action);
        default:
            return state;
    }
};
export default reducer;
