const Icon = (props) => {
    const { icon, height = '', width = '' } = props;
    return (
        !!icon && (
            <img src={icon} alt="" height={height ? height : '100%'} width={width ? width : ''} />
        )
    );
};

export default Icon;
