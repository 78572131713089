import { Transforms, Editor, Range, Element, Path } from 'slate';

export class TableUtil {
    constructor(editor) {
        this.editor = editor;
    }

    insertTable = (rows, columns) => {
        try {
            const [tableNode] = Editor.nodes(this.editor, {
                match: (n) =>
                    !Editor.isEditor(n) &&
                    Element.isElement(n) &&
                    (n.type === 'table' || n.type === 'tableWithoutHeaders'),
                mode: 'highest',
            });

            if (tableNode) return;
            if (!rows || !columns) {
                return;
            }
            //Creating a 2-d array of blank string as default text for the table
            const cellText = Array.from({ length: rows }, () =>
                Array.from({ length: columns }, () => ''),
            );
            const newTable = createTableNode(cellText, rows, columns);

            Transforms.insertNodes(this.editor, newTable, {
                mode: 'highest',
            });
            Transforms.insertNodes(
                this.editor,
                { type: 'paragraph', children: [{ text: '' }] },
                { mode: 'highest' },
            );
        } catch (err) {
            console.error('Error in insert table operation', err);
        }
    };

    removeTable = () => {
        try {
            Transforms.removeNodes(this.editor, {
                match: (n) => {
                    return (
                        !Editor.isEditor(n) &&
                        Element.isElement(n) &&
                        (n.type === 'table' || n.type === 'tableWithoutHeaders')
                    );
                },
                // mode:'highest'
            });
        } catch (err) {
            console.error('Error in remove table operation', err);
        }
    };

    insertRow = (position) => {
        try {
            const { selection } = this.editor;
            if (!!selection && Range.isCollapsed(selection)) {
                const [tableNode] = Editor.nodes(this.editor, {
                    match: (n) =>
                        !Editor.isEditor(n) && Element.isElement(n) && n.type === 'table-row',
                });
                if (tableNode) {
                    const [[table, tablePath]] = Editor.nodes(this.editor, {
                        match: (n) =>
                            !Editor.isEditor(n) &&
                            Element.isElement(n) &&
                            (n.type === 'table' || n.type === 'tableWithoutHeaders'),
                    });
                    const [, currentRow] = tableNode;

                    const path = position === 'after' ? Path.next(currentRow) : currentRow;

                    Transforms.insertNodes(this.editor, createRow(Array(table.columns).fill('')), {
                        at: path,
                    });
                    Transforms.setNodes(
                        this.editor,
                        { rows: table.rows + 1 },
                        {
                            at: tablePath,
                        },
                    );
                }
            }
        } catch (err) {
            console.error('Error in insert row table operation', err);
        }
    };

    insertColumn = (action) => {
        try {
            const { selection } = this.editor;
            if (!!selection && Range.isCollapsed(selection)) {
                const [tableNode] = Editor.nodes(this.editor, {
                    match: (n) =>
                        !Editor.isEditor(n) && Element.isElement(n) && n.type === 'table-cell',
                });
                if (tableNode) {
                    const [[table, tablePath]] = Editor.nodes(this.editor, {
                        match: (n) =>
                            !Editor.isEditor(n) &&
                            Element.isElement(n) &&
                            (n.type === 'table' || n.type === 'tableWithoutHeaders'),
                    });
                    const [, currentCell] = tableNode;
                    const startPath = action === 'after' ? Path.next(currentCell) : currentCell;

                    // The last two indices of the path represents the row and column. We need to add one cell to each row starting from the first row
                    startPath[startPath.length - 2] = 0;
                    for (let row = 0; row < table.rows; row++) {
                        Transforms.insertNodes(this.editor, createTableCell(''), {
                            at: startPath,
                        });
                        startPath[startPath.length - 2]++;
                    }

                    Transforms.setNodes(
                        this.editor,
                        { columns: table.columns + 1 },
                        {
                            at: tablePath,
                        },
                    );
                }
            }
        } catch (err) {
            console.error('Error in insert column table operation', err);
        }
    };
}

const createRow = (cellText) => {
    try {
        const newRow = Array.from(cellText, (value) => createTableCell(value));
        return {
            type: 'table-row',
            children: newRow,
        };
    } catch (err) {
        console.error('Error in create row', err);
    }
};

export const createTableCell = (text) => {
    try {
        return {
            type: 'table-cell',
            children: [
                {
                    type: 'paragraph',
                    children: [{ text }],
                },
            ],
        };
    } catch (err) {
        console.error('Error in create table cell', err);
    }
};

const createTableNode = (cellText, rows, columns) => {
    try {
        const tableChildren = Array.from(cellText, (value) => createRow(value));
        let tableNode = {
            type: 'table',
            children: tableChildren,
            rows,
            columns,
        };
        return tableNode;
    } catch (err) {
        console.error('Error in create table node', err);
    }
};
