const namespace = 'CREDIT_BUREAU_';

const CONSTANTS = {
    INITIALIZE_CHARTS_DATA: `${namespace}INTITLIZE_CHARTS_DATA`,

    FETCH_CHART_DATA: `${namespace}FETCH_CHART_DATA`,
    FETCH_CHART_DATA_SUCCESS: `${namespace}FETCH_CHART_DATA_SUCCESS`,
    FETCH_CHART_DATA_ERROR: `${namespace}FETCH_CHART_DATA_ERROR`,
    FETCH_CIBIL_OVERVIEW: `${namespace}FETCH_CIBIL_OVERVIEW`,
    FETCH_CIBIL_OVERVIEW_SUCCESS: `${namespace}FETCH_CIBIL_OVERVIEW_SUCCESS`,
    FETCH_CIBIL_OVERVIEW_ERROR: `${namespace}FETCH_CIBIL_OVERVIEW_ERROR`,
    FETCH_DIRECTORS_OVERVIEW: `${namespace}FETCH_DIRECTORS_OVERVIEW`,

    SET_LOADING_DIRECTOR_OVERVIEW: `${namespace}SET_LOADING_DIRECTOR_OVERVIEW`,
    FETCH_DIRECTOR_OVERVIEW_SUCCESS: `${namespace}FETCH_DIRECTOR_OVERVIEW_SUCCESS`,
    FETCH_DIRECTOR_OVERVIEW_ERROR: `${namespace}FETCH_DIRECTOR_OVERVIEW_ERROR`,
    FETCH_CHARGE_DETAILS: `${namespace}FETCH_CHARGE_DETAILS`,
    FETCH_CHARGE_DETAILS_SUCCESS: `${namespace}FETCH_CHARGE_DETAILS_SUCCESS`,
    FETCH_CREDIT_BUREAU_SUMMARY_TABLE: `${namespace}FETCH_CREDIT_BUREAU_SUMMARY_TABLE`,
};

export default CONSTANTS;

export const CHART_NAMES = {
    REPAYMENTS_OVERVIEW: 'repaymentsOverview',
    REPAYMENTS_OVERVIEW_HISTORY: 'repaymentsOverviewHistory',
    ACCOUNTS_WITH_DELAYS: 'accountsWithDelays',
    DPDS_BIFURCATION: 'dpdsBifurcation',
    DPDS_BIFURCATION_BY_INSTITUTION: 'dpdsBifurcationByInstitution',
    ALL_DPDS: 'allDpds',
    PRINCIPLE_OUTSTANDING: 'principleOutstanding',

    NEW_ACCOUNTS_OPENED: 'newAccountsOpened',
    ACCOUNTS_AND_ENQUIRIES: 'accountsAndEnquiries',
    ALL_ACCOUNTS_BY_LOAN_TYPE: 'allAccountsByLoanType',
    ALL_ACCOUNTS_BY_LOAN_SIZE: 'allAccountsByLoanSize',
    ALL_ACCOUNTS_TOP_FIVE_LENDERS: 'allAccountsTopFiveLenders',
    ALL_ACCOUNTS_TENURE: 'allAccountsByLoanTenure',
    PROJECTED_REPAYMENTS: 'projectedRepayments',
    OWNERSHIP_TYPE: 'ownershipType',
    TYPE_OF_INSTITUTION: 'typeOfInstitution',
};
