export function checkIfOfficeFileIsEncrypted(file) {
    return new Promise(function (resolve, reject) {
        // File extensions for modern and old Office files
        const modernExtensions = ['.docx', '.xlsx', '.pptx'];
        const oldExtensions = ['.doc', '.xls', '.ppt'];

        // Determine the type of Office file based on the extension
        const isModernOfficeFile = modernExtensions.some((ext) => file.name.endsWith(ext));
        const isOldOfficeFile = oldExtensions.some((ext) => file.name.endsWith(ext));

        if (!isModernOfficeFile && !isOldOfficeFile) {
            resolve(false); // Not an Office file
            return;
        }

        const reader = new FileReader();

        reader.onload = function (event) {
            const data: any = event.target?.result;
            const uint8Array = new Uint8Array(data);

            if (isModernOfficeFile) {
                // Check for modern Office file encryption by looking for ZIP signature and essential files
                if (
                    uint8Array[0] !== 0x50 ||
                    uint8Array[1] !== 0x4b ||
                    uint8Array[2] !== 0x03 ||
                    uint8Array[3] !== 0x04
                ) {
                    resolve(true); // Not a valid ZIP file; likely encrypted
                    return;
                }

                const textDecoder = new TextDecoder('utf-8');
                const text = textDecoder.decode(uint8Array);

                // Look for 'EncryptionInfo' and 'EncryptedPackage' entries for modern encrypted Office files
                if (
                    text.indexOf('[Content_Types].xml') !== -1 &&
                    (text.indexOf('EncryptionInfo') === -1 ||
                        text.indexOf('EncryptedPackage') === -1)
                ) {
                    resolve(false); // File is not encrypted
                } else {
                    resolve(true); // File is encrypted
                }
            } else if (isOldOfficeFile) {
                // Check for old Office file encryption by searching for encryption marker bytes
                const marker = [0xe1, 0x00, 0x00, 0x00];
                let isEncrypted = false;

                for (let i = 0; i < uint8Array.length - marker.length; i++) {
                    let match = true;
                    for (let j = 0; j < marker.length; j++) {
                        if (uint8Array[i + j] !== marker[j]) {
                            match = false;
                            break;
                        }
                    }
                    if (match) {
                        isEncrypted = true;
                        break;
                    }
                }

                resolve(isEncrypted);
            } else {
                resolve(false); // Not a recognized Office file type
            }
        };

        reader.onerror = function (event) {
            reject('Error reading file');
        };

        // Determine the file size to read based on the file type
        const MAX_SIZE = 5 * 1024 * 1024; // 5 MB for modern files, 100 KB for old Office files
        const blob = isModernOfficeFile ? file.slice(0, MAX_SIZE) : file.slice(0, 1024 * 100);
        reader.readAsArrayBuffer(blob);
    });
}

export function checkIfPdfIsEncrypted(file) {
    return new Promise(function (resolve, reject) {
        if (file.type !== 'application/pdf' && !file.name.endsWith('.pdf')) {
            resolve(false); // Not a PDF file
            return;
        }

        const reader = new FileReader();

        reader.onload = function (event) {
            const data: any = event.target?.result;
            const textDecoder = new TextDecoder('utf-8');
            const text = textDecoder.decode(data);

            // Search for 'trailer' and '/Encrypt' keywords
            let isEncrypted = false;
            const trailerIndex = text.lastIndexOf('trailer');

            if (trailerIndex !== -1) {
                const trailerSection = text.substring(trailerIndex);
                if (trailerSection.indexOf('/Encrypt') !== -1) {
                    isEncrypted = true;
                }
            } else {
                // If 'trailer' keyword is not found, search the whole text for '/Encrypt'
                if (text.indexOf('/Encrypt') !== -1) {
                    isEncrypted = true;
                }
            }

            resolve(isEncrypted);
        };

        reader.onerror = function (event) {
            reject('Error reading file');
        };

        // Read the last 1 MB of the file where the trailer is usually located
        const fileSize = file.size;
        const start = Math.max(0, fileSize - 1024 * 1024); // Last 1 MB
        const blob = file.slice(start, fileSize);
        reader.readAsArrayBuffer(blob);
    });
}

export function checkIfZipIsEncrypted(file) {
    return new Promise(function (resolve, reject) {
        if (file.type !== 'application/zip' && !file.name.endsWith('.zip')) {
            resolve(false); // Not a ZIP file
            return;
        }

        let reader = new FileReader();

        reader.onload = function (event) {
            const data: any = event.target?.result;
            const uint8Array = new Uint8Array(data);
            let offset = 0;
            let isEncrypted = false;
            const dataLength = uint8Array.length;

            while (offset < dataLength) {
                // Check for the local file header signature (0x04034b50)
                if (
                    uint8Array[offset] === 0x50 &&
                    uint8Array[offset + 1] === 0x4b &&
                    uint8Array[offset + 2] === 0x03 &&
                    uint8Array[offset + 3] === 0x04
                ) {
                    // Read the general purpose bit flag (2 bytes at offset + 6 and +7)
                    const generalPurposeBitFlag =
                        uint8Array[offset + 6] | (uint8Array[offset + 7] << 8);

                    // Check if the encryption bit is set
                    if ((generalPurposeBitFlag & 0x0001) !== 0) {
                        isEncrypted = true;
                        break; // No need to check further
                    }

                    // Calculate the length of the file name and extra field to move to the next header
                    const fileNameLength = uint8Array[offset + 26] | (uint8Array[offset + 27] << 8);
                    const extraFieldLength =
                        uint8Array[offset + 28] | (uint8Array[offset + 29] << 8);

                    // Calculate the compressed size to skip over file data
                    const compressedSize =
                        uint8Array[offset + 18] |
                        (uint8Array[offset + 19] << 8) |
                        (uint8Array[offset + 20] << 16) |
                        (uint8Array[offset + 21] << 24);

                    // Move the offset to the start of the next local file header
                    offset += 30 + fileNameLength + extraFieldLength + compressedSize;
                } else {
                    // If no more local file headers are found, break the loop
                    break;
                }
            }
            console.log('isEncrypted', isEncrypted);
            resolve(isEncrypted);
        };

        reader.onerror = function (event) {
            reject('Error reading file');
        };

        // Read the entire file (or first 10 MB if the file is large)
        const MAX_SIZE = 10 * 1024 * 1024; // 10 MB
        const blob = file.slice(0, MAX_SIZE);
        reader.readAsArrayBuffer(blob);
    });
}

export const OfficeFileTypes = [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/msword',
    'application/vnd.ms-excel',
    'application/vnd.ms-powerpoint',
];

export const PASSWORD_PROTECTION_STATUS = {
    PASSWORD_PROTECTED: 'PASSWORD_PROTECTED',
    NOT_PASSWORD_PROTECTED: 'NOT_PASSWORD_PROTECTED',
    PASSWORD_VALIDATED: 'PASSWORD_VALIDATED',
    PASSWORD_INVALID: 'PASSWORD_INVALID',
};
