import React, { MouseEventHandler } from 'react';
import { Modal } from '../../../../../../../../common/_custom';
import styles from './ConfirmationModal.module.scss';

type ConfirmationModalProps = {
    showModal: {
        closeModal: boolean;
    };
    onClose: () => void;
    onClickSave: MouseEventHandler<HTMLButtonElement>;
    onClickNoSave: MouseEventHandler<HTMLButtonElement>;
    isInvestor: boolean;
};

function ConfirmationModal(props: ConfirmationModalProps) {
    const { showModal, onClose, onClickNoSave, onClickSave, isInvestor } = props;
    return (
        <Modal
            className={styles.MapDataModal}
            open={showModal.closeModal}
            style={{ padding: '2rem' }}
            onClose={onClose}
            size="flexible"
            persistent={true}
        >
            <div className={styles.ModalBody}>
                <div style={{ padding: '1rem 0' }}>
                    <h2>Are you sure you want to close?</h2>
                    <p>You still have unsaved mapping in your selected range.</p>
                </div>
                <div className={styles.ButtonContainer}>
                    <button
                        className={isInvestor ? styles.WhiteButtonInv : styles.WhiteButton}
                        onClick={onClickNoSave}
                    >
                        Continue Without Saving
                    </button>
                    <button
                        className={isInvestor ? styles.BlueButtonInv : styles.BlueButton}
                        onClick={onClickSave}
                    >
                        Save Mapping
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default ConfirmationModal;
