import { isEmpty } from '../../../utils/utils';
import { MONTHLY_VIEW_DATA_TYPES } from '../../../store/company/constants';

export const UPLOAD_COMPONENTS = {
    COMPANY_CMR: 'companyCMR',
    DIRECTOR_CIBIL: 'directorCibil',
    GST_REPORTS: 'gstReports',
    BANK_STATEMENTS: 'bankStatements',
    FETCH_BANK_ACCOUNTS: 'fetchBankAccounts',
    FINANCIALS: 'financials',
    ADDITONAL_DOCUMENTS: 'uploadAdditionalDocuments',
    PROVISIONAL_FINANCIALS: 'provisionalFinancials',
    COMPANY_KYC: 'companyKyc',
    DIRECTOR_KYC: 'directorKyc',
};

export const UPLOAD_TABS = {
    CREDIT_BUREAU: 'creditBureau',
    GST_REPORT: 'gstReport',
    BANK_STATEMENT: 'bankStatement',
    FINANCIALS: 'financials',
};

export const STEP_CHECKS = {
    isAccountConnected: (account) => {
        return !!account?.entityReferenceId || !!account?.bankStatements?.length;
    },
};

export const STEP_COMPLETION: any = {
    [UPLOAD_TABS.GST_REPORT]: {
        isComplete: (uploadJourneyData: any = {}) => {
            const unconnectedGST = uploadJourneyData?.gstMasterList?.find(
                (gst: any) => !gst.connected,
            );
            const gstReports = uploadJourneyData?.gstReportData?.gstReports?.filter(
                (doc) => !doc?.isDeleted,
            );
            return !unconnectedGST || !!gstReports?.length;
        },
    },
    [UPLOAD_TABS.BANK_STATEMENT]: {
        isComplete: (uploadJourneyData: any = {}) => {
            const bankAccountStatementList = uploadJourneyData?.bankAccountStatementList || [];
            if (!bankAccountStatementList?.length) return false;
            const unconnectedBank = bankAccountStatementList?.find(
                (account) => !STEP_CHECKS.isAccountConnected(account),
            );
            return !!!unconnectedBank;
        },
    },

    isAllComplete: (uploadJourneyData) => {
        return !!STEP_COMPLETION[UPLOAD_TABS.GST_REPORT]?.isComplete(uploadJourneyData);
    },
};
export function getPrevious24Months() {
    let today = new Date();
    today.setMonth(today.getMonth() - 24);
    let dates: string[] | null = [];
    for (let i = 0; i <= 24; i++) {
        let month = today.getMonth();
        let year = today.getFullYear();
        dates.push(`${month + 1 < 10 ? '0' : ''}${month + 1}-${year}`);
        if (month === 0) {
            today.setMonth(1);
        }
        today.setMonth(month + 1);
    }
    return dates;
}

type StringValueMap = { [date: string]: string };
export const initializeMonthlyViewData = (monthlyViewData: any, setMonthlyViewData: Function) => {
    const { INGESTED, NOT_UPLOADED, UPLOADED } = MONTHLY_VIEW_DATA_TYPES;
    const months = getPrevious24Months();
    let data = monthlyViewData || {};

    let bankStatementDates: StringValueMap = {};
    let availableMisMonthlyDates: StringValueMap = {};
    let availableGSTMonthlyDates: StringValueMap = {};
    let invoicingCompleteCount = 0,
        gstCompleteCount = 0,
        pnlCompleteCount = 0,
        cashflowCompleteCount = 0,
        balanceSheetCompleteCount = 0,
        isDataMissing = false;

    months.forEach((month) => {
        let gstStatus = '';
        if (!isEmpty(data.availableBankTrxMonthlyDates)) {
            let dataAvailable = true;
            for (let account of Object.keys(data.availableBankTrxMonthlyDates)) {
                if (data.availableBankTrxMonthlyDates[account][month] !== INGESTED) {
                    dataAvailable = false;
                    isDataMissing = true;
                    break;
                }
            }
            bankStatementDates[month] = dataAvailable ? INGESTED : NOT_UPLOADED;
        }

        if (
            [
                data.availablePnlMonthlyDates?.[month],
                data.availableCashflowMonthlyDates?.[month],
                data.availableBalanceSheetMonthlyDates?.[month],
            ].every((val) => val === INGESTED)
        )
            availableMisMonthlyDates[month] = INGESTED;
        else {
            isDataMissing = isDataMissing ? isDataMissing : true;
            availableMisMonthlyDates[month] = NOT_UPLOADED;
        }

        if (!isEmpty(data.availableInvoiceGstMonthlyDates)) {
            Object.keys(data.availableInvoiceGstMonthlyDates || {}).forEach((gst) => {
                if (!gstStatus) {
                    if (data.availableInvoiceGstMonthlyDates?.[gst]?.[month] === NOT_UPLOADED)
                        gstStatus = NOT_UPLOADED;
                    else if (data.availableInvoiceGstMonthlyDates?.[gst]?.[month] === UPLOADED)
                        gstStatus = NOT_UPLOADED;
                }
            });
            if (!gstStatus) gstStatus = INGESTED;
        } else gstStatus = NOT_UPLOADED;
        availableGSTMonthlyDates[month] = gstStatus;

        if (data.availableInvoiceMonthlyDates?.[month] === INGESTED) invoicingCompleteCount++;
        if (data.availableInvoiceGstMonthlyDates?.[month] === INGESTED) gstCompleteCount++;
        if (data.availablePnlMonthlyDates?.[month] === INGESTED) pnlCompleteCount++;
        if (data.availableCashflowMonthlyDates?.[month] === INGESTED) cashflowCompleteCount++;
        if (data.availableBalanceSheetMonthlyDates?.[month] === INGESTED)
            balanceSheetCompleteCount++;
    });

    if (
        invoicingCompleteCount < 24 ||
        gstCompleteCount < 24 ||
        pnlCompleteCount < 24 ||
        cashflowCompleteCount < 24 ||
        balanceSheetCompleteCount < 24
    )
        isDataMissing = isDataMissing ? isDataMissing : true;

    data.availableMisMonthlyDates = availableMisMonthlyDates;
    data.availableGSTMonthlyDates = availableGSTMonthlyDates;
    data.bankStatementDates = bankStatementDates;
    data.invoicing_complete_months = invoicingCompleteCount;
    data.gst_complete_months = gstCompleteCount;
    data.pnl_complete_months = pnlCompleteCount;
    data.cashflow_complete_months = cashflowCompleteCount;
    data.balance_sheet_complete_months = balanceSheetCompleteCount;
    data.is_data_missing = isDataMissing;
    setMonthlyViewData(data);
};
