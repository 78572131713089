export const sourceMap = {
    whats_app: 'WhatsApp',
    gmail: 'Gmail',
    app: 'Magic Upload',
};

export enum HEADER_TITLE {
    ORGANISATION_NAME = 'Organisation Name',
    SOURCE = 'Source',
    UPLOADED_BY = 'Uploaded By',
}

export enum KEY_NAME {
    ASSESSEE_ORGID_LIST = 'assesseeOrgIdList',
    SOURCES = 'sources',
    USER_IDS = 'userIds',
}
