import { useEffect } from 'react';
import styles from '../MapDataModal.module.scss';
import EditableDropdown from '../../../../../../../../common/EditableDropdown/EditableDropdown';
import { useSearchParams } from 'react-router-dom';
import { isEmpty } from '../../../../../../../../utils/utils';
import infoBlackIcon from '../../../../../../../../assets/info.svg';
import React from 'react';
import Tooltip from '../../../../../../../../common/_custom/Tooltip/Tooltip';

type DataInputScreenProps = {
    setShowModal: Function;
    setImportMetrics: Function;
    importMetrics: {
        sheetName: string;
        metricRange: string;
        dateRange: string;
    };
    setShowDrawer: Function;
    fetchDropdownData: Function;
    rawDropdownOptions: any;
    sheetNameOptions: string[];
    metricRangeOptions: string[];
    dateRangeOptions: any;
    fillDataAccordingly: Function;
    isInvestor: boolean;
    setDateRangeOptions: Function;
    setSheetNameOptions: Function;
};

function DataInputScreen(props: DataInputScreenProps) {
    const {
        setShowModal,
        setImportMetrics,
        importMetrics,
        setShowDrawer,
        fetchDropdownData,
        rawDropdownOptions,
        sheetNameOptions,
        setSheetNameOptions,
        metricRangeOptions,
        dateRangeOptions,
        fillDataAccordingly,
        isInvestor,
        setDateRangeOptions,
    } = props;
    const [params, setParams] = useSearchParams();

    useEffect(() => {
        setImportMetrics({
            sheetName: '',
            metricRange: '',
            dateRange: '',
        });
        setDateRangeOptions([]);
        setSheetNameOptions([]);
        fetchDropdownData();
    }, [params.get('fileId')]);
    useEffect(() => {
        if (sheetNameOptions.length === 1) {
            setImportMetrics({
                ...importMetrics,
                sheetName: sheetNameOptions[0],
            });
            fillDataAccordingly(rawDropdownOptions, 'metric', sheetNameOptions[0]);
        }
        if (dateRangeOptions?.length === 1) {
            setImportMetrics({
                ...importMetrics,
                dateRange: dateRangeOptions[0]?.cellRange,
            });
            fillDataAccordingly(rawDropdownOptions, 'date', importMetrics.sheetName);
        }
    }, [sheetNameOptions, dateRangeOptions]);
    return (
        <div className={styles.MainScreen}>
            <div style={{ padding: '1rem 0' }}>
                <h2>Map data for Import</h2>
                <p>
                    Map your imported data in minutes with standard financial statements and
                    organise imported data.
                </p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', height: '100%' }}>
                <div className={styles.MappedData}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                        <div className={styles.MappedDataBulletPoint}>1</div>
                        <div className={styles.MappedDataText}>Sheet to Import:</div>
                    </div>
                    <EditableDropdown
                        id="sheetName"
                        wrapperStyle={{ width: '40%' }}
                        placeHolder="Select Sheet"
                        removeInput
                        value={importMetrics.sheetName}
                        setOutput={(value) => {
                            setImportMetrics({
                                ...importMetrics,
                                sheetName: value,
                                metricRange: '',
                                dateRange: '',
                            });
                            fillDataAccordingly(rawDropdownOptions, 'metric', value);
                        }}
                        options={isEmpty(sheetNameOptions) ? ['Data Not Found'] : sheetNameOptions}
                    />
                </div>
                {importMetrics.sheetName && (
                    <div className={styles.MappedData}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                            <div className={styles.MappedDataBulletPoint}>2</div>
                            <div className={styles.MappedDataText}>
                                Column with Metrics:
                                <Tooltip
                                    fitContent
                                    direction="right"
                                    content={
                                        <span style={{ whiteSpace: 'nowrap' }}>
                                            The dropdown contains the list of Columns
                                            <br /> having Particulars in your selected sheet
                                        </span>
                                    }
                                >
                                    <img
                                        style={{ marginBottom: '-0.25rem', marginLeft: '0.25rem' }}
                                        src={infoBlackIcon}
                                        alt="info"
                                        height={15}
                                        width={15}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                        <EditableDropdown
                            id="metricRange"
                            wrapperStyle={{ width: '40%' }}
                            placeHolder="Select"
                            removeInput
                            value={importMetrics.metricRange}
                            showTick={
                                rawDropdownOptions.find(
                                    (item) => item.sheetName === importMetrics.sheetName,
                                )?.isConfirmed
                            }
                            setOutput={(value) => {
                                setImportMetrics({ ...importMetrics, metricRange: value });
                                fillDataAccordingly(
                                    rawDropdownOptions,
                                    'date',
                                    importMetrics.sheetName,
                                );
                            }}
                            options={
                                isEmpty(metricRangeOptions)
                                    ? ['Data Not Found']
                                    : metricRangeOptions
                            }
                        />
                    </div>
                )}
                {importMetrics.metricRange && (
                    <div className={styles.MappedData}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                            <div className={styles.MappedDataBulletPoint}>3</div>
                            <div className={styles.MappedDataText}>Row with date range:</div>
                        </div>
                        <EditableDropdown
                            id="dateRange"
                            wrapperStyle={{ width: '40%' }}
                            placeHolder="Select"
                            removeInput
                            value={importMetrics.dateRange}
                            setOutput={(value) =>
                                setImportMetrics({ ...importMetrics, dateRange: value })
                            }
                            options={
                                isEmpty(dateRangeOptions) ? ['Data Not Found'] : dateRangeOptions
                            }
                        />
                    </div>
                )}
                <div className={styles.ButtonContainer}>
                    <button
                        className={isInvestor ? styles.purpleWhiteBtn : styles.WhiteButton}
                        onClick={() => {
                            params.delete('fileId');
                            params.delete('currentScreen');
                            setParams(params);
                            setShowModal({ mapDataModal: false, closeModal: false });
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        disabled={
                            !importMetrics.dateRange ||
                            !importMetrics.metricRange ||
                            !importMetrics.sheetName
                        }
                        className={isInvestor ? styles.purpleBtn : styles.BlueButton}
                        onClick={() => {
                            setShowDrawer(true);
                            params.delete('currentScreen');
                            setParams(params);
                            setShowModal({ mapDataModal: false, closeModal: false });
                        }}
                    >
                        Proceed
                    </button>
                </div>
            </div>
        </div>
    );
}

export default DataInputScreen;
