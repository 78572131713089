import React from 'react';
import { FETCH_BTN_STATES } from '../../BankAccounts';
import fetchPurple from '../../../../../../../assets/fetch-purple.svg';
import redInfo from '../../../../../../../assets/red-i.svg';
import styles from './FetchButton.module.scss';
import clsx from 'clsx';
import { Mixpanel } from '../../../../../../../utils/mixpanel';
import { COMPANY_BS_FETCH_BANK_ACCOUNT_STATEMENT } from '../../../../../../../utils/constants/mixpanelEvents/companyEvents';
import { purpleFetch, verifyOrange } from '../../../../../../../assets/hostedassets';
import { USER_TYPE } from '../../../../../../../utils/constants/user';
import FetchFillIcon from '../../../../../../../assets/Svg/FetchFilIcon';

function FetchButton({
    fetchButtonState,
    setFetchButtonState,
    disabled = false,
    hideRetryBankFetch = false,
    checkForExistingAccount = () => false,
    userType,
}) {
    const getButtonFromState = () => {
        switch (fetchButtonState) {
            case FETCH_BTN_STATES.INITIAL:
            case FETCH_BTN_STATES.SHOW_FINBOX:
                return (
                    <div className={styles.UploadStateOptions}>
                        <div className={styles.UploadText}>
                            Upload pdf statement for the last 12 months.
                        </div>
                        {!hideRetryBankFetch && (
                            <div
                                className={clsx(
                                    styles.RetryFetch,
                                    { [styles.Disabled]: disabled },
                                    { [styles.isCompany]: userType === USER_TYPE.COMPANY },
                                )}
                                onClick={() => setFetchButtonState(FETCH_BTN_STATES.SHOW_FINBOX)}
                            >
                                <FetchFillIcon
                                    colour={'var(--primary-text-colour)'}
                                    height="12"
                                    width="12"
                                    className={styles.fetchIcon}
                                />
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    Fetch via net banking
                                </div>
                            </div>
                        )}
                    </div>
                );
            case FETCH_BTN_STATES.UPLOAD_MANUALLY:
                return (
                    <div className={styles.UploadStateOptions}>
                        <div className={styles.UploadText}>
                            Upload pdf statement for the last 12 months.
                        </div>
                        {!hideRetryBankFetch && (
                            <div
                                className={clsx(styles.RetryFetch, { [styles.Disabled]: disabled })}
                                onClick={() => setFetchButtonState(FETCH_BTN_STATES.SHOW_FINBOX)}
                            >
                                <FetchFillIcon
                                    colour={'var(--primary-text-colour)'}
                                    height="12"
                                    width="12"
                                    className={styles.fetchIcon}
                                />
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    Fetch via net banking
                                </div>
                            </div>
                        )}
                    </div>
                );
            case FETCH_BTN_STATES.EXIT_FINBOX:
                return (
                    <div className={styles.UploadStateOptions}>
                        <div className={styles.UploadText}>
                            Upload pdf statement for the last 12 months.
                        </div>
                        {!hideRetryBankFetch && (
                            <div
                                className={clsx(styles.RetryFetch, { [styles.Disabled]: disabled })}
                                onClick={() => setFetchButtonState(FETCH_BTN_STATES.SHOW_FINBOX)}
                            >
                                <FetchFillIcon
                                    colour={'var(--primary-text-colour)'}
                                    height="12"
                                    width="12"
                                    className={styles.fetchIcon}
                                />
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    Fetch via net banking
                                </div>
                            </div>
                        )}
                    </div>
                );
            case FETCH_BTN_STATES.CONNECTED:
                return <div className={styles.Connected}>Fetched Successfully</div>;
        }
    };
    return <div className={styles.ButtonContainer}>{getButtonFromState()}</div>;
}

export default FetchButton;
