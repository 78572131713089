import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useGSTContext } from '../../Context/GstContext';
import FormInput from '../Forms/FormInput';

const GstConsent = (props) => {
    const { formValues, buttonDisable, setFormValues, handleProceed, gstData } = useGSTContext();
    return (
        <>
            <DialogContent
                sx={{
                    width: '330px',
                    maxWidth: '100%',
                    margin: '0 0 60px 0',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        margin: '0 0 40px 0',
                    }}
                >
                    <Typography
                        color={'#000000'}
                        fontWeight={'600'}
                        fontSize={'14px'}
                        fontFamily={'gilroy-medium, sans-serif'}
                    >
                        {gstData?.gstin_number}
                    </Typography>
                    <Typography
                        color={'#AEAEAE'}
                        fontSize={'14px'}
                        fontWeight={500}
                        fontFamily={'gilroy-medium, sans-serif'}
                    >
                        {gstData?.state}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        rowGap: '10px',
                        flexDirection: 'column',
                    }}
                >
                    <FormInput
                        inputProps={{
                            required: true,
                            id: 'gstUserName',
                            value: formValues.gstUserName,
                            onChange: (e) => {
                                setFormValues((prev) => {
                                    return {
                                        ...prev,
                                        gstUserName: e.target.value,
                                    };
                                });
                            },
                            className: 'loginInput form-control',
                        }}
                        labelProps={{
                            toDisableOrNot: formValues.gstUserName,
                            htmlFor: 'gstUserName',
                        }}
                        label={'GST User Name'}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                size="small"
                                inputProps={{ style: { fontFamily: 'gilroy-medium, sans-serif' } }}
                                sx={{
                                    fontWeight: '500',
                                    fontSize: '13.75px',
                                    fontFamily: 'gilroy-medium, sans-serif',
                                    p: '4px 9px',
                                    '&.Mui-checked': {
                                        color: 'black',
                                    },
                                }}
                                checked={formValues.consent}
                                onChange={(e) => {
                                    setFormValues((prev) => {
                                        return {
                                            ...prev,
                                            consent: e.target.checked,
                                        };
                                    });
                                }}
                            />
                        }
                        sx={{
                            margin: 0,
                            alignItems: 'flex-start',
                        }}
                        label={
                            <Typography
                                fontFamily={'gilroy-medium, sans-serif'}
                                fontWeight={500}
                                fontSize={'13.75px'}
                                sx={{
                                    userSelect: 'none',
                                }}
                            >
                                {'I agree to provide consent to fetch my GST report'}
                            </Typography>
                        }
                    />
                </Box>
            </DialogContent>
            <DialogActions
                sx={{
                    display: 'block',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    p: '8px 16px 20px !important',
                }}
            >
                <Button
                    variant="contained"
                    onClick={handleProceed}
                    disabled={buttonDisable}
                    sx={{
                        backgroundColor: 'var(--primary-button-bg)',
                        width: '100%',
                        height: '40px',
                        marginBottom: '8px',
                        color: 'var(--primary-button-text)',
                        fontFamily: 'gilroy-medium, sans-serif',
                        fontSize: '12px',
                        fontWeight: '600',
                        '&:hover': {
                            backgroundColor: 'var(--primary-button-bg)',
                        },
                        '&:disabled': {
                            backgroundColor: '#D8D8D8', // Set background color for disabled state
                            color: 'white', // Set text color for disabled state
                            cursor: 'not-allowed', // Change cursor for disabled state
                        },
                    }}
                >
                    Proceed
                </Button>
                <Typography
                    color={'#8A8A8A'}
                    fontSize={'11px'}
                    fontWeight={500}
                    fontFamily={'gilroy-medium, sans-serif'}
                    textAlign={'center'}
                >
                    We will get one time read access of your GST files
                </Typography>
            </DialogActions>
        </>
    );
};

export default GstConsent;
