import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import styles from './CompanyAdditionalDetailsForm.module.scss';
import Form from '../../../../common/_custom/Form/Form';
import { Button, Input } from '../../../../common/_custom';
import Dropdown from '../../../../common/_custom/Dropdown';
import { saveApplicationDetails } from '../../../../store/user/actions';
import { getCompanyConfigDataAndSetFormData } from '../../../../store/company/apiHelpers';
import { ORG_MASTER_STATUS, USER_TYPE } from '../../../../utils/constants/user';
import LoadingSpinner from '../../../../common/_custom/LoadingSpinner/LoadingSpinner';
import { BASE_ROUTES, COMPANY_ROUTES } from '../../../../utils/constants/routesConst';
import { Mixpanel } from '../../../../utils/mixpanel';
import { COMPANY_START_YOUR_APPLICATION } from '../../../../utils/constants/mixpanelEvents/companyEvents';

interface CompanyAdditionalDetailsFormProps {
    // formData: CompanyLoginData;
}
interface DropdownOption {
    id: number | string;
    label: string;
}

interface AdditionalDetailsConfig {
    [key: string]: {
        type: 'dropdown' | 'text' | 'number';
        isRequired: boolean;
        placeholder?: string;
        dropdownOptions?: DropdownOption[];
    };
}

interface TermsAndConditionConfig {
    privacyPolicy: string;
    termsAndCondition: string;
}

function CompanyAdditionalDetailsForm({}: CompanyAdditionalDetailsFormProps) {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.user);
    const userData = user.userData;
    const isSavingApplicationDetails = user.isSaveApplicationDetails;
    const [fetchingConfig, setIsFetchingConfig] = useState(true);
    const [configData, setConfigData] = useState<AdditionalDetailsConfig>({});
    const [termsAndPolicyConfig, setTermsAndPolicyConfig] = useState<TermsAndConditionConfig>();
    const [formData, setFormData] = useState({});
    const [termsCheck, setTermsCheck] = useState(false);

    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (userData?.applicationStatus === ORG_MASTER_STATUS.ADDITIONAL_DETAIL_COMPLETE) {
            navigate(COMPANY_ROUTES.UPLOAD);
        } else if (userData?.primaryEmail && userData?.investorOrgId) {
            getCompanyConfigDataAndSetFormData(
                {
                    primaryEmail: userData?.primaryEmail,
                    investorOrgId: userData?.investorOrgId,
                },
                {
                    onSuccess: (config) => {
                        initialiseFormAndErrorsFromConfig(config?.additionalDetailsConfig || {});
                        setConfigData(config?.additionalDetailsConfig || {});
                        setTermsAndPolicyConfig(config?.termsAndPolicyConfig || {});
                    },
                    onError: () => {
                        navigate(BASE_ROUTES.LOGIN);
                    },
                },
            );
        }
    }, [userData]);

    const initialiseFormAndErrorsFromConfig = (additionalDetailsConfig = {}) => {
        const formDataObject = Object.entries(additionalDetailsConfig).reduce(
            (formDataObj, [fieldName, fieldConfig]: any) => {
                formDataObj = {
                    ...formDataObj,
                    [fieldName]: fieldConfig?.type === 'dropdown' ? null : '',
                };
                return formDataObj;
            },
            {},
        );
        const errorsObject = Object.entries(additionalDetailsConfig).reduce(
            (errorsObj, [fieldName]: any) => {
                errorsObj = {
                    ...errorsObj,
                    [fieldName]: false,
                };
                return errorsObj;
            },
            {},
        );
        setFormData(formDataObject);
        setErrors(errorsObject);
        setIsFetchingConfig(false);
    };

    const isStartApplicationDisabled = () => {
        return (
            !Object.entries(configData).every(([fieldName, fieldConfig]) => {
                if (fieldConfig?.isRequired && !formData[fieldName]) return false;
                return true;
            }) || !termsCheck
        );
    };

    const checkIfFormHasErrors = () => {
        let hasErrors = false;
        let updatedErrors = Object.keys(errors).reduce((errorsObj, fieldName) => {
            const error = configData[fieldName]?.isRequired
                ? configData[fieldName]?.type === 'dropdown'
                    ? formData[fieldName] === null
                    : !!!formData[fieldName]?.length
                : false;
            errorsObj[fieldName] = error;
            if (!hasErrors && error) hasErrors = true;
            return errorsObj;
        }, {});
        setErrors(updatedErrors);
        return hasErrors;
    };

    const onStartYourApplication = (payload) => {
        Mixpanel.track(COMPANY_START_YOUR_APPLICATION);
        const hasErrors = checkIfFormHasErrors();
        if (hasErrors) return;
        else {
            const updatedPayload = getPayloadToSubmit(payload);
            dispatch(
                saveApplicationDetails({
                    orgAdditionalDetails: {
                        ...updatedPayload,
                        submittedBy: userData?.userName,
                        accountType: USER_TYPE.COMPANY,
                        investorOrgId: userData?.investorOrgId,
                        orgId: userData?.assesseeOrgId, //
                    },
                }),
            );
        }
    };

    const getPayloadToSubmit = (formData) => {
        const payload = {};
        Object.keys(formData).map((formDataKey) => {
            if (formData[formDataKey])
                payload[formDataKey] =
                    configData?.[formDataKey]?.type === 'dropdown'
                        ? formData?.[formDataKey]?.label
                        : configData?.[formDataKey]?.type === 'number'
                        ? parseFloat(formData?.[formDataKey]) || 0
                        : formData?.[formDataKey];
        });
        return payload;
    };

    const changeValue = (val, fieldName) => {
        setErrors((errorsObj) => ({
            ...errorsObj,
            [fieldName]: false,
        }));
        setFormData((prevData) => ({
            ...prevData,
            [fieldName]: val,
        }));
    };

    const renderFormField = (fieldName, fieldConfig, index) => {
        const { type, placeholder, isRequired, dropdownOptions } = fieldConfig;
        switch (type) {
            case 'text':
            case 'number':
                return (
                    <Input
                        type={type}
                        key={fieldName}
                        placeholder={`${placeholder}` + (isRequired ? '' : ' (Optional)')}
                        value={formData?.[fieldName] || ''}
                        onChange={(val) => changeValue(val, fieldName)}
                        errorMessage={'This field is required'}
                        isValid={!errors[fieldName]}
                        style={{ marginTop: '35px' }}
                    />
                );
            case 'dropdown':
                return (
                    <Dropdown
                        selectedOption={formData?.[fieldName]}
                        optionsList={dropdownOptions}
                        itemClickHandler={(selectedItem: DropdownOption) =>
                            changeValue(selectedItem, fieldName)
                        }
                        style={{ marginTop: '35px', zIndex: 100 - index }}
                        label={placeholder}
                        placeholder={`${placeholder}` + (isRequired ? '' : ' (Optional)')}
                        hasError={errors[fieldName]}
                        errorMessage={'This field is required'}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div className={styles.CompanySignupFormContainer}>
            {fetchingConfig ? (
                <div className={styles.Loading}>
                    <LoadingSpinner color="black" height="60px" />{' '}
                </div>
            ) : (
                <div className={styles.Content}>
                    <Form>
                        <div className={styles.Title}>Application Details</div>

                        {Object.entries(configData).map(([fieldName, fieldConfig], index) => (
                            <div key={index}>{renderFormField(fieldName, fieldConfig, index)}</div>
                        ))}

                        {!!termsAndPolicyConfig && (
                            <div className={styles.Tnc}>
                                <input
                                    type="checkbox"
                                    checked={termsCheck}
                                    onChange={(e) => setTermsCheck(e.target.checked)}
                                />
                                <div>
                                    I agree to the{' '}
                                    <a
                                        className={styles.Link}
                                        href={termsAndPolicyConfig?.termsAndCondition}
                                        target="_blank"
                                    >
                                        Terms of Service
                                    </a>{' '}
                                    and
                                    <a
                                        className={styles.Link}
                                        href={termsAndPolicyConfig?.privacyPolicy}
                                        target="_blank"
                                    >
                                        {' '}
                                        Privacy Policy
                                    </a>
                                    .
                                </div>
                            </div>
                        )}

                        <Button
                            text="Start Your Application"
                            onClick={() => onStartYourApplication(formData)}
                            disabled={isStartApplicationDisabled()}
                            loading={isSavingApplicationDetails}
                            style={{ marginTop: '35px' }}
                            variant="primary"
                        />
                    </Form>
                </div>
            )}
        </div>
    );
}

export default CompanyAdditionalDetailsForm;
