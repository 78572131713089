import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../_custom/LoadingSpinner/LoadingSpinner';

interface FileViewerProps {
    fileUrl: string;
}

const DocumentViewer: React.FC<FileViewerProps> = ({ fileUrl }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Reset loading state when fileUrl changes
        setLoading(true);
    }, [fileUrl]);

    const handleLoad = () => {
        setLoading(false);
    };
    return (
        <div>
            {loading && (
                <div
                    style={{
                        width: '100%',
                        height: '100dvh',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <LoadingSpinner height={'5rem'} color={'var(--primary-text-colour)'} />
                </div>
            )}
            <iframe
                src={`${fileUrl}#view=FitH`}
                style={{
                    width: '100%',
                    height: '100dvh',
                    display: loading ? 'none' : 'block',
                    background: 'white',
                }}
                width={'100%'}
                allowFullScreen
                onLoad={handleLoad}
            />
        </div>
    );
};

export default DocumentViewer;
