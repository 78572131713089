import React from 'react';

const PencilIcon = ({ colour = '#E36414', width = '14', height = '14' }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_3700_5959)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.4375 10.7917V13.5625H3.20833L11.2292 5.46875L8.45833 2.69792L0.4375 10.7917ZM13.3438 3.35417C13.6354 3.0625 13.6354 2.625 13.3438 2.33333L11.6667 0.65625C11.375 0.364583 10.9375 0.364583 10.6458 0.65625L9.33333 1.96875L12.1042 4.73958L13.3438 3.35417Z"
                    fill={colour}
                />
            </g>
            <defs>
                <clipPath id="clip0_3700_5959">
                    <rect width="14" height="14" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default PencilIcon;
