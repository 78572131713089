import React from 'react';
import styles from '../../MagicUpload.module.scss';

const AnimatedFolder: React.FC = () => {
    return (
        <div>
            <svg
                width="54"
                height="44"
                viewBox="0 0 54 44"
                fill="none"
                className={styles.folderIcon}
            >
                <path
                    d="M26.0611 4.80697L22.2748 1.39928C21.778 0.955726 21.1075 0.706126 20.4081 0.703125H9.26113C4.23897 0.703125 0.167969 4.36703 0.167969 8.88697V35.7193C0.167969 37.8896 1.1263 39.9715 2.8313 41.5061C4.53647 43.0406 6.84963 43.9031 9.26113 43.9031H39.0748C44.097 43.9031 48.168 40.2392 48.168 35.7193V13.687C48.168 11.5166 47.2096 9.43477 45.5046 7.90013C43.7995 6.36562 41.4863 5.50312 39.0748 5.50312H27.9278C27.2285 5.50013 26.558 5.25053 26.0611 4.80697Z"
                    fill="var(--secondary-bg-colour)"
                    stroke="var(--primary-bg-colour)"
                />
                <path
                    d="M8.70162 43.9993C6.32862 43.9986 4.07796 42.9439 2.55846 41.1209C1.03962 39.2974 0.168457 37.3398 0.168457 34.5591V11.8924C0.872124 8.0436 4.25629 5.26877 8.16846 5.3326L45.5018 5.3316C47.8983 5.2926 50.186 5.5571 51.7355 7.38594C53.2856 9.21477 53.9335 11.6411 53.5018 13.9993L51.0753 37.4394C50.3716 41.2883 46.9875 44.0631 43.0753 43.9993H8.70162Z"
                    className={styles.folderLid}
                    fill="var(--primary-text-colour)"
                />
            </svg>
        </div>
    );
};

export default AnimatedFolder;
