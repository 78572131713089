import './ShimmerLoading.scss';

const ShimmerLoading = () => {
    return (
        <div className="container">
            <div className="leftSide">
                <div className="logoPlaceholder">
                    <div className="logoShimmer"></div>
                </div>
            </div>
            <div className="rightSide">
                <div className="card">
                    <div className="header">
                        <div className="shimmer titleShimmer"></div>
                    </div>
                    <div className="form">
                        <div className="inputGroup">
                            <div className="shimmer inputShimmer"></div>
                        </div>
                        <div className="createAccount">
                            <div className="shimmer buttonShimmer"></div>
                            <div className="shimmer buttonShimmer"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShimmerLoading;
