import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    createDeal,
    createInvestmentPreference,
    getDealData,
    getInvestmentPreference,
    getLenderDealData,
    getMetricsForDealCreation,
    getPecMatchingData,
    setDealDrawer,
    updateInvestmentPreference,
} from '../../store/investor/action';
import {
    fetchAllAvailableProducts,
    fetchCompanySectorsList,
    getDealFormConfig,
} from '../../store/investor/apiHelpers';
import { initializeDatepickerDates } from '../../utils/dateUtils';
import {
    convertToFirstLetterCapital,
    flatten,
    getPlatformUserId,
    getSubdomain,
    isEmpty,
    unflatten,
} from '../../utils/utils';

import Dropdown from '../_custom/Dropdown';
import Form from '../_custom/Form/Form';
import LoadingSpinner from '../_custom/LoadingSpinner/LoadingSpinner';
import Input from '../_custom/Input/Input';
import InvestorDealShare from './InvestorDealShare/InvestorDealShare';
import MultiSelectDropdown from '../_custom/MultiSelectDropdown/MultiSelectDropdown';
import SideDrawer from '../_custom/SideDrawer/SideDrawer';
import UploadCard from '../../modules/Company/UploadJourney/components/UploadCard/UploadCard';

import styles from './ManageDealDrawer.module.scss';
import { useWindowUnloadEffect } from '../../utils/constants/hooks';
import DealDrawerButtonComponents from './DealDrawerButtonComponents/DealDrawerButtonComponents';
import FormSideStepper from '../FormSideStepper/FormSideStepper';
import clsx from 'clsx';
import {
    AICA_COMPANY_PROFILE,
    AICA_CREATE_DEAL_DETAILS,
    AICA_GET_COMPANY_SECTOR_LIST,
    AICA_INVESTMENT_PREFERENCE,
    CONFIG_STEP,
    CONFIG_TYPE,
    EXCLUDED_LABEL_TYPES,
    IMPORTANCE_LEVELS,
    UPLOAD_FILES_PREFIXES,
} from './DealDrawerConstants';
import { toast } from 'react-toastify';
import { updateQueryParams } from '../../utils/searchParamUtils';
import { USER_TYPE } from '../../utils/constants/user';
import { Mixpanel } from '../../utils/mixpanel';
import {
    getCompanyMetrics,
    updateCompanyMetrics,
} from '../../store/debtProducts/apiHelper_debtProducts';

type Props = {
    source: string;
    onClose?: Function;
};

type Sector = {
    _id: string;
    label: string;
};
const ManageDealDrawer: React.FC<Props> = ({ source, onClose }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const dealDrawerState = useAppSelector((state) => state.investor.dealDrawer);
    const loggedInUser = useAppSelector((state) => state.user.userData);
    const platformData = useAppSelector((state) => state.user.domainMetadata);
    const user = useAppSelector((state) => state.user);
    const userData = user?.userData;
    const urlParams = useParams();
    const [params, setParams] = useSearchParams();

    const [formConfig, setFormConfig] = useState<any>(null);
    const [stepperConfig, setStepperConfig] = useState<any>(null);
    const [formData, setFormData] = useState<{ [key: string]: any }>({});
    const [step, setStep] = useState<number>(1);
    const [disablePrimary, setDisablePrimary] = useState<boolean>(true);
    const [lenderList, setLenderList] = useState<{ id: string; name: string }[]>([]);
    const [preFilledDataFetched, setPreFilledDataFetched] = useState<boolean>(false);
    const [loadingPrefilledData, setLoadingPrefilledData] = useState<boolean>(false);
    const [animationClass, setAnimationClass] = useState<string>('');
    const [investorName, setInvestorName] = useState('');
    const [investmentPreferenceId, setInvestmentPreferenceId] = useState<string | null>(null);
    const [isFromComplete, setIsFromComplete] = useState<boolean>(false);
    const [restrictedMap, setRestrictedMap] = useState<Map<string, boolean>>(new Map());
    const [loanTypeOptions, setLoanTypeOptions] = useState<any>({});
    const [loanTypeNameToIdMap, setLoanTypeNameToIdMap] = useState(new Map<string, string>());
    const [duplicate, setDuplicate] = useState(false);
    const subDomain = getSubdomain();
    const [sectorList, setSectorList] = useState<Sector[]>([]);

    const userOrgId =
        sessionStorage.getItem('user_org_id') ?? localStorage.getItem('user_org_id') ?? '';
    const dealDrawerBodyRef = useRef<any>(null);
    const isLender = user.userData.accountType === USER_TYPE.LENDER;
    const flowId = 16;

    const currentConfig = formConfig?.[step - 1];
    const stepContainerRef = useRef(null);

    const fetchPecData = () => {
        if (dealDrawerState.isPec) {
            if (!investmentPreferenceId && params.get('investmentPreferenceId')) {
                setInvestmentPreferenceId(params.get('investmentPreferenceId'));
            }
            if (investmentPreferenceId || params.get('investmentPreferenceId')) {
                dispatch(
                    getInvestmentPreference(
                        {
                            investmentPreferenceId:
                                investmentPreferenceId ??
                                params.get('investmentPreferenceId') ??
                                '',
                        },
                        {
                            onSuccess: (data) => {
                                if (params.has('Duplicate')) {
                                    data.preferredTerms = { ...data.preferredTerms, loanTypes: [] };
                                }
                                setFormData(flatten(data));
                            },
                        },
                    ),
                );
            }
        }
    };
    const fetchLoanTypes = async () => {
        const isDebtProducts = source === 'Debt Products';
        let payload = {
            aggregatorId: userData?.investorOrgId,
            ...(investmentPreferenceId &&
                !params.has('Duplicate') && { investmentPreferenceId: investmentPreferenceId }),
            ...(isLender && { lenderId: userData?.lenderOrgId }),
        };
        const response = await fetchAllAvailableProducts(payload, isDebtProducts);
        if (response) {
            setLoanTypeOptions(response);
        }
    };

    const fetchCompanySector = async () => {
        try {
            const response = await fetchCompanySectorsList();
            if (response) {
                setSectorList(response?.[AICA_GET_COMPANY_SECTOR_LIST]?.data);
            }
        } catch (error) {
            console.error('Error fetching company sectors:', error);
        }
    };

    useEffect(() => {
        if (params.has('Duplicate')) {
            setDuplicate(true);
        }
    }, []);

    useEffect(() => {
        if (duplicate) {
            params.delete('investmentPreferenceId');
            updateQueryParams(params, setParams, {});
        }
    }, [duplicate]);

    useEffect(() => {
        if (
            (dealDrawerState.isPec && currentConfig?.step === CONFIG_STEP.PREFERRED_TERMS) ||
            (source === 'Debt Products' && currentConfig?.step === CONFIG_STEP.DEAL_TERMS)
        ) {
            fetchLoanTypes();
        }
    }, [currentConfig?.step]);

    useEffect(() => {
        if (currentConfig?.step === CONFIG_STEP.BORROWER_PROFILE) {
            fetchCompanySector();
        }
    }, [currentConfig?.step]);

    useEffect(() => {
        if (loanTypeOptions) {
            const productMap = new Map<string, string>();
            Object.values(loanTypeOptions).forEach((product: any) => {
                productMap.set(product.productName, product.productId);
            });
            setLoanTypeNameToIdMap(productMap);
        }
    }, [loanTypeOptions]);

    const createMapOfRequiredVsSectionKeys = () => {
        let requiredMap = new Map();
        // requiredMap should have sectionKey.formKey=> isRequired we need to incorporate min max and importance keys as well we need the whole form config not the currentstepConfig
        if (formConfig) {
            for (const currentStepConfig of formConfig) {
                for (const config of currentStepConfig.config) {
                    let sectionKey = config?.sectionKey;
                    for (const key in config?.config) {
                        let formKey = key;
                        if (sectionKey) formKey = `${sectionKey}.${key}`;
                        if (config?.config[key]?.isRequired) {
                            if (config.config[key].type === 'range') {
                                let minKey = `minimum${convertToFirstLetterCapital(key)}`;
                                let maxKey = `maximum${convertToFirstLetterCapital(key)}`;
                                if (sectionKey) {
                                    minKey = `${sectionKey}.${minKey}`;
                                    maxKey = `${sectionKey}.${maxKey}`;
                                }
                                requiredMap.set(minKey, true);
                                requiredMap.set(maxKey, true);
                            } else requiredMap.set(formKey, true);
                        }
                    }
                }
            }
        }
        setRestrictedMap(requiredMap);
    };

    // This function will validate the formData with the createMapOfRequiredVsSectionKeys map and return true or false based on whether all the values are filled or not
    const checkCompleteForm = () => {
        const requiredMap: Map<string, boolean> = restrictedMap;
        let isValid = true;
        // @ts-ignore
        for (const [key, value] of requiredMap) {
            if (!formData[key]) {
                isValid = false;
                break;
            }
        }
        setIsFromComplete(isValid);
    };

    useEffect(() => {
        createMapOfRequiredVsSectionKeys();
    }, [formConfig]);

    useEffect(() => {
        checkCompleteForm();
    }, [formData]);

    useEffect(() => {
        if (!dealDrawerState.dealId || dealDrawerState.isPec) return;
        dispatch(
            getDealData(
                {
                    dealId: dealDrawerState.dealId,
                },
                {
                    onSuccess: (dealData) => {
                        const dealNotes = dealData?.dealTerms?.dealNotes?.[0]?.note;
                        if (dealNotes) dealData.dealTerms.dealNotes = dealNotes;
                        setFormData(flatten(dealData));
                    },
                },
            ),
        );
    }, [dealDrawerState.dealId]);

    useEffect(() => {
        if (!dealDrawerState.lenderDealId) return;
        dispatch(
            getLenderDealData(
                {
                    dealId: dealDrawerState.lenderDealId,
                },
                {
                    onSuccess: (dealData) => {
                        const dealNotes = dealData?.dealTerms?.dealNotes?.[0]?.note;
                        if (dealNotes) dealData.dealTerms.dealNotes = dealNotes;
                        setFormData(flatten(dealData));
                    },
                },
            ),
        );
    }, [dealDrawerState.lenderDealId]);

    useEffect(() => {
        if (!dealDrawerState.step || !formConfig) return;
        setStep(formConfig.findIndex((config) => config.step === dealDrawerState.step) + 1);
    }, [dealDrawerState.step, formConfig]);

    useEffect(() => {
        if (platformData && loggedInUser) {
            getDealFormConfig(
                {
                    accountType: loggedInUser.accountType,
                    investorOrgId: platformData?.investorOrgId,
                    primaryEmail: loggedInUser?.primaryEmail,
                    configName: dealDrawerState?.isPec
                        ? AICA_INVESTMENT_PREFERENCE
                        : source === 'Debt Products'
                        ? AICA_COMPANY_PROFILE
                        : AICA_CREATE_DEAL_DETAILS,
                },
                {
                    onSuccess: (configData, orgName) => {
                        if (orgName) setInvestorName(orgName);
                        setFormConfig(configData?.pages ?? []);
                        setStepperConfig(configData?.stepper ?? []);
                    },
                },
            );
        }
    }, [platformData, loggedInUser]);

    useEffect(() => {
        if (isEmpty(formData)) fetchPecData();
    }, [formData]);

    useEffect(() => {
        if (!platformData || !currentConfig || !dealDrawerState || dealDrawerState.isPec) return;
        if (source === 'Debt Products') {
            if (!preFilledDataFetched) {
                setLoadingPrefilledData(true);
                getCompanyMetrics({
                    assesseeOrgId: urlParams.id as string,
                })
                    .then((data) => {
                        setLoadingPrefilledData(false);
                        if (Object.keys(data)?.length) setFormData(flatten(data));
                        setPreFilledDataFetched(true);
                    })
                    .catch((err) => {});
            }
        } else {
            if (
                currentConfig?.step === 'BORROWER_PROFILE' &&
                isEmpty(dealDrawerState.dealId) &&
                isEmpty(dealDrawerState.lenderDealId) &&
                !preFilledDataFetched
            ) {
                const [from, to] = initializeDatepickerDates('Yearly');
                setLoadingPrefilledData(true);
                dispatch(
                    getMetricsForDealCreation(
                        {
                            parentOrgId: platformData.investorOrgId,
                            orgId: dealDrawerState.companyId as string,
                            startDate: from,
                            endDate: to,
                        },
                        {
                            onSuccess: (metricsData) => {
                                setLoadingPrefilledData(false);
                                setFormData(flatten(metricsData));
                                setPreFilledDataFetched(true);
                            },
                            onError: () => {
                                setLoadingPrefilledData(false);
                            },
                        },
                    ),
                );
            }
            if (currentConfig?.step === 'SELECT_INVESTORS') {
                const createDealPayload: any = unflatten(formData);
                createDealPayload.dealProposerId = platformData?.investorOrgId;
                createDealPayload.dealBeneficiaryId = dealDrawerState?.companyId;
                createDealPayload.dealCreatedBy = loggedInUser?.userName;
                if (createDealPayload.dealTerms?.dealNotes) {
                    createDealPayload.dealTerms.dealNotes = [
                        {
                            note: createDealPayload.dealTerms.dealNotes,
                            noteBy: loggedInUser?.userName,
                            noteDate: new Date().toISOString(),
                        },
                    ];
                }
                dispatch(
                    getPecMatchingData(createDealPayload, {
                        onSuccess: (pecMatchingData) => {
                            setLenderList(pecMatchingData);
                        },
                    }),
                );
            }
        }
    }, [platformData, currentConfig, dealDrawerState]);

    useEffect(() => {
        if (animationClass) {
            const timer = setTimeout(() => {
                setAnimationClass('fade-in');
            }, 600); // Duration of the animation in milliseconds

            // Cleanup function to clear the timeout if the component unmounts or if animationClass changes
            return () => clearTimeout(timer);
        }
    }, [animationClass]);

    useEffect(() => {
        validateForm();
    }, [currentConfig, formData]);

    useEffect(() => {
        dealDrawerBodyRef?.current?.scrollTo?.({
            top: 0,
        });
    }, [step]);

    useEffect(() => {
        return () => {
            resetEditorDataInLocalStorage();
        };
    }, []);

    useWindowUnloadEffect(() => {
        //This will remove the locally stored editor data
        resetEditorDataInLocalStorage();
    }, true);

    const resetEditorDataInLocalStorage = () => {
        localStorage.removeItem('one_pager_content');
        localStorage.removeItem('one_pager_assessee');
    };

    const validateForm = () => {
        const currentStepKeys: string[] = [];
        for (const currentStepConfig of currentConfig?.config ?? []) {
            const sectionKey = currentStepConfig?.sectionKey;
            for (const key in currentStepConfig.config) {
                let formKey = key;
                if (sectionKey) formKey = `${sectionKey}.${key}`;
                if (currentStepConfig.config[key].isRequired) {
                    if (currentStepConfig.config[key].type === 'range') {
                        let minKey = `minimum${convertToFirstLetterCapital(key)}`;
                        let maxKey = `maximum${convertToFirstLetterCapital(key)}`;
                        let importanceKey = `importance${convertToFirstLetterCapital(key)}`;
                        if (sectionKey) {
                            minKey = `${sectionKey}.${minKey}`;
                            maxKey = `${sectionKey}.${maxKey}`;
                            importanceKey = `${sectionKey}.${importanceKey}`;
                        }
                        currentStepKeys.push(minKey);
                        currentStepKeys.push(maxKey);
                        if (currentStepConfig.config[key].importance) {
                            currentStepKeys.push(importanceKey);
                        }
                    } else if (currentStepConfig.config[key].importance) {
                        let importanceKey = `importance${convertToFirstLetterCapital(key)}`;
                        if (sectionKey) {
                            importanceKey = `${sectionKey}.${importanceKey}`;
                        }
                        currentStepKeys.push(importanceKey);
                    } else currentStepKeys.push(formKey);
                }
            }
        }
        const isFormValid = currentStepKeys.every(
            (key) => key in formData && !isEmpty(formData[key]),
        );
        setDisablePrimary(!isFormValid);
    };

    const closeDrawer = () => {
        const newParams = new URLSearchParams(params);
        newParams.delete('investmentPreferenceId');
        newParams.delete('Duplicate');
        updateQueryParams(newParams, setParams, {});
        dispatch(setDealDrawer({ open: false }));

        onClose?.();
    };

    const handleChange = (key: string, value: any) => {
        setFormData((prev) => ({ ...prev, [key]: value }));
    };

    function checkIfWeNeedToShowDependentSection(
        section: { config: { [x: string]: any }; sectionKey: any },
        key: string,
        formData: { [x: string]: any },
    ) {
        const config = section.config[key];

        if (config.dependent && config.dependent.length > 0) {
            for (const dependency of config.dependent) {
                const condition = dependency.condition;
                // Extract the value from formData based on the condition key
                const formDataValue = formData[`${section.sectionKey}.${condition.key}`];
                switch (condition.operator) {
                    // add more cases here if needed
                    case 'EQUAL':
                        if (formDataValue === condition.value) {
                            return false;
                        }
                        break;
                    default:
                        return false;
                }
            }
            return true;
        }
        return false;
    }

    const companyMetricsUpdate = (navigationFunction: Function, isStepper: boolean) => {
        updateCompanyMetrics({
            orgId: urlParams.id as string,
            parentOrgId: userData?.investorOrgId,
            companyMetrics: unflatten(formData),
        })
            .then((res) => {
                navigationFunction?.();
            })
            .catch();
    };

    const createOrUpdatePEC = (navigationFunction: Function, isStepper: boolean) => {
        const createInvestmentPayload: any = unflatten(formData);
        createInvestmentPayload.aggregatorId = platformData?.investorOrgId;
        createInvestmentPayload.lenderId = userOrgId;
        createInvestmentPayload.activityBy = loggedInUser?.userName;
        createInvestmentPayload.type =
            user.userData.accountType === 'INVESTOR' ? 'ADMIN' : user.userData.accountType;
        createInvestmentPayload.status = isFromComplete ? 'COMPLETE' : 'PENDING';
        createInvestmentPayload.products = createInvestmentPayload.preferredTerms.loanTypes
            .map((productName) => loanTypeNameToIdMap.get(productName))
            .filter(Boolean);
        if (investmentPreferenceId && !params.has('Duplicate')) {
            createInvestmentPayload.investmentPreferenceId = investmentPreferenceId;
            Mixpanel.track('PEC Edited', {
                userEmail: userData?.primaryEmail,
                PNOId: userData?.investorOrgId,
                PECId: investmentPreferenceId,
                LoanTypes: createInvestmentPayload.products,
                MetricsUpdated: createInvestmentPayload,
                UpdatedBy: userData?.userName,
                LastUpdated: new Date().toISOString(),
            });
            dispatch(
                updateInvestmentPreference(createInvestmentPayload, {
                    onSuccess: (res) => {
                        if (!isStepper && currentConfig.ctas.primary.text === 'Save & Close') {
                            closeDrawer();
                            toast.success('Investment Preferences saved successfully');
                            return;
                        }
                        navigationFunction();
                    },
                }),
            );
        } else {
            createInvestmentPayload.investmentPreferenceType = 'PEC';
            if (params.has('Duplicate')) {
                Mixpanel.track('PEC Duplicated', {
                    userEmail: userData?.primaryEmail,
                    PNOId: userData?.investorOrgId,
                    LoanTypes: createInvestmentPayload.products,
                    DuplicatedBy: userData?.userName,
                    DuplicationTime: new Date().toISOString(),
                });
            } else {
                Mixpanel.track('PEC Created', {
                    userEmail: userData?.primaryEmail,
                    PNOId: userData?.investorOrgId,
                    PNOName: subDomain,
                    LoanTypes: createInvestmentPayload.products,
                    CreationTime: new Date().toISOString(),
                });
            }
            dispatch(
                createInvestmentPreference(createInvestmentPayload, {
                    onSuccess: (res) => {
                        params.set('investmentPreferenceId', res?.investmentPreferenceId);
                        setParams(params);
                        if (params.has('Duplicate')) {
                            params.delete('Duplicate');
                            updateQueryParams(params, setParams, {});
                        }
                        setInvestmentPreferenceId(res?.investmentPreferenceId);
                        setAnimationClass('slide-down');
                        setTimeout(() => {
                            setStep(step + 1);
                        }, 300);
                    },
                }),
            );
        }
    };

    const handlePrimary = () => {
        if (source === 'Debt Products') {
            companyMetricsUpdate(() => {
                if (step === formConfig?.length) {
                    closeDrawer();
                    setTimeout(() => {
                        onClose?.();
                    }, 300);
                } else {
                    setAnimationClass('slide-down');
                    setTimeout(() => {
                        setStep(step + 1);
                    }, 300);
                }
            }, false);
        } else {
            if (dealDrawerState.isPec) {
                createOrUpdatePEC(() => {
                    setAnimationClass('slide-down');
                    setTimeout(() => {
                        setStep(step + 1);
                    }, 300);
                }, false);
            } else {
                if (currentConfig?.step === 'SELECT_INVESTORS') {
                    const createDealPayload: any = unflatten(formData);
                    createDealPayload.dealProposerId = platformData?.investorOrgId;
                    createDealPayload.dealBeneficiaryId = dealDrawerState?.companyId;
                    createDealPayload.dealCreatedBy = loggedInUser?.userName;
                    createDealPayload.owner = {
                        name: loggedInUser?.userName,
                        userId: getPlatformUserId(),
                        activityBy: loggedInUser?.userName,
                        activityDate: new Date().toISOString(),
                    };
                    if (createDealPayload?.dealTerms?.dealNotes) {
                        createDealPayload.dealTerms.dealNotes = [
                            {
                                note: createDealPayload?.dealTerms?.dealNotes,
                                noteBy: loggedInUser?.userName,
                                noteDate: new Date().toISOString(),
                            },
                        ];
                    }
                    dispatch(
                        createDeal(createDealPayload, {
                            onSuccess: () => {
                                closeDrawer();
                                navigate('/investor/app/track', {
                                    state: { scrollPosition: 0 },
                                });
                            },
                        }),
                    );
                    return;
                }
                if (step + 1 > formConfig.length) return;

                // Set animation class for slide up and then move to the next step
                setAnimationClass('slide-down');
                setTimeout(() => {
                    setStep(step + 1);
                }, 300);
            }
        }
    };

    const handleSecondary = () => {
        if (step - 1 < 1) return;

        // Set animation class for slide up and then move to the previous step
        setAnimationClass('slide-up');
        setTimeout(() => {
            setStep(step - 1);
        }, 300); // Duration of the slide-down animation
    };

    const handleStepperClick = (index: number) => {
        if (index > step) {
            setAnimationClass('slide-up');
        } else {
            setAnimationClass('slide-down');
        }
        if (dealDrawerState.isPec) {
            createOrUpdatePEC(() => {
                setTimeout(() => {
                    setStep(index);
                }, 300);
            }, true);
        } else {
            setTimeout(() => {
                setStep(index);
            }, 300);
        }
    };

    const renderInput = (sectionKey: string, key: string, config: any) => {
        let formKey = key;
        let importanceKey = `importance${convertToFirstLetterCapital(key)}`;

        if (sectionKey) {
            importanceKey = `${sectionKey}.${importanceKey}`;
            formKey = `${sectionKey}.${key}`;
        }

        const categoryId =
            currentConfig?.config?.[0]?.config?.documents?.documents?.components?.[0]?.categoryId;
        switch (config?.type) {
            case CONFIG_TYPE.SEARCH_DROPDOWN:
            case CONFIG_TYPE.DROPDOWN: {
                let isValid = formData[formKey] && !formData[importanceKey];
                return (
                    <div
                        className={styles.Dflex}
                        style={{
                            width: '100%',
                        }}
                    >
                        <Dropdown
                            enableSearch={config?.type === 'search-dropdown'}
                            selectedOption={{
                                id: formData[formKey] as string | number,
                                label: formData[formKey] as string,
                            }}
                            optionsList={config?.dropdownOptions?.map((option) => ({
                                id: option._id,
                                label: option.label,
                            }))}
                            placeholder={
                                config.isRequired
                                    ? config?.placeholder
                                    : `${config?.placeholder} (Optional)`
                            }
                            label={
                                config.isRequired
                                    ? config?.placeholder
                                    : `${config?.placeholder} (Optional)`
                            }
                            itemClickHandler={(option) => handleChange(formKey, option.id)}
                            style={
                                config?.importance || dealDrawerState?.isPec
                                    ? { width: '70%' }
                                    : { width: '100%' }
                            }
                            disabled={dealDrawerState?.readonly}
                            errorMessage={config?.errorMessage}
                        />
                        {config?.importance && (
                            <Dropdown
                                selectedOption={{
                                    id: formData[importanceKey] as string | number,
                                    label: formData[importanceKey] as string,
                                }}
                                listStyles={{ minWidth: '0' }}
                                placeholder={'Importance'}
                                style={{ width: '30%' }}
                                hasError={isValid}
                                errorMessage={'Required'}
                                optionsList={IMPORTANCE_LEVELS}
                                itemClickHandler={(option) =>
                                    handleChange(importanceKey, option.id)
                                }
                            />
                        )}
                    </div>
                );
            }
            case CONFIG_TYPE.MULTIPLE_SELECT: {
                let isValid = formData[formKey] && !formData[importanceKey];
                let isAllDone = !formData[formKey] && formData[importanceKey];
                return (
                    <div className={styles.Dflex} style={{ width: '100%' }}>
                        <MultiSelectDropdown
                            hasError={isAllDone}
                            errorMessage={'Required'}
                            WrapperStyle={
                                config?.importance || dealDrawerState?.isPec
                                    ? { width: '70%' }
                                    : { width: '100%' }
                            }
                            selectedOptions={((formData[formKey] ?? []) as string[]) || []}
                            options={config?.dropdownOptions?.map((option) => ({
                                id: option._id,
                                label: option.label,
                            }))}
                            placeholder={
                                config.isRequired
                                    ? config?.placeholder
                                    : `${config?.placeholder} (Optional)`
                            }
                            onChange={(selectedOptions) => handleChange(formKey, selectedOptions)}
                            disabled={dealDrawerState?.readonly}
                        />
                        {config?.importance && (
                            <Dropdown
                                selectedOption={{
                                    id: formData[importanceKey] as string | number,
                                    label: formData[importanceKey] as string,
                                }}
                                listStyles={{ minWidth: '0' }}
                                style={{ width: '30%' }}
                                placeholder={'Importance'}
                                hasError={isValid}
                                label={'Importance'}
                                errorMessage={'Required'}
                                optionsList={IMPORTANCE_LEVELS}
                                itemClickHandler={(option) =>
                                    handleChange(importanceKey, option.id)
                                }
                            />
                        )}
                    </div>
                );
            }
            case CONFIG_TYPE.RANGE: {
                let minKey = `minimum${convertToFirstLetterCapital(key)}`;
                let maxKey = `maximum${convertToFirstLetterCapital(key)}`;
                let isValid = true;
                let errorMessage = '';
                let isImp = false;
                if (sectionKey) {
                    minKey = `${sectionKey}.${minKey}`;
                    maxKey = `${sectionKey}.${maxKey}`;
                }
                if (!isEmpty(formData[minKey]) && !isEmpty(formData[maxKey])) {
                    isValid = Number(formData[minKey]) <= Number(formData[maxKey]);
                    errorMessage = isValid ? '' : 'Invalid range';
                    if (isValid && !formData[importanceKey]) {
                        isImp = true;
                    }
                } else if (formData[importanceKey] && (!formData[minKey] || !formData[maxKey])) {
                    isValid = false;
                    errorMessage = 'Required';
                }
                return (
                    <div className={styles.Dflex} style={{ width: '100%' }}>
                        <div
                            style={
                                config?.importance || dealDrawerState.isPec
                                    ? { width: '70%' }
                                    : { width: '100%' }
                            }
                            className={styles.RangeInput}
                        >
                            <Input
                                key={`min-${key}`}
                                type="number"
                                allowNegative={config?.allowNegative ?? false}
                                allowDecimal={config?.allowDecimal ?? true}
                                value={(formData[minKey] as string | number) || ''}
                                placeholder="Minimum"
                                onChange={(value) => handleChange(minKey, value)}
                                unit={config?.unit}
                                style={{ marginTop: 0, width: '100%' }}
                                unitStyle={{
                                    minWidth: '50%',
                                    backgroundColor: formData[minKey] ? '' : '#f7f7f7',
                                }}
                                disabled={dealDrawerState?.readonly}
                                errorMessage={errorMessage}
                                isValid={isValid}
                                inputStyle={{ backgroundColor: formData[minKey] ? '' : '#f7f7f7' }}
                            />
                            <Input
                                key={`max-${key}`}
                                type="number"
                                allowNegative={config?.allowNegative ?? false}
                                allowDecimal={config?.allowDecimal ?? true}
                                value={(formData[maxKey] as string | number) || ''}
                                placeholder="Maximum"
                                onChange={(value) => handleChange(maxKey, value)}
                                unit={config?.unit}
                                style={{ marginTop: 0, width: '100%' }}
                                unitStyle={{
                                    minWidth: '50%',
                                    backgroundColor: formData[maxKey] ? '' : '#f7f7f7',
                                }}
                                disabled={dealDrawerState?.readonly}
                                errorMessage={errorMessage}
                                isValid={isValid}
                                inputStyle={{ backgroundColor: formData[maxKey] ? '' : '#f7f7f7' }}
                            />
                        </div>
                        {config?.importance && (
                            <Dropdown
                                selectedOption={{
                                    id: formData[importanceKey] as string | number,
                                    label: formData[importanceKey] as string,
                                }}
                                listStyles={{ minWidth: '0' }}
                                placeholder={'Importance'}
                                style={{ width: '30%' }}
                                label={'Importance'}
                                optionsList={IMPORTANCE_LEVELS}
                                hasError={isImp}
                                errorMessage={'Required'}
                                itemClickHandler={(option) =>
                                    handleChange(importanceKey, option.id)
                                }
                            />
                        )}
                    </div>
                );
            }
            case CONFIG_TYPE.MULTIPLE_SELECT_API: {
                let isValid = formData[formKey] && !formData[importanceKey];
                let isAllDone = !formData[formKey] && formData[importanceKey];
                const options =
                    sectionKey === CONFIG_STEP.preferredTerms ||
                    sectionKey === CONFIG_STEP.dealTerms
                        ? Object.values(loanTypeOptions).map((item) => ({
                              id: (item as { productName: string }).productName,
                              label: (item as { productName: string }).productName,
                          }))
                        : sectionKey === CONFIG_STEP.borrowerProfile
                        ? Object.values(sectorList)?.map((option) => ({
                              id: (option as { _id: string })._id,
                              label: (option as { label: string }).label,
                          }))
                        : [];
                return (
                    <div className={styles.Dflex} style={{ width: '100%' }}>
                        <MultiSelectDropdown
                            hasError={isAllDone}
                            errorMessage={'Required'}
                            WrapperStyle={
                                config?.importance || dealDrawerState?.isPec
                                    ? { width: '65%' }
                                    : { width: '100%' }
                            }
                            selectedOptions={((formData[formKey] ?? []) as string[]) || []}
                            options={options}
                            placeholder={
                                config.isRequired
                                    ? config?.placeholder
                                    : `${config?.placeholder} (Optional)`
                            }
                            onChange={(selectedOptions) => handleChange(formKey, selectedOptions)}
                            disabled={dealDrawerState?.readonly}
                        />
                        {config?.importance && (
                            <Dropdown
                                selectedOption={{
                                    id: formData[importanceKey] as string | number,
                                    label: formData[importanceKey] as string,
                                }}
                                listStyles={{ minWidth: '0' }}
                                style={{ width: '30%' }}
                                placeholder={'Importance'}
                                hasError={isValid}
                                label={'Importance'}
                                errorMessage={'Required'}
                                optionsList={IMPORTANCE_LEVELS}
                                itemClickHandler={(option) =>
                                    handleChange(importanceKey, option.id)
                                }
                            />
                        )}
                    </div>
                );
            }
            case CONFIG_TYPE.RECUR_INVESTOR_TOGGLE:
            case CONFIG_TYPE.INVESTOR_TOGGLE:
                return (
                    <InvestorDealShare
                        source={config.type}
                        lenderList={lenderList}
                        networkLabel={config?.placeholder}
                        totalInvestors={lenderList.length}
                        showInvestorList={config.type === 'investor-toggle'}
                        selectedInvestors={(formData?.dealReceiverIds ?? []) as string[]}
                        updateSelectedInvestors={(sharedInvestors) =>
                            handleChange('dealReceiverIds', sharedInvestors)
                        }
                        style={{ marginTop: '-24px' }}
                    />
                );
            case CONFIG_TYPE.UPLOAD:
                return (
                    <UploadCard
                        assesseeOrgId={dealDrawerState?.companyId as string}
                        categoryId={config?.documents?.components?.[0]?.categoryId}
                        flowId={flowId}
                        existingFiles={formData[formKey]
                            ?.filter(
                                (doc) =>
                                    doc?.documentName?.includes(
                                        UPLOAD_FILES_PREFIXES.ATTACHMENT_PREFIX,
                                    ),
                            )
                            ?.map((doc) => {
                                return {
                                    docId: doc.docId,
                                    docName: doc.documentName,
                                };
                            })}
                        metadataUpdate={(fileInfo) =>
                            handleChange(formKey, [
                                ...(formData[formKey] ?? []),
                                {
                                    docId: fileInfo?.docId,
                                    documentName: `ATTACHMENT_${fileInfo?.fileName}`,
                                    action: 'SEND_TO_INVESTOR',
                                },
                            ])
                        }
                        fileDeleteHandler={(fileInfo) =>
                            handleChange(
                                formKey,
                                formData[formKey]?.filter((doc) => doc.docId !== fileInfo.docId),
                            )
                        }
                        allowMultiple
                        disabled={dealDrawerState?.readonly}
                        disableDelete={dealDrawerState?.readonly}
                        style={
                            config?.importance || dealDrawerState?.isPec
                                ? { width: '70%' }
                                : { width: '100%' }
                        }
                        fileNamePrefix="ATTACHMENT_"
                    />
                );
            case CONFIG_TYPE.BUTTON:
                return (
                    <DealDrawerButtonComponents
                        componentKey={key as string}
                        formData={formData}
                        config={config}
                        categoryId={categoryId}
                        handleChange={handleChange}
                        step={step}
                        source={source}
                    />
                );

            case CONFIG_TYPE.SELECT_API: {
                let isValid = formData[formKey] && !formData[importanceKey];
                return (
                    <div
                        className={styles.Dflex}
                        style={{
                            width: '100%',
                        }}
                    >
                        <Dropdown
                            enableSearch={config?.type === CONFIG_TYPE.SELECT_SECTOR_API}
                            selectedOption={{
                                id: formData[formKey] as string | number,
                                label: formData[formKey] as string,
                            }}
                            optionsList={Object.values(sectorList)?.map((option) => ({
                                id: (option as { _id: string })._id,
                                label: (option as { label: string }).label,
                            }))}
                            placeholder={
                                config.isRequired
                                    ? config?.placeholder
                                    : `${config?.placeholder} (Optional)`
                            }
                            label={
                                config.isRequired
                                    ? config?.placeholder
                                    : `${config?.placeholder} (Optional)`
                            }
                            itemClickHandler={(option) => handleChange(formKey, option.id)}
                            style={
                                config?.importance || dealDrawerState?.isPec
                                    ? { width: '70%' }
                                    : { width: '100%' }
                            }
                            disabled={dealDrawerState?.readonly}
                            errorMessage={config?.errorMessage}
                        />
                        {config?.importance && (
                            <Dropdown
                                selectedOption={{
                                    id: formData[importanceKey] as string | number,
                                    label: formData[importanceKey] as string,
                                }}
                                listStyles={{ minWidth: '0' }}
                                placeholder={'Importance'}
                                style={{ width: '30%' }}
                                hasError={isValid}
                                errorMessage={'Required'}
                                optionsList={IMPORTANCE_LEVELS}
                                itemClickHandler={(option) =>
                                    handleChange(importanceKey, option.id)
                                }
                            />
                        )}
                    </div>
                );
            }

            default:
                return (
                    <div className={styles.Dflex} style={{ width: '100%' }}>
                        <Input
                            key={key}
                            type={config?.type}
                            allowNegative={config?.allowNegative ?? false}
                            allowDecimal={config?.allowDecimal ?? true}
                            value={formData[formKey] as string | number}
                            placeholder={
                                config.isRequired
                                    ? config?.placeholder
                                    : `${config?.placeholder} (Optional)`
                            }
                            onChange={(value) => handleChange(formKey, value)}
                            unit={config?.unit}
                            style={
                                config?.importance || dealDrawerState?.isPec
                                    ? { width: config?.importance ? '70%' : '65%', marginTop: 0 }
                                    : { marginTop: 0, width: '100%' }
                            }
                            disabled={dealDrawerState?.readonly}
                        />
                        {config?.importance && (
                            <Dropdown
                                selectedOption={{
                                    id: formData[importanceKey] as string | number,
                                    label: formData[importanceKey] as string,
                                }}
                                listStyles={{ minWidth: '0' }}
                                style={{ width: '30%' }}
                                label={'Importance'}
                                optionsList={IMPORTANCE_LEVELS}
                                itemClickHandler={(option) =>
                                    handleChange(importanceKey, option.id)
                                }
                            />
                        )}
                    </div>
                );
        }
    };

    return (
        <>
            <SideDrawer
                dontClose
                width={dealDrawerState.isPec ? '75%' : '67%'}
                onClose={closeDrawer}
            >
                <div className={styles.DrawerHeading}>
                    <div className={styles.DrawerHeadingTitle}>
                        {dealDrawerState.drawerLabel ?? 'Create Deal'}
                        <span className={styles.CompanyName}>
                            <span className={styles.VerticalSeparator}>|</span>
                            {dealDrawerState?.companyName ?? investorName ?? ''}
                        </span>
                    </div>
                </div>
                {currentConfig && !loadingPrefilledData ? (
                    <Form onSubmit={handlePrimary}>
                        <div className={styles.ManageDealDrawer} ref={dealDrawerBodyRef}>
                            <div className={styles.DealsFormWrapper}>
                                <FormSideStepper
                                    isViewOnly={
                                        (!dealDrawerState.isPec && dealDrawerState?.readonly) ??
                                        false
                                    }
                                    step={step}
                                    statusTrigger={!disablePrimary}
                                    onStepClick={handleStepperClick}
                                    stepperConfig={stepperConfig}
                                    checkCompleteForm={isFromComplete}
                                />
                                <div
                                    className={clsx({
                                        [styles.Step]: true,
                                        [styles.slideUp]: animationClass === 'slide-up',
                                        [styles.slideDown]: animationClass === 'slide-down',
                                        [styles.fadeIn]: animationClass === 'fade-in',
                                    })}
                                >
                                    <div className={styles.StepContainer} ref={stepContainerRef}>
                                        {currentConfig.config.map(
                                            (
                                                section: {
                                                    text?: any;
                                                    config: any;
                                                    sectionKey: any;
                                                },
                                                index: number,
                                            ) => {
                                                return (
                                                    <div
                                                        key={section.text}
                                                        className={styles.Section}
                                                    >
                                                        <div className={styles.SectionHeading}>
                                                            {index === 0 && `${step}.`}{' '}
                                                            {section.text}
                                                        </div>
                                                        {!dealDrawerState?.readonly &&
                                                            index === 0 && (
                                                                <div className={styles.StepHeading}>
                                                                    <span
                                                                        className={
                                                                            styles.StepHeadingSecondaryText
                                                                        }
                                                                    >
                                                                        {currentConfig.text}
                                                                    </span>
                                                                </div>
                                                            )}
                                                        <div
                                                            className={styles.HorizontalSeparator}
                                                        />
                                                        <div className={styles.InputList}>
                                                            {Object.keys(section.config).map(
                                                                (key) => {
                                                                    if (
                                                                        checkIfWeNeedToShowDependentSection(
                                                                            section,
                                                                            key,
                                                                            formData,
                                                                        )
                                                                    )
                                                                        return;
                                                                    return (
                                                                        <div
                                                                            className={
                                                                                styles.InputItem
                                                                            }
                                                                            key={`input-${key}`}
                                                                        >
                                                                            {!EXCLUDED_LABEL_TYPES.includes(
                                                                                section.config[key]
                                                                                    ?.type,
                                                                            ) && (
                                                                                <div
                                                                                    style={
                                                                                        dealDrawerState.isPec
                                                                                            ? {
                                                                                                  width: '40%',
                                                                                              }
                                                                                            : {}
                                                                                    }
                                                                                    className={
                                                                                        styles.InputLabel
                                                                                    }
                                                                                >
                                                                                    <div>
                                                                                        {
                                                                                            section
                                                                                                .config[
                                                                                                key
                                                                                            ]?.label
                                                                                        }
                                                                                    </div>
                                                                                    {section.config[
                                                                                        key
                                                                                    ]?.subLabel && (
                                                                                        <div
                                                                                            className={
                                                                                                styles.SubLabel
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                section
                                                                                                    .config[
                                                                                                    key
                                                                                                ]
                                                                                                    ?.subLabel
                                                                                            }
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            )}
                                                                            <div
                                                                                style={{
                                                                                    width: '100%',
                                                                                }}
                                                                            >
                                                                                {renderInput(
                                                                                    section.sectionKey,
                                                                                    key,
                                                                                    section.config[
                                                                                        key
                                                                                    ],
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                },
                                                            )}
                                                        </div>
                                                    </div>
                                                );
                                            },
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!dealDrawerState?.readonly && (
                            <div className={styles.DrawerFooter}>
                                <div className={styles.CtaContainer}>
                                    {currentConfig.ctas?.primary && (
                                        <button
                                            type="submit"
                                            disabled={disablePrimary}
                                            className={`${styles.Button} ${styles.Primary}`}
                                        >
                                            {currentConfig.ctas.primary.text}
                                        </button>
                                    )}
                                    {currentConfig.ctas?.secondary && (
                                        <button
                                            type="button"
                                            disabled={step === 1}
                                            className={`${styles.Button} ${styles.Secondary}`}
                                            onClick={handleSecondary}
                                        >
                                            {currentConfig.ctas.secondary.text}
                                        </button>
                                    )}
                                </div>
                            </div>
                        )}
                    </Form>
                ) : (
                    <LoadingSpinner
                        className={styles.DealDrawerLoader}
                        color="var(--primary-text-colour)"
                        height="50px"
                    />
                )}
            </SideDrawer>
        </>
    );
};

export default ManageDealDrawer;
