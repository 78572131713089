import React from 'react';

const FetchIcon = ({ colour = '#E36414', width = '14', height = '20' }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 14 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.99479 0.835938V3.33594C10.6781 3.33594 13.6615 6.31927 13.6615 10.0026C13.6615 11.3109 13.2781 12.5276 12.6281 13.5526L11.4115 12.3359C11.7865 11.6443 11.9948 10.8443 11.9948 10.0026C11.9948 7.24427 9.75313 5.0026 6.99479 5.0026V7.5026L3.66146 4.16927L6.99479 0.835938ZM1.99479 10.0026C1.99479 12.7609 4.23646 15.0026 6.99479 15.0026V12.5026L10.3281 15.8359L6.99479 19.1693V16.6693C3.31146 16.6693 0.328125 13.6859 0.328125 10.0026C0.328125 8.69427 0.711458 7.4776 1.36146 6.4526L2.57812 7.66927C2.20312 8.36094 1.99479 9.16094 1.99479 10.0026Z"
                fill={colour}
            />
        </svg>
    );
};

export default FetchIcon;
