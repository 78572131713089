import {
    CreateAccountV2Payload,
    FetchConfigAfterLoginPayload,
    InvestorLoginSignupPayload,
    InvestorSignupPayload,
    LoginWithOtpAfterSignupPayload,
    LoginWithOtpPayloadV2,
    ResendVerificationEmailPayload,
    ResetPasswordV2,
    SendResetLinkV2,
    VerifyEmailPayload,
} from './actions';
import axios from 'axios';
import { cerberusBaseUrl, GET, POST } from '../../../utils/axios-interceptor';
import { getTimeStamp } from '../../../utils/dateUtils';
import { DomainMetadataReqPayload } from '../actions';

export function createV2(payload: CreateAccountV2Payload) {
    return axios({
        url: `${cerberusBaseUrl}${CERBERUS_API_ENDPOINTS.CREATE_V2}`,
        method: POST,
        data: payload,
    });
}

export function loginV2(payload: LoginWithOtpAfterSignupPayload) {
    return axios({
        url: `${cerberusBaseUrl}${CERBERUS_API_ENDPOINTS.LOGIN_V2}`,
        method: POST,
        data: payload,
    });
}

export function fetchConfigAfterLoginService(payload: FetchConfigAfterLoginPayload) {
    return axios({
        url: API_ENDPOINTS.FETCH_CONFIG_DATA,
        method: POST,
        data: {
            requestId: getTimeStamp(),
            orgInfo: payload,
            userRole: payload?.userRole,
        },
    });
}

export function generateOtpV2(payload: LoginWithOtpPayloadV2) {
    return axios({
        url: `${cerberusBaseUrl}${CERBERUS_API_ENDPOINTS.GENERATE_OTP_V2}`,
        method: POST,
        data: payload,
    });
}
export function fetchDomainMetadataService(payload: DomainMetadataReqPayload) {
    return axios({
        url: API_ENDPOINTS.FETCH_DOMAIN_METADATA,
        method: GET,
        params: {
            requestId: getTimeStamp(),
            domain: payload.domainName || 'founderlink.ai',
            subDomain: payload.subdomainName || 'aica',
        },
    });
}
export function sendResetLinkV2(payload: SendResetLinkV2) {
    return axios({
        url: `${cerberusBaseUrl}${CERBERUS_API_ENDPOINTS.SEND_RESET_LINK}`,
        method: POST,
        data: payload,
    });
}
export function resetPasswordV2(payload: ResetPasswordV2) {
    return axios({
        url: `${cerberusBaseUrl}${CERBERUS_API_ENDPOINTS.RESET_PASSWORD}`,
        method: POST,
        data: payload,
    });
}
export function summonAica(payload: { assesseeOrgId: string }) {
    return axios({
        url: API_ENDPOINTS.SUMMON_AICA,
        method: POST,
        data: payload,
    });
}
export function investorSignup(payload: InvestorSignupPayload) {
    return axios({
        url: API_ENDPOINTS.INVESTOR_SIGNUP,
        method: POST,
        data: {
            requestId: getTimeStamp(),
            orgInfo: payload,
        },
    });
}
export function investorSignupLogin(payload: InvestorLoginSignupPayload) {
    return axios({
        url: API_ENDPOINTS.INVESTOR_SIGNUP_LOGIN,
        method: GET,
        params: {
            requestId: getTimeStamp(),
            emailId: payload.emailId,
        },
    });
}
export function addAssessee(data: any) {
    return axios({
        url: `${cerberusBaseUrl}${CERBERUS_API_ENDPOINTS.ASSESSEE_INVITATION}`,
        method: POST,
        data,
    });
}

export function logoutUserService() {
    return axios({
        baseURL: cerberusBaseUrl,
        url: CERBERUS_API_ENDPOINTS.LOGOUT_USER,
        method: GET,
        params: {
            requestId: getTimeStamp(),
        },
    });
}

export function verifyEmailService(payload: VerifyEmailPayload) {
    return axios({
        baseURL: cerberusBaseUrl,
        url: CERBERUS_API_ENDPOINTS.VERIFY_EMAIL,
        method: POST,
        data: payload,
    });
}

export function resendEmailVerificationService(payload: ResendVerificationEmailPayload) {
    return axios({
        baseURL: cerberusBaseUrl,
        url: CERBERUS_API_ENDPOINTS.RESEND_VERIFICATION_EMAIL,
        method: POST,
        data: payload,
    });
}

const CERBERUS_API_ENDPOINTS = {
    CREATE_V2: '/v2/access/signUp',
    LOGIN_V2: '/v2/access/login',
    GENERATE_OTP_V2: '/v2/mfa/generateOtp',
    SEND_RESET_LINK: '/v2/password/sendResetLink',
    RESET_PASSWORD: '/v2/password/reset',
    ASSESSEE_INVITATION: '/v2/access/createUser',
    LOGOUT_USER: '/v2/access/logout',
    VERIFY_EMAIL: '/v2/access/verifyEmail',
    RESEND_VERIFICATION_EMAIL: '/v2/access/resendVerificationEmail',
};

const API_ENDPOINTS = {
    FETCH_CONFIG_DATA: 'account/fetchConfigAfterLogin',
    FETCH_DOMAIN_METADATA: '/account/domain/metadata',
    SUMMON_AICA: '/summon/summonAica',
    INVESTOR_SIGNUP: '/account/org/create',
    INVESTOR_SIGNUP_LOGIN: '/account/org/checkInvestor',
};
