import React, { useEffect, useState } from 'react';
import { Input } from '../../../../../common/_custom';
import styles from './BankAccounts.module.scss';
import clsx from 'clsx';
import dayjs from 'dayjs';

import greenTick from '../../../../../assets/green-tick.svg';
import infoYellow from '../../../../../assets/info-yellow.svg';
import bluePencil from '../../../../../assets/blue-pencil.svg';

import FinboxModal from '../FinboxModal/FinboxModal';
import { bankAccountsUpdate, getFinboxRedirectUrl } from '../../../../../store/company/apiHelpers';
import UploadCard from '../UploadCard/UploadCard';
import { STEP_CHECKS } from '../../uploadJourneyUtils';
import { isEmpty } from '../../../../../utils/utils';
import { toast } from 'react-toastify';
import FetchButton from './components/FetchButton/FetchButton';
import { bank_names, FILE_FORMATS } from '../UploadCard/utils/utils';
import Dropdown from '../../../../../common/_custom/Dropdown';
import { Mixpanel } from '../../../../../utils/mixpanel';
import {
    COMPANY_BS_ADD_ANOTHER_ACCOUNT,
    COMPANY_BS_FD_ACCOUNT,
    COMPANY_BS_FD_FILE_UPLOAD,
    COMPANY_BS_OD_ACCOUNT,
    COMPANY_BS_OD_FILE_UPLOAD,
    COMPANY_STATEMENT_UPLOAD,
} from '../../../../../utils/constants/mixpanelEvents/companyEvents';
import { orangePencil } from '../../../../../assets/hostedassets';
import PencilIcon from '../../../../../assets/Svg/PencilIcon';

export const BANK_NAME = 'bankName';
export const BANK_STMTS = 'bankStatements';
export const HAS_OD = 'hasOD';
export const HAS_FD = 'hasFD';
export const OD_SUPPORTING_DOCS = 'odSupportingDocs';
export const FD_SUPPORTING_DOCS = 'fdSupportingDocs';
export const ENTITY_REF_ID = 'entityReferenceId';
export const ENTITY_TYPE = 'entityType';
export const OD_LIMIT = 'odLimit';
export const FD_LIMIT = 'fdLimit';
export const IS_NEW_ACCOUNT = 'isNewAccount';
export const BANK_ID = 'bankId';

export const FETCH_BTN_STATES = {
    INITIAL: 'INITIAL',
    SHOW_FINBOX: 'SHOW_FINBOX',
    EXIT_FINBOX: 'EXIT_FINBOX',
    UPLOAD_MANUALLY: 'UPLOAD_MANUALLY',
    CONNECTED: 'CONNECTED',
};

const { INITIAL, SHOW_FINBOX, EXIT_FINBOX, UPLOAD_MANUALLY, CONNECTED } = FETCH_BTN_STATES;
const bank_names_options = bank_names.map((bank) => ({ id: bank, label: bank }));

function BankAccounts({
    bankAccounts,
    assesseeOrgId,
    fetchOnboardingData,
    uploadContainerRef,
    categoryId,
    flowId,
    userType,
}) {
    const [newAccountData, setNewAccountData] = useState<any>(null); //form data for new account, or account being edited currently

    const [fetchButtonState, setFetchButtonState] = useState(INITIAL);
    const [existingAccounts, setExistingAccounts] = useState([]); //existing accounts to be shown as collapsed items
    // const [expandedCardIndex, setExpandedCardIndex] = useState(null); //to decide which bank card is expanded
    const [editingCardIndex, setEditingCardIndex] = useState(null); //to decide which bank account is being edited

    const [finboxUrl, setFinboxUrl] = useState(null);
    const formActive = !!newAccountData;
    const allAcctDetailsEntered = !!newAccountData?.[BANK_NAME];

    useEffect(() => {
        if (!finboxUrl) getFinboxRedirectingUrl();
    }, [finboxUrl]);

    useEffect(() => {
        if (bankAccounts?.length) {
            setExistingAccounts(bankAccounts);
            const selectedEditingAccountIndex = bankAccounts?.findIndex?.(
                (account) => account?.[BANK_ID] === newAccountData?.[BANK_ID],
            );
            if (selectedEditingAccountIndex >= 0)
                updateCurrentAccountBeingEdited(
                    bankAccounts[selectedEditingAccountIndex],
                    selectedEditingAccountIndex,
                );
        } else initialiseNewAccount();
    }, [bankAccounts]);

    useEffect(() => {
        if (newAccountData) {
            if (newAccountData?.[ENTITY_REF_ID]) setFetchButtonState(CONNECTED);
            else if (newAccountData?.[BANK_STMTS]?.length) setFetchButtonState(UPLOAD_MANUALLY);
        }
    }, [newAccountData]);

    const scrollToTop = () => {
        uploadContainerRef?.current?.scrollTo?.({
            top: 0,
            behavior: 'smooth',
        });
    };
    const initialiseNewAccount = () => {
        setEditingCardIndex(null);
        setNewAccountData({
            [BANK_NAME]: '',
            [BANK_STMTS]: [],
            [BANK_ID]: '',
            [HAS_OD]: false,
            [OD_LIMIT]: '',
            [OD_SUPPORTING_DOCS]: [],
            [HAS_FD]: false,
            [FD_LIMIT]: '',
            [FD_SUPPORTING_DOCS]: [],
            [IS_NEW_ACCOUNT]: true,
        });
        setFetchButtonState(INITIAL);
        scrollToTop();
        Mixpanel.track(COMPANY_BS_ADD_ANOTHER_ACCOUNT);
    };

    const updateCurrentAccountBeingEdited = (selectedEditingAccount, editingIndex) => {
        setEditingCardIndex(editingIndex);
        setNewAccountData({
            ...selectedEditingAccount,
            [IS_NEW_ACCOUNT]: false,
            [HAS_OD]: newAccountData[HAS_OD] || selectedEditingAccount[HAS_OD] || false,
            [OD_LIMIT]: newAccountData[OD_LIMIT] || selectedEditingAccount[OD_LIMIT] || '',
            [HAS_FD]: newAccountData[HAS_FD] || selectedEditingAccount[HAS_FD] || false,
            [FD_LIMIT]: newAccountData[FD_LIMIT] || selectedEditingAccount[FD_LIMIT] || '',
        });
    };

    const onChangeAccountForm = (key, value) => {
        setNewAccountData((data) => ({
            ...data,
            [key]: value,
        }));
    };

    const onEditClick = (accountData, index) => {
        const selectedBankAccount = bank_names_options.find(
            (bank) => bank.label === accountData[BANK_NAME],
        );
        setNewAccountData({
            ...accountData,
            [BANK_NAME]: selectedBankAccount?.label,
        });
        setEditingCardIndex(index);
        scrollToTop();
    };

    const getFinboxRedirectingUrl = async () => {
        const obj = {
            from_date: dayjs().subtract(11, 'months').format('01/MM/YYYY'),
            to_date: dayjs().subtract(1, 'days').format('DD/MM/YYYY'),
            link_id: assesseeOrgId,
        };
        // if (bank_name) obj.bank_name = bank_name; ALREADY COMMENTED OUT

        const redirect_url = await getFinboxRedirectUrl(obj);
        redirect_url && setFinboxUrl(redirect_url);
    };

    const onSuccess = (payload: any) => {
        const updatedAccountData = {
            ...newAccountData,
            [ENTITY_REF_ID]: payload.entityId,
            [ENTITY_TYPE]: 'SDK',
        };
        setFetchButtonState(CONNECTED);
        onSaveClick(updatedAccountData);
    };

    const onExit = () => {
        setFetchButtonState(EXIT_FINBOX);
    };

    const onError = () => {};

    const onSaveClick = async (
        accountDataToSave,
        bankStmtFile: null | Object = null,
        odFile: null | Object = null,
        fdFile: null | Object = null,
    ) => {
        if (isEmpty(accountDataToSave[BANK_NAME])) {
            toast.error('Bank Name cannot be empty.');
            return;
        }
        const isFileUpload = !!(
            bankStmtFile ||
            odFile ||
            fdFile ||
            (!newAccountData[ENTITY_REF_ID] && accountDataToSave[ENTITY_REF_ID])
        );
        await bankAccountsUpdate(
            {
                assesseeOrgId: assesseeOrgId,
                bankName: accountDataToSave[BANK_NAME],
                entityReferenceId: accountDataToSave[ENTITY_REF_ID] || undefined,
                entityType: accountDataToSave[ENTITY_TYPE] || 'MANUAL',
                entityDetail: 'MANUAL',
                ...(bankStmtFile && { bankStatement: bankStmtFile }),
                ...(odFile && {
                    odSupportingDoc: odFile,
                    hasOD: true,
                    odLimit: accountDataToSave[OD_LIMIT],
                }),
                ...(fdFile && {
                    fdSupportingDoc: fdFile,
                    hasFD: true,
                    fdLimit: accountDataToSave[FD_LIMIT],
                }),
                // ...(accountDataToSave[IS_NEW_ACCOUNT] && { isNewAccount: true }),
                ...(accountDataToSave[BANK_ID] && { bankId: accountDataToSave[BANK_ID] }),
            },
            {
                onSuccess: (res) => {
                    setNewAccountData((data) => ({
                        ...data,
                        [BANK_ID]: res.bankId,
                        [BANK_STMTS]: res.bankStatements,
                    }));
                    if (!isFileUpload) {
                        setNewAccountData(null);
                        setEditingCardIndex(null);
                    }
                    fetchOnboardingData();
                },
            },
        );
    };

    const updateBankStatementAttachment = async (fileData, isDelete = false) => {
        const newFileAdded = {
            docId: fileData?.docId,
            docName: isDelete ? fileData?.docName : fileData?.fileName,
            isDeleted: isDelete,
        };
        await onSaveClick(newAccountData, newFileAdded, null, null); //only add bank stmt file
        Mixpanel.track(COMPANY_STATEMENT_UPLOAD);
    };

    const updateODAttachment = async (fileData, isDelete = false) => {
        const newFileAdded = {
            docId: fileData?.docId,
            docName: isDelete ? fileData?.docName : fileData?.fileName,
            isDeleted: isDelete,
        };
        await onSaveClick(newAccountData, null, newFileAdded, null);
        Mixpanel.track(COMPANY_BS_OD_FILE_UPLOAD);
    };

    const updateFDAttachment = async (fileData, isDelete = false) => {
        const newFileAdded = {
            docId: fileData?.docId,
            docName: isDelete ? fileData?.docName : fileData?.fileName,
            isDeleted: isDelete,
        };
        await onSaveClick(newAccountData, null, null, newFileAdded);
        Mixpanel.track(COMPANY_BS_FD_FILE_UPLOAD);
    };

    const getAccountForm = (accountData, index) => {
        const isEditing = index === null;
        const isNewAccount = index === null;
        return (
            <div className={styles.BankAccounts} key={`$accountData-${index}`}>
                <div className={styles.Top}>
                    <div>
                        {isNewAccount
                            ? 'Bank Account (with OD/FD details)'
                            : `${accountData?.[BANK_NAME]}`}
                    </div>
                    <div className={styles.Right}>
                        {!isEditing && (
                            <>
                                <div
                                    className={styles.EditBtn}
                                    onClick={() => {
                                        onEditClick(accountData, index);
                                        // setNewAccountData(accountData);
                                        // setEditingCardIndex(index);
                                    }}
                                >
                                    <PencilIcon
                                        colour={'var(--primary-text-colour)'}
                                        height="14"
                                        width="14"
                                    />
                                    Edit
                                </div>
                            </>
                        )}
                        {STEP_CHECKS.isAccountConnected(accountData) ? (
                            <img src={greenTick} alt="dd" height="20px" />
                        ) : (
                            <>
                                {!accountData[IS_NEW_ACCOUNT] && (
                                    <img src={infoYellow} alt="dd" height="20px" />
                                )}
                            </>
                        )}
                    </div>
                </div>

                {isEditing && (
                    <>
                        <div className={styles.InputRows}>
                            <Dropdown
                                label="Bank Name"
                                optionsList={bank_names_options}
                                selectedOption={
                                    bank_names_options?.find(
                                        (option) => option.label === accountData[BANK_NAME],
                                    ) || null
                                }
                                itemClickHandler={(selectedOption) =>
                                    onChangeAccountForm(BANK_NAME, selectedOption.label)
                                }
                                placeholder="Bank Name"
                                disabled={STEP_CHECKS.isAccountConnected(accountData)}
                                enableSearch
                                hideOtherOptionWhenNoMatch={true}
                            />
                        </div>

                        <div className={styles.FetchContainer}>
                            <FetchButton
                                fetchButtonState={fetchButtonState}
                                setFetchButtonState={setFetchButtonState}
                                disabled={!allAcctDetailsEntered}
                                hideRetryBankFetch={accountData?.[BANK_STMTS]?.length}
                                userType={userType}
                                // checkForExistingAccount={() => checkForExistingAccount(accountData)}
                            />
                            <UploadCard
                                disableDelete
                                allowMultiple
                                assesseeOrgId={assesseeOrgId}
                                metadataUpdate={updateBankStatementAttachment}
                                fileDeleteHandler={(fileData) =>
                                    updateBankStatementAttachment(fileData, true)
                                }
                                acceptedFormats={FILE_FORMATS.PDF}
                                // categoryId={19}
                                categoryId={categoryId}
                                flowId={flowId}
                                // flowId={16}
                                existingFiles={accountData?.[BANK_STMTS]}
                                disabled={!allAcctDetailsEntered}
                                // accountNumber={accountData[ACCOUNT_NUMBER]}
                                // stopUpload={() => checkForExistingAccount(accountData)}
                            />
                        </div>

                        <div
                            className={clsx(styles.CheckboxRow, {
                                [styles.CheckboxRowDisabled]: !allAcctDetailsEntered,
                            })}
                        >
                            <input
                                type="checkbox"
                                checked={accountData[HAS_OD] || false}
                                onChange={(e) => {
                                    e.target.checked && Mixpanel.track(COMPANY_BS_OD_ACCOUNT);
                                    onChangeAccountForm(HAS_OD, e.target.checked);
                                }}
                                disabled={
                                    !allAcctDetailsEntered ||
                                    accountData[OD_SUPPORTING_DOCS]?.length
                                }
                            />
                            Has Overdraft Facility (OD)
                        </div>
                        {accountData[HAS_OD] && (
                            <div className={styles.OverdraftBox}>
                                <Input
                                    onChange={(val) => onChangeAccountForm(OD_LIMIT, val)}
                                    placeholder="Overdraft Limit (INR)"
                                    value={accountData?.[OD_LIMIT]}
                                    disabled={!isEditing}
                                    style={{ marginTop: '0' }}
                                    inputStyle={{ background: 'white' }}
                                    labelStyle={{ fontSize: '12px' }}
                                />
                                <div className={styles.UploadText}>
                                    Upload OD Supporting Document like Sanction Letter or Statement
                                </div>
                                <UploadCard
                                    allowMultiple
                                    disableDelete
                                    assesseeOrgId={assesseeOrgId}
                                    // accountNumber={accountData[ACCOUNT_NUMBER]}
                                    metadataUpdate={updateODAttachment}
                                    // categoryId={19}
                                    categoryId={categoryId}
                                    // flowId={16}
                                    flowId={flowId}
                                    existingFiles={accountData[OD_SUPPORTING_DOCS]}
                                    fileDeleteHandler={(fileData) =>
                                        updateODAttachment(fileData, true)
                                    }
                                    acceptedFormats={FILE_FORMATS.PDF}
                                    disabled={!allAcctDetailsEntered}
                                    // stopUpload={() => checkForExistingAccount(accountData)}
                                />
                            </div>
                        )}

                        <div
                            className={clsx(styles.CheckboxRow, {
                                [styles.CheckboxRowDisabled]: !allAcctDetailsEntered,
                            })}
                        >
                            <input
                                type="checkbox"
                                checked={accountData[HAS_FD] || false}
                                onChange={(e) => {
                                    e.target.checked && Mixpanel.track(COMPANY_BS_FD_ACCOUNT);
                                    onChangeAccountForm(HAS_FD, e.target.checked);
                                }}
                                disabled={
                                    !allAcctDetailsEntered ||
                                    accountData[FD_SUPPORTING_DOCS]?.length
                                }
                            />
                            Has Fixed Deposit (FD)
                        </div>
                        {accountData[HAS_FD] && (
                            <div className={styles.OverdraftBox}>
                                <Input
                                    onChange={(val) => onChangeAccountForm(FD_LIMIT, val)}
                                    placeholder="Fixed Deposit Amount (INR)"
                                    value={accountData?.[FD_LIMIT]}
                                    disabled={!isEditing}
                                    style={{ marginTop: '0' }}
                                    inputStyle={{ background: 'white' }}
                                    labelStyle={{ fontSize: '12px' }}
                                />
                                <div className={styles.UploadText}>
                                    Upload Fixed Deposit receipt (last 12 month).
                                </div>
                                <UploadCard
                                    allowMultiple
                                    disableDelete
                                    assesseeOrgId={assesseeOrgId}
                                    metadataUpdate={updateFDAttachment}
                                    // accountNumber={accountData[ACCOUNT_NUMBER]}
                                    // categoryId={19}
                                    categoryId={categoryId}
                                    // flowId={16}
                                    flowId={flowId}
                                    existingFiles={accountData[FD_SUPPORTING_DOCS]}
                                    fileDeleteHandler={(fileData) =>
                                        updateFDAttachment(fileData, true)
                                    }
                                    acceptedFormats={FILE_FORMATS.PDF}
                                    disabled={!allAcctDetailsEntered}
                                    // stopUpload={() => checkForExistingAccount(accountData)}
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
        );
    };

    return (
        <>
            {newAccountData && <>{getAccountForm(newAccountData, null)}</>}

            {existingAccounts?.map?.((accountData, index) => (
                <div key={index}>
                    {index !== editingCardIndex && <>{getAccountForm(accountData, index)}</>}
                </div>
            ))}

            {!newAccountData?.[IS_NEW_ACCOUNT] && (
                <div className={styles.AddNewAccount}>
                    <div className={styles.Title}>Do you have other bank accounts?</div>
                    <div className={styles.Text}>
                        Please add all your company bank accounts for us to process your
                        application.
                    </div>

                    <div className={styles.AddBtn} onClick={() => initialiseNewAccount()}>
                        Add Another Account
                    </div>
                </div>
            )}

            {fetchButtonState === SHOW_FINBOX && (
                <FinboxModal
                    showFinbox
                    finboxUrl={finboxUrl}
                    onSuccess={onSuccess}
                    onExit={onExit}
                    onError={onError}
                />
            )}
        </>
    );
}

export default BankAccounts;
