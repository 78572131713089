const toolbarGroups = [
    [
        {
            id: 1,
            format: 'fontFamily',
            type: 'dropdown',
            options: [
                { text: 'Sans Serif', value: 'sans' },
                { text: 'Serif', value: 'serif' },
                { text: 'MonoSpace', value: 'monospace' },
            ],
        },
        {
            id: 2,
            format: 'fontSize',
            type: 'dropdown',
            options: [
                { text: 'Small', value: 'small' },
                { text: 'Normal', value: 'normal' },
                { text: 'Medium', value: 'medium' },
                { text: 'Huge', value: 'huge' },
            ],
        },
    ],
    [
        {
            id: 3,
            format: 'bold',
            type: 'mark',
            icon: 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/textEditor/bold-icon.svg',
        },
        {
            id: 4,
            format: 'italic',
            type: 'mark',
            icon: 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/textEditor/italic.svg',
        },
        {
            id: 5,
            format: 'underline',
            type: 'mark',
            icon: 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/textEditor/underline.svg',
        },
        {
            id: 6,
            format: 'strikethrough',
            type: 'mark',
            icon: 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/textEditor/strikethrough.svg',
        },
    ],
    [
        {
            id: 7,
            format: 'color',
            type: 'color-picker',
        },
        {
            id: 8,
            format: 'bgColor',
            type: 'color-picker',
        },
    ],
    // [
    //     {
    //         id: 9,
    //         format: 'superscript',
    //         type: 'mark',
    //     },
    //     {
    //         id: 10,
    //         format: 'subscript',
    //         type: 'mark',
    //     },
    // ],
    // [
    //     {
    //         id: 11,
    //         format: 'headingOne',
    //         type: 'block',
    //     },
    //     {
    //         id: 12,
    //         format: 'headingTwo',
    //         type: 'block',
    //     },
    //     {
    //         id: 13,
    //         format: 'headingThree',
    //         type: 'block',
    //     },
    //     {
    //         id: 14,
    //         format: 'blockquote',
    //         type: 'block',
    //     },
    // ],
    [
        {
            id: 15,
            format: 'orderedList',
            type: 'block',
            icon: 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/textEditor/list-numbered.svg',
        },
        {
            id: 16,
            format: 'unorderedList',
            type: 'block',
            icon: 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/textEditor/list-bulleted.svg',
        },
    ],
    [
        {
            id: 17,
            format: 'alignLeft',
            type: 'block',
            icon: 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/justify-left.svg',
        },
        {
            id: 18,
            format: 'alignCenter',
            type: 'block',
            icon: 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/justify-center.svg',
        },
        {
            id: 19,
            format: 'alignRight',
            type: 'block',
            icon: 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/justify-right.svg',
        },
    ],
    [
        // {
        //     id: 20,
        //     format: 'link',
        //     type: 'link',
        //     icon: 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/textEditor/link.svg',
        // },
        // {
        //     id: 21,
        //     format: 'image',
        //     type: 'embed',
        //     icon: 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/textEditor/image.svg',
        // },
        {
            id: 23,
            type: 'table',
            icon: 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/textEditor/table.svg',
        },
    ],
    // [
    //     {
    //         id: 25,
    //         type: 'id',
    //     },
    //     {
    //         id: 26,
    //         type: 'equation',
    //     },
    //     {
    //         id: 27,
    //         type: 'codeToText',
    //     },
    // ],
];

export default toolbarGroups;
