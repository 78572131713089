import axios from 'axios';
import { toast } from 'react-toastify';
import { GET, polusBaseUrl, POST } from '../../../utils/axios-interceptor';
import { getTimeStamp } from '../../../utils/dateUtils';

export function getCibilOverviewData(payload) {
    let queries = `?requestId=${getTimeStamp()}&assesseeId=${payload.assesseeOrgId}`;
    if (payload.directorId) {
        queries = `?requestId=${getTimeStamp()}&assesseeId=${payload.assesseeOrgId}&directorId=${
            payload.directorId
        }`;
    }
    return axios({
        baseURL: polusBaseUrl,
        url: API_ENDPOINTS.GET_CIBIL_OVERVIEW_DATA + queries,
        method: GET,
    });
}

export function getDirectorsOverviewData(payload) {
    return axios({
        baseURL: polusBaseUrl,
        url:
            API_ENDPOINTS.GET_DIRECTORS_OVERVIEW_DATA +
            `?requestId=${getTimeStamp()}&orgId=${payload.orgId}`,
        method: GET,
    });
}

// export

export const downloadData = async (data: object, responseType: any, url: string): Promise<any> => {
    return axios({
        baseURL: polusBaseUrl,
        url,
        method: POST,
        data,
        responseType,
    });
};

export async function downloadCibilNeedInfoSheet(payload) {
    try {
        const response = await downloadData(
            payload,
            'arraybuffer',
            API_ENDPOINTS.DOWNLOAD_CIBIL_SHEET,
        );
        if (response) {
            const url = window.URL.createObjectURL(
                new Blob([response.data], { type: 'application/octet-stream' }),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Cibil_Query_${payload?.name}.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
        }
    } catch (error) {
        toast.error('Please try in sometime');
    }
}

export function getCreditBureauSummaryTableService(payload) {
    return axios({
        baseURL: polusBaseUrl,
        url: API_ENDPOINTS.GET_CREDIT_BUREAU_SUMMARY_TABLE,
        method: GET,
        params: {
            requestId: getTimeStamp(),
            ...payload,
        },
    });
}

const API_ENDPOINTS = {
    GET_CREDIT_BUREAU_DATA: '/profile/assessee/overview/main',
    GET_CIBIL_OVERVIEW_DATA: '/insights/cibilOverviewData',
    GET_DIRECTORS_OVERVIEW_DATA: '/unifier/fetchDirectorOverviewList',
    DOWNLOAD_CIBIL_SHEET: '/insights/generate-need-info-cibil-sheet',
    GET_CREDIT_BUREAU_SUMMARY_TABLE: '/cibil/table',
};
