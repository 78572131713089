import React, { useEffect, useState } from 'react';
import styles from './MagicUpload.module.scss';
import { useSearchParams } from 'react-router-dom';
import DrawerContent from './components/DrawerContent';
import useMagicUploadLogic from './useMagicUploadLogic';

type MagicUploadProps = {};
const MagicUpload: React.FC<MagicUploadProps> = (props: MagicUploadProps) => {
    const { isVisible, toggleDrawer } = useMagicUploadLogic();
    return (
        <>
            <div
                data-testid="backdrop"
                className={`${styles.backdrop} ${isVisible ? styles.show : ''}`}
                onClick={toggleDrawer}
            ></div>
            <div className={`${styles.drawer} ${isVisible ? styles.show : ''}`}>
                <DrawerContent toggleDrawer={toggleDrawer} />
            </div>
        </>
    );
};

export default MagicUpload;
