import { useState } from 'react';
import arrowBackPurple from '../../assets/arrow-back-purple.svg';
import { arrowOrange } from '../../assets/hostedassets';
import { EMAIL } from '../../utils/constants/companyLogin';
import { emailRegex } from '../../utils/utils';
import { Button, Input } from '../_custom';
import Form from '../_custom/Form/Form';
import styles from './SendPasswordLink.module.scss';
import ArrowIcon from '../../assets/Svg/ArrowIcon';

function SendPasswordLink({ formData, changeValue, onSendPasswordLinkClick, onBackClick }) {
    const [sending, setSending] = useState(false);

    const handleClick = () => {
        setSending(true);
        onSendPasswordLinkClick(
            () => {
                setSending(false);
                onBackClick();
            },
            () => {
                setSending(false);
            },
        );
    };

    const handleImageKeyDown = (e) => {
        if (e.key === ' ' || e.key === 'Spacebar') {
            onBackClick();
            e.preventDefault();
        }
    };
    return (
        <div className={styles.SendPasswordLink}>
            <Form>
                <ArrowIcon
                    color="var(--primary-text-colour)"
                    width="20"
                    height="20"
                    onClick={onBackClick}
                    tabIndex={0}
                    role="button"
                    onKeyDown={handleImageKeyDown}
                    className={styles.Back}
                />
                <div className={styles.Title}>Forgot Password</div>
                <Input
                    placeholder="Email Address"
                    // type="password"
                    value={formData[EMAIL]}
                    onChange={(val: string) => changeValue(val, EMAIL)}
                    // errorMessage={EMAIL_ERROR_MSG}
                    // isValid={!errors[EMAIL]}
                />

                <Button
                    loading={sending}
                    text="Send Password Link"
                    loadingText="Please Wait"
                    onClick={handleClick}
                    style={{ marginTop: '40px' }}
                    disabled={!emailRegex.test(formData[EMAIL])}
                    variant="primary"
                />
            </Form>
        </div>
    );
}

export default SendPasswordLink;
