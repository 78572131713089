import clsx from 'clsx';
import React, { useMemo, useState } from 'react';

import Checkbox from '../../_custom/Checkbox/Checkbox';

import { GROUP_ICON, WIFI_TETHERING } from '../../../assets/hostedassets';
import ToggleButton from '../../ToggleButton/ToggleButton';

import styles from './InvestorDealShare.module.scss';

type Props = {
    source: string;
    lenderList: { id: string; name: string }[];
    networkLabel: string;
    totalInvestors: number;
    selectedInvestors: string[];
    updateSelectedInvestors: (sharedInvestors: string[]) => void;
    showInvestorList?: boolean;
    style?: React.CSSProperties;
};

const InvestorDealShare: React.FC<Props> = ({
    source,
    lenderList,
    networkLabel,
    totalInvestors,
    selectedInvestors,
    updateSelectedInvestors,
    showInvestorList,
    style,
}) => {
    const [shared, setShared] = useState<boolean>(true);

    const nonRecurInvestors = useMemo(() => {
        return selectedInvestors.filter((investor) => investor !== 'RECUR');
    }, [selectedInvestors]);

    const networkLogo = useMemo(() => {
        switch (source) {
            case 'recur-investor-toggle':
                return WIFI_TETHERING;
            case 'investor-toggle':
            default:
                return GROUP_ICON;
        }
    }, [source]);

    const toggleShare = () => {
        if (source === 'recur-investor-toggle') {
            if (!shared) {
                updateSelectedInvestors([...selectedInvestors, 'RECUR']);
            } else {
                updateSelectedInvestors(
                    selectedInvestors.filter((investor) => investor !== 'RECUR'),
                );
            }
        } else if (selectedInvestors.includes('RECUR')) {
            updateSelectedInvestors(['RECUR']);
        } else {
            updateSelectedInvestors([]);
        }
        setShared(!shared);
    };

    const onInvestorSelect = (option: { id: string; name: string }, checked: boolean) => {
        const newSelectedInvestors = checked
            ? [...selectedInvestors, option.id]
            : selectedInvestors.filter((selectedInvestor) => selectedInvestor !== option.id);
        updateSelectedInvestors(newSelectedInvestors);
    };

    const selectAllInvestors = (checked: boolean) => {
        if (selectedInvestors.includes('RECUR')) {
            updateSelectedInvestors(
                checked ? ['RECUR', ...lenderList.map((investor) => investor.id)] : ['RECUR'],
            );
            return;
        }
        const newSelectedInvestors = checked ? lenderList.map((investor) => investor.id) : [];

        updateSelectedInvestors(newSelectedInvestors);
    };

    return (
        <div className={styles.InvestorDealShareContainer} style={style}>
            <div
                className={clsx(styles.InvestorDealShare, {
                    [styles.Shared]: shared,
                    [styles.RecurInvestor]: source === 'recur-investor-toggle',
                })}
            >
                <div className={styles.NetworkContainer}>
                    <div className={styles.NetworkLogo}>
                        <img src={networkLogo} alt="Network Logo" />
                    </div>

                    <div className={styles.NetworkDetails}>
                        <div className={styles.NetworkLabel}>{networkLabel}</div>
                        <div className={styles.NetworkStats}>{totalInvestors} investors</div>
                    </div>
                </div>

                {/* <div className={styles.NetworkShare}>
                    <span className={styles.ShareLabel}>Share</span>
                    <ToggleButton checked={shared} onChange={toggleShare} />
                </div> */}
            </div>
            {shared && showInvestorList && (
                <div className={styles.InvestorList}>
                    <div className={styles.SelectAll}>
                        <Checkbox
                            label="Select All"
                            checked={nonRecurInvestors.length === lenderList.length}
                            onChange={selectAllInvestors}
                            customStyles={{
                                width: '100%',
                                padding: '16px 24px',
                                fontSize: '12px',
                                fontWeight: 500,
                                color: '#979797',
                            }}
                        />
                        <div className={styles.SelectedText}>
                            {nonRecurInvestors.length} investors selected
                        </div>
                    </div>
                    {lenderList.map((investor) => (
                        <Checkbox
                            key={`investor-select-${investor.id}`}
                            label={investor.name}
                            checked={nonRecurInvestors.includes(investor.id)}
                            onChange={(checked) => onInvestorSelect(investor, checked)}
                            customStyles={{
                                width: '100%',
                                padding: '22px 24px',
                                fontSize: '12px',
                                fontWeight: 500,
                                color: '#000000',
                            }}
                            isMultiSelect
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default InvestorDealShare;
