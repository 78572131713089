import UploadCard from '../UploadCard/UploadCard';
import { FILE_FORMATS } from '../UploadCard/utils/utils';
import { useAppSelector } from '../../../../../app/hooks';
import { UPLOAD_COMPONENTS } from '../../uploadJourneyUtils';
import useHandleDirectorKycDocs from './useHandleDirectorKycDocs';
import AddDirectorModal from './Components/AddDirectorModal';
import { plusPurple, plusOrange } from '../../../../../assets/hostedassets';
import styles from './DirectorKyc.module.scss';
import { useEffect, useState } from 'react';
import PlusIcon from '../../../../../assets/Svg/PlusIcon';

type CompanyKYCProps = {
    assesseeOrgId: string;
    categoryId: string;
    flowId: number;
    constitutionType: string;
};

const DirectorKyc = (props: CompanyKYCProps) => {
    const configData = useAppSelector((state) => state.company.config);
    const [tabData, setTabData] = useState<any>([]);
    const { assesseeOrgId, categoryId, flowId, constitutionType } = props;
    const {
        updateKycDocs,
        directorDetails = [],
        showAddDirector,
        openAddDirectorModal,
        closeAddDirectorModal,
        newDirectorDetails,
        handleNewDirectorDetails,
        addDirector,
        isAddingDirector,
    } = useHandleDirectorKycDocs();

    useEffect(() => {
        if (constitutionType && configData?.tabConfigMetadata) {
            let acceptedTabs = configData?.tabConfigMetadata[constitutionType]?.directorKyc;
            let filteredTabs = configData?.tabConfigs[
                UPLOAD_COMPONENTS.DIRECTOR_KYC
            ].components?.map((tab) => {
                return {
                    ...tab,
                    formFields: tab.formFields.filter((field) =>
                        acceptedTabs.includes(field.identifier),
                    ),
                };
            });
            setTabData(filteredTabs);
        } else {
            setTabData(configData?.tabConfigs[UPLOAD_COMPONENTS.DIRECTOR_KYC].components);
        }
    }, []);

    return (
        <div className={styles.Box}>
            <h3 className={styles.Title}>Director KYC</h3>
            <p className={styles.SubTitle}>Add below required KYC documents</p>
            {directorDetails.map((director) => {
                return (
                    <>
                        <div className={styles.Separator} />
                        <h5 className={styles.DirectorHeading}>KYC for {director.name}</h5>
                        {tabData.map((tab) => {
                            return tab.formFields.map((field) => {
                                return (
                                    <UploadCard
                                        existingFiles={director.documentsList}
                                        assesseeOrgId={assesseeOrgId}
                                        categoryId={Number(categoryId)}
                                        kycCategory={field.label}
                                        kycDescription={field.label}
                                        flowId={flowId}
                                        kycFileFlow
                                        metadataUpdate={(res) => {
                                            updateKycDocs(
                                                res,
                                                field,
                                                assesseeOrgId,
                                                false,
                                                director.id,
                                            );
                                        }}
                                        kycCategoryId={director.id}
                                        fileDeleteHandler={(file) => {
                                            updateKycDocs(
                                                file,
                                                field,
                                                assesseeOrgId,
                                                true,
                                                director.id,
                                            );
                                        }}
                                        acceptedFormats={[
                                            ...FILE_FORMATS.EXCEL_PDF,
                                            ...FILE_FORMATS.JSON,
                                        ]}
                                        onClickFunction={() => {
                                            // Mixpanel.track(COMPANY_DOCS_UPLOAD_ADDITIONAL_CLICK);
                                        }}
                                    />
                                );
                            });
                        })}
                    </>
                );
            })}
            <div className={styles.Separator} />
            <div className={styles.AddDirector} onClick={openAddDirectorModal}>
                <button className={styles.AddDirectorBtn}>
                    <PlusIcon colour={'var(--primary-text-colour)'} height="10" width="10" />
                    Add Director
                </button>
            </div>
            {showAddDirector && (
                <AddDirectorModal
                    newDirectorDetails={newDirectorDetails}
                    showAddDirector={showAddDirector}
                    closeAddDirectorModal={closeAddDirectorModal}
                    handleNewDirectorDetails={handleNewDirectorDetails}
                    addDirector={addDirector}
                    isAddingDirector={isAddingDirector}
                />
            )}
        </div>
    );
};

export default DirectorKyc;
