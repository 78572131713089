import styles from './UploadCard.module.scss';
import { useEffect, useState } from 'react';
import DragDropFileHandler from './components/DragDropFileHandler/DragDropFileHandler';
import { FILE_FORMATS, fileWithDescriptionDropdownOptions } from './utils/utils';
import {
    downloadFile,
    getSignedUrl,
    saveDocMetaDataInDocService,
    uploadFile,
} from './utils/api/helpers';
import SingleFile from './components/SingleFile/SingleFile';
import { toast } from 'react-toastify';
import FileWithDescription from './components/FileWithDescriptionHandler/FileWithDescription';
import { useAppSelector } from '../../../../../app/hooks';
import { updateUploadingStatus } from '../../../../../store/company/action';
import { useDispatch } from 'react-redux';
import KycFileUpload from './components/KycFileUpload/KycFileUpload';
import { isEmpty } from '../../../../../utils/utils';

interface UploadCardProps {
    disabled?: boolean;
    uploadText?: string;
    existingFiles?: [];
    acceptedFormats?: string[];
    assesseeOrgId: string;
    isInvestor?: boolean;
    hideUpload?: boolean;
    kycFileFlow?: boolean;
    categoryId: number;
    flowId: number;
    allowMultiple?: boolean;
    accountNumber?: string;
    disableDelete?: boolean;
    metadataUpdate?: Function;
    fileDeleteHandler?: Function;
    stopUpload?: Function;
    onClickFunction?: Function;
    fileWithDescription?: boolean;
    showButton?: boolean;
    buttonText?: string;
    onBtnClickFunction?: Function;
    isBtnDisabled?: { [key: string]: boolean };
    overrideLoadingState?: boolean;
    btnLoading?: { [key: string]: boolean };
    style?: React.CSSProperties;
    kycCategory?: string;
    kycCategoryId?: string;
    kycDescription?: string;
    fileNamePrefix?: string;
    isNotVisible?: boolean;
    dropDownOptions?: string[];
}

function UploadCard({
    disabled = false,
    btnLoading = {},
    isInvestor,
    existingFiles = [],
    uploadText = '',
    acceptedFormats = FILE_FORMATS.EXCEL_PDF,
    assesseeOrgId,
    metadataUpdate,
    categoryId,
    fileDeleteHandler,
    hideUpload = false,
    flowId,
    allowMultiple = false,
    kycFileFlow = false,
    accountNumber = '',
    disableDelete = false,
    stopUpload = () => false,
    fileWithDescription = false,
    onClickFunction,
    showButton = false,
    overrideLoadingState = false,
    isBtnDisabled = {},
    buttonText = '',
    onBtnClickFunction,
    kycCategory,
    style,
    kycCategoryId,
    kycDescription,
    fileNamePrefix = '',
    isNotVisible = false,
    dropDownOptions,
}: UploadCardProps) {
    const FILE_SIZE_MB = 50;
    const FILE_SIZE_MB_KB = FILE_SIZE_MB * 1024 * 1024;
    const [filesToUpload, setFilesToUpload] = useState<any>([]);
    const [fileDescription, setFileDescription] = useState('');
    const [uploadingFiles, setUploadingFiles] = useState(false);
    const isUploading = useAppSelector((state) => state.company.isUploading);
    const [files, setFiles] = useState<any>([]);
    const platformData = useAppSelector((state) => state.user.domainMetadata);
    const dispatch = useDispatch();

    useEffect(() => {
        if (filesToUpload?.length) {
            beginFileUpload(filesToUpload?.[0]);
        }
    }, [filesToUpload]);

    const Filer = (capturedFiles) => {
        if (stopUpload?.()) return;
        const filesArr = [...capturedFiles];
        if (filesArr.length > 1 && !allowMultiple) {
            toast.error('Multiple files not allowed');
            return;
        }
        const { filteredFiles } = handleFilesValidation(filesArr);

        let filesToBeMapped = filteredFiles;
        const files = filesToBeMapped?.map((file, index) => {
            return {
                file: file,
                id: index + 1,
                isUploading: true,
                uploadProgress: 0,
            };
        });
        setFilesToUpload(files);
    };

    const handleFilesValidation = (files: File[]) => {
        let filteredFiles: File[] = [];
        let acceptedExtensions = '';
        if (files && files?.length) {
            filteredFiles = files.filter((file) => {
                if (file.size > FILE_SIZE_MB_KB) {
                    toast.error(`File size cannot be greater than ${FILE_SIZE_MB}Mb`);
                    return false;
                }

                return true;
            });
        }

        return { acceptedExtensions, filteredFiles };
    };

    const beginFileUpload = async (fileData) => {
        if (!fileData) return;
        getSignedUrlFromS3(fileData);
    };

    const getSignedUrlFromS3 = async (fileData) => {
        const uploadConfig = {
            assesseeOrgId: assesseeOrgId,
            categoryId: categoryId,
            mnemosyneOrgId: platformData?.investorOrgId,
            ...(!!accountNumber && { accountNumber: accountNumber }),
        };
        try {
            const signResponse = await getSignedUrl(fileData, uploadConfig);
            if (!signResponse.signedUrl) {
                toast.error('Error in uploading file.');
                setFilesToUpload((files) => {
                    let updatedFiles = [...files];
                    updatedFiles.shift();
                    return updatedFiles;
                });
                return;
            }
            mainUploadFunction(fileData, signResponse);
        } catch (err) {}
    };
    const mainUploadFunction = async (fileData, signResponse) => {
        const uploadResponse = await uploadFile(fileData?.file, signResponse?.signedUrl);
        const input_saveMetadata = {
            categoryId: categoryId,
            mnemosyneOrgId: platformData?.investorOrgId,
            fileName: fileData.file.name,
            fileSizeKb: fileData?.file?.size / 1_000,
            flowId: flowId,
            fileType: fileData.file.type,
            ...(!!accountNumber && { dk1: accountNumber }),
        };
        const docData = await saveDocMetaDataInDocService({
            input: input_saveMetadata,
            orgId: assesseeOrgId,
        });
        if (!docData?.id) {
            toast.error('Error in uploading file.');
            setFilesToUpload((files) => {
                let updatedFiles = [...files];
                updatedFiles.shift();
                return updatedFiles;
            });
            return;
        }
        const fileInfo = {
            url: uploadResponse?.url,
            fileName: fileData.file.name,
            docId: docData?.id,
            ...(fileWithDescription && { description: fileDescription }),
        };
        metadataUpdateFunction(fileInfo);
    };

    const metadataUpdateFunction = async (fileInfo) => {
        if (overrideLoadingState) dispatch(updateUploadingStatus(true));
        await metadataUpdate?.(fileInfo);
        if (!overrideLoadingState) {
            setFilesToUpload((files) => {
                let updatedFiles = [...files];
                updatedFiles.shift();
                return updatedFiles;
            });
            setFileDescription('');
        }
    };
    useEffect(() => {
        if (overrideLoadingState && !isUploading) {
            setFilesToUpload((files) => {
                let updatedFiles = [...files];
                updatedFiles.shift();
                return updatedFiles;
            });
            setFileDescription('');
        }
    }, [isUploading]);
    const onUploadCompletion = async () => {};

    return (
        <div className={styles.UploadCard} style={style}>
            {!!uploadText && (
                <div className={styles.UploadText}>
                    {uploadText}&nbsp;
                    {!!acceptedFormats && (
                        <span className={styles.AcceptedFormats}>
                            (supported file formats:{' '}
                            {acceptedFormats?.join(',')?.replaceAll('.', ' ')})
                        </span>
                    )}
                </div>
            )}

            {fileWithDescription ? (
                <>
                    <FileWithDescription
                        onChange={Filer}
                        disabled={disabled}
                        acceptedFormats={acceptedFormats}
                        fileDescription={fileDescription}
                        fileToUpload={filesToUpload?.[0]}
                        setFileDescription={setFileDescription}
                        existingFiles={existingFiles}
                        fileDeleteHandler={fileDeleteHandler}
                        downloadFile={downloadFile}
                        isDropdown
                        dropdownOptions={
                            isEmpty(dropDownOptions)
                                ? fileWithDescriptionDropdownOptions
                                : dropDownOptions
                        }
                    />
                </>
            ) : kycFileFlow ? (
                <>
                    <KycFileUpload
                        onChange={Filer}
                        kycCategoryId={kycCategoryId}
                        kycDescription={kycDescription}
                        kycCategory={kycCategory}
                        disabled={disabled}
                        acceptedFormats={acceptedFormats}
                        fileDescription={fileDescription}
                        fileToUpload={filesToUpload?.[0]}
                        setFileDescription={setFileDescription}
                        existingFiles={existingFiles}
                        fileDeleteHandler={fileDeleteHandler}
                        downloadFile={downloadFile}
                        isDropdown
                        dropdownOptions={fileWithDescriptionDropdownOptions}
                    />
                </>
            ) : (
                <>
                    {!hideUpload && (
                        <DragDropFileHandler
                            onChange={Filer}
                            disabled={disabled || filesToUpload?.length > 0}
                            acceptedFormats={acceptedFormats}
                            allowMultiple={allowMultiple}
                            onClickFunction={onClickFunction}
                        />
                    )}

                    {existingFiles?.map((file: any, index) => {
                        if (!file?.isDeleted)
                            return (
                                <SingleFile
                                    isInvestor={isInvestor}
                                    btnLoading={btnLoading}
                                    buttonText={buttonText}
                                    onClickFunction={onBtnClickFunction}
                                    showButton={showButton}
                                    isBtnDisabled={isBtnDisabled}
                                    file={file}
                                    key={index}
                                    fileDeleteHandler={fileDeleteHandler}
                                    downloadFile={downloadFile}
                                    disableDelete={disableDelete}
                                    fileNamePrefix={fileNamePrefix}
                                    isNotVisible={isNotVisible}
                                />
                            );
                    })}

                    {filesToUpload?.map((fileData, index) => (
                        <SingleFile
                            isInvestor={isInvestor}
                            btnLoading={btnLoading}
                            buttonText={buttonText}
                            onClickFunction={onBtnClickFunction}
                            showButton={showButton}
                            isBtnDisabled={!btnLoading}
                            file={fileData}
                            key={index}
                            fileDeleteHandler={fileDeleteHandler}
                            downloadFile={downloadFile}
                        />
                    ))}
                </>
            )}
        </div>
    );
}

export default UploadCard;
