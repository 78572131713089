const namespace = 'USER_';

export const RECUR_PNO_DOMAIN = 'recur.aicatech.in';
export const CONSTANTS = {
    // INTERNAL
    SET_FETCHING_DOMAIN_METADATA: `${namespace}SET_FETCHING_DOMAIN_METADATA`,
    SET_IS_CREATING_ACCOUNT: `${namespace}SET_IS_CREATING_ACCOUNT`,
    SET_IS_SAVING_APPLICATION_DETAILS: `${namespace}SET_IS_SAVING_APPLICATION_DETAILS`,
    SET_GENERATING_OTP: `${namespace}SET_GENERATING_OTP`,
    SET_LOGGING_IN: `${namespace}SET_LOGGING_IN`,
    SET_IS_FETCHING_CONFIG: `${namespace}SET_IS_FETCHING_CONFIG`,
    SET_IP: `${namespace}SET_IP`,

    SET_USER_DATA: `${namespace}SET_USER_DATA`,
    SET_USER_DATA_FROM_COMPANY_CONFIG: `${namespace}SET_USER_DATA_FROM_COMPANY_CONFIG`,
    SET_APPLICATION_STATUS: `${namespace}SET_APPLICATION_STATUS`,
    RESET_USER_DATA: `${namespace}RESET_USER_DATA`,
    UPDATE_AICA_COINS: `${namespace}UPDATE_AICA_COINS`,
    UPDATE_ASSESSEE_ORG_ID: `${namespace}UPDATE_ASSESSEE_ORG_ID`,

    //API--COMMON
    FETCH_DOMAIN_METADATA: `${namespace}FETCH_DOMAIN_DATA`,
    FETCH_DOMAIN_METADATA_SUCCESS: `${namespace}FETCH_DOMAIN_DATA_SUCCESS`,

    //API--COMPANIES
    CREATE_ACCOUNT: `${namespace}CREATE_ACCOUNT`,
    CREATE_ACCOUNT_SUCCESS: `${namespace}CREATE_ACCOUNT_SUCCESS`,

    SAVE_APPLICATION_DETAILS: `${namespace}SAVE_APPLICATION_DETAILS`,
    SAVE_APPLICATION_DETAILS_SUCCESS: `${namespace}SAVE_APPLICATION_DETAILS`,

    LOGIN_WITH_PASSWORD: `${namespace}LOGIN_WITH_PASSWORD`,
    LOGIN_WITH_PASSWORD_SUCCESS: `${namespace}LOGIN_WITH_PASSWORD_SUCCESS`,

    GENERATE_OTP: `${namespace}GENERATE_OTP`,
    GENERATE_OTP_SUCCESS: `${namespace}GENERATE_OTP_SUCCESS`,

    LOGIN_WITH_OTP: `${namespace}LOGIN_WITH_OTP`,
    LOGIN_WITH_OTP_SUCCESS: `${namespace}LOGIN_WITH_OTP_SUCCESS`,

    VERIFY_OTP: `${namespace}VERIFY_OTP`,
    VERIFY_OTP_SUCCESS: `${namespace}VERIFY_OTP_SUCCESS`,

    FORGOT_PASSWORD: `${namespace}FORGOT_PASWORD`,
    FORGOT_PASSWORD_SUCCESS: `${namespace}FORGOT_PASWORD_SUCCESS`,

    UPDATE_PASSWORD: `${namespace}UPDATE_PASSWORD`,
    UPDATE_PASSWORD_SUCCESS: `${namespace}UPDATE_PASSWORD_SUCCESS`,

    FETCH_SUMMON_AICA_DATA: `${namespace}FETCH_SUMMON_AICA_DATA`,
    FETCH_SUMMON_AICA_DATA_SUCCESS: `${namespace}FETCH_SUMMON_AICA_DATA`,
    UPDATE_USER_FROM_SUMMON_AICA: `${namespace}UPDATE_USER_FROM_SUMMON_AICA`,

    FETCH_INTERNAL_SUMMON_AICA_DATA: `${namespace}FETCH_INTERNAL_SUMMON_AICA_DATA`,
    FETCHING_CONFIG_AFTER_LOGIN_SUCCESS: `${namespace}FETCHING_CONFIG_AFTER_LOGIN_SUCCESS`,
    // FETCH_CONFIG_DATA: `${namespace}FETCH_CONFIG_DATA`,
    // FETCH_CONFIG_DATA_SUCCESS: `${namespace}FETCH_CONFIG_DATA_SUCCESS`
};

export const FEATURES = {
    ALERTS: 'ALERTS',
    AICA_SCORECARD: 'AICA_SCORECARD',
    REFRESH_REPORT: 'REFRESH_REPORT',
    DOWNLOAD_PDF: 'DOWNLOAD_PDF',
};

export const FEATURE_STATUS = {
    HIDE: 'HIDE',
    SHOW: 'SHOW',
    COMING_SOON: 'COMING_SOON',
};
