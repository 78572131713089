import greyCross from '../../../../../../../assets/grey-cross.svg';
import LoadingSpinner from '../../../../../../../common/_custom/LoadingSpinner/LoadingSpinner';
import styles from './SingleFile.module.scss';
import { useEffect, useState } from 'react';

function SingleFile({
    file,
    fileDeleteHandler,
    downloadFile,
    disableDelete = false,
    showButton = false,
    buttonText,
    onClickFunction,
    isBtnDisabled = {},
    btnLoading,
    isInvestor,
    fileNamePrefix = '',
    isNotVisible = false,
}) {
    const [fileData, setFileData] = useState<any>(null);
    const [fileExtension, setFileExtension] = useState('');

    useEffect(() => {
        if (file) {
            let data = file;
            const fileName = file?.docName?.split('.')?.slice(0, -1)?.join('.') || '';
            const fileExtension = file?.docName?.split('.')?.pop() || '';
            setFileData({
                ...(data || {}),
                fileName: fileName,
                fileExtension: fileExtension,
            });
            setFileExtension(fileExtension);
        }
    }, [file]);

    return (
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
            <div
                style={showButton ? { width: '70%', marginRight: '1rem' } : { width: '100%' }}
                className={styles.SingleFile}
            >
                <div
                    className={styles.FileName}
                    title={fileData?.docName || fileData?.file?.name}
                    onClick={() => downloadFile?.(fileData?.docId, fileData?.fileName)}
                >
                    {(fileData?.docName || fileData?.file?.name)?.replace(fileNamePrefix, '')}
                </div>

                {!!fileData?.docId ? (
                    !disableDelete &&
                    !isNotVisible && (
                        <img
                            className={styles.DeleteIcon}
                            src={greyCross}
                            alt="gc"
                            height="16px"
                            onClick={() => fileDeleteHandler?.(file)}
                        />
                    )
                ) : (
                    <LoadingSpinner color="#C3C3C3" />
                )}
            </div>
            {showButton && (
                <button
                    disabled={
                        isBtnDisabled[fileData?.docId] ||
                        !['csv', 'xlsx', 'xls'].includes(fileExtension)
                    }
                    onClick={() => onClickFunction(file)}
                    title={
                        !['csv', 'xlsx', 'xls'].includes(fileExtension)
                            ? 'Data Mapping not supported for this file type'
                            : ''
                    }
                    style={
                        btnLoading[fileData?.docId]
                            ? { cursor: 'not-allowed', pointerEvents: 'none' }
                            : {}
                    }
                    className={isInvestor ? styles.FileButtonInv : styles.FileButton}
                >
                    {fileData?.isDocConfirmed
                        ? 'Edit Data Mapping'
                        : btnLoading[fileData?.docId]
                        ? 'Analysing Data'
                        : buttonText}
                    {btnLoading[fileData?.docId] && (
                        <LoadingSpinner color={'var(--primary-text-colour)'} />
                    )}
                </button>
            )}
        </div>
    );
}

export default SingleFile;
