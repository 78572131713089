import { lazy } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useGSTContext } from '../Context/GstContext';
import styles from './GSTModal.module.scss';

import GstOtp from './GstOtp/GstOtp';
import GstLoader from './GSTLoader/GstLoader';
import GstConsent from './GstConsent/GstConsent';
import GstErrorScreens from './GstErrorScreens/GstErrorScreens';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const EnumObject = {
    GstConsent: (props) => <GstConsent {...props} />,
    Loader: (props) => <GstLoader {...props} />,
    GstErrorScreens: (props) => <GstErrorScreens {...props} />,
    GstAccessViaAPI: (props) => <GstAccessViaAPI {...props} />,
    GstOtp: (props) => <GstOtp {...props} />,
};

const GSTModal = () => {
    const { showModal, handleCloseModal, handleBack, step } = useGSTContext();
    const { gstfetchModal, gstInputField } = styles;
    const Component = EnumObject[step.component](step.props);
    return (
        <BootstrapDialog
            onClose={handleCloseModal}
            aria-labelledby="customized-dialog-title"
            open={showModal}
        >
            <DialogTitle
                sx={{
                    m: 0,
                    p: 2,
                    fontWeight: '700',
                    fontFamily: 'gilroy-medium, sans-serif',
                    fontSize: '16px',
                    cursor: step.component === 'GstConsent' ? 'default' : 'pointer',
                    color: step.component === 'GstConsent' ? '#000000' : '#2F8FFF',
                }}
                id="customized-dialog-title"
                onClick={() => {
                    step.component !== 'GstConsent' && handleBack(step.component);
                }}
            >
                {step.component === 'GstConsent' ? 'GST Report' : 'Back'}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleCloseModal}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: '#BCBCBC',
                }}
            >
                <CloseIcon />
            </IconButton>
            {Component}
        </BootstrapDialog>
    );
};

export default GSTModal;
