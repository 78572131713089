export const USER_TYPE = {
    INVESTOR: 'INVESTOR',
    COMPANY: 'ASSESSEE',
    PARTNER: 'PARTNER',
    LENDER: 'LENDER',
    ADMIN_TEAM: 'ADMIN_TEAM',
};

export const ORG_MASTER_STATUS = {
    ADDITIONAL_DETAIL_COMPLETE: 'ADDITIONAL_DETAIL_COMPLETE',
    ADDITIONAL_DETAIL_PENDING: 'ADDITIONAL_USER_DETAIL_PENDING',
};
