import styles from './TableSelector.module.scss';
function TableWithoutHeader(props) {
    const { attributes, children, element } = props;
    return (
        <table className={styles.TableWithoutHeaders}>
            <tbody {...attributes}>{children}</tbody>
        </table>
    );
}

export default TableWithoutHeader;
