const namespace = 'Company_';
export const COMPANY_LOGIN = `${namespace}Login`;
export const COMPANY_LOGIN_WITH_OTP = `${namespace}LoginWithOTP`;
export const COMPANY_SUBMIT_OTP = `${namespace}SubmitOtp`;
export const COMPANY_START_YOUR_APPLICATION = `${namespace}StartYourApplication`;
export const COMPANY_CREATE_ACCOUNT = `${namespace}CreateAccount`;
export const COMPANY_OPEN_TAB = `${namespace}OpenTab`;
export const COMPANY_LOGOUT = `${namespace}Logout`;

//GST
export const COMPANY_GST_FETCH_GST = `${namespace}GST_FetchGst`;
export const COMPANY_GST_PROCEED = `${namespace}GST_Proceed`;
export const COMPANY_GST_SUBMIT_OTP = `${namespace}GST_SubmitOtp`;

//BANK STATEMENTS
// export const COMPANY_BS_VERIFY_ACCOUNT = `${namespace}BS_VerifyAccount`;
export const COMPANY_BS_FETCH_BANK_ACCOUNT_STATEMENT = `${namespace}BS_FetchBankAccountStatement`;
export const COMPANY_BS_UPLOAD_MANUALLY = `${namespace}BS_UploadManually`;

export const COMPANY_BS_OD_FILE_SUBMIT = `${namespace}BS_ODFileSubmit`;
export const COMPANY_BS_ADD_MORE_STATEMENTS = `${namespace}BS_AddMoreStatements`;
export const COMPANY_BS_ALL_STATEMENTS_UPLOADED = `${namespace}BS_AllStatementsUploaded`;
export const COMPANY_BS_OTHER_DOCS_START = `${namespace}BS_OtherDocsStart`;
export const COMPANY_BS_OTHER_DOCS_FINISH = `${namespace}BS_OtherDocsFinish`;

export const COMPANY_AUDITED_UPLOAD = `${namespace}Audited_Upload`;
export const COMPANY_PROVISIONAL_UPLOAD = `${namespace}Provisional_Upload`;
export const COMPANY_STATEMENT_UPLOAD = `${namespace}BankStatement_Upload`;
export const COMPANY_BS_OD_ACCOUNT = `${namespace}BS_ODAccount`;
export const COMPANY_BS_OD_FILE_UPLOAD = `${namespace}BS_ODFileUpload`;
export const COMPANY_BS_ADD_ANOTHER_ACCOUNT = `${namespace}BS_AddAnotherAccount`;
export const COMPANY_BS_FD_ACCOUNT = `${namespace}BS_FDAccount`;
export const COMPANY_BS_FD_FILE_UPLOAD = `${namespace}BS_FDFileUpload`;
export const TOGGLE_LOANS = `${namespace}ToggleLoans`;
export const DEBT_SCHEDULE_UPLOAD = `${namespace}DebtSchedule_Upload`;
export const ADD_ADDITIONAL_DATA = `${namespace}AddAdditionalData`;

//DOCS UPLOAD
export const COMPANY_DOCS_UPLOAD_ADDITIONAL_CLICK = `${namespace}Docs_UploadAdditionalClick`;

//CREDIT BUREAU
export const COMPANY_CB_PROVIDE_CONSENT = `${namespace}CB_ProvideConsent`;
export const COMPANY_CB_VERIFY_DIRECTOR = `${namespace}CB_VerifyDirector`;
export const COMPANY_CB_ADD_DIRECTOR = `${namespace}CB_AddDirector`;
