import axios from 'axios';
import { GET, POST } from '../../utils/axios-interceptor';
import { getTimeStamp } from '../../utils/dateUtils';
import {
    CreateAccountReqPayload,
    DomainMetadataReqPayload,
    ForgotPasswordPayload,
    InternalSummonAicaDataPayload,
    LoginWithPasswordPayload,
    SaveApplicationDetailsPayload,
    SummonAicaDataPayload,
    UpdatePasswordPayload,
    VerifyOtpPayload,
} from './actions';

export function fetchDomainMetadataService(payload: DomainMetadataReqPayload) {
    return axios({
        url: API_ENDPOINTS.FETCH_DOMAIN_METADATA,
        method: GET,
        params: {
            requestId: getTimeStamp(),
            domain: payload.domainName || 'founderlink.ai',
            subDomain: payload.subdomainName || 'aica',
        },
    });
}

export function createAccountService(payload: CreateAccountReqPayload) {
    return axios({
        url: API_ENDPOINTS.CREATE_ACCOUNT,
        method: POST,
        data: {
            requestId: getTimeStamp(),
            orgInfo: {
                ...payload,
            },
        },
    });
}

export function saveApplicationDetails(payload: SaveApplicationDetailsPayload) {
    return axios({
        url: API_ENDPOINTS.SAVE_APPLICATION_DETAILS,
        method: POST,
        data: {
            requestId: getTimeStamp(),
            ...payload,
        },
    });
}

export function generateOtpService(payload: CreateAccountReqPayload) {
    return axios({
        url: API_ENDPOINTS.GENERATE_OTP,
        method: POST,
        data: {
            requestId: getTimeStamp(),
            orgInfo: {
                accountType: payload.accountType,
                primaryEmail: payload.primaryEmail,
                investorOrgId: payload.investorOrgId,
                verifyToken: payload?.verifyToken,
                signupToken: payload?.signupToken,
            },
        },
    });
}

export function verifyOtpService(payload: VerifyOtpPayload) {
    return axios({
        url: API_ENDPOINTS.VERIFY_OTP,
        method: POST,
        data: {
            requestId: getTimeStamp(),
            orgInfo: {
                accountType: payload.accountType,
                primaryEmail: payload.primaryEmail,
                investorOrgId: payload.investorOrgId,
                otp: payload.otp,
            },
        },
    });
}

export function loginWithOtp(payload: VerifyOtpPayload) {
    return axios({
        url: API_ENDPOINTS.LOGIN_WITH_OTP,
        method: POST,
        data: {
            requestId: getTimeStamp(),
            orgInfo: {
                accountType: payload.accountType,
                primaryEmail: payload.primaryEmail,
                investorOrgId: payload.investorOrgId,
                otp: payload.otp,
            },
        },
    });
}

export function loginWithPassword(payload: LoginWithPasswordPayload) {
    return axios({
        url: API_ENDPOINTS.VERIFY,
        method: POST,
        data: {
            orgInfo: {
                accountType: payload.accountType,
                primaryEmail: payload.primaryEmail,
                investorOrgId: payload.investorOrgId,
                password: payload.password,
            },
        },
    });
}

export function forgotPasswordService(payload: ForgotPasswordPayload) {
    return axios({
        url: API_ENDPOINTS.FORGOT_PASSWORD,
        method: POST,
        data: {
            orgInfo: {
                accountType: payload.accountType,
                primaryEmail: payload.primaryEmail,
                investorOrgId: payload.investorOrgId,
                verifyToken: payload?.verifyToken,
            },
        },
    });
}

export function updatePasswordService(payload: UpdatePasswordPayload) {
    return axios({
        url: API_ENDPOINTS.UPDATE_PASSWORD,
        method: POST,
        data: {
            email: payload.email,
            passwordResetToken: payload.passwordResetToken,
            password: payload.password,
        },
    });
}

export function getSummonAicaData(payload: SummonAicaDataPayload) {
    return axios({
        url: API_ENDPOINTS.GET_SUMMON_AICA_DATA,
        method: POST,
        data: {
            investorOrgId: payload.investorOrgId,
            assesseeOrgId: payload.assesseeOrgId,
        },
    });
}

export function getInternalSummonAicaData(payload: InternalSummonAicaDataPayload) {
    return axios({
        url: API_ENDPOINTS.GET_INTERNAL_SUMMON_AICA_DATA,
        method: POST,
        data: {
            ...payload,
        },
    });
}

const API_ENDPOINTS = {
    FETCH_DOMAIN_METADATA: '/account/domain/metadata',
    CREATE_ACCOUNT: 'account/org/create',
    VERIFY: 'account/login/password',
    GENERATE_OTP: 'account/login/generate-otp',
    VERIFY_OTP: '/account/verify',
    LOGIN_WITH_OTP: 'account/login/otp',
    FORGOT_PASSWORD: 'account/forgotPassword',
    UPDATE_PASSWORD: 'account/resetPassword',
    GET_SUMMON_AICA_DATA: 'summon/fetchUserData',
    SAVE_APPLICATION_DETAILS: 'account/org/additionalDetails',
    GET_INTERNAL_SUMMON_AICA_DATA: 'summon/internal',
};
