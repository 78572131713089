import { useState } from 'react';
import { FileNode } from './DataRoom';
import { getFolderStructure } from '../../store/dataRoom/apiHelper';
import {
    downloadFile,
    downloadFileUsingURLHelper,
    getPresignedUrl,
} from '../Company/UploadJourney/components/UploadCard/utils/api/helpers';
import { toast } from 'react-toastify';
import { getTimeStamp } from '../../utils/dateUtils';
import { Mixpanel } from '../../utils/mixpanel';
import { useAppSelector } from '../../app/hooks';
import {
    AICA_DATA_ROOM_FILE_DOWNLOADED,
    AICA_DATA_ROOM_FILE_VIEWED,
} from '../../utils/constants/mixpanelEvents/investorEvents';
import { getObjAndFileNameS3 } from '../../utils/utils';
import { deleteDoc } from '../../common/MagicUpload/MagicUploadHelper';
import { useParams } from 'react-router-dom';

const useFetchDataRoomFiles = (fetchFiles?: Function) => {
    const [showFile, setShowFile] = useState<boolean>(false);
    const [downloading, setDownloading] = useState<boolean>(false);
    const [fileUrl, setFileUrl] = useState<string>('');
    const user = useAppSelector((state) => state.user.userData);
    const companyName = useAppSelector((state) => state.investor.selectedCompany)?.orgName;
    const userEmail = user.primaryEmail;
    const userName = user.userName;
    const params = useParams();

    const handleFileClick = async (docId: number | string, name: string) => {
        if (!isNaN(Number(docId))) {
            const res = await getPresignedUrl({ docId: Number(docId) });
            if (res) {
                Mixpanel.track(AICA_DATA_ROOM_FILE_VIEWED, {
                    userEmail,
                    companyName,
                    userName,
                    fileType: res.fileType,
                });
                if (res.fileType === 'application/pdf') {
                    setShowFile(true);
                    setFileUrl(res.uri);
                } else {
                    await downloadFile(docId, () => {}, name);
                }
            } else {
                toast.error('Error in fetching file');
            }
        } else {
            const { obj, extention }: any = getObjAndFileNameS3(docId);
            const bucketName = obj?.bucket;
            const res = await downloadFileUsingURLHelper(obj.url, bucketName);
            if (res) {
                Mixpanel.track(AICA_DATA_ROOM_FILE_VIEWED, {
                    userEmail,
                    companyName,
                    userName,
                    fileType: extention,
                });
                if (extention?.toLowerCase() === 'pdf') {
                    setShowFile(true);
                    setFileUrl(res);
                } else {
                    const fileRes = await fetch(res);
                    if (fileRes.status === 200) {
                        const blob = await fileRes.blob();
                        const link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = name ?? 'file';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    } else {
                        toast.error('Error in downloading file');
                    }
                }
            }
        }
    };

    const downloadTheFile = async (docId: number | string, name: string) => {
        if (!isNaN(Number(docId))) {
            await downloadFile(docId, () => {}, name);
        } else {
            const { obj }: any = getObjAndFileNameS3(docId);
            const bucketName = obj?.bucket;

            const res = await downloadFileUsingURLHelper(obj.url, bucketName);
            if (res) {
                const fileRes = await fetch(res);
                if (fileRes.status === 200) {
                    const blob = await fileRes.blob();
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = name ?? 'file';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                } else {
                    toast.error('Error in downloading file');
                }
            }
        }
    };
    const downloadTheFolder = async (data: FileNode[], name: string) => {
        setDownloading(true);
        const payload = {
            parentPath: name,
            requestId: getTimeStamp(),
            fileNodes: data,
        };
        Mixpanel.track(AICA_DATA_ROOM_FILE_DOWNLOADED, {
            userEmail,
            companyName,
            userName,
        });
        const res = await getFolderStructure(payload);
        if (res) {
            let blob = new Blob([res], { type: 'octet/stream' });
            let fileName = `${name}.zip`;
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
            toast.success('Folder Downloaded Successfully');
            setDownloading(false);
        } else {
            toast.error('Error in fetching folder');
            setDownloading(false);
        }
    };

    const handleDeleteFile = async (
        docId: number | string,
        name: string,
        masterFolderName: string,
    ) => {
        let result = false;
        await deleteDoc(
            {
                docId: docId,
                requestId: getTimeStamp(),
                orgId: params.id,
                pnoId: user.investorOrgId,
                docType: masterFolderName,
            },
            {
                onSuccess: () => {
                    result = true;
                    toast.success('File Deleted Successfully');
                    if (fetchFiles) fetchFiles();
                },
            },
        );
        return result;
    };
    return {
        showFile,
        handleFileClick,
        setShowFile,
        fileUrl,
        downloadTheFile,
        downloadTheFolder,
        downloading,
        handleDeleteFile,
    };
};

export default useFetchDataRoomFiles;
