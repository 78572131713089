const namespace = 'USER_';
export const CONSTANTS = {
    //AUTH-V2
    SET_LOGGIN_IN: `${namespace}SET_LOGGIN_IN`,
    SET_IP: `${namespace}SET_IP`,
    SET_FETCHING_DOMAIN_METADATA_V2: `${namespace}SET_FETCHING_DOMAIN_METADATA_V2`,
    FETCH_DOMAIN_METADATA_SUCCESS_V2: `${namespace}FETCH_DOMAIN_METADATA_SUCCESS_V2`,
    FETCH_DOMAIN_METADATA_V2: `${namespace}FETCH_DOMAIN_METADATA_V2`,
    SET_IS_CREATING_ACCOUNT_V2: `${namespace}SET_IS_CREATING_ACCOUNT_V2`,
    CREATE_ACCOUNT_V2: `${namespace}CREATE_ACCOUNT_V2`,
    CREATE_ACCOUNT_V2_SUCCESS: `${namespace}CREATE_ACCOUNT_V2_SUCCESS`,
    LOGIN_WITH_PASSWORD_V2: `${namespace}LOGIN_WITH_PASSWORD_V2`,
    LOGIN_WITH_PASSWORD_V2_SUCCESS: `${namespace}LOGIN_WITH_PASSWORD_V2_SUCCESS`,
    FETCHING_CONFIG_AFTER_LOGIN: `${namespace}FETCHING_CONFIG_AFTER_LOGIN`,
    FETCHING_CONFIG_AFTER_LOGIN_SUCCESS: `${namespace}FETCHING_CONFIG_AFTER_LOGIN_SUCCESS`,
    LOGIN_WITH_OTP_V2: `${namespace}LOGIN_WITH_OTP_V2`,
    LOGIN_WITH_OTP_V2_SUCCESS: `${namespace}LOGIN_WITH_OTP_V2_SUCCESS`,
    SET_GENERATING_OTP_V2: `${namespace}SET_GENERATING_OTP_V2`,
    GENERATE_OTP_SUCCESS_V2: `${namespace}GENERATE_OTP_SUCCESS_V2`,
    GENERATE_OTP_V2: `${namespace}GENERATE_OTP_V2`,
    SET_LOGGING_IN_V2: `${namespace}SET_LOGGING_IN_V2`,
    VERIFY_OTP_SUCCESS_V2: `${namespace}VERIFY_OTP_SUCCESS_V2`,
    FORGOT_PASSWORD_V2: `${namespace}FORGOT_PASSWORD_V2`,
    RESET_PASSWORD_V2: `${namespace}RESET_PASSWORD_V2`,
    LOGIN_FOR_SUMMON_AICA_V2: `${namespace}LOGIN_FOR_SUMMON_AICA_V2`,
    SET_USER_DATA: `${namespace}SET_USER_DATA`,
    RESET_USER_DATA: `${namespace}RESET_USER_DATA`,
    UPDATE_USER_FROM_SUMMON_AICA: `${namespace}UPDATE_USER_FROM_SUMMON_AICA`,
    SET_IS_SAVING_APPLICATION_DETAILS: `${namespace}SET_IS_SAVING_APPLICATION_DETAILS`,
    SET_APPLICATION_STATUS: `${namespace}SET_APPLICATION_STATUS`,
    SET_IS_FETCHING_CONFIG: `${namespace}SET_IS_FETCHING_CONFIG`,
    SET_USER_DATA_FROM_COMPANY_CONFIG: `${namespace}SET_USER_DATA_FROM_COMPANY_CONFIG`,
    UPDATE_AICA_COINS: `${namespace}UPDATE_AICA_COINS`,
    UPDATE_ASSESSEE_ORG_ID: `${namespace}UPDATE_ASSESSEE_ORG_ID`,
    UPDATE_USER_EMAIL_VERIFIED: `${namespace}UPDATE_USER_EMAIL_VERIFIED`,
    INVESTOR_SIGNUP: `${namespace}INVESTOR_SIGNUP`,
    INVESTOR_SIGNUP_LOGIN: `${namespace}INVESTOR_SIGNUP_LOGIN`,
    ASSESSEE_ADDITION: `${namespace}ASSESSEE_ADDITION`,
    LOGOUT_USER: `${namespace}LOGOUT_USER`,
    VERIFY_EMAIL: `${namespace}VERIFY_EMAIL`,
    RESEND_VERIFICATION_EMAIL: `${namespace}RESEND_VERIFICATION_EMAIL`,
};
