import React from 'react';
import FileTree from './Components/FileTree/FileTree';
import styles from './FileExplorer.module.scss';
import { FileNode } from '../../DataRoom';
import { Button } from '../../../../common/_custom';
import { dataRoomDownloadIcon } from '../../../../assets/hostedassets';
import { isEmpty } from '../../../../utils/utils';
import EmptyBank from '../../../Dashboard/DashboardModules/BankDataTable/components/EmptyBank/EmptyBank';
import useFetchDataRoomFiles from '../../useFetchDataRoomFiles';

interface FileExplorerProps {
    data: FileNode[];
    selectedCompany: any;
    exportData: Function;
    fetchFiles: Function;
    isRecurCompany: boolean;
}

const FileExplorer: React.FC<FileExplorerProps> = ({
    data,
    selectedCompany,
    exportData,
    fetchFiles,
    isRecurCompany,
}) => {
    const { downloadTheFolder, downloading } = useFetchDataRoomFiles();
    return (
        <div className={styles.fileExplorer}>
            <header className={styles.header}>
                <h1>Data Room</h1>
                <div className={styles.separator} />
                <Button
                    disabled={isEmpty(data)}
                    text={
                        <div className={styles.ButtonTextContainer}>
                            <img src={dataRoomDownloadIcon} alt={'Download'} />
                            Download All
                        </div>
                    }
                    style={{ margin: 0 }}
                    loading={downloading}
                    loadingText={'Downloading'}
                    onClick={async () => {
                        await downloadTheFolder(data, selectedCompany?.orgName ?? 'Data');
                    }}
                    variant={'purple'}
                />
            </header>
            <div className={styles.fileList}>
                <div className={styles.fileTreeHeader}>
                    <div className={styles.fileTreeHeaderItem}>Data</div>
                    <div className={styles.fileTreeHeaderItem}>Last Updated</div>
                </div>
                <div className={styles.FileTreeContainer}>
                    {isEmpty(data) ? (
                        <EmptyBank
                            style={{ background: 'transparent', boxShadow: 'none', height: '80%' }}
                            text={'No Data Available'}
                        />
                    ) : (
                        <div style={{ height: '100%' }}>
                            <FileTree
                                data={data}
                                index={0}
                                fetchFiles={fetchFiles}
                                isRecurCompany={isRecurCompany}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default FileExplorer;
