import DashboardHeader from '../Dashboard/components/DashboardHeader/DashboardHeader';
import React, { useEffect, useState } from 'react';
import useLegacyBusinessDataFetch from './useLegacyBusinessDataFetch';
import FileExplorer from './Components/FileExplorer/FileExplorer';
import styles from './DataRoom.module.scss';
import LoadingSpinner from '../../common/_custom/LoadingSpinner/LoadingSpinner';
import { createPortal } from 'react-dom';
import useCallApiToFetchFileData from './useCallApiToFetchFileData';
import { ACCESS_TIERS, ACCESS_TYPES } from '../../store/investor/constants';
import { useAppSelector } from '../../app/hooks';
import UnlockScanButton from '../Dashboard/DashboardModules/Overview/components/Buttons/UnlockScan/UnlockScanButton';
import { useDispatch } from 'react-redux';
import {
    setDealDrawer,
    setShowUpgradeToScan,
    updateUntaggedMuFileNumber,
} from '../../store/investor/action';
import AddToScanButton from '../Dashboard/DashboardModules/Overview/components/Buttons/AddToScan/AddToScanButton';
import { USER_TYPE } from '../../utils/constants/user';
import { MagicUploadBtnIconWhite, plusWhite2 } from '../../assets/hostedassets';
import { Button } from '../../common/_custom';
import ManageDealDrawer from '../../common/ManageDealDrawer/ManageDealDrawer';
import { getUntaggedCount } from '../../common/MagicUpload/MagicUploadHelper';
import { useParams, useSearchParams } from 'react-router-dom';
export type FileNode = {
    name: string;
    type: 'file' | 'folder';
    docId?: number;
    updatedAt: string;
    innerFiles?: FileNode[];
};

const DataRoom = () => {
    const {
        exportData,
        isUserRestricted,
        selectedCompany,
        refreshReportStatus,
        downloadPDFStatus,
        goBackToPortfolio,
        // renderHeaderButton,
        unlockAicaReport,
    } = useLegacyBusinessDataFetch();
    const header = document.getElementById('header') ?? document.createElement('div');
    const { fileData, isFetchingData, fetchFiles, isRecurCompany } = useCallApiToFetchFileData(
        selectedCompany?.orgName,
    );
    const investorData = useAppSelector((state) => state.investor);
    const permissions = investorData.permissions;
    const isScanLockedForUser = permissions?.[ACCESS_TIERS.SCAN] === ACCESS_TYPES.LOCKED;
    const dispatch = useDispatch();
    const [showInviteCompanyModal, setShowInviteCompanyModal] = useState(false);
    const [showAddToScanModal, setShowAddToScanModal] = useState('');
    const isDemoProfile = !!selectedCompany?.demoOrg;
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const user = useAppSelector((state) => state.user);
    const showDealDrawer = useAppSelector((state) => state.investor.dealDrawer.open);
    const isPartner = user.userData.accountType === USER_TYPE.PARTNER;
    const showMu =
        !(isPartner || user.userData.accountType === USER_TYPE.ADMIN_TEAM) &&
        permissions.showMagicUpload;
    const untaggedCount = useAppSelector((state) => state.investor.untaggedCount);

    const getUntaggedStatus = async () => {
        const res = getUntaggedCount(
            {
                pnoId: user.userData.investorOrgId,
                orgId: params.id,
            },
            {
                onSuccess: (res) => {
                    dispatch(updateUntaggedMuFileNumber(res));
                },
            },
        );
    };

    useEffect(() => {
        if (showMu) {
            getUntaggedStatus();
        }
    }, [showMu]);

    const renderHeaderButton = () => {
        if (isUserRestricted === false) {
            if (isScanLockedForUser)
                return (
                    <UnlockScanButton
                        onClick={() => dispatch(setShowUpgradeToScan(ACCESS_TIERS.SCAN))}
                    />
                );
            else if (selectedCompany?.companyAccessType == ACCESS_TIERS.WATCH)
                return (
                    <AddToScanButton
                        onClick={() => {
                            setShowInviteCompanyModal(true);
                            setShowAddToScanModal('scan');
                        }}
                        accessType={selectedCompany?.companyAccessType}
                        isDemoProfile={isDemoProfile}
                    />
                );
            else if (user.userData.accountType === USER_TYPE.INVESTOR && !isDemoProfile) {
                return (
                    <>
                        {showMu && (
                            <div style={{ position: 'relative' }}>
                                <div
                                    className={styles.MagicUploadButton}
                                    onClick={() => {
                                        searchParams.set('magicUpload', 'true');
                                        searchParams.set('isCompany', 'true');
                                        setSearchParams(searchParams);
                                    }}
                                >
                                    <img
                                        src={MagicUploadBtnIconWhite}
                                        alt={'MagicUploadIcon'}
                                        height={'16px'}
                                        width={'16px'}
                                    />
                                    <span className={styles.Text}>Magic Upload</span>
                                </div>
                                {untaggedCount > 0 && (
                                    // <img
                                    //     src={MUExclamationRed}
                                    //     alt={'exclamation'}
                                    //     className={styles.ExclamationIcon}
                                    // />
                                    <div className={styles.redDot}>{untaggedCount}</div>
                                )}
                            </div>
                        )}
                    </>
                );
            }
        }
    };

    const openCreateDealDrawer = () => {
        dispatch(
            setDealDrawer({
                open: true,
                companyId: selectedCompany?.assesseeOrgId,
                companyName: selectedCompany?.orgName,
                profileGenerationStatus: selectedCompany?.profileGenerationStatus,
            }),
        );
    };

    return (
        <div className={styles.DataRoomWrapper}>
            {createPortal(
                <DashboardHeader
                    exportData={exportData}
                    isUserRestricted={isUserRestricted}
                    selectedCompany={selectedCompany}
                    refreshReportStatus={refreshReportStatus}
                    downloadPDFStatus={downloadPDFStatus}
                    goBackToPortfolio={goBackToPortfolio}
                    renderHeaderButton={renderHeaderButton}
                    unlockAicaReport={unlockAicaReport}
                />,
                header,
            )}

            {showDealDrawer && <ManageDealDrawer source="Data Room Page" />}

            <div className={styles.DataRoomContainer}>
                {isFetchingData ? (
                    <div className={styles.LoadingContainer}>
                        <LoadingSpinner color={'#4D4D4D'} height={'50px'} />
                    </div>
                ) : (
                    <FileExplorer
                        isRecurCompany={isRecurCompany ?? false}
                        fetchFiles={fetchFiles ?? (() => {})}
                        exportData={exportData}
                        data={fileData ?? []}
                        selectedCompany={selectedCompany}
                    />
                )}
            </div>
        </div>
    );
};

export default DataRoom;
