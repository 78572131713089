import React from 'react';

const ArrowIcon = ({
    color = '#2F8FFF',
    width = '20',
    height = '20',
    onClick,
    onKeyDown,
    tabIndex,
    role,
    className,
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick}
            onKeyDown={onKeyDown}
            tabIndex={tabIndex}
            role={role}
            className={className}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15 8.25H5.85L10.05 4.05L9 3L3 9L9 15L10.05 13.95L5.85 9.75H15V8.25Z"
                fill={color}
            />
            <mask
                id="mask0_753_86813"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="3"
                y="3"
                width="12"
                height="12"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15 8.25H5.85L10.05 4.05L9 3L3 9L9 15L10.05 13.95L5.85 9.75H15V8.25Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_753_86813)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 0H18V18H9.76028H0V0Z"
                    fill={color}
                />
            </g>
        </svg>
    );
};

export default ArrowIcon;
