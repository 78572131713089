import React from 'react';
import { MUEmailGrey, MUWebsite, MUWhatsappGrey } from '../../../assets/hostedassets';

export type SourceType = 'whats_app' | 'gmail' | 'app';

export const getSourceIcon = (source: SourceType) => {
    switch (source) {
        case 'whats_app':
            return <img src={MUWhatsappGrey} alt={'Whatsapp'} />;
        case 'gmail':
            return <img style={{ height: '22px' }} src={MUEmailGrey} alt={'Email'} />;
        case 'app':
            return <img src={MUWebsite} alt={'Website'} />;
        default:
            return '-';
    }
};
