import { useEffect, useState } from 'react';
import styles from './OnePagerGenerateButton.module.scss';
import DealSummaryEditor from './DealSummaryEditor/DealSummaryEditor';
import ToggleButton from '../../ToggleButton/ToggleButton';
import clsx from 'clsx';
import { getOnePagerData } from '../../../store/investor/apiHelpers';
import { initialiseDealSummaryEditor } from './DealSummaryEditor/dealSummaryEditorInitializer';
import { initializeDatepickerDates } from '../../../utils/dateUtils';
import Tooltip from '../../_custom/Tooltip/Tooltip';
import { downloadFile } from '../../../modules/Company/UploadJourney/components/UploadCard/utils/api/helpers';
import { useAppSelector } from '../../../app/hooks';
import {
    ctaPurpleRight,
    ctaWhiteRight,
    downloadIconDataRoomHovered,
} from '../../../assets/hostedassets';
import LoadingSpinner from '../../_custom/LoadingSpinner/LoadingSpinner';
import { Mixpanel } from '../../../utils/mixpanel';
import { AICA_GENERATE_ONE_PAGER } from '../../../utils/constants/mixpanelEvents/investorEvents';
import { FILE_SHARE_STATUS } from '../DealDrawerConstants';

function OnePagerGenerate({
    formData,
    categoryId,
    flowId,
    metadataUpdate,
    assesseeOrgId,
    onShareToggle,
    existingData,
    step,
    isLocked,

    source,
}) {
    const [generated, setGenerated] = useState(false);

    const [generating, setGenerating] = useState(false);
    const [editorInitialData, setEditorInitialData] = useState<any>(null);
    const [editorReady, setEditorReady] = useState(false);

    const existingEditorContent = localStorage.getItem('one_pager_content');
    const existingEditorContentOrgId = localStorage.getItem('one_pager_assessee');
    const companyMainImg = useAppSelector((state) => state.user.domainMetadata?.mainImageUrl);
    const userData = useAppSelector((state) => state.user.userData);
    const dealDrawerState = useAppSelector((state) => state.investor.dealDrawer);

    useEffect(() => {
        if (
            assesseeOrgId &&
            existingEditorContentOrgId &&
            existingEditorContent &&
            assesseeOrgId === existingEditorContentOrgId
        ) {
            setEditorInitialData(JSON.parse(existingEditorContent));
            setGenerated(true);
        }
    }, [assesseeOrgId, existingEditorContentOrgId, existingEditorContent]);

    const fetchDataForOnePager = () => {
        setGenerating(true);
        const [fromDate, toDate] = initializeDatepickerDates('Monthly');
        getOnePagerData(
            {
                orgId: assesseeOrgId,
                startDate: fromDate,
                endDate: toDate,
            },
            {
                onSuccess: (apiData = {}) => {
                    initialiseDealSummaryEditor(
                        apiData,
                        formData,
                        editorInitialData,
                        setEditorInitialData,
                        setEditorReady,
                        setGenerating,
                        companyMainImg,
                    );
                },
            },
        );
    };

    const onGenerateClick = () => {
        if (generating) return;
        fetchDataForOnePager();
        Mixpanel.track(AICA_GENERATE_ONE_PAGER, {
            parentId: userData.investorOrgId,
            userId: userData.userId,
            userRole: userData.accountType,
            companyOrgId: dealDrawerState.companyId,
        });
    };

    const renderGenerateDealButton = () => {
        return (
            <div
                className={clsx(styles.GenerateButton, {
                    // [styles.Disabled]: disable,
                    [styles.Locked]: isLocked || (source === 'Deals List Page' && !existingData),
                })}
                onClick={onGenerateClick}
            >
                {generating ? (
                    <LoadingSpinner height="15px" style={{ margin: 0 }} />
                ) : (
                    <img src={ctaWhiteRight} alt="" height={'15px'} />
                )}
                {generating ? 'Generating...' : 'Generate Deal Summary'}
            </div>
        );
    };

    const downloadOnePager = () => {
        existingData?.docId &&
            downloadFile(
                Number(existingData?.docId),
                () => {},
                existingData?.documentName?.replace('ONEPAGER_', ''),
            );
    };

    return (
        <div className={styles.OnePagerButton}>
            {generated || (source === 'Deals List Page' && existingData) ? (
                <div className={styles.GeneratedButton}>
                    {source === 'Deals List Page' && existingData ? (
                        <div className={styles.Left} onClick={() => downloadOnePager()}>
                            <img
                                src={downloadIconDataRoomHovered}
                                alt=""
                                height={'12px'}
                                // style={{ transform: 'rotate(135deg)' }}
                            />
                            Download
                        </div>
                    ) : (
                        <div className={styles.Left} onClick={() => setEditorReady(true)}>
                            <img src={ctaPurpleRight} alt="" height={'10px'} />
                            Edit Summary
                        </div>
                    )}
                    <div className={styles.Right}>
                        Share
                        <ToggleButton
                            checked={existingData?.action === FILE_SHARE_STATUS.SEND_TO_INVESTOR}
                            onChange={() => {
                                onShareToggle(
                                    existingData?.action === FILE_SHARE_STATUS.SEND_TO_INVESTOR
                                        ? FILE_SHARE_STATUS.SKIP_NOTIFICATION
                                        : FILE_SHARE_STATUS.SEND_TO_INVESTOR,
                                );
                            }}
                            disabled={source === 'Deals List Page'}
                        />
                    </div>
                </div>
            ) : (
                <>
                    {' '}
                    {isLocked ? (
                        <Tooltip
                            content="Please unlock AICA report to generate Deal Summary"
                            direction="left"
                            fitContent
                            containerFullWidth
                            // alwaysVisible
                        >
                            {renderGenerateDealButton()}
                        </Tooltip>
                    ) : (
                        <>{renderGenerateDealButton()}</>
                    )}
                </>
            )}

            {editorReady && (
                <DealSummaryEditor
                    openModal={editorReady}
                    closeModal={() => setEditorReady(false)}
                    categoryId={categoryId}
                    flowId={flowId}
                    assesseeOrgId={assesseeOrgId}
                    metadataUpdate={metadataUpdate}
                    editorInitialData={editorInitialData}
                    editorReady={editorReady}
                />
            )}
        </div>
    );
}

export default OnePagerGenerate;
