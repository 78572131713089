import SvgIcon from '@mui/material/SvgIcon';

const WhiteRedirectSVG = (props) => (
    <SvgIcon {...props}>
        <svg
            width="10"
            height="10"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14 6.25V2.5H10.25"
                stroke="white" // Set the icon color to white
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M9.5 7L14 2.5"
                stroke="white" // Set the icon color to white
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M12 9V13C12 13.1326 11.9473 13.2598 11.8536 13.3536C11.7598 13.4473 11.6326 13.5 11.5 13.5H3.5C3.36739 13.5 3.24021 13.4473 3.14645 13.3536C3.05268 13.2598 3 13.1326 3 13V5C3 4.86739 3.05268 4.74021 3.14645 4.64645C3.24021 4.55268 3.36739 4.5 3.5 4.5H7.5"
                stroke="white" // Set the icon color to white
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    </SvgIcon>
);

export default WhiteRedirectSVG;
