import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { saveKycDocsHelper } from '../UploadCard/utils/api/helpers';
import { fetchUploadJourneyData, sendCibilConsentLink } from '../../../../../store/company/action';
import { USER_TYPE } from '../../../../../utils/constants/user';
import { useAppSelector } from '../../../../../app/hooks';
import { toast } from 'react-toastify';

const useHandleDirectorKycDocs = () => {
    const dispatch = useDispatch();
    const userData = useAppSelector((state) => state.user.userData);
    const uploadJourneyData = useAppSelector((state) => state.company.uploadJourneyData);
    const [showAddDirector, setShowAddDirector] = useState<boolean>(false);
    const [isAddingDirector, setIsAddingDirector] = useState<boolean>(false);

    const [newDirectorDetails, setNewDirectorDetails] = useState<{
        name: string;
        phone: string;
        errorMessage?: string;
    }>({ name: '', phone: '' });

    const directorDetails = uploadJourneyData?.directorKycData?.kycData;
    const updateKycDocs = async (
        kycDocs: any,
        metadata: any,
        assesseeOrgId: string,
        del = false,
        din: string,
    ) => {
        const payload = {
            assesseeOrgId: assesseeOrgId,
            kycType: 'DIRECTOR',
            kycDataId: uploadJourneyData?.directorKycData?.id,
            additionalDoc: {
                docId: kycDocs.docId,
                docName: kycDocs.fileName,
                description: metadata.label,
                isDeleted: del,
                id: din,
            },
        };
        await saveKycDocsHelper(payload, {
            onSuccess: () => {
                dispatch(
                    fetchUploadJourneyData({
                        accountType: USER_TYPE.COMPANY,
                        // primaryEmai: userData.primaryEmail,
                        // investorOrgId: userData.investorOrgId,
                        assesseeOrgId: assesseeOrgId,
                    }),
                );
            },
        });
    };

    const openAddDirectorModal = () => {
        setShowAddDirector(true);
    };

    const closeAddDirectorModal = () => {
        setNewDirectorDetails({ name: '', phone: '' });
        setShowAddDirector(false);
    };

    const handleNewDirectorDetails = (key: string, value: string) => {
        if (key === 'phone' && (value.length === 0 || value.length === 10)) {
            setNewDirectorDetails({ ...newDirectorDetails, errorMessage: '' });
        }
        setNewDirectorDetails((prev) => ({ ...prev, [key]: value }));
    };

    const fetchUploadData = () => {
        dispatch(
            fetchUploadJourneyData({
                accountType: USER_TYPE.COMPANY,
                assesseeOrgId: userData.assesseeOrgId || '',
            }),
        );
    };

    const addDirector = () => {
        if (!newDirectorDetails.name) {
            setNewDirectorDetails({
                ...newDirectorDetails,
                errorMessage: 'Please enter director name',
            });
            return;
        }
        if (newDirectorDetails.phone && newDirectorDetails.phone.length !== 10) {
            setNewDirectorDetails({
                ...newDirectorDetails,
                errorMessage: 'Please enter a valid phone number',
            });
            return;
        }
        setIsAddingDirector(true);
        dispatch(
            sendCibilConsentLink({
                assesseeOrgId: userData.assesseeOrgId,
                email: userData.primaryEmail,
                directorName: newDirectorDetails.name,
                ...(newDirectorDetails.phone && { phoneNumber: newDirectorDetails.phone }),
                flowType: 'ADD_DIRECTOR',
                onSuccess: () => {
                    setIsAddingDirector(false);
                    setShowAddDirector(false);
                    setNewDirectorDetails({ name: '', phone: '' });
                    toast.success('Director added successfully');
                    fetchUploadData();
                },
                onError: (errorMessage: string) => {
                    toast.error(errorMessage);
                    setIsAddingDirector(false);
                },
            }),
        );
    };

    return {
        updateKycDocs,
        directorDetails,
        showAddDirector,
        openAddDirectorModal,
        closeAddDirectorModal,
        newDirectorDetails,
        handleNewDirectorDetails,
        addDirector,
        isAddingDirector,
    };
};

export default useHandleDirectorKycDocs;
