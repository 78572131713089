import { FORMERR } from 'dns';
import React from 'react';

interface FormProps {
    children?: React.ReactNode;
    onSubmit?: Function;
    className?: string;
    style?: React.CSSProperties;
    validate?: boolean;
}

function Form({ children, onSubmit, className, style, validate = false }: FormProps) {
    return (
        <form
            noValidate={!validate}
            onSubmit={(e) => {
                e.preventDefault();
                onSubmit?.();
            }}
        >
            {!!children && children}
        </form>
    );
}

export default Form;
