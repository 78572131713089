import { useEffect, useRef, useState } from 'react';
import './Tooltip.scss';
import clsx from 'clsx';
/**
 * @Desciption Custom Tooltip component to display information on hover
 * @param content Tooltip message content
 * @param direction Tooltip position with respect to target element
 * @param children Elements for which the Tooltip is to be shown on hover
 */
interface TooltipProps {
    content: string | React.ReactNode;
    direction: 'top' | 'right' | 'bottom' | 'left';
    isBenchmarking?: boolean;
    alwaysVisible?: boolean;
    inverted?: boolean;
    small?: boolean;
    containerFullWidth?: boolean;
    fitContent?: boolean;
    containerFullHeight?: boolean;
    children?: React.ReactNode;
    height?: string;
    style?: React.CSSProperties;
    isDealNotesTooltip?: boolean;
    isOverviewDetail?: boolean;
    hideDelay?: number;
    flexible?: boolean;
}

const Tooltip = ({
    content = '',
    direction = 'top',
    children,
    alwaysVisible,
    inverted,
    small,
    containerFullWidth,
    fitContent,
    containerFullHeight,
    isBenchmarking,
    height = '',
    style,
    isDealNotesTooltip,
    isOverviewDetail,
    hideDelay = 0,
    flexible = false,
}: TooltipProps) => {
    const [active, setActive] = useState(false);
    const hideTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

    useEffect(() => {
        if (alwaysVisible) setActive(true);
    }, [alwaysVisible]);

    useEffect(() => {
        return () => {
            if (hideTimeoutRef.current) {
                clearTimeout(hideTimeoutRef.current);
            }
        };
    }, []);

    const showTip = () => {
        if (alwaysVisible) return;
        if (hideTimeoutRef.current) {
            clearTimeout(hideTimeoutRef.current);
            hideTimeoutRef.current = null;
        }
        setActive(true);
    };

    const hideTip = () => {
        if (alwaysVisible) return;
        if (hideDelay > 0) {
            hideTimeoutRef.current = setTimeout(() => {
                setActive(false);
            }, hideDelay);
        } else {
            setActive(false);
        }
    };

    return (
        <div
            className="Tooltip-Wrapper"
            onMouseEnter={showTip}
            onMouseLeave={hideTip}
            style={{
                width: containerFullWidth ? '100%' : '',
                height: containerFullHeight ? '100%' : '',
            }}
        >
            {children}
            {active && (
                <div
                    className={`${
                        isDealNotesTooltip && direction === 'bottom' ? 'DealNotesTooltipBottom' : ''
                    } ${isBenchmarking ? 'BenchmarkTooltip' : ''} Tooltip-Tip ${direction}`}
                    style={{
                        color: inverted ? 'white' : '#979797',
                        wordBreak: 'break-word',
                        whiteSpace: inverted ? 'nowrap' : 'pre-wrap',
                        width: fitContent ? 'fit-content' : '',
                        minWidth: small ? '80px' : '200px',
                        height: height || 'fit-content',
                        overflowY: height ? 'auto' : 'hidden',
                        overflowX: 'hidden',
                        display: content ? 'inline-block' : 'none',
                        zIndex: 1000,
                        scrollbarWidth: isOverviewDetail ? 'none' : 'auto',
                        ...style,
                    }}
                >
                    <div className={clsx('Content', { OverviewDetail: isOverviewDetail })}>
                        {content}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Tooltip;
