import clsx from 'clsx';
import { useRef } from 'react';
import black_plus from '../../../../../../../assets/black-plus.svg';
import styles from './DragDropFileHandler.module.scss';
import { toast } from 'react-toastify';

function DragDropFileHandler({
    onChange,
    disabled = false,
    acceptedFormats,
    allowMultiple,
    onClickFunction,
}) {
    const fileInputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
    const handleClick = () => {
        onClickFunction?.();
        fileInputRef?.current?.click?.();
    };

    const handleChange = (e) => {
        onChange(e.target.files);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        onChange(e.dataTransfer.files);
        if (e.dataTransfer) e.dataTransfer.clearData();
        // setDragActive(false);
    };

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === 'dragenter' || e.type === 'dragover') {
            if (e.dataTransfer) e.dataTransfer.dropEffect = 'copy';
        } else if (e.type === 'dragleave') {
            // setDragActive(false);
        }
    };
    return (
        <div
            className={clsx(styles.DragDropFileHandler, {
                [styles.Disabled]: disabled,
            })}
            onDrop={handleDrop}
            onDragOver={handleDrag}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onClick={handleClick}
        >
            <input
                type="file"
                ref={fileInputRef}
                onChangeCapture={handleChange}
                style={{ display: 'none' }}
                accept={acceptedFormats}
                multiple={!!allowMultiple}
            />
            <div className={styles.Left}>
                <div className={styles.Drag}>Drag and Drop</div>
                <div className={styles.Select}>Select a file or drop one here</div>
            </div>
            <div className={styles.Right}>
                <img src={black_plus} alt="plus" height="20px" />
            </div>
        </div>
    );
}

export default DragDropFileHandler;
