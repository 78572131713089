import styles from './EditableTable.module.scss';
import { get, isEmpty, set } from 'lodash';
import EditableDropdown from '../../../../../../../../common/EditableDropdown/EditableDropdown';
import { useDispatch } from 'react-redux';
import { fetchMisTableOptions } from '../../../../../../../../store/company/action';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

function EditableTable({
    tableValue,
    changedData,
    setChangedData,
    isMappingConfirmed,
}: {
    tableValue: any;
    changedData: any;
    setChangedData: any;
    isMappingConfirmed?: boolean;
}) {
    const dispatch = useDispatch();
    const params = useParams();
    const [rawOptions, setRawOptions] = useState<any>([]);
    const [dataTypeOptions, setDataTypeOptions] = useState<string[]>([]);
    const [classificationDropdowns, setClassificationDropdowns] = useState({});
    const [showOptionValue, setShowOptionValue] = useState({});
    const [dataFormatOptions, setDataFormatOptions] = useState<string[]>([]);
    const [disableInput, setDisableInput] = useState({});
    const handleOptionChange = (value, row, changeType) => {
        let dataObj: {
            id: string;
            parent: string;
            [key: string]: string | number;
        } = {
            id: row?.id ?? '',
            parent: row?.metric ?? '',
        };

        if (changeType === 'additionalClassification') {
            dataObj.misClassification = rawOptions
                .find((item) => item.misDisplayName === row.dataType)
                ?.ledger_classifications.find((item) => item.name === value)?.id;
        }
        if (changeType === 'dataType') {
            dataObj.misClassification = rawOptions.find((item) => item.misDisplayName === value)
                ?.ledger_classifications?.[0].id;
            dataObj.additionalClassification = rawOptions.find(
                (item) => item.misDisplayName === value,
            )?.ledger_classifications[0]?.name;
        }
        dataObj[`${changeType}`] = value;
        let isAlreadyChanged = changedData.findIndex((item) => item.id === row?.id);
        if (isAlreadyChanged !== -1) {
            if (changeType === 'additionalClassification') {
                changedData[isAlreadyChanged].misClassification = rawOptions
                    .find((item) => item.misDisplayName === changedData[isAlreadyChanged].dataType)
                    ?.ledger_classifications.find((item) => item.name === value)?.id;
            }
            changedData[isAlreadyChanged][`${changeType}`] = value;
            setChangedData([...changedData]);
            return;
        }
        setChangedData([...changedData, dataObj]);
    };
    const fetchOptions = () => {
        dispatch(
            fetchMisTableOptions({
                orgId: params.id || '',
                source: 'AicaFinancials',
                onSuccess: (res) => {
                    setRawOptions(res.data?.data?.[0]?.misOptions);
                    setDataTypeOptions(
                        res.data?.data?.[0]?.misOptions?.map((item) => {
                            return item.misDisplayName;
                        }),
                    );
                    setDataFormatOptions(
                        res.data?.data?.[1].dataFormats.map((item) => item.dataFormat),
                    );
                },
            }),
        );
    };
    useEffect(() => {
        if (!isEmpty(dataTypeOptions)) {
            let tempObj = {};
            tableValue?.data?.rows?.forEach((row, index) => {
                rawOptions?.forEach((item: any) => {
                    if (item.misDisplayName === row.dataType) {
                        let temp = item.ledger_classifications.map((classification) => {
                            return classification.name;
                        });
                        tempObj[index] = temp;
                    }
                });
                setClassificationDropdowns({ ...tempObj });
            });
        }
    }, [dataTypeOptions]);
    const fetchClassificationDropdowns = (misDisplayName, index) => {
        let tempObj = { ...classificationDropdowns };
        rawOptions?.forEach((item: any) => {
            if (item.misDisplayName === misDisplayName) {
                let temp = item.ledger_classifications.map((classification) => {
                    return classification.name;
                });
                tempObj[index] = temp;
            }
        });
        setClassificationDropdowns(tempObj);
    };
    useEffect(() => {
        let tempObj = { ...disableInput };
        tableValue?.data?.rows?.forEach((row, index) => {
            if (row.dataType === 'Other Metric (MIS)') {
                tempObj[index] = true;
            }
        });
        setDisableInput(tempObj);
    }, [tableValue]);

    useEffect(() => {
        fetchOptions();
    }, []);
    const getStylesOnCondition = (row, header, index) => {
        if (index === 0)
            return {
                backgroundColor: '#F7F7F7',
                color: '#000000',
                fontFamily: 'Gilroy-Bold',
            };
        if (row.dataType === 'Other Metrics')
            return {
                backgroundColor: '#FFF7E4',
                color: '#000000',
            };
        if (header.name === 'Data Type') {
            return {
                zIndex: '0',
            };
        }
    };
    const renderCell = (row, header, index, rowIndex) => {
        if (header.name === 'Data Type') {
            return (
                <div
                    style={
                        row.dataType === 'Other Metric (MIS)' ||
                        get(row, 'classification.name') === 'Unclassified'
                            ? {
                                  background: '#FFF7E4',
                                  position: 'relative',
                                  borderRight: '1px solid #eaecf0',
                                  borderBottom: '1px solid #eaecf0',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  height: '100%',
                              }
                            : {
                                  position: 'relative',
                                  borderRight: '1px solid #eaecf0',
                                  borderBottom: '1px solid #eaecf0',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  height: '100%',
                              }
                    }
                >
                    <EditableDropdown
                        id={`${row.id}dataType`}
                        inputStyle={
                            row.dataType === 'Other Metric (MIS)' ||
                            get(row, 'classification.name') === 'Unclassified'
                                ? { background: '#FFF7E4' }
                                : {}
                        }
                        wrapperStyle={
                            row.dataType === 'Other Metric (MIS)' ||
                            get(row, 'classification.name') === 'Unclassified'
                                ? {
                                      background: '#FFF7E4',
                                      width: '100%',
                                      padding: '0 0.75rem',
                                      height: '100%',
                                  }
                                : { width: '100%', padding: '0 0.75rem', height: '100%' }
                        }
                        removeBorder
                        disabled={isMappingConfirmed}
                        imgStyle={{ right: '0.25rem' }}
                        removeInput={true}
                        value={row.dataType}
                        options={dataTypeOptions ?? ['Data Not Found']}
                        setOutput={(value) => {
                            fetchClassificationDropdowns(value, rowIndex);
                            handleOptionChange(value, row, 'dataType');
                            setShowOptionValue({ ...showOptionValue, [rowIndex]: true });
                            if (value === 'Other Metric (MIS)') {
                                setDisableInput({ ...disableInput, [rowIndex]: true });
                            } else {
                                setDisableInput({ ...disableInput, [rowIndex]: false });
                            }
                        }}
                    />
                </div>
            );
        }
        if (header.name === 'Additional Classification') {
            return (
                <div
                    style={
                        row.dataType === 'Other Metric (MIS)' ||
                        get(row, 'classification.name') === 'Unclassified'
                            ? {
                                  background: '#FFF7E4',
                                  position: 'relative',
                                  borderRight: '1px solid #eaecf0',
                                  borderBottom: '1px solid #eaecf0',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  height: '100%',
                              }
                            : {
                                  position: 'relative',
                                  borderRight: '1px solid #eaecf0',
                                  borderBottom: '1px solid #eaecf0',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  height: '100%',
                              }
                    }
                >
                    <EditableDropdown
                        id={`${row.id}additionalClassification`}
                        wrapperStyle={{ width: '100%', padding: '0 0.75rem', height: '100%' }}
                        inputStyle={
                            row.dataType === 'Other Metric (MIS)' ||
                            get(row, 'classification.name') === 'Unclassified'
                                ? { background: '#FFF7E4' }
                                : {}
                        }
                        removeBorder
                        disabled={disableInput[rowIndex]}
                        imgStyle={{ right: '0.25rem' }}
                        removeInput={true}
                        value={
                            showOptionValue[rowIndex]
                                ? classificationDropdowns?.[rowIndex]?.[0]
                                : get(row, header.key) ?? '-'
                        }
                        options={classificationDropdowns?.[rowIndex] ?? ['Select Data Type First']}
                        setOutput={(value) => {
                            handleOptionChange(value, row, 'additionalClassification');
                        }}
                    />
                </div>
            );
        }
        if (header.name === 'Data Format') {
            return (
                <div
                    style={
                        row.dataType === 'Other Metric (MIS)' ||
                        get(row, 'classification.name') === 'Unclassified'
                            ? {
                                  background: '#FFF7E4',
                                  position: 'relative',
                                  borderRight: '2px solid #eaecf0',
                                  borderBottom: '1px solid #eaecf0',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  height: '100%',
                              }
                            : {
                                  position: 'relative',
                                  borderRight: '2px solid #eaecf0',
                                  borderBottom: '1px solid #eaecf0',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  height: '100%',
                              }
                    }
                >
                    <EditableDropdown
                        id={`${row.id}dataFormat`}
                        wrapperStyle={{ width: '100%', padding: '0 0.75rem', height: '100%' }}
                        removeBorder
                        inputStyle={
                            row.dataType === 'Other Metric (MIS)' ||
                            get(row, 'classification.name') === 'Unclassified'
                                ? { background: '#FFF7E4' }
                                : {}
                        }
                        imgStyle={{ right: '0.25rem' }}
                        removeInput={true}
                        value={get(row, header.key) ?? '-'}
                        options={dataFormatOptions ?? ['Select Data Type First']}
                        setOutput={(value) => {
                            handleOptionChange(value, row, 'dataFormat');
                        }}
                    />
                </div>
            );
        }
        return (
            <div
                style={getStylesOnCondition(row, header, index)}
                key={index}
                className={styles.TableCell}
                title={get(row, header.key)}
            >
                {get(row, header.key) ?? '-'}
            </div>
        );
    };

    return (
        <div style={{ padding: '1rem', height: '90%', overflow: 'hidden' }}>
            <div className={styles.TableWrapper}>
                <div className={styles.FixedColumns}>
                    <div className={styles.TableHeader}>
                        {tableValue?.data?.headers?.slice(1, 5).map((header, index) => (
                            <div
                                style={
                                    index === 0
                                        ? { backgroundColor: '#F7F7F7' }
                                        : header.name === 'Data Format'
                                        ? { borderRight: '2px solid #eaecf0' }
                                        : {}
                                }
                                key={index}
                                className={styles.TableHeaderCell}
                                title={header.name}
                            >
                                {header.name}
                            </div>
                        ))}
                    </div>
                    <div className={styles.TableBody}>
                        {tableValue?.data?.rows?.map((row, rowIndex) => (
                            <div key={rowIndex} className={styles.TableRow}>
                                {tableValue?.data?.headers.slice(1, 5).map((header, index) => {
                                    return renderCell(row, header, index, rowIndex);
                                })}
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.ScrollableColumns}>
                    <div className={styles.TableHeader}>
                        {tableValue?.data?.headers?.slice(5).map((header, index) => (
                            <div key={index} className={styles.TableHeaderCell}>
                                {header.name ?? '-'}
                            </div>
                        ))}
                    </div>
                    <div className={styles.TableBody}>
                        {tableValue?.data?.rows?.map((row, index) => (
                            <div key={index} className={styles.TableRow}>
                                {tableValue?.data?.headers?.slice(5).map((header, index) => {
                                    return (
                                        <div key={index} className={styles.TableCell}>
                                            {row.mis_amounts.find(
                                                (item) => item.period === header.name,
                                            )?.amount ??
                                                get(row, header.key) ??
                                                '-'}
                                        </div>
                                    );
                                })}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditableTable;
