import styles from './UnlockScanButton.module.scss';
import whiteLock from '../../../../../../../assets/white-lock.svg';

function UnlockScanButton({ onClick }) {
    return (
        <div className={styles.UnlockScanButton} onClick={onClick}>
            <img src={whiteLock} alt="" height={'20px'} />
            Unlock Insights
        </div>
    );
}

export default UnlockScanButton;
