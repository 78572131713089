import React from 'react';
import styles from './TableSelector.module.scss';

const Table = (props) => {
    const { attributes, children, element } = props;
    return (
        <table className={styles.EditorTable}>
            <tbody {...attributes}>{children}</tbody>
        </table>
    );
};

export default Table;
