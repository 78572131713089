import React from 'react';

const PlusIcon = ({ colour = '#E36414', height = '15', width = '15' }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
            <g id="+">
                <path
                    id="Path"
                    d="M5.64854 9.13828V15H9.35146V9.13828H15V5.86172H9.35146V0H5.64854V5.86172H0V9.13828H5.64854Z"
                    fill={colour}
                />
            </g>
        </svg>
    );
};

export default PlusIcon;
