import { useEffect } from 'react';
// import { toast } from 'react-toastify';
import Modal from '../../../../../common/_custom/Modal/Modal';

export default function FinboxModal({ showFinbox, finboxUrl, onSuccess, onExit, onError }: any) {
    useEffect(() => {
        window.addEventListener('message', eventTrigrred);
        return () => {
            window.removeEventListener('message', eventTrigrred);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const eventTrigrred = (e: any) => {
        if (e.data.status === 'exit') {
            onExit(e.data.payload);
        } else if (e.data.status === 'success') {
            onSuccess(e.data.payload);
        } else if (e.data.status === 'error') {
            onError(e.data.payload);
        }
    };

    if (finboxUrl) {
        return (
            <div>
                <Modal open={showFinbox} onClose={onExit} size="flexible" withoutCloseButton>
                    <iframe title="Finbox" src={finboxUrl} width="470px" height="700px" />
                </Modal>
            </div>
        );
    } else {
        // toast.error('Some error occurred');
        return null;
    }
}
