import clsx from 'clsx';
import styles from './InvestmentReport.module.scss';
import { ctaWhiteRight, downloadIconDataRoomHovered } from '../../../assets/hostedassets';
import LoadingSpinner from '../../_custom/LoadingSpinner/LoadingSpinner';
import { useEffect, useRef, useState } from 'react';
import { getInvestmentReport } from '../../../store/investor/apiHelpers';
import ToggleButton from '../../ToggleButton/ToggleButton';
import Tooltip from '../../_custom/Tooltip/Tooltip';
import { toast } from 'react-toastify';
import { downloadFile } from '../../../modules/Company/UploadJourney/components/UploadCard/utils/api/helpers';
import { Mixpanel } from '../../../utils/mixpanel';
import { AICA_GENERATE_INVESTMENT_REPORT } from '../../../utils/constants/mixpanelEvents/investorEvents';
import { useAppSelector } from '../../../app/hooks';
import { FILE_SHARE_STATUS } from '../DealDrawerConstants';

export const GENERATION_STATUS = {
    IN_PROGRESS: 'IN_PROGRESS',
    SUCCESS: 'SUCCESS',
};

function InvestmentReport({
    isLocked = true,
    metadataUpdate,
    onShareToggle,
    existingData,
    source,
    assesseeOrgId,
    companyName,
}) {
    const [generating, setGenerating] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const userData = useAppSelector((state) => state.user.userData);
    const dealDrawerState = useAppSelector((state) => state.investor.dealDrawer);
    const apiPollingRef: any = useRef(null);
    const pollingCount = useRef(0);

    useEffect(() => {
        return () => {
            if (apiPollingRef?.current) clearInterval(apiPollingRef.current);
        };
    }, []);

    const fetchInvestmentReport = (pollingCount) => {
        pollingCount.current += 1;
        setGenerating(true);
        getInvestmentReport(
            { assesseeOrgId: assesseeOrgId },
            {
                onSuccess: (data) => {
                    const reportGenStatus = data?.reportGenerationStatus;
                    if (pollingCount.current >= 10) {
                        clearInterval(apiPollingRef.current);
                        toast.error(data?.responseData?.responseMessage);
                        setGenerating(false);
                    } else {
                        if (reportGenStatus === GENERATION_STATUS.IN_PROGRESS) {
                            if (apiPollingRef.current) return;
                            apiPollingRef.current = setInterval(() => {
                                fetchInvestmentReport(pollingCount);
                            }, 10000);
                        } else if (reportGenStatus === GENERATION_STATUS.SUCCESS) {
                            if (apiPollingRef.current) clearInterval(apiPollingRef.current);
                            onInvestmentReportReceived({
                                fileName: data?.fileName,
                                docId: data?.fileUrl,
                                action: FILE_SHARE_STATUS.SEND_TO_INVESTOR,
                            });
                        }
                    }
                },
                onError: (error) => {
                    toast.error(error?.responseData?.responseMessage);
                    setGenerating(false);
                },
            },
        );
    };

    const onInvestmentReportReceived = (fileData) => {
        metadataUpdate(fileData);
        downloadGeneratedReport(fileData?.docId);
        setGenerating(false);
    };

    const generateButton = () => {
        return (
            <div
                className={clsx(styles.GenerateButton, {
                    [styles.Locked]: isLocked || source === 'Deals List Page',
                })}
                onClick={() => fetchInvestmentReport(pollingCount)}
            >
                {generating ? (
                    <LoadingSpinner height="15px" style={{ margin: 0 }} />
                ) : (
                    <img src={ctaWhiteRight} alt="" height={'15px'} />
                )}
                {generating ? 'Generating...' : 'Generate Investment Report'}
            </div>
        );
    };

    const downloadGeneratedReport = (documentId = '') => {
        if (!downloading) {
            setDownloading(true);
            downloadFile(
                Number(documentId),
                () => {
                    setDownloading(false);
                },
                `Investment_Report_${companyName}`,
                () => {
                    setDownloading(false);
                },
            );

            Mixpanel.track(AICA_GENERATE_INVESTMENT_REPORT, {
                parentId: userData.investorOrgId,
                userId: userData.userId,
                userRole: userData.accountType,
                companyOrgId: dealDrawerState.companyId,
            });
        }
    };

    return (
        <div className={styles.InvestmentReport}>
            {!existingData ? (
                <>
                    {isLocked ? (
                        <Tooltip
                            content="Please unlock AICA report to generate Investment Report"
                            direction="left"
                            fitContent
                            containerFullWidth
                        >
                            {generateButton()}
                        </Tooltip>
                    ) : (
                        <>{generateButton()}</>
                    )}
                </>
            ) : (
                <div className={styles.FileRow}>
                    <div
                        className={styles.Left}
                        onClick={() => downloadGeneratedReport(existingData?.docId)}
                    >
                        {downloading ? (
                            <LoadingSpinner
                                height="13px"
                                style={{ margin: 0 }}
                                color="var(--primary-text-colour)"
                            />
                        ) : (
                            <img src={downloadIconDataRoomHovered} alt="" height={'13px'} />
                        )}
                        Investment Report
                    </div>
                    <div className={styles.Right}>
                        Share
                        <ToggleButton
                            checked={existingData?.action === FILE_SHARE_STATUS.SEND_TO_INVESTOR}
                            onChange={() => {
                                onShareToggle(
                                    existingData?.action === FILE_SHARE_STATUS.SEND_TO_INVESTOR
                                        ? FILE_SHARE_STATUS.SKIP_NOTIFICATION
                                        : FILE_SHARE_STATUS.SEND_TO_INVESTOR,
                                );
                            }}
                            disabled={source === 'Deals List Page'}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default InvestmentReport;
