import { Box, Typography, DialogContent, CircularProgress, DialogActions } from '@mui/material';
import { useGSTContext } from '../../Context/GstContext';

const GstLoader = () => {
    const { gstData } = useGSTContext();
    return (
        <DialogContent
            sx={{
                width: '330px',
                maxWidth: '100%',
                margin: '0 0 80px 0',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    margin: '0 0 40px 0',
                }}
            >
                <Typography
                    color={'#000000'}
                    fontWeight={'600'}
                    fontSize={'14px'}
                    fontFamily={'gilroy-medium, sans-serif'}
                >
                    {gstData?.gstin_number}
                </Typography>
                <Typography
                    color={'#AEAEAE'}
                    fontSize={'14px'}
                    fontWeight={500}
                    fontFamily={'gilroy'}
                >
                    {gstData?.state}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                    rowGap: '5px',
                    width: '100%',
                    height: '92px',
                    borderRadius: '8px',
                    backgroundColor: '#FBFBFB',
                    color: '#000000',
                    padding: '10px',
                }}
            >
                <CircularProgress color="inherit" />
                <Typography
                    color={'#000000'}
                    fontWeight={'600'}
                    fontSize={'10px'}
                    fontFamily={'gilroy-medium, sans-serif'}
                    textAlign={'center'}
                >
                    {' '}
                    Fetching GST data
                </Typography>
            </Box>
            <DialogActions></DialogActions>
        </DialogContent>
    );
};

export default GstLoader;
