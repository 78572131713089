export const FILE_FORMATS = {
    PDF_AND_ZIP: ['.pdf', '.zip'],
    EXCEL: [
        '.csv',
        '.xls',
        '.xlsx',
        '.xlsm',
        '.xlsb',
        '.xltx',
        '.xltm',
        '.xlt',
        '.xlam',
        '.xla',
        '.xlw',
        '.xlr',
    ],
    PDF: ['.pdf'],
    EXCEL_PDF: [
        '.pdf',
        '.csv',
        '.xlsx',
        '.xlsm',
        '.xlsb',
        '.xltx',
        '.xltm',
        '.xls',
        '.xlt',
        '.xlam',
        '.xla',
        '.xlw',
        '.xlr',
        '.pptx',
        '.ppt',
        '.zip',
        '.jpg',
        '.jpeg',
        '.tar',
        '.txt',
        '.png',
    ],
    JSON: ['.json'],
};

export const bank_names = [
    'AU Small Finance Bank',
    'Abhyudaya Co-operative Bank Ltd',
    'Airtel Payments Bank',
    'Allahabad Bank',
    'Andhra Bank',
    'Andhra Pradesh Grameena Vikas Bank',
    'Axis Bank',
    'Bandhan Bank',
    'Bank of Baroda',
    'Bank of India',
    'Bank of Maharashtra',
    'Bharat Co-Operative Bank',
    'Canara Bank',
    'Central Bank of India',
    'Citibank',
    'City Union Bank',
    'Corporation Bank',
    'DBS Bank',
    'DCB Bank',
    'Dena Bank',
    'Deutsche Bank',
    'Dhanlaxmi Bank',
    'ESAF Small Finance Bank',
    'Equitas Small Finance Bank',
    'Federal Bank',
    'Fincare Small Finance Bank',
    'Fingrowth Co-Operative Bank',
    'GS Mahanagar Co-Op Bank',
    'HDFC Bank',
    'HSBC Bank',
    'ICICI Bank',
    'IDBI Bank',
    'IDFC First Bank',
    'Indian Bank',
    'Indian Overseas Bank',
    'IndusInd Bank',
    'Jammu and Kashmir Bank',
    'Janakalyan Sahakari Bank Ltd',
    'Janata Sahakari Bank Ltd',
    'Karnataka Bank',
    'Karnavati Co-op Bank Ltd',
    'Karur Vysya Bank',
    'Khattri Coop Urban Bank Ltd',
    'Kotak Mahindra Bank',
    'Lakshmi Vilas Bank',
    'Maharashtra Gramin Bank',
    'NKGSB Co-operative Bank',
    'National Mercantile Co-operative Bank Limited',
    'New India Co-Operative Bank',
    'Nutan Nagarik Sahakari Bank Limited',
    'Oriental Bank of Commerce',
    'Paytm Payments Bank',
    'Pochampally Co-Operative Urban Bank',
    'Prime Co-operative Bank Ltd',
    'Punjab & Sind Bank',
    'Punjab National Bank',
    'RBL Bank',
    'SVC Co-Operative Bank',
    'Saraswat Co-operative Bank',
    'Shinhan Bank India',
    'South Indian Bank',
    'State Bank of India',
    'Standard Chartered Bank',
    'Suryoday Small Finance Bank',
    'Syndicate Bank',
    'TJSB Sahakari Bank Ltd',
    'Tamilnad Mercantile Bank',
    'The Ahmedabad Mercantile Co-operative Bank Limited',
    'The Vaidyanath Urban Co-Op Bank Ltd',
    'UCO Bank',
    'Ujjivan Small Finance Bank',
    'Union Bank of India',
    'United Bank of India',
    'Vijaya Bank',
    'Yes Bank',
    'Utkarsh Small Finance Bank',
];

export const fileWithDescriptionDropdownOptions = [
    { id: '1', label: 'Sanction Letters' },
    { id: '2', label: 'Equity Infusion/Equity raise docs' },
    { id: '3', label: 'Receivables Ageing' },
    { id: '4', label: 'Bureau Reports' },
    { id: '5', label: 'Invoicing Data' },
    { id: '6', label: 'Pitch Deck and Other Documents' },
    { id: '7', label: 'Shareholding Pattern' },
    { id: '8', label: 'Financial Projections' },
    { id: '9', label: 'ITR Filings' },
    { id: '10', label: 'Ageing Details' },
    { id: '11', label: 'Others' },
];

export const addTimeStamp = (fileName: string) => {
    // Add timestamp to the file name before the extension
    const fileExtension = fileName.split('.').pop();
    const fileNameWithoutExtension = fileName.split('.').slice(0, -1).join('.');
    const timeStamp = new Date().getTime();
    return `${fileNameWithoutExtension}_${timeStamp}.${fileExtension}`;
};
