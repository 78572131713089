import React from 'react';
import styles from './DebtProductOverview.module.scss';
import ProbabilityBar from '../ProbabilityBar/ProbabilityBar';
import { debtProdTermsError, debtProdTermsOk } from '../../../../assets/hostedassets';
import { DebtProductRowData } from '../../debtProductTypes';
import SideDrawer from '../../../../common/_custom/SideDrawer/SideDrawer';
import closeIcon from '../../../../common/_custom/Modal/close.svg';

const STATUS = {
    PASSED: 'PASSED',
    FAILED: 'FAILED',
};

function DebtProductOverview({
    data,
    closeModal,
}: {
    data: DebtProductRowData;
    closeModal: Function;
}) {
    const overviewData = data?.details;
    const termsData = overviewData?.terms || {};
    const eligibilityData = overviewData?.eligibility || {};

    const getStatusIcon = (status) => {
        switch (status) {
            case true:
                return debtProdTermsOk;
            case false:
            default:
                return debtProdTermsError;
        }
    };

    return (
        <SideDrawer width={'50%'} onClose={() => closeModal()} allowClose>
            <div className={styles.DebtProductOverview}>
                <div className={styles.Header}>
                    <span>
                        {data.productName || '-'}
                        {!!data?.lenderName && (
                            <span className={styles.LenderName}> | {data.lenderName}</span>
                        )}
                    </span>

                    <img
                        className={styles.CloseIcon}
                        src={closeIcon}
                        alt="close"
                        height="13px"
                        width="13px"
                        onClick={() => closeModal?.()}
                    />
                </div>

                <div className={styles.Body}>
                    {!!Object.keys(termsData)?.length && (
                        <div className={styles.Table}>
                            <div className={styles.TableHeader}>
                                <div className={styles.Metric}>Tentative Terms</div>
                                <ProbabilityBar probability={data?.probability} />
                            </div>
                            {Object.entries(termsData)?.map(([key, val]: [string, any]) => (
                                <div key={key} className={styles.Row}>
                                    <div className={styles.Metric}>{val.text}</div>

                                    {val?.MATCHED?.length || val?.UNMATCHED?.length ? (
                                        <div className={styles.ValueRows}>
                                            {val?.MATCHED?.map((str, i) => (
                                                <div className={styles.ValueRow} key={i}>
                                                    <img
                                                        src={debtProdTermsOk}
                                                        alt={'error'}
                                                        height={'14px'}
                                                    />
                                                    {str}
                                                </div>
                                            ))}
                                            {val?.UNMATCHED?.map((str, i) => (
                                                <div className={styles.ValueRow} key={i}>
                                                    <img
                                                        src={debtProdTermsError}
                                                        alt={'ok'}
                                                        height={'14px'}
                                                    />
                                                    {str}
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div className={styles.Value}>
                                            <img
                                                src={getStatusIcon(val.STATUS)}
                                                alt={'ok'}
                                                height={'14px'}
                                            />
                                            <div className={styles.CalcValue}>
                                                {val?.COMPANY_VALUE}
                                            </div>
                                            <div className={styles.OriginalValue}>
                                                / {val?.PEC_VALUE}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}

                    {!!Object.keys(eligibilityData)?.length && (
                        <div className={styles.Table}>
                            <div className={styles.TableHeader}>
                                <div className={styles.Metric}>Eligibility Criteria</div>
                            </div>
                            {Object.entries(eligibilityData)?.map(([key, val]: [string, any]) => (
                                <div key={key} className={styles.Row}>
                                    <div className={styles.Metric}>{val.text}</div>

                                    {val?.MATCHED?.length || val?.UNMATCHED?.length ? (
                                        <div className={styles.ValueRows}>
                                            {val?.MATCHED?.map((str, i) => (
                                                <div className={styles.ValueRow} key={i}>
                                                    <img
                                                        src={debtProdTermsOk}
                                                        alt={'ok'}
                                                        height={'14px'}
                                                    />
                                                    {str}
                                                </div>
                                            ))}
                                            {val?.UNMATCHED?.map((str, i) => (
                                                <div className={styles.ValueRow} key={i}>
                                                    <img
                                                        src={debtProdTermsError}
                                                        alt={'ok'}
                                                        height={'14px'}
                                                    />
                                                    {str}
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div className={styles.Value}>
                                            <img
                                                src={getStatusIcon(val.STATUS)}
                                                alt={'ok'}
                                                height={'14px'}
                                            />
                                            <div className={styles.CalcValue}>
                                                {val?.COMPANY_VALUE}
                                            </div>
                                            <div className={styles.OriginalValue}>
                                                / {val?.PEC_VALUE}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </SideDrawer>
    );
}

export default DebtProductOverview;
