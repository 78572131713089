import { Button, Input, Modal } from '../../../common/_custom';
import styles from './AddAssessee.module.scss';
import React from 'react';
import { COUNTRY_CODE, EMAIL, NAME, PHONE } from '../../../utils/constants/companyLogin';
import CountryCodesDropdown from '../CompanyLogin/CountryCodesDropdown/CountryCodesDropdown';
import useAddAssessee from './useAddAssessee';

type AddAssesseeProps = {
    modalOpen: boolean;
    closeModal: Function;
    assesseeOrgId: string;
};

const AddAssessee = (props: AddAssesseeProps) => {
    const { modalOpen, closeModal, assesseeOrgId } = props;
    const { formData, changeFormValue, disabled, loading, onInviteUserClick, setFormData } =
        useAddAssessee(assesseeOrgId, closeModal);
    return (
        <Modal
            open={modalOpen ?? true}
            onClose={() => {
                closeModal(false);
                setFormData({
                    [NAME]: '',
                    [EMAIL]: '',
                    [PHONE]: '',
                    [COUNTRY_CODE]: null,
                });
            }}
            persistent
            size="flexible"
            style={{ padding: '0' }}
        >
            <div className={styles.AddAssesseeWrapper}>
                <div className={styles.Title}>Send Invite</div>
                <div className={styles.FormContainer}>
                    <Input
                        value={formData[NAME]}
                        onChange={(val) => changeFormValue(val, NAME)}
                        placeholder={'Full Name'}
                    />
                    <Input
                        value={formData[EMAIL]}
                        onChange={(val) => changeFormValue(val, EMAIL)}
                        placeholder={'Email ID'}
                    />
                    <div className={styles.Row}>
                        <CountryCodesDropdown
                            //@ts-ignore
                            style={{ fontFamily: 'Gilroy-Medium' }}
                            setCountryCode={(val) => changeFormValue(val, COUNTRY_CODE)}
                        />
                        <Input
                            placeholder="Phone Number"
                            value={formData[PHONE]}
                            onChange={(val) => changeFormValue(val, PHONE)}
                            style={{ marginTop: '0', width: '100%' }}
                            type="number"
                            // isValid={!formErrors[PHONE]}
                            // errorMessage={formErrors[PHONE]}
                        />
                    </div>
                    <Button
                        onClick={onInviteUserClick}
                        text={'Send Invite'}
                        variant="purple"
                        disabled={disabled}
                        loading={loading}
                        style={{ width: '100%' }}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default AddAssessee;
