import styles from './FilteredPills.module.scss';
import { PURPLE_CROSS } from '../../../../assets/hostedassets';
import CrossIcon from '../../../../assets/Svg/CrossIcon';

type FilteredPillsProps = {
    filteredPills: {
        [key: string]: {
            value: string;
            label: string;
        }[];
    };
    setFilteredPills: React.Dispatch<
        React.SetStateAction<{
            assesseeOrgIdList: { value: string; label: string }[];
            userIds: { value: string; label: string }[];
            sources: { value: string; label: string }[];
        }>
    >;
};

const FilteredPills = (props: FilteredPillsProps) => {
    const { filteredPills, setFilteredPills } = props;

    const removePill = (pill: { value: string; label: string }) => {
        const newPills: any = { ...filteredPills };
        const key = Object.keys(filteredPills).find((k) =>
            filteredPills[k].some((p) => p.value === pill.value),
        ) as string;
        newPills[key] = newPills[key].filter((p) => p.value !== pill.value);
        setFilteredPills(newPills);
    };

    const clearAll = () => {
        setFilteredPills({
            assesseeOrgIdList: [],
            userIds: [],
            sources: [],
        });
    };
    const checkIFPillsEmpty = () => {
        for (const key in filteredPills) {
            if (filteredPills[key].length > 0) {
                return false;
            }
        }
        return true;
    };

    return (
        <>
            {checkIFPillsEmpty() ? (
                <>
                    <div className={styles.FilteredPillsWrapper}>
                        <span style={{ visibility: 'hidden' }}>No filters applied</span>
                    </div>
                </>
            ) : (
                <div className={styles.FilteredPillsWrapper}>
                    {Object.keys(filteredPills).map((pill) =>
                        filteredPills[pill].map((p) => (
                            <div key={p.value} className={styles.Pill}>
                                <span>{p.label}</span>
                                <span className={styles.CrossIcon} onClick={() => removePill(p)}>
                                    <CrossIcon
                                        colour={'var(--primary-text-colour)'}
                                        height="10"
                                        width="10"
                                    />
                                </span>
                            </div>
                        )),
                    )}
                    {/*Create a clear all button*/}
                    {!checkIFPillsEmpty() && (
                        <div className={styles.ClearAllPill} onClick={() => clearAll()}>
                            <span>Clear All</span>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default FilteredPills;
