import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import clsx from 'clsx';
import styles from './Dropdown.module.scss';
import bottomAngleIcon from '../../../assets/bottomAngleIcon.svg';
import searchIcon from '../../../assets/search-glass.svg';
import { useUpdateEffect } from '../../../utils/customHooks';
import { isEmpty } from '../../../utils/utils';
import { crossBtnGrey } from '../../../assets/hostedassets';
import { DropdownOption } from './dropdownTypes';

interface DropdownProps {
    customClass?: string;
    customListClass?: string;
    disabled?: boolean;
    enableSearch?: boolean;
    placeholder?: string;
    label?: string;
    collapseDropdown?: boolean;
    nowrap?: boolean;
    selectedOption: null | DropdownOption;
    optionsList: DropdownOption[];
    itemClickHandler: (item: DropdownOption) => void;
    style?: React.CSSProperties;
    inputStyle?: React.CSSProperties;
    dropdownName?: string;
    hasError?: boolean;
    darkPlaceholder?: boolean;
    errorMessage?: string;
    variant?: 'white' | 'gray';
    size?: 'normal' | 'tiny';
    listStyles?: React.CSSProperties;
    hideOtherOptionWhenNoMatch?: boolean;
}

const Dropdown = ({
    placeholder = 'Select',
    label = '',
    optionsList,
    selectedOption = null,
    itemClickHandler,
    disabled = false,
    enableSearch = false,
    collapseDropdown,
    style = {},
    inputStyle = {},
    hasError = false,
    errorMessage,
    darkPlaceholder = false,
    variant = 'gray',
    size = 'normal',
    listStyles,
    hideOtherOptionWhenNoMatch = false, // Default to false
}: DropdownProps) => {
    const [showList, setShowList] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selection, setSelection] = useState<DropdownOption | null>(selectedOption);
    const [keyboardSelectedOption, setKeyboardSelectedOption] = useState<DropdownOption | null>(
        null,
    );
    const [filteredOptions, setFilteredOptions] = useState<DropdownOption[]>(optionsList);
    const [noResults, setNoResults] = useState(false);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const [iconLink, setIconLink] = useState<string | JSX.Element>('');

    useEffect(() => {
        setSelection(selectedOption);
        setSearchTerm(selection?.label ?? '');
    }, [selectedOption]);

    useUpdateEffect(() => {
        if (disabled || collapseDropdown) setShowList(false);
    }, [disabled, collapseDropdown]);

    useEffect(() => {
        setFilteredOptions(optionsList);
    }, [optionsList]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setShowList(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    const resetSearchTermAndFilteredOptions = useCallback(
        (value: string) => {
            setSearchTerm(value);
            changeFilterOptionsFromValue(value);
        },
        [optionsList, enableSearch],
    );

    const changeFilterOptionsFromValue = useCallback(
        (value: string) => {
            let filter = [...optionsList];
            if (enableSearch && value) {
                filter = optionsList.filter((option) =>
                    option.label.toLowerCase().includes(value.toLowerCase()),
                );
                if (filter.length === 0) {
                    setNoResults(true);
                    if (hideOtherOptionWhenNoMatch) {
                        filter = [{ label: 'No match found', id: 'no-match', notSelectable: true }];
                    } else {
                        filter = [{ label: 'Other', id: 0 }];
                    }
                } else {
                    setNoResults(false);
                }
            }
            setFilteredOptions(filter);
        },
        [optionsList, enableSearch, hideOtherOptionWhenNoMatch],
    );

    useEffect(() => {
        // setIconLink as soon as the component mounts and search from optionsList
        let tempIconLink: string | JSX.Element = '';
        optionsList.forEach((option) => {
            if (option.label === selectedOption?.label) {
                tempIconLink = option.icon ?? '';
            }
        });
        setIconLink(tempIconLink);
    }, []);

    const onSearchTermChange = useCallback(
        (value: string) => {
            setSearchTerm(value);
            changeFilterOptionsFromValue(value);
            setSelection(value !== selectedOption?.label ? null : selectedOption);
            if (!showList) setShowList(true);
            setKeyboardSelectedOption(null);
            if (value) setNoResults(false);
        },
        [changeFilterOptionsFromValue, showList, selectedOption],
    );
    const onOptionClick = useCallback(
        (option: DropdownOption) => {
            if (option.notSelectable) {
                setSelection(null);
                setSearchTerm('');
                setFilteredOptions(optionsList);
                setShowList(false);
                setIconLink('');
                return;
            }
            setIconLink(option?.icon ?? '');
            itemClickHandler(option);
            setSearchTerm(option.label);
            setSelection(option);
            setNoResults(false);
            inputRef.current?.blur?.();
        },
        [itemClickHandler, optionsList],
    );

    const getDisplayValue = () => {
        if (document.activeElement === inputRef.current && enableSearch) return searchTerm;
        return selection ? selection.label : '';
    };

    const closeListOnBlur = () => {
        setShowList(false);
        resetSearchTermAndFilteredOptions(selection ? selection.label : '');
    };

    const handleRemove = () => {
        setSelection(null);
        setIconLink('');
        itemClickHandler({ id: '', label: '' });
        resetSearchTermAndFilteredOptions('');
    };

    const dropdownIcon = useMemo(() => {
        if (disabled) return null;
        return (
            <img
                className={clsx(styles.DropdownIcon, {
                    [styles.DropdownIconInverted]: showList && !enableSearch,
                })}
                src={enableSearch ? searchIcon : bottomAngleIcon}
                alt="icon"
                width={enableSearch ? '16px' : '24px'}
                height={enableSearch ? '16px' : '24px'}
            />
        );
    }, [disabled, enableSearch, showList]);

    const keyNavigation = useCallback(
        (e) => {
            const { key } = e;
            if (key === 'Tab') {
                closeListOnBlur();
                return;
            }

            if (key === 'ArrowDown' || key === 'ArrowUp' || key === 'Enter') {
                e.preventDefault();
            }

            if (!keyboardSelectedOption && key === 'ArrowDown') {
                setKeyboardSelectedOption(filteredOptions?.[0]);
            } else if (keyboardSelectedOption) {
                const currentIndex = filteredOptions.findIndex(
                    (option) => option.id === keyboardSelectedOption.id,
                );
                let nextIndex = currentIndex;

                if (key === 'ArrowDown') {
                    nextIndex = currentIndex + 1;
                } else if (key === 'ArrowUp') {
                    nextIndex = currentIndex - 1;
                } else if (key === 'Enter') {
                    onOptionClick(keyboardSelectedOption);
                    return;
                }

                const nextOption = filteredOptions[nextIndex];
                if (nextOption) {
                    setKeyboardSelectedOption(nextOption);
                    document.getElementById(`option-${nextIndex}`)?.scrollIntoView({
                        block: 'nearest',
                        inline: 'nearest',
                        behavior: 'smooth',
                    });
                }
            }
        },
        [keyboardSelectedOption, filteredOptions, onOptionClick],
    );

    return (
        <div
            ref={dropdownRef}
            className={clsx(styles.Dropdown, {
                [styles.Error]: (hasError || noResults) && !!errorMessage,
                [styles.Disabled]: disabled,
            })}
            onClick={() => {
                if (enableSearch && !showList) setSearchTerm(selection?.label || '');
                if (!showList) inputRef.current?.focus?.();
                setShowList(!showList);
            }}
            onBlur={closeListOnBlur}
            onKeyDown={keyNavigation}
            onMouseDown={(e) => e.preventDefault()}
            style={style}
        >
            {label &&
            (selection?.id === null ||
                selection?.label === '' ||
                selection?.label === undefined) ? null : (
                <label className={styles.Label}>{label}</label>
            )}
            <div
                className={clsx(styles.Main, {
                    [styles.White]: variant === 'white',
                    [styles.Tiny]: size === 'tiny',
                    [styles.hasData]: showList || inputRef.current?.value,
                    [styles.noFocus]: selection && !showList,
                })}
            >
                <div className={styles.IconContainer}>
                    {iconLink ? (
                        typeof iconLink === 'string' ? (
                            <img src={iconLink} alt="icon" className={styles.IconStuff} />
                        ) : (
                            <span className={styles.IconStuff}>{iconLink}</span>
                        )
                    ) : null}
                    <input
                        id="input"
                        className={clsx(styles.Input, {
                            [styles.SelectedValue]:
                                selection || document.activeElement === inputRef.current,
                            [styles.Frozen]: !enableSearch,
                            [styles.White]: variant === 'white',
                            [styles.hasData1]: !!getDisplayValue(),
                        })}
                        style={iconLink ? { paddingLeft: '1rem' } : {}}
                        value={getDisplayValue()}
                        onChange={(e) => onSearchTermChange(e.target.value)}
                        placeholder={placeholder}
                        onFocus={() =>
                            setSearchTerm(
                                enableSearch && selection?.label ? selection.label + ' ' : '',
                            )
                        }
                        disabled={!enableSearch || disabled}
                        ref={inputRef}
                    />
                </div>
                {searchTerm ? (
                    <>
                        {disabled ? (
                            <></>
                        ) : (
                            <img src={crossBtnGrey} alt="removeIcon" onClick={handleRemove} />
                        )}
                    </>
                ) : (
                    dropdownIcon
                )}
                {showList && (
                    <div style={listStyles} className={styles.List}>
                        {filteredOptions.map((option, index) => (
                            <div
                                id={`option-${index}`}
                                onMouseDown={() => onOptionClick(option)}
                                key={option.id}
                                className={clsx(styles.ListItem, {
                                    [styles.ItemHovered]: keyboardSelectedOption?.id === option.id,
                                })}
                            >
                                {option.icon &&
                                    (typeof option.icon === 'string' ? (
                                        <img src={option.icon} alt="icon" className={styles.Icon} />
                                    ) : (
                                        <span className={styles.Icon}>{option.icon}</span>
                                    ))}
                                {option.label}
                            </div>
                        ))}
                    </div>
                )}
            </div>
            {!isEmpty(errorMessage) && (hasError || noResults) && (
                <div
                    className={clsx(styles.ErrorMsg, {
                        [styles.HideErrorMsg]: !(hasError || noResults),
                    })}
                >
                    {errorMessage}
                </div>
            )}
        </div>
    );
};

export default Dropdown;
