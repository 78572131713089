import { useEffect, useState } from 'react';
import { Mixpanel } from '../../utils/mixpanel';
import { AICA_DOWNLOAD_DATA } from '../../utils/constants/mixpanelEvents/investorEvents';
import { setRolesForUser } from '../../store/user/userV2/reducer';
import { getTimeStamp } from '../../utils/dateUtils';
import { exportRawFromUnderwritingAndPolus } from '../../store/company/apiHelpers';
import { useAppSelector } from '../../app/hooks';
import { getFeatureStatus, isRestrictedUser } from '../../utils/utils';
import { RESTRICTED_ROLES } from '../../store/investor/constants';
import { FEATURES } from '../../store/user/constants';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { unlockAICAReport } from '../../store/investor/investorApiHelper';
import { fetchInvestorConfig, fetchSingleCompanyDetails } from '../../store/investor/action';
import { useDispatch } from 'react-redux';
import { changeOrgIdHeaderForCharts } from '../../utils/axios-interceptor';
import axios from 'axios';

const useLegacyBusinessDataFetch = () => {
    const [exportingData, setExportingData] = useState(false);
    const user = useAppSelector((state) => state.user);
    const [selectedCompany, setSelectedCompany] = useState<any>({});
    // const selectedCompany = useAppSelector((state) => state.investor.selectedCompany);
    const isUserRestricted = isRestrictedUser(user?.userData?.roles?.[0], RESTRICTED_ROLES);
    const availableFeatures = user.domainMetadata?.availableFeatures || [];
    const refreshReportStatus = getFeatureStatus(availableFeatures || [], FEATURES.REFRESH_REPORT);
    const downloadPDFStatus = getFeatureStatus(availableFeatures || [], FEATURES.DOWNLOAD_PDF);
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const params = useParams();

    const exportData = (stopAdditional = true) => {
        setExportingData(true);
        Mixpanel.track(AICA_DOWNLOAD_DATA, {
            Role: setRolesForUser(user.userData.roles?.[0]),
            InvestorOrgId: user.userData.investorOrgId,
            CompanyOrgId: selectedCompany?.assesseeOrgId,
            AICASection: selectedCompany?.companyAccessType,
            Timestamp: getTimeStamp(),
        });
        exportRawFromUnderwritingAndPolus(
            {
                assesseeOrgId: selectedCompany?.assesseeOrgId,
                orgName: selectedCompany?.orgName,
            },
            {
                onSuccess: () => {
                    setExportingData(false);
                },
                onError: () => {
                    setExportingData(false);
                },
            },
            stopAdditional,
        );
    };

    const goBackToPortfolio = () => {
        searchParams.delete('selectedTab');
        setSearchParams(searchParams);
        // const accessTypeInParams = searchParams.get('type');
        // const companyAccessType = selectedCompany?.companyAccessType;
        switch (location.state?.from) {
            case 'WATCH':
                navigate('/investor/app/watch');
                break;
            case 'SCAN':
                navigate('/investor/app/scan');
                break;
            case 'TRACK':
                navigate('/investor/app/track');
                break;
            case 'MONITOR':
                navigate('/investor/app/monitor');
                break;
            case 'DEALS':
                navigate('/lender/app/deals');
                break;
            default:
                navigate(
                    `/investor/app/${
                        selectedCompany?.companyAccessType?.toLowerCase() || 'watch'
                    }?fetchType=${searchParams.get('fetchType')}&page=${searchParams.get(
                        'page',
                    )}&type=${selectedCompany?.companyAccessType?.toLowerCase()}`,
                    { state: location.state },
                );
                break;
        }
    };

    // const renderHeaderButton = () => {
    //     return null;
    // };
    const unlockAicaReport = (callbackFunction = () => {}) => {
        params.id &&
            user.domainMetadata?.investorOrgId &&
            unlockAICAReport(
                {
                    assesseeOrgId: params.id,
                    investorOrgId: user.domainMetadata?.investorOrgId,
                },
                {
                    onSuccess: () => {
                        // callbackFunction && callbackFunction?.();
                        // refetchOverviewRef.current = true;
                    },
                },
            );
    };
    useEffect(() => {
        if (params.id && user.domainMetadata?.investorOrgId) {
            dispatch(
                fetchSingleCompanyDetails(
                    {
                        assesseeOrgId: params.id,
                        investorOrgId: user.domainMetadata?.investorOrgId,
                    },
                    {
                        onSuccess: (companyData) => setSelectedCompany(companyData),
                    },
                ),
            );
        }
    }, [user.domainMetadata?.investorOrgId, params.id]);

    useEffect(() => {
        if (user.userData.primaryEmail && user.userData.investorOrgId && params?.id) {
            params?.id && changeOrgIdHeaderForCharts(axios, params.id);
            dispatch(
                fetchInvestorConfig({
                    accountType: user.userData.accountType,
                    primaryEmail: user.userData.primaryEmail,
                    investorOrgId: user.userData.investorOrgId,
                    assesseeOrgId: params.id,
                    reportId: selectedCompany?.reportId || '',
                    userRole: user?.userData?.roles?.[0],
                    configName: 'AICA_DASHBOARD',
                }),
            );
        }

        return () => {
            const investorOrgId = user.userData.investorOrgId;
            changeOrgIdHeaderForCharts(axios, investorOrgId);
        };
    }, [user.userData.primaryEmail, user.userData.investorOrgId, params?.id]);

    return {
        exportingData,
        exportData,
        isUserRestricted,
        selectedCompany,
        refreshReportStatus,
        downloadPDFStatus,
        goBackToPortfolio,
        // renderHeaderButton,
        unlockAicaReport,
    };
};

export default useLegacyBusinessDataFetch;
