import React from 'react';
import closeIcon from '../../../../assets/close.svg';
import styles from '../SideDrawer.module.scss';

interface SideDrawerHeaderProps {
    children?: React.ReactNode;
    width?: string;
}
function SideDrawerHeader({ children, width }: SideDrawerHeaderProps) {
    return (
        <div className={styles.DrawerHeader}>
            <div style={width ? { width: width } : {}}>{children}</div>
        </div>
    );
}

export default SideDrawerHeader;
