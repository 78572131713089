import { MouseEventHandler, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Input, Button } from '../../../../common/_custom';
import Dropdown from '../../../../common/_custom/Dropdown';
import { DropdownOption } from '../../../../common/_custom/Dropdown/dropdownTypes';
import Form from '../../../../common/_custom/Form/Form';
import { sectorDropdownOptions } from '../../../../store/company/utilities';
import {
    IDENTIFIER,
    EMAIL,
    PAN_ERROR_MSG,
    NAME_ERROR_MSG,
    EMAIL_ERROR_MSG,
    PASSWORD,
    BUSINESS_NAME,
    SECTOR,
    WEBSITE,
    SECTOR_ERROR_MSG,
    WEBSITE_ERROR_MSG,
    BUSINESS_NAME_ERROR_MSG,
    PASSWORD_ERROR_MSG,
    PHONE,
    PHONE_ERROR_MSG,
    COUNTRY_CODE,
} from '../../../../utils/constants/companyLogin';
import { companyFormValidations } from '../../../../utils/constants/formValidations';
import { BASE_ROUTES } from '../../../../utils/constants/routesConst';
import { emailRegex } from '../../../../utils/utils';
import { CompanyLoginData, OTP_CREATE } from '../CompanyLogin';
import styles from './CompanySignupForm.module.scss';
import CountryCodesDropdown from '../CountryCodesDropdown/CountryCodesDropdown';
import { Mixpanel } from '../../../../utils/mixpanel';
import { COMPANY_CREATE_ACCOUNT } from '../../../../utils/constants/mixpanelEvents/companyEvents';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { createAccountV2 } from '../../../../store/user/userV2/actions';
import { USER_TYPE } from '../../../../utils/constants/user';
import {
    ENTER_WEBSITE_OR_LEGAL_NAME,
    WEBSITE_OK,
    IDENTIFIER_ERROR,
    IDENTIFIER_OK,
    IDENTIFIER_ERROR_WEBSITE_OK,
    SUCCESS,
} from '../../../Dashboard/components/InviteCompanyModal/InviteCompanyModal';
import { infoPurpleIcon } from '../../../../assets/hostedassets';
import CompanyLegalNameSearch, {
    CompanyData,
} from '../../../../common/CompanyLegalNameSearch/CompanyLegalNameSearch';

interface CompanySignupFormProps {
    formData: CompanyLoginData;
    handleFormChange: Function;
    handleSubmitClick: Function;
    setShowOtp: any;
}

interface CountryCodeData {
    countryId: string;
    countryName: string;
    countryCode: string;
    callingCode: string;
    minCallingNumberLength: number;
    maxCallingNumberLength: number;
}
const BORROWER_SIGNUP = 'BORROWER_SIGNUP';

function CompanySignupForm({
    formData,
    handleFormChange,
    setShowOtp, // handleSubmitClick,
}: CompanySignupFormProps) {
    const [identifierState, setIdentifierState] = useState(ENTER_WEBSITE_OR_LEGAL_NAME);
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();
    const query = new URLSearchParams(window.location.search);
    const email = query.get('email') || '';
    const name = query.get('name') || '';
    const isSigupLoading = useAppSelector((state) => state.user.creatingAccount);
    const domainMetadata = useAppSelector((state) => state.user.domainMetadata);
    const [searchParams, setSearchParams] = useSearchParams();
    const inviteCode = searchParams.get('inviteCode') ?? '';
    const companyPickedFromDropdown = useRef<CompanyData>(null);

    const disabled = !(
        (formData[IDENTIFIER] || formData[WEBSITE]) &&
        formData[BUSINESS_NAME] &&
        formData[EMAIL] &&
        formData[PASSWORD] &&
        formData[PHONE] &&
        formData[COUNTRY_CODE]
    );

    const navigate = useNavigate();
    const [errors, setErrors] = useState({
        [BUSINESS_NAME]: false,
        [IDENTIFIER]: '',
        [SECTOR]: false,
        [WEBSITE]: '',
        [EMAIL]: false,
        [PASSWORD]: false,
        [PHONE]: false,
    });

    useEffect(() => {
        if (companyFormValidations.isEmailValid(email)) {
            handleFormChange(email, [EMAIL]);
        }
        if (companyFormValidations.isNameValid(name)) {
            handleFormChange(name, [BUSINESS_NAME]);
        }
    }, []);

    const changeValue = (val, key) => {
        setErrors((errors) => ({
            ...errors,
            [key]: false,
        }));
        handleFormChange(val, key);
    };

    const onSubmit = () => {
        setErrors((errors) => ({
            ...errors,
            [BUSINESS_NAME]: !companyFormValidations.isNameValid(formData[BUSINESS_NAME]),
            [EMAIL]: !companyFormValidations.isEmailValid(formData[EMAIL]),
            [PASSWORD]: !companyFormValidations.isPasswordValid(formData[PASSWORD]),
            [WEBSITE]: !companyFormValidations.isWebsiteValid(formData[WEBSITE])
                ? WEBSITE_ERROR_MSG
                : '',
            [SECTOR]: !companyFormValidations.isSectorValid(formData[SECTOR]?.label),
            [PHONE]: !companyFormValidations.isPhoneValid(formData[PHONE], formData[COUNTRY_CODE]),
        }));

        if (companyFormValidations.allValid(formData)) {
            Mixpanel.track(COMPANY_CREATE_ACCOUNT);
            handleSubmitClick();
        }
    };

    const handleSubmitClick = () => {
        if (identifierState === IDENTIFIER_OK) {
            setShowOtp(OTP_CREATE);
        }
        dispatch(
            createAccountV2(
                {
                    user: {
                        name: formData[BUSINESS_NAME],
                        emailId: formData[EMAIL]?.toLowerCase(),
                        investorId: domainMetadata?.investorOrgId || '',
                        accountType: USER_TYPE.COMPANY,
                        inviteCode: inviteCode,
                        accountTypes: [USER_TYPE.COMPANY],
                        password: formData[PASSWORD],
                        journeyName: BORROWER_SIGNUP,
                        ...(formData[WEBSITE] && { websiteUrl: formData[WEBSITE] }),
                        ...(formData[IDENTIFIER] && { identifier: formData[IDENTIFIER] }),
                        ...(formData[WEBSITE] &&
                            formData[IDENTIFIER] && { newReqDomainAndIdentifier: true }),
                        // ...(formData[SECTOR]?.label && { sector: formData[SECTOR].label }),
                        mobileNumber: formData[PHONE],
                        countryId: formData[COUNTRY_CODE]?.countryId || '',
                        countryCode: formData[COUNTRY_CODE]?.callingCode || '',
                        triggerNotification: true,
                        ...(!!companyPickedFromDropdown.current && {
                            isPickedFromCompaniesList: true,
                            companyDetails: {
                                ...companyPickedFromDropdown.current,
                            },
                        }),
                    },
                    platformId: domainMetadata?.productName || '',
                },
                {
                    onSuccess: (data) => {
                        if (data?.user?.domainErrorMessage) {
                            setErrors((errors) => ({
                                ...errors,
                                [WEBSITE]: data?.user?.domainErrorMessage,
                                [IDENTIFIER]: '',
                            }));
                            if (
                                data?.user?.domainErrorMessage
                                    ?.toLowerCase?.()
                                    ?.includes?.('legal name')
                            ) {
                                setIdentifierState(WEBSITE_OK);
                            }
                            setLoading(false);
                        } else if (data?.user?.identifierErrorMessage) {
                            setErrors((errors) => ({
                                ...errors,
                                [WEBSITE]: '',
                                [IDENTIFIER]: data?.user?.identifierErrorMessage,
                            }));
                            if (
                                identifierState === WEBSITE_OK &&
                                formData[WEBSITE] &&
                                formData[IDENTIFIER]
                            )
                                setIdentifierState(IDENTIFIER_ERROR_WEBSITE_OK);
                            else setIdentifierState(IDENTIFIER_ERROR);
                            setLoading(false);
                        } else if (data?.user?.orgName) {
                            if (formData[IDENTIFIER]) {
                                const signupToken = data?.user?.signUpToken;
                                setSearchParams({ signupToken: signupToken });
                                setShowOtp(OTP_CREATE);
                            } else {
                                changeValue(data?.user?.orgName, IDENTIFIER);
                                setIdentifierState(IDENTIFIER_OK);
                            }
                            setLoading(false);
                        } else {
                            const signupToken = data?.user?.signUpToken;
                            setSearchParams({ signupToken: signupToken });
                            setShowOtp(OTP_CREATE);
                        }
                        setLoading(false);
                    },
                },
            ),
        );
    };

    return (
        <div className={styles.CompanySignupFormContainer}>
            <div className={styles.Title}>Getting Started!</div>

            <Form>
                <Input
                    placeholder="Full Name"
                    value={formData[BUSINESS_NAME]}
                    onChange={(val: string) => changeValue(val, BUSINESS_NAME)}
                    errorMessage={BUSINESS_NAME_ERROR_MSG}
                    isValid={!errors[BUSINESS_NAME]}
                    disabled={!!companyFormValidations.isNameValid(name)}
                />
                <Input
                    placeholder="Website"
                    value={formData[WEBSITE]}
                    onChange={(val: string) => changeValue(val, WEBSITE)}
                    errorMessage={errors[WEBSITE]}
                    isValid={formData[WEBSITE] ? !errors[WEBSITE] : true}
                    disabled={
                        identifierState === WEBSITE_OK ||
                        identifierState === IDENTIFIER_OK ||
                        identifierState === IDENTIFIER_ERROR_WEBSITE_OK ||
                        (!!formData[WEBSITE] && errors[WEBSITE]?.includes?.('legal')) ||
                        !!errors[IDENTIFIER]
                    }
                />

                <CompanyLegalNameSearch
                    placeholder="Company Legal Name or PAN"
                    value={formData[IDENTIFIER]}
                    onChange={(val: string) => {
                        if (
                            identifierState === IDENTIFIER_OK ||
                            identifierState === IDENTIFIER_ERROR_WEBSITE_OK ||
                            identifierState === IDENTIFIER_ERROR
                        ) {
                            if (formData[WEBSITE] && identifierState !== IDENTIFIER_ERROR)
                                setIdentifierState(WEBSITE_OK);
                            else setIdentifierState(ENTER_WEBSITE_OR_LEGAL_NAME);
                        }
                        changeValue(val, IDENTIFIER);
                    }}
                    errorMessage={errors[IDENTIFIER]}
                    isValid={!errors[IDENTIFIER]}
                    companyPickedFromDropdown={companyPickedFromDropdown}
                    source="company_signup"
                />
                {identifierState === IDENTIFIER_OK && (
                    <div className={styles.IdentifierFound}>
                        <img src={infoPurpleIcon} alt="" height={'10px'} />
                        Legal Name found! Please validate before submitting it.
                    </div>
                )}
                <div className={styles.Row}>
                    <CountryCodesDropdown
                        setCountryCode={(val) => changeValue(val, COUNTRY_CODE)}
                    />
                    <Input
                        type="number"
                        placeholder="Phone Number"
                        value={formData[PHONE]}
                        onChange={(val: string) => changeValue(val, PHONE)}
                        errorMessage={PHONE_ERROR_MSG}
                        isValid={!errors[PHONE]}
                        style={{ marginTop: '0', width: '100%' }}
                    />
                </div>

                <Input
                    placeholder="Email"
                    value={formData[EMAIL]}
                    onChange={(val: string) => changeValue(val.trim(), EMAIL)}
                    errorMessage={EMAIL_ERROR_MSG}
                    isValid={!errors[EMAIL]}
                    disabled={!!companyFormValidations.isEmailValid(email)}
                />
                <Input
                    placeholder="Password"
                    type="password"
                    value={formData[PASSWORD]}
                    onChange={(val: string) => changeValue(val, PASSWORD)}
                    errorMessage={PASSWORD_ERROR_MSG}
                    isValid={!errors[PASSWORD]}
                />

                <Button
                    text="Create Account"
                    loadingText="Please Wait"
                    loading={isSigupLoading}
                    onClick={onSubmit}
                    disabled={
                        disabled ||
                        (identifierState !== ENTER_WEBSITE_OR_LEGAL_NAME &&
                            !formData[IDENTIFIER]) ||
                        !!errors[IDENTIFIER]
                    }
                    variant="primary"
                />

                <div className={styles.Signup}>
                    Already have an account?&nbsp;
                    <button className={styles.Login} onClick={() => navigate(BASE_ROUTES.LOGIN)}>
                        Login.
                    </button>
                </div>
            </Form>
        </div>
    );
}

export default CompanySignupForm;
