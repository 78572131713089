import React from 'react';
import { useFocused, useSelected, useSlateStatic } from 'slate-react';
import { removeLink } from '../../utils/link.js';
import styles from './LinkStyles.module.scss';
// import { expandPurple } from '../../../../assets/hostedassets';

const Link = ({ attributes, element, children }) => {
    const editor = useSlateStatic();
    const selected = useSelected();
    const focused = useFocused();
    return (
        <div className={styles.Link}>
            <a href={element.href} {...attributes} {...element.attr} target={element.target}>
                {children}
            </a>
            {selected && focused && (
                <div className={styles.LinkPopup} contentEditable={false}>
                    <a href={element.href} target={element.target}>
                        {element.href}
                    </a>
                    {/* <button onClick={() => removeLink(editor)}> */}
                    <div style={{ border: '1px solid red' }}>
                        {' '}
                        <img src={expandPurple} alt="" className={styles.Img} />
                    </div>

                    {/* </button> */}
                </div>
            )}
        </div>
    );
};

export default Link;
