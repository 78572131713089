import React from 'react';

const FilteredIconMU = ({ colour = '#6021B3', width = '14', height = '10', style }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 14 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={style}
        >
            <path
                d="M5.5 9.5V8H8.5V9.5H5.5ZM2.5 5.75V4.25H11.5V5.75H2.5ZM0.25 2V0.5H13.75V2H0.25Z"
                fill={colour}
            />
        </svg>
    );
};

export default FilteredIconMU;
