import './scss/app.scss';
import { BrowserRouter } from 'react-router-dom';
import RootRoutes from './routes/RootRoutes';
import ApplyInterceptor from './ApplyInterceptor';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { ProductFruits } from 'react-product-fruits';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { setIp } from './store/user/actions';
import { Mixpanel } from './utils/mixpanel';
import Bowser from 'bowser';
import * as Sentry from '@sentry/react';

let is_prod = process.env.REACT_APP_ENVIRONMENT === 'production';
let is_staging = process.env.REACT_APP_ENVIRONMENT === 'staging';
let product_fruit_code = process.env.REACT_APP_PRODUCT_FRUIT_CODE;

function App() {
    const dispatch = useAppDispatch();
    const investorName = useAppSelector((state) => state.user.domainMetadata?.orgName);
    const userId = useAppSelector((state) => state.user.userData.userId);

    useEffect(() => {
        fetchIpData();
    }, []);

    const fetchIpData = () => {
        const url = process.env.REACT_APP_API_URL || '';
        url &&
            fetch(url)
                .then((response) => {
                    return response.json();
                })
                .then((res) => {
                    const headers = JSON.parse(res?.headers || '{}');
                    headers?.['x-forwarded-for'] && dispatch(setIp(headers?.['x-forwarded-for']));

                    const browser = Bowser.getParser(window.navigator.userAgent);
                    Mixpanel.register({
                        ip: headers?.['x-forwarded-for'],
                        OperatingSystem: browser.getOSName(),
                        OperatingSystem_Version: browser.getOSVersion(),
                        Browser: browser.getBrowserName(),
                        Browser_Version: browser.getBrowserVersion(),
                    });
                })
                .catch((err) => console.error('Problem fetching IP Address', err));
    };

    const userInfo = {
        username: userId || 'logged_out_user',
    };

    return (
        <div className="App">
            <Helmet>
                <title>{investorName ?? 'AICA'}</title>
            </Helmet>
            <BrowserRouter>
                <ApplyInterceptor />
                <RootRoutes />
            </BrowserRouter>
            {!!product_fruit_code && !!userId && is_prod && (
                <ProductFruits workspaceCode={product_fruit_code} language="en" user={userInfo} />
            )}
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    );
}

export default Sentry.withProfiler(App);
