import React from 'react';
import styles from './ProbabilityBar.module.scss';

function ProbabilityBar({ probability = '0' }: { probability: string }) {
    var probabilityValueForBar = parseFloat(probability) || 0;
    probabilityValueForBar = probabilityValueForBar > 100 ? 100 : probabilityValueForBar;
    return (
        <div className={styles.Probability}>
            <div className={styles.Value}>{probability}%</div>

            <div className={styles.BarContainer}>
                <div
                    className={styles.BarFill}
                    style={{ width: `${probabilityValueForBar}%` }}
                ></div>
            </div>
        </div>
    );
}

export default ProbabilityBar;
