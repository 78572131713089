import axios from 'axios';
import { CustomRequest } from '../../../../../../../utils/axios-interceptor';

const GET = 'GET';
const POST = 'POST';

export const API_URL = process.env.REACT_APP_WEBUTILS_API_URL;
// export const API_URL = 'http://localhost:9000/utilities/rest';
export const GST_API_SECRET = 'gsts3cr3T';

export const initiateGstOtp = (data: any, headers: any) => {
    const axiosPayload: CustomRequest = {
        method: POST,
        url: `${API_URL}/gst/initiateOtp`,
        data: data,
        headers: headers,
        isAuthRequired: true,
    };
    return axios(axiosPayload);
};

export const enableAPIAccess = (data: any, headers: any) => {
    const axiosPayload: CustomRequest = {
        method: POST,
        url: `${API_URL}/gst/enableApiAccess`,
        data: data,
        headers: headers,
        isAuthRequired: true,
    };
    return axios(axiosPayload);
};

export const submitGstOtp = (data: any, headers: any) => {
    const axiosPayload: CustomRequest = {
        method: POST,
        url: `${API_URL}/gst/submitOtp`,
        data: data,
        headers: headers,
        isAuthRequired: true,
    };
    return axios(axiosPayload);
};
