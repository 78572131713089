import { Tab } from '../../../common/TabSelector/TabSelector';
import { defaultColors } from '../graphColorData';
import { chartInitData } from '../transformer';
import { graphDataType } from '../types';
import CONSTANTS from './constants';
import { CHART_COLORS } from './creditBureau_graphColorData';
import {
    CMRCreditSummaryData,
    CreditHighlightsData,
    CreditSummaryOfKeyOfficialsData,
} from './types';

interface CibilOverview {
    totalActiveAccounts: number | null;
    totalSanctionedAmount: number | null;
    creditUtilisation: number | null;
    enquiriesInPast90Days: number | null;
    noOfDpdAccounts: number | null;
    totalOverdueAmount: number | null;
    highestDpdRecorded: number | null;
    creditScoreMin: number | null;
    creditScoreMax: number | null;
    riskCategory: string | null;
    noOfOfficials: number | null;
    totalDpdsRecorded: number | null;
}

interface DirectorsOverviewResponse {
    name: string | null;
    totalAccounts: number | null;
    dpdAccounts: number | null;
    currentOverdue: number | null;
    maxDpds: number | null;
    cibilScore: number | null;
    directorId: string | null;
}
interface CreditBureauReducer {
    isLoading: boolean;
    loadingDirectorsOverviewList: boolean;
    mainData: {
        cmrCreditSummary: null | CMRCreditSummaryData;
        listDirectorsOverview: DirectorsOverviewResponse[];
        creditHighlights: null | CreditHighlightsData;
        chargeDetails: any;
    };
    charts: {} | graphDataType[];
    cibilOverviewData: CibilOverview | null;
}

const inititalState: CreditBureauReducer = {
    isLoading: false,
    loadingDirectorsOverviewList: false,
    mainData: {
        cmrCreditSummary: null,
        listDirectorsOverview: [],
        creditHighlights: null,
        chargeDetails: null,
    },
    charts: {},
    cibilOverviewData: null,
};

const initializeChartsData = (state, action) => {
    let chartsEnum = {};

    action.payload?.chartPanel?.sections?.forEach((section) => {
        section?.cards?.forEach((chart) => {
            const colors = CHART_COLORS?.[chart] || defaultColors;
            chartsEnum[chart] = { ...chartInitData, colors };
        });
    });

    return {
        ...state,
        charts: chartsEnum,
    };
};

const fetchChargesDataSuccess = (state, action) => {
    return {
        ...state,
        mainData: {
            ...state?.mainData,
            chargeDetails: action?.payload,
        },
    };
};

const fetchChartDataSuccess = (state, action) => {
    const graphName = action.payload.graphName;

    return {
        ...state,
        charts: {
            ...state.charts,
            [graphName]: {
                ...state?.charts?.[graphName],
                isLoading: false,
                isFetched: true,
                ...action.payload.data,
            },
        },
    };
};

const fetchChartDataError = (state, action) => {
    const graphName = action?.payload?.graphNames?.[0];
    return {
        ...state,
        charts: {
            ...state.charts,
            [graphName]: {
                ...state.charts?.graphName,
                isLoading: false,
            },
        },
    };
};

const fetchCibilOverviewSuccess = (state, action) => {
    return {
        ...state,
        cibilOverviewData: action?.payload?.cibilOverviewData,
    };
};

const setLoadingDirectorOverview = (state, action) => {
    return {
        ...state,
        loadingDirectorsOverviewList: action.payload,
    };
};

const fetchDirectorsOverviewSuccess = (state, action) => {
    return {
        ...state,
        loadingDirectorsOverviewList: false,
        mainData: {
            ...state?.mainData,
            listDirectorsOverview: action?.payload?.listDirectorsOverview || [],
        },
    };
};

const reducer = (state: CreditBureauReducer = inititalState, action: any): CreditBureauReducer => {
    switch (action.type) {
        case CONSTANTS.INITIALIZE_CHARTS_DATA:
            return initializeChartsData(state, action);
        case CONSTANTS.FETCH_CHART_DATA_SUCCESS:
            return fetchChartDataSuccess(state, action);
        case CONSTANTS.FETCH_CHART_DATA_ERROR:
            return fetchChartDataError(state, action);
        case CONSTANTS.FETCH_CIBIL_OVERVIEW_SUCCESS:
            return fetchCibilOverviewSuccess(state, action);

        case CONSTANTS.SET_LOADING_DIRECTOR_OVERVIEW:
            return setLoadingDirectorOverview(state, action);
        case CONSTANTS.FETCH_DIRECTOR_OVERVIEW_SUCCESS:
            return fetchDirectorsOverviewSuccess(state, action);
        case CONSTANTS.FETCH_CHARGE_DETAILS_SUCCESS:
            return fetchChargesDataSuccess(state, action);
        default:
            return state;
    }
};

export default reducer;
