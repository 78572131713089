import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getAllDocs } from './MagicUploadHelper';
import { useAppSelector } from '../../app/hooks';

const useMagicUploadLogic = () => {
    const [params, setParams] = useSearchParams();
    const [isVisible, setIsVisible] = useState(false);
    const [tabState, setTabState] = useState<'new' | 'tagged' | 'all'>('all');

    useEffect(() => {
        if (params.get('magicUpload') === 'true') {
            setIsVisible(true);
        }
    }, [params]);

    const toggleDrawer = () => {
        setIsVisible(false);
        setTimeout(() => {
            params.delete('magicUpload');
            params.delete('isCompany');
            setParams(params);
        }, 500);
    };

    return {
        isVisible,
        toggleDrawer,
        setIsVisible,
        tabState,
        setTabState,
    };
};

export default useMagicUploadLogic;
