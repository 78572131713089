import { CHART_NAMES } from './constants';

export const CHART_COLORS = {
    [CHART_NAMES.REPAYMENTS_OVERVIEW]: {
        primary: '#900004',
        secondary: '#EEE',
        tertiary: '',
        fourth: '',
        fifth: '',
    },
    [CHART_NAMES.REPAYMENTS_OVERVIEW_HISTORY]: {
        primary: '#ED6E72',
        secondary: '',
        tertiary: '',
        fourth: '',
        fifth: '',
    },
    [CHART_NAMES.ACCOUNTS_WITH_DELAYS]: {
        primary: '#EEE',
        secondary: '#ED6E72',
        tertiary: '',
        fourth: '',
        fifth: '',
    },
    [CHART_NAMES.DPDS_BIFURCATION]: {
        primary: '#ED6E72',
        secondary: '#EEE',
        tertiary: '',
        fourth: '',
        fifth: '',
    },
    [CHART_NAMES.DPDS_BIFURCATION_BY_INSTITUTION]: {
        primary: '#ED6E72',
        secondary: '#EEE',
        tertiary: '',
        fourth: '',
        fifth: '',
    },
    [CHART_NAMES.ALL_DPDS]: {
        primary: '',
        secondary: '',
        tertiary: '',
        fourth: '',
        fifth: '',
    },
    [CHART_NAMES.PRINCIPLE_OUTSTANDING]: {
        primary: '#900004',
        secondary: '#EEE',
        tertiary: '#939393',
        fourth: '',
        fifth: '',
    },
    [CHART_NAMES.NEW_ACCOUNTS_OPENED]: {
        primary: '#000',
        secondary: '#97E0FC',
        tertiary: '#2F8FFF',
        fourth: '#0057BC',
        fifth: '#92B7FF',
    },
    [CHART_NAMES.ACCOUNTS_AND_ENQUIRIES]: {
        primary: '#0057BC',
        secondary: '#EEE',
        tertiary: '',
        fourth: '',
        fifth: '',
    },
    [CHART_NAMES.ALL_ACCOUNTS_BY_LOAN_SIZE]: {
        primary: '#EEE',
        secondary: '#0057BC',
        tertiary: '',
        fourth: '',
        fifth: '',
    },
    [CHART_NAMES.ALL_ACCOUNTS_BY_LOAN_TYPE]: {
        primary: '#EEE',
        secondary: '#0057BC',
        tertiary: '',
        fourth: '',
        fifth: '',
    },
    [CHART_NAMES.ALL_ACCOUNTS_TOP_FIVE_LENDERS]: {
        primary: '#0057BC',
        secondary: '#EEE',
        tertiary: '',
        fourth: '',
        fifth: '',
    },
    [CHART_NAMES.ALL_ACCOUNTS_TENURE]: {
        primary: '#0057BC',
        secondary: '#EEE',
        tertiary: '',
        fourth: '',
        fifth: '',
    },
    [CHART_NAMES.PROJECTED_REPAYMENTS]: {
        primary: '#C69AFF',
        secondary: '#5B20A9',
        tertiary: '',
        fourth: '',
        fifth: '',
    },
    [CHART_NAMES.OWNERSHIP_TYPE]: {
        primary: '',
        secondary: '',
        tertiary: '',
        fourth: '',
        fifth: '',
    },
    [CHART_NAMES.TYPE_OF_INSTITUTION]: {
        primary: '',
        secondary: '',
        tertiary: '',
        fourth: '',
        fifth: '',
    },
};
