import { REPORT_NAVIGATION, REPORT_TAB_STATES } from '../../TabConstants';
import clsx from 'clsx';
import styles from './ReportNavigation.module.scss';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import greyLock from '../../../../../../assets/grey-lock-2.svg';
import { Mixpanel } from '../../../../../../utils/mixpanel';
import { AICA_DATA_ROOM_OPENED } from '../../../../../../utils/constants/mixpanelEvents/investorEvents';
import { useAppSelector } from '../../../../../../app/hooks';
import { USER_TYPE } from '../../../../../../utils/constants/user';
import { ACCESS_TIERS } from '../../../../../../store/investor/constants';

const REPORT_SECTIONS = {
    'debt-products': 'debtProducts',
    portfolio: 'aicaReport',
    'data-room': 'dataRoom',
};

const ReportNavigation = ({ selectedCompany }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    const [searchParams, setSearchParams] = useSearchParams();
    const [tabHovered, setTabHovered] = useState<string>('');
    const [tabSelected, setTabSelected] = useState<string>('');

    const dashboardConfig = useAppSelector((state) => state.dashboard.dashboardReducer.config);
    const reportTabs = dashboardConfig?.additionalDetailsConfig?.companyTabs;

    const companyName = useAppSelector((state) => state.investor.selectedCompany)?.orgName;
    const user = useAppSelector((state) => state.user.userData);
    const userEmail = user.primaryEmail;
    const userName = user.userName;
    const isPartner = user?.accountType === USER_TYPE.PARTNER;
    const filteredTabs = isPartner
        ? reportTabs?.filter((tab) => tab.id === 'dataRoom')
        : reportTabs;
    const tabsWithLockStatusUpdate = filteredTabs?.map((tab) => ({
        ...tab,
        state:
            tab.id === 'dataRoom' && selectedCompany?.companyAccessType === ACCESS_TIERS.WATCH
                ? 'LOCKED'
                : tab?.state,
    }));

    const currentReportSection = location.pathname?.split('/')?.[3];

    useEffect(() => {
        //* This is for preventing unauthorised access to report tabs
        if (currentReportSection && tabsWithLockStatusUpdate) {
            if (
                !(
                    tabsWithLockStatusUpdate?.find(
                        (tab) => tab.id === REPORT_SECTIONS[currentReportSection],
                    )?.state === 'UNLOCKED'
                )
            )
                navigate(-1);
        }
    }, [currentReportSection, tabsWithLockStatusUpdate]);

    useEffect(() => {
        if (searchParams.get('tab')) {
            setTabSelected(searchParams.get('tab') as string);
        } else {
            const newSearchParams = new URLSearchParams(searchParams.toString());
            newSearchParams.set('tab', 'aicaReport');
            setSearchParams(newSearchParams);
        }
    }, [searchParams]);

    const trackMixpanel = () => {
        Mixpanel.track(AICA_DATA_ROOM_OPENED, {
            userEmail,
            companyName,
            userName,
        });
    };

    return (
        <>
            {tabsWithLockStatusUpdate?.map(
                (tab) =>
                    tab.state !== REPORT_TAB_STATES.HIDDEN && (
                        <div
                            key={tab?.id}
                            className={clsx({
                                [styles.TabContainer]: true,
                                [styles.TabContainerHovered]:
                                    tab.id === tabHovered &&
                                    tab?.state === REPORT_TAB_STATES.UNLOCKED,
                                [styles.TabContainerSelected]: tab?.id === tabSelected,
                                [styles.DisabledTab]: tab?.state === REPORT_TAB_STATES.LOCKED,
                            })}
                            onClick={() => {
                                if (tab?.state === REPORT_TAB_STATES.LOCKED) return;
                                if (tab?.id === 'dataRoom') trackMixpanel();
                                setTabSelected(tab?.id);
                                const newSearchParams = new URLSearchParams(
                                    searchParams.toString(),
                                );
                                newSearchParams.set('tab', tab?.id);

                                navigate(
                                    `${REPORT_NAVIGATION?.[tab?.id]}/${
                                        params.id ?? ''
                                    }?${newSearchParams.toString()}`,
                                    {
                                        state: location.state,
                                    },
                                );
                            }}
                            onMouseEnter={() => setTabHovered(tab?.id)}
                            onMouseLeave={() => setTabHovered('')}
                        >
                            <div
                                className={clsx({
                                    [styles.TabTextContainerHovered]:
                                        tab?.id === tabHovered &&
                                        tab?.state === REPORT_TAB_STATES.UNLOCKED,
                                    [styles.TabTextContainerSelected]: tab?.id === tabSelected,
                                    [styles.TabTextContainer]: true,
                                })}
                            >
                                <img
                                    src={
                                        tab.id === tabSelected ||
                                        (tab.id === tabHovered &&
                                            tab?.state === REPORT_TAB_STATES.UNLOCKED)
                                            ? tab?.selectedIcon
                                            : tab?.icon
                                    }
                                    alt={tab?.title}
                                />
                                <span>{tab?.title}</span>
                                {tab?.state === REPORT_TAB_STATES.LOCKED && (
                                    <img src={greyLock} alt="lock" height="20px" width="20px" />
                                )}
                            </div>
                        </div>
                    ),
            )}
        </>
    );
};

export default ReportNavigation;
