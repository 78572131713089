import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import './GstOtp.scss';
import { useGSTContext } from '../../Context/GstContext';

const GstOtp = () => {
    const { otp, setOtp, otpBtnDisable, handleSubmitOtp, gstData } = useGSTContext();
    const handlePaste = (elmnt) => {
        const val = elmnt.clipboardData.getData('text');
        const otpArray = val.trim().split('');

        //to set focus
        const focusOn = otpArray.length < 6 ? otpArray.length : 5;

        // Ensure otpArray always contains 6 values
        while (otpArray.length < 6) {
            otpArray.push(' ');
        }

        const updatedOtp = {};
        otpArray.slice(0, 6).map((value, index) => {
            updatedOtp[`otp${index + 1}`] = value;
        });
        setOtp(updatedOtp);
        elmnt.target.form.elements[focusOn].focus();
    };

    const handleKeyDown = (elmnt) => {
        const tabIndex = elmnt.target.tabIndex;
        const NULL_VALUE = elmnt.target.value ? false : true;
        const CURSOR_AT_START = elmnt.target.selectionStart === 0 ? true : false;
        let focusOn = -1;

        // check for keys
        switch (elmnt.key) {
            case 'Delete':
            case 'Backspace':
                if (NULL_VALUE) focusOn = tabIndex - 2;
                break;
            case 'ArrowLeft':
                if (CURSOR_AT_START || NULL_VALUE) focusOn = tabIndex - 2;
                break;
            case 'ArrowRight':
                if (!CURSOR_AT_START || NULL_VALUE) focusOn = tabIndex;
                break;
            default:
        }

        // check for numbers
        if (elmnt.key >= 0 && elmnt.key <= 9 && !NULL_VALUE) {
            let tempOtp = otp;
            // replace already existing value with new one
            tempOtp[`otp${tabIndex}`] = elmnt.key;
            setOtp(tempOtp);
            elmnt.target.value = elmnt.key;
        }

        //setting focus to corresponding input box
        if (0 <= focusOn && focusOn < 6) {
            elmnt.target.form.elements[focusOn].focus();
        }
    };

    const inputfocus = (elmnt) => {
        const focusOn = elmnt.target.tabIndex;
        if (elmnt.key >= 0 && elmnt.key <= 9) {
            if (focusOn < 6) elmnt.target.form.elements[focusOn].focus();
        }
    };

    return (
        <>
            <DialogContent
                sx={{
                    width: '330px',
                    maxWidth: '100%',
                    margin: '0 0 70px 0',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        margin: '0 0 30px 0',
                    }}
                >
                    <Typography
                        color={'#000000'}
                        fontWeight={'600'}
                        fontSize={'14px'}
                        fontFamily={'gilroy-medium, sans-serif'}
                    >
                        {gstData?.gstin_number}
                    </Typography>
                    <Typography
                        color={'#AEAEAE'}
                        fontSize={'14px'}
                        fontWeight={500}
                        fontFamily={'gilroy'}
                    >
                        {gstData?.state}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography
                        fontSize={'15px'}
                        fontWeight={500}
                        fontFamily={'gilroy-medium, sans-serif'}
                        lineHeight={'14px'}
                        letterSpacing={'0'}
                        color="#000000"
                    >
                        OTP sent to{' '}
                        <Typography
                            component={'span'}
                            fontWeight={700}
                            fontSize={'inherit'}
                            fontFamily={'inherit'}
                            sx={{ marginRight: '5px' }}
                        >
                            mobile number registered with GST
                        </Typography>
                    </Typography>
                </Box>
                <Box>
                    <form>
                        <div className="form-group">
                            <div className="gstotpContainer">
                                <input
                                    name="otp1"
                                    type="text"
                                    autoComplete="off"
                                    className="gstotpInput"
                                    value={otp.otp1}
                                    onChange={(e) => {
                                        if (!isNaN(e.target.value.trim())) {
                                            setOtp((prev) => {
                                                return {
                                                    ...prev,
                                                    otp1: e.target.value,
                                                };
                                            });
                                        }
                                    }}
                                    tabIndex="1"
                                    maxLength="1"
                                    onPaste={(e) => handlePaste(e)}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                    onKeyUp={(e) => inputfocus(e)}
                                />
                                <input
                                    name="otp2"
                                    type="text"
                                    autoComplete="off"
                                    className="gstotpInput"
                                    value={otp.otp2}
                                    onChange={(e) => {
                                        if (!isNaN(e.target.value.trim())) {
                                            setOtp((prev) => {
                                                return {
                                                    ...prev,
                                                    otp2: e.target.value,
                                                };
                                            });
                                        }
                                    }}
                                    tabIndex="2"
                                    maxLength="1"
                                    onPaste={(e) => handlePaste(e)}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                    onKeyUp={(e) => inputfocus(e)}
                                />
                                <input
                                    name="otp3"
                                    type="text"
                                    autoComplete="off"
                                    className="gstotpInput"
                                    value={otp.otp3}
                                    onChange={(e) => {
                                        if (!isNaN(e.target.value.trim())) {
                                            setOtp((prev) => {
                                                return {
                                                    ...prev,
                                                    otp3: e.target.value,
                                                };
                                            });
                                        }
                                    }}
                                    tabIndex="3"
                                    maxLength="1"
                                    onPaste={(e) => handlePaste(e)}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                    onKeyUp={(e) => inputfocus(e)}
                                />
                                <input
                                    name="otp4"
                                    type="text"
                                    autoComplete="off"
                                    className="gstotpInput"
                                    value={otp.otp4}
                                    onChange={(e) => {
                                        if (!isNaN(e.target.value.trim())) {
                                            setOtp((prev) => {
                                                return {
                                                    ...prev,
                                                    otp4: e.target.value,
                                                };
                                            });
                                        }
                                    }}
                                    tabIndex="4"
                                    maxLength="1"
                                    onPaste={(e) => handlePaste(e)}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                    onKeyUp={(e) => inputfocus(e)}
                                />
                                <input
                                    name="otp5"
                                    type="text"
                                    autoComplete="off"
                                    className="gstotpInput"
                                    value={otp.otp5}
                                    onChange={(e) => {
                                        if (!isNaN(e.target.value.trim())) {
                                            setOtp((prev) => {
                                                return {
                                                    ...prev,
                                                    otp5: e.target.value,
                                                };
                                            });
                                        }
                                    }}
                                    tabIndex="5"
                                    maxLength="1"
                                    onPaste={(e) => handlePaste(e)}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                    onKeyUp={(e) => inputfocus(e)}
                                />
                                <input
                                    name="otp6"
                                    type="text"
                                    autoComplete="off"
                                    className="gstotpInput"
                                    value={otp.otp6}
                                    onChange={(e) => {
                                        if (!isNaN(e.target.value.trim())) {
                                            setOtp((prev) => {
                                                return {
                                                    ...prev,
                                                    otp6: e.target.value,
                                                };
                                            });
                                        }
                                    }}
                                    tabIndex="6"
                                    maxLength="1"
                                    onPaste={(e) => handlePaste(e)}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                    onKeyUp={(e) => inputfocus(e)}
                                />
                            </div>
                        </div>
                    </form>
                </Box>
            </DialogContent>

            <DialogActions sx={{ p: '8px 16px 20px !important' }}>
                <Button
                    variant="contained"
                    onClick={handleSubmitOtp}
                    disabled={otpBtnDisable}
                    sx={{
                        backgroundColor: '#2F8FFF',
                        width: '100%',
                        height: '40px',
                        marginBottom: '8px',
                        color: '#FFFFFF',
                        fontFamily: 'gilroy-medium, sans-serif',
                        fontSize: '12px',
                        fontWeight: '600',
                        '&:disabled': {
                            backgroundColor: '#D8D8D8', // Set background color for disabled state
                            color: 'white', // Set text color for disabled state
                            cursor: 'not-allowed', // Change cursor for disabled state
                        },
                    }}
                >
                    Submit OTP
                </Button>
            </DialogActions>
        </>
    );
};

export default GstOtp;
