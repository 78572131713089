import styles from './CompanyKYC.module.scss';
import UploadCard from '../UploadCard/UploadCard';
import { FILE_FORMATS } from '../UploadCard/utils/utils';
import { Mixpanel } from '../../../../../utils/mixpanel';
import { COMPANY_DOCS_UPLOAD_ADDITIONAL_CLICK } from '../../../../../utils/constants/mixpanelEvents/companyEvents';
import { useAppSelector } from '../../../../../app/hooks';
import { UPLOAD_COMPONENTS, UPLOAD_TABS } from '../../uploadJourneyUtils';
import { useEffect, useState } from 'react';
import useHandleKycDocs from './useHandleKycDocs';

type CompanyKYCProps = {
    assesseeOrgId: string;
    categoryId: string;
    flowId: number;
    constitutionType: string;
};

const CompanyKYC = (props: CompanyKYCProps) => {
    const configData = useAppSelector((state) => state.company.config);
    const [tabData, setTabData] = useState<any>([]);
    const { assesseeOrgId, categoryId, flowId, constitutionType } = props;
    const { updateKycDocs, kycDocumentList } = useHandleKycDocs();
    useEffect(() => {
        if (constitutionType && configData?.tabConfigMetadata) {
            let acceptedTabs = configData?.tabConfigMetadata?.[constitutionType]?.companyKyc;
            let filteredTabs = configData?.tabConfigs?.[
                UPLOAD_COMPONENTS.COMPANY_KYC
            ].components?.map((tab) => {
                return {
                    ...tab,
                    formFields: tab.formFields?.filter(
                        (field) => acceptedTabs?.includes(field.identifier),
                    ),
                };
            });
            setTabData(filteredTabs);
        } else {
            setTabData(configData?.tabConfigs[UPLOAD_COMPONENTS.COMPANY_KYC].components);
        }
    }, [constitutionType, configData]);
    return (
        <div className={styles.Box}>
            <h3 className={styles.Title}>Company KYC</h3>
            <p className={styles.SubTitle}>Add below required KYC documents</p>
            <div className={styles.Separator} />
            {tabData?.map((tab) => {
                return tab?.formFields?.map((field) => {
                    return (
                        <UploadCard
                            existingFiles={kycDocumentList}
                            assesseeOrgId={assesseeOrgId}
                            categoryId={Number(categoryId)}
                            kycCategory={field.label}
                            kycDescription={field.label}
                            flowId={flowId}
                            kycFileFlow
                            metadataUpdate={(res) => {
                                updateKycDocs(res, field, assesseeOrgId);
                            }}
                            kycCategoryId={field.id}
                            fileDeleteHandler={(file) => {
                                updateKycDocs(file, field, assesseeOrgId, true);
                            }}
                            acceptedFormats={[...FILE_FORMATS.EXCEL_PDF, ...FILE_FORMATS.JSON]}
                            onClickFunction={() => {
                                // Mixpanel.track(COMPANY_DOCS_UPLOAD_ADDITIONAL_CLICK);
                            }}
                        />
                    );
                });
            })}
        </div>
    );
};

export default CompanyKYC;
