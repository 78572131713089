import { EMAIL } from '../../utils/constants/companyLogin';
import { OTP, Button } from '../_custom';
import { useEffect, useRef, useState } from 'react';

import backArrow from '../../assets/black-arrow-back.svg';
import styles from './EnterOTPScreen.module.scss';
import { useAppSelector } from '../../app/hooks';

interface EnterOTPScreenProps {
    readonly otp: string;
    readonly otpReceiver?: string;
    readonly formData: any;
    readonly setOtp: Function;
    readonly onSubmit: Function;
    readonly onResend: Function;
    readonly onBackClick?: Function;
}

function EnterOTPScreen({
    otp,
    otpReceiver,
    setOtp,
    onSubmit,
    onResend,
    formData,
    onBackClick,
}: EnterOTPScreenProps) {
    const [resetOtp, setResetOtp] = useState(false);
    const otpRef = useRef(otp);
    const loggingIn = useAppSelector((state) => state.user.loggingIn);

    useEffect(() => {
        otpRef.current = otp;
    }, [otp]);

    useEffect(() => {
        window.addEventListener('keypress', handleKeyPress);
        return () => {
            window.removeEventListener('keypress', handleKeyPress);
        };
    }, []);

    const handleKeyPress = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            onSubmit(otpRef.current);
        }
    };

    const handleBackClick = () => {
        setOtp('');
        onBackClick?.();
    };

    const handleResend = () => {
        setResetOtp(true);
        onResend();
    };

    return (
        <div className={styles.CompanyOTP}>
            <div className={styles.Title}>
                <img src={backArrow} alt="back" height="20px" onClick={handleBackClick} />
                <span>OTP sent to &nbsp;{otpReceiver ?? formData[EMAIL]}</span>
            </div>

            <div className={styles.OtpBoxContainer}>
                <OTP setUserOtp={setOtp} resetOtp={resetOtp} setResetOtp={setResetOtp} />
            </div>

            <div className={styles.Buttons}>
                <Button text="Resend OTP" variant="purpleinverted" onClick={handleResend} />
                <Button
                    text="Submit"
                    onClick={() => onSubmit()}
                    variant={'primary'}
                    loading={loggingIn}
                />
            </div>
        </div>
    );
}

export default EnterOTPScreen;
