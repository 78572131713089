import React from 'react';
import isEmpty from 'lodash/isEmpty';

import { Button, Input, Modal } from '../../../../../../common/_custom';

import styles from '../DirectorKyc.module.scss';

type Props = {
    newDirectorDetails: { name: string; phone: string; errorMessage?: string };
    showAddDirector: boolean;
    closeAddDirectorModal: () => void;
    handleNewDirectorDetails: (key: string, value: string) => void;
    addDirector: () => void;
    isAddingDirector: boolean;
};

const AddDirectorModal: React.FC<Props> = ({
    newDirectorDetails,
    showAddDirector,
    closeAddDirectorModal,
    handleNewDirectorDetails,
    addDirector,
    isAddingDirector,
}) => {
    const isDisabled =
        isEmpty(newDirectorDetails?.name) || !isEmpty(newDirectorDetails?.errorMessage);

    return (
        <Modal open={showAddDirector} onClose={closeAddDirectorModal} size="flexible">
            <div className={styles.AddDirectorModal}>
                <h3 className={styles.AddDirectorHeading}>Add Director</h3>
                <Input
                    type="text"
                    placeholder="Director Name (Same as per PAN)"
                    value={newDirectorDetails.name}
                    onChange={(name) => handleNewDirectorDetails('name', name)}
                    style={{ marginTop: '0', marginBottom: '32px' }}
                />
                <Input
                    type="number"
                    placeholder="Director Phone Number (Optional)"
                    value={newDirectorDetails.phone}
                    onChange={(phone) => handleNewDirectorDetails('phone', phone)}
                    style={{ marginTop: '0', marginBottom: '32px' }}
                    maxLength={10}
                    errorMessage={newDirectorDetails.errorMessage}
                    isValid={isEmpty(newDirectorDetails?.errorMessage)}
                />
                <Button
                    text="Add Director"
                    variant="primary"
                    size="wide"
                    disabled={isDisabled}
                    onClick={addDirector}
                    loading={isAddingDirector}
                />
            </div>
        </Modal>
    );
};

export default AddDirectorModal;
