import React from 'react';

const CrossFilledIcon = ({ colour = '#E36414', onClick, className }) => {
    return (
        <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick}
            className={className}
        >
            <g clipPath="url(#clip0_3699_5957)">
                <path
                    d="M7 0C10.871 0 14 3.129 14 7C14 10.871 10.871 14 7 14C3.129 14 0 10.871 0 7C0 3.129 3.129 0 7 0ZM9.513 3.5L7 6.013L4.487 3.5L3.5 4.487L6.013 7L3.5 9.513L4.487 10.5L7 7.987L9.513 10.5L10.5 9.513L7.987 7L10.5 4.487L9.513 3.5Z"
                    fill={colour}
                />
            </g>
            <defs>
                <clipPath id="clip0_3699_5957">
                    <rect width="14" height="14" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default CrossFilledIcon;
